
import { useState, useEffect } from "react";

import { Link, useParams } from 'react-router-dom';

import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip, Table, Modal } from 'react-bootstrap';
import { sendRequest } from "../../utilities/api";
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { getToday } from "../../utilities";
import Swal from "sweetalert2";
import { countries } from "../../store/customData";


function Inbound(props) {
    const [id] = useState(props.id);
    const [meta, setMeta] = useState(props.meta);
    const [data, setData] = useState(props.data);
    const [currentTab, setCurrentTab] = useState('flow');
    const [loading, setLoading] = useState(false);
    const [businessFlow, setBusinessFlow] = useState(props.data.business_hour);
    const [nonBusinessFlow, setNonBusinessFlow] = useState(props.data.nonbusinesshour_flow);
    const [afterHourFlow, setAfterHourFlow] = useState(props.data.afterhour_callflow == 'Yes' ? true : false);
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        if (businessFlow.length < 1) {
            addBusiness();
        }

        if (nonBusinessFlow.length < 1) {
            addNonBusinessFlow();
        }
    }, [])

    const addBusiness = () => {
        setBusinessFlow(prevBusinessFlow => [
            ...prevBusinessFlow,
            { label: "Start", flowId: "", key: "Start" }
        ]);
    }

    const addNonBusinessFlow = () => {
        setNonBusinessFlow(prevBusinessFlow => [
            ...prevBusinessFlow,
            { label: "Start", flowId: "", key: "Start" }
        ]);
    }

    const renderBusinessUi = () => {
        return businessFlow.map((el, i) => {
            if (el.key) {
                return <div className={`border py-3 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_${i}`}>
                    <div className={`row`} >
                        <div className="col-md-2 mb-3">
                            <Form.Group className="form-group">
                                <Form.Control
                                    name={`business_hourarr[${i}][key]`}
                                    value={`${i == 0 ? 'Start' : 'Next'}`}
                                    placeholder="Enter label"
                                    className="form-controll"
                                    required
                                    readOnly
                                />

                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="form-group">
                                <Form.Select
                                    name={`business_hourarr[${i}][flowId]`}
                                    className="select form-control"
                                    onChange={(e) => handleChange(e, i)}
                                    defaultValue={businessFlow[i].flowId || ''}
                                    required
                                >
                                    <option value="">Select Call Flow</option>
                                    <optgroup label="IVR Options">
                                        <option value="Forward">Forward To</option>
                                        <option value="Voicemail">Voicemail</option>
                                        <option value="Greeting">Greeting</option>
                                        <option value="SMS">SMS</option>
                                        <option value="Connect_To_Agent">Connect To Agent</option>
                                        <option value="Unsubscribe">Unsubscribe</option>
                                        <option value="Hangup">Hangup/End Call</option>
                                    </optgroup>
                                    <optgroup label="My Call Flows">
                                        {
                                            meta.ivrFlows.map((row, i) => {
                                                return <option key={'ivr_v-' + i} value={row.id}>{row.ivr_type} - {row.ivr_title}</option>
                                            })
                                        }
                                    </optgroup>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        {addContent(businessFlow, 'business_hourarr', i, businessFlow[i].flowId)}

                        {
                            i != 0 && (
                                <div className="col-md-1" >
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => removeClick(i)}
                                    >
                                        <Icon name={'trash'} />
                                    </button>
                                </div>
                            )
                        }

                    </div>
                </div>
            }
        })
    }

    const handleChange = (e, i) => {
        const { name, value } = e.target;
        const updatedBusinessFlow = [...businessFlow];
        updatedBusinessFlow[i] = { ...updatedBusinessFlow[i], [name]: value };
        updatedBusinessFlow[i] = { ...updatedBusinessFlow[i], flowId: value };
        setBusinessFlow(updatedBusinessFlow);
    };

    const removeClick = (i) => {
        const updatedBusinessFlow = [...businessFlow];
        updatedBusinessFlow[i] = {};
        setBusinessFlow(updatedBusinessFlow);
    };

    const renderNonBusinessUi = () => {
        return nonBusinessFlow.map((el, i) => {
            if (el.key) {
                return <div className={`border py-3 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_after_${i}`}>
                    <div className="row">
                        <div className="col-md-2 mb-3">
                            <Form.Group className="form-group">
                                <Form.Control
                                    name={`business_after_hourarr[${i}][key]`}
                                    value={`${i == 0 ? 'Start' : 'Next'}`}
                                    placeholder="Enter label"
                                    className="form-controll"
                                    required
                                    readOnly
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="form-group">
                                <Form.Select
                                    name={`business_after_hourarr[${i}][flowId]`}
                                    className="select form-control"
                                    onChange={(e) => handleChangeNonHour(e, i)}
                                    defaultValue={nonBusinessFlow[i].flowId || ''}
                                    required
                                >
                                    <option value="">Select Call Flow</option>
                                    <optgroup label="IVR Options">
                                        <option value="Forward">Forward To</option>
                                        <option value="Voicemail">Voicemail</option>
                                        <option value="Greeting">Greeting</option>
                                        <option value="SMS">SMS</option>
                                        <option value="Connect_To_Agent">Connect To Agent</option>
                                        <option value="Unsubscribe">Unsubscribe</option>
                                        <option value="Hangup">Hangup/End Call</option>
                                    </optgroup>
                                    <optgroup label="My Call Flows">
                                        {
                                            meta.ivrFlows.map((row, i) => {
                                                return <option key={'ivr_v-' + i} value={row.id}>{row.ivr_type} - {row.ivr_title}</option>
                                            })
                                        }
                                    </optgroup>
                                </Form.Select>
                            </Form.Group>
                        </div>

                        {addContent(nonBusinessFlow, 'business_after_hourarr', i, nonBusinessFlow[i].flowId)}

                        {
                            i != 0 && (
                                <div className="col-md-1" >
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => removeClickeNonHour(i)}
                                    >
                                        <Icon name={'trash'} />
                                    </button>
                                </div>
                            )
                        }

                    </div>
                </div>
            }
        })
    }

    const handleChangeNonHour = (e, i) => {
        const { name, value } = e.target;
        const updatedNonBusinessFlow = [...nonBusinessFlow];
        updatedNonBusinessFlow[i] = { ...updatedNonBusinessFlow[i], [name]: value };
        updatedNonBusinessFlow[i] = { ...updatedNonBusinessFlow[i], flowId: value };
        setNonBusinessFlow(updatedNonBusinessFlow);
    };

    const removeClickeNonHour = (i) => {
        const updatedNonBusinessFlow = [...nonBusinessFlow];
        updatedNonBusinessFlow[i] = {};
        setNonBusinessFlow(updatedNonBusinessFlow);
    };

    const addContent = (flow, name_key, i, type) => {
        if (type == 'Forward') {
            return (
                <>
                    <div className="col-md-2">
                        <Form.Select
                            required defaultValue={flow[i].country_code} name={`${name_key}[${i}][country_code]`}
                            className="select form-control"

                        >
                            <option value="">Select Code</option>
                            {countries.map((row, i) => {
                                return (
                                    <option key={'code' + i} value={`${row.code}`}>
                                        {row.code}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                    <div className="col-md-2">
                        <Form.Control
                            name={`${name_key}[${i}][dial]`}
                            defaultValue={flow[i].dial}
                            className="form-controll"
                            required
                            maxLength="10"
                        />
                    </div>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].caller_id} name={`${name_key}[${i}][caller_id]`}
                            className="select form-control"

                        >
                            <option value="default">Caller ID of the caller</option>
                            <option value="any">Caller ID of the campaign</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-2" >
                        <Form.Control
                            name={`${name_key}[${i}][call_time_out]`}
                            defaultValue={flow[i].call_time_out}
                            className="form-controll"
                            placeholder="Sec(s)"
                            required
                            pattern="\d*"
                            maxLength="2"
                            max="60"
                        />
                    </div>
                </>
            )
        } else if (type == 'Voicemail') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].voicemail} name={`${name_key}[${i}][voicemail]`}
                            className="select form-control"

                        >
                            <option value="">Select Voicemail</option>
                            {
                                meta.voiceTemplates.map((row, i) => {
                                    return <option key={'voice_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'Greeting') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].greeting} name={`${name_key}[${i}][greeting]`}
                            className="select form-control"

                        >
                            <option value="">Select Greeting</option>
                            {
                                meta.voiceTemplates.map((row, i) => {
                                    return <option key={'greeting_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'SMS') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].sms} name={`${name_key}[${i}][sms]`}
                            className="select form-control"

                        >
                            <option value="">Select SMS</option>
                            {
                                meta.smsTemplates.map((row, i) => {
                                    return <option key={'sms_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'Connect_To_Agent') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].callergroupId} name={`${name_key}[${i}][callergroupId]`}
                            className="select form-control"

                        >
                            <option value="">Select Agent</option>
                            {
                                meta.callerGroup.map((row, i) => {
                                    return <option key={'agent' + i} value={row.id}>{row.name}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        }
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('default_flow', meta.defaultFlow);
        submitData.append('step', 4);
        sendRequest(`campaign/${id}/update`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire('Success', response.message, 'success')
                }
                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    const fetchIvrFlow = async (event) => {
        sendRequest(`campaign/${id}/ivrflow`, 'POST', [])
            .then(response => {
                if (response.status === true) {
                    setMeta((prevState) => ({
                        ...prevState,
                        ivrFlows: response.data.records,
                    }))
                }
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    const handleSubmitIvr = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`campaign/${id}/ivrflow/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchIvrFlow();
                }
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    return (
        <div>
            <div className="bio-block">
                <Tab.Container onSelect={(e) => setCurrentTab(e)} id="custom-tabs-example" defaultActiveKey={currentTab}>
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                        <Nav.Item>
                            <Nav.Link eventKey="flow"> Inbound Flow</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ivr">Manage IVR Menus</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="flow">
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md={2}>
                                        <div className="d-flex">
                                            Campaign Hours Flow: {' '}
                                            <button
                                                type="button"
                                                className="btn btn-link btn-sm fs-6"
                                                onClick={() => addBusiness()}>
                                                <Icon name={'plus'} />
                                            </button>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        {renderBusinessUi()}
                                    </Col>

                                    <Form.Check defaultChecked={afterHourFlow} defaultValue={'Yes'} name="afterhour_callflow" onChange={(e) => setAfterHourFlow(e.target.checked)} type="checkbox" id="afterhour_callflow" label="After Hour Call Flow" />
                                    {
                                        afterHourFlow && (
                                            <>
                                                <Col md={2}>
                                                    Non Campaign Hours Flow: {' '}
                                                    <button
                                                        type="button"
                                                        className="btn btn-link btn-sm"
                                                        onClick={() => addNonBusinessFlow()}>
                                                        <Icon name={'plus'} />
                                                    </button>
                                                </Col>
                                                <Col md={10}>
                                                    {renderNonBusinessUi()}
                                                </Col>
                                            </>
                                        )
                                    }
                                    <Col className="col-12">
                                        <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                            {
                                                loading && (
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                )
                                            }
                                            <span className="ms-1">Update</span>
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ivr">
                            <div className="text-end">
                                <Button size="sm" onClick={handleShowModal}>Build New IVR</Button>
                            </div>
                            <Table responsive className="small">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        meta.ivrFlows.map((row, i) => {
                                            return (
                                                <tr>
                                                    <td>{row.public_id}</td>
                                                    <td>{row.ivr_title}</td>
                                                    <td>{row.ivr_type}</td>
                                                    <td>
                                                        <Link to={`ivr/update/${row.public_id}`} ><Icon name={'edit'} /></Link>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add User</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={handleSubmitIvr}>
                                        <Row className="g-3">
                                            <Col lg="12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="ivr_title">Ivr Title</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name="ivr_title" id="ivr_title" placeholder="Ivr Title" />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col lg="12">
                                                <div className="d-flex gap g-2">
                                                    <div className="gap-col">
                                                        <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                                            {
                                                                loading && (
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                )
                                                            }
                                                            <span className="">Add</span>
                                                        </Button>

                                                    </div>
                                                    <div className="gap-col">
                                                        <button type="button" className="border-0 btn btn-sm" onClick={handleCloseModal}>Discard</button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};


export default Inbound;