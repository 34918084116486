import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import { capitalizeFirstLetter } from '../../utilities';
function ActivityLogs() {
    const { type } = useParams();
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`packages/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Full Name",
            key: 'full_name',
            selector: (row) => row.full_name,
            cell: (row) => (
                <span>{row.full_name}</span>
            ),
        },
        {
            name: "Email",
            key: 'email',
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
        },
        {
            name: "Type",
            key: 'type',
            selector: (row) => row.type,
            cell: (row) => (
                <span>{row.type}</span>
            ),
        },
        {
            name: "Operation",
            key: 'operation',
            selector: (row) => row.operation,
            cell: (row) => (
                <span>{row.operation}</span>
            ),
        },
        {
            name: "Ip Address",
            key: 'ip_address',
            selector: (row) => row.ip_address,
            cell: (row) => (
                <span>{row.ip_address}</span>
            ),
        },


        {
            name: "Dated",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },

    ];

    return (
        <Layout title="Logs" content="container">
            <div className="groups">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Logs</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/report-manage/${type}/logs`}>Logs</Link></li>
                                    {/* <li className="breadcrumb-item active" aria-current="page"></li> */}
                                </ol>
                            </nav>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {
                                        customDateFilter && (
                                            <>
                                                <Col>
                                                    <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>

                                                <Col>
                                                    <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>
                                            </>
                                        )
                                    }

                                    <Col>
                                        <Form.Select size="sm" name="status" >
                                            <option value="">All </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>

                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive noWordBreak"
                                slug={`logs/${type}`}
                            />
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default ActivityLogs;
