import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import Swal from 'sweetalert2';
function RoleList() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete this`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`roles/${id}`, 'DELETE', { id: id })
                    .then(response => {
                        if (childRef.current) {
                            childRef.current.handleFilter(new FormData());
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });

    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Name",
            key: 'name',

            selector: (row) => row.name,
            cell: (row) => (
                <span>
                    {
                        hasPermission('editRole') ? (
                            <Link to={`/role-manage/edit/${row.public_id}`}>
                                {row.name}
                            </Link>
                        ) : (
                            row.name
                        )
                    }


                </span>
            ),
        },
        {
            name: "Description",
            key: 'description',
            selector: (row) => row.description,
            cell: (row) => (
                <span>{row.description}</span>
            ),
        },
        {
            name: <div className='w-100 text-center'>Status</div>,
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Pending" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },
        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">
                    <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
                        {
                            hasPermission('deleteRole') && (
                                <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                    <Icon name="trash" className='text-danger'></Icon>
                                    {/* <span>Delete</span> */}
                                </button>
                            )
                        }
                    </LinkList>
                    {/* <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="center">
                            <div className="dropdown-content py-1">
                                <LinkList className="link-list-hover-bg-primary link-list-md">
                                    {
                                        hasPermission('editRole') && (
                                            <LinkListItem to={`/role-manage/edit/${row.public_id}`}>
                                                <Icon name="edit"></Icon><span>Edit</span>
                                            </LinkListItem>
                                        )
                                    }

                                    {
                                        hasPermission('deleteRole') && (
                                            <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                                <Icon name="trash"></Icon><span>Delete</span>
                                            </button>
                                        )
                                    }
                                </LinkList>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            ),
        },

    ];

    return (
        <Layout title="Roles List" content="container-fluid">
            <div className="roles">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Role List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Roles</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                {
                                    hasPermission('createRole') && (

                                        <li>
                                            <Link className='btn btn-primary d-md-inline-flex btn-sm' to="/role-manage/create">
                                                <Icon name="plus" />
                                                <span>Add Role</span>
                                            </Link>

                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {
                                        customDateFilter && (
                                            <>
                                                <Col>
                                                    <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>

                                                <Col>
                                                    <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>
                                            </>
                                        )
                                    }
                                    <Col>
                                        <Form.Select size="sm" name="status" >
                                            <option value="">All </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5 btn-sm'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' className='btn-sm' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>

                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive"
                                slug={'roles/lists'}
                                extraCondition={{ 'type': 'default' }}
                            />
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default RoleList;
