import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function CalanderNav(props) {
    const { hasPermission } = useAuth();
    return (
        <div>
            <div className='btns'>

                {
                    hasPermission('viewCalendar') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '2' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/calendar-manage/view/MA=='}>View</Link>
                    )
                }
                {
                    hasPermission('viewCalendar') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '1' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/calendar-manage/lists'}>Manage</Link>
                    )
                }

                {
                    hasPermission('viewCalendarAppointment') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '3' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/calendar-manage/appointments/lists'}>List</Link>
                    )
                }

                {
                    hasPermission('viewCalendarAppointmentStatus') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '4' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/calendar-manage/appointments/status/lists'}>Status</Link>
                    )
                }

                {
                    hasPermission('viewCalendarsAutoResponder') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '5' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/calendar-manage/responder/lists'}>Auto Responder</Link>
                    )
                }

            </div>
        </div>
    );
}

export default CalanderNav;
