import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';

function DomainList() {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [master_domain, setMasterDomain] = useState('10xstr.com');
	const formRef = useRef(null);
	const childRef = useRef(null);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};


	const submitHandler = async (event) => {
		setLoading(false);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		submitData.append('master_domain', master_domain);
		let url = 'domains/create';
		if (data.public_id) {
			url = `domains/update/${data.public_id}`;
		}

		sendRequest(url, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					closeModal();
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
				setLoading(true);
			})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	};

	const openModal = (row = {}) => {
		if (!row.master_domain) {
			row.master_domain = '10xsecure.com';
		}
		setData(row);
		setTimeout(() => {
			setModal(true);
		}, 200);
	}

	const closeModal = () => {
		setData({});
		setModal(false);
	}


	const userColumns = [
		// {
		// 	name: "Id",
		// 	key: 'id',
		// 	selector: (row) => row.id,
		// 	cell: (row) => (
		// 		<span>{row.id}</span>
		// 	),
		// },

		{
			name: "Sub-domain",
			key: 'name',
			selector: (row) => row.name,
			cell: (row) => (

				hasPermission('viewDomains') ? (
					<>
						<Link to={`/domains-manage/view/${row.public_id}`}>
							<span>{row.name}</span>
						</Link>
						<a className='ms-1' target="_blank" href={`http://${row.name}`}> <em class="icon ni ni-link-alt"></em></a>
					</>
				) : (
					<>
						<span>{row.name}</span>
						<a target="_blank" href={`http://${row.name}`}> <em class="icon ni ni-link-alt"></em></a>
					</>
				)


			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},

		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},

		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">

							{
								hasPermission('editDomains') && (
									<button className="btn p-0" onClick={() => { openModal(row) }}>
										<Icon name="edit" ></Icon>
									</button>
								)
							}
						</LinkList>
					</div>
				</div>
			),
		},

	];

	return (
		<Layout title="Sub Domains List" content="container-fluid">
			<div className="domains">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Domain List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Domains</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								{
									hasPermission('createDomains') && (
										<li>
											<button className="btn btn-sm btn-primary" onClick={() => openModal()}>
												<Icon name="plus" />
												<span>Add Sub Domain</span>
											</button>
										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>
										)
									}

									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'domains/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>

			<Modal show={modal} onHide={() => closeModal()} size="lg">
				<Form onSubmit={submitHandler}>
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">{data.public_id ? 'Edit' : 'Add'} Sub Domains</Modal.Title>
					</Modal.Header>

					<Modal.Body >
						<Row>
							{!data.public_id && (
								<>
									<Col md="6">
										<Form.Group className="form-group mb-2">
											<Form.Label htmlFor="title">Name
												{" "}
												{data.name && (`http://${data.name.replace(/\s/g, "")}.${master_domain}`
												)}
											</Form.Label>
											<div className="form-control-wrap">
												<Form.Control onChange={(e) => setData({ ...data, name: (e.target.value.replace(/\s/g, '')) })} defaultValue={data.name} type="text" name="name" required />
											</div>
										</Form.Group>
									</Col>
									<Col md="4">
										<Form.Group className="form-group">
											<Form.Label htmlFor="master_id">Master Domain</Form.Label>
											<div className="form-control-wrap">
												<Form.Select defaultValue={data.master_id}
													onChange={(e) => {
														const selectedIndex = e.target.selectedIndex;
														const selectedOptionText = e.target.options[selectedIndex].text;
														setData({ ...data, master_id: e.target.value });
														data.name && setMasterDomain(selectedOptionText);
													}}
													name="master_id" className="form-controll">
													<option value="2">10xstr.com</option>
													<option value="3">10xrms.com</option>
													<option value="4">dtbsi.com</option>
												</Form.Select>
											</div>
										</Form.Group>
									</Col>

									<Col md="6">
										<Form.Group className="form-group mb-2">
											<Form.Label htmlFor="header_logo">Header Logo (Recommended Size:120 x 100)</Form.Label>
											<div className="form-control-wrap">
												<Form.Control type="file" name="header_logo" />
											</div>
										</Form.Group>
									</Col>
									<Col md="3">
										<Form.Group className="form-group">
											<Form.Label htmlFor="header_text_color">Header Text Color</Form.Label>
											<div className="form-control-wrap">
												<Form.Control className="w-100" defaultValue={'#FFFFFF'} type="color" name="header_text_color" required />
											</div>
										</Form.Group>
									</Col>
									<Col md="3">
										<Form.Group className="form-group">
											<Form.Label htmlFor="header_bg_color">Header BG</Form.Label>
											<div className="form-control-wrap">
												<Form.Control className="w-100" defaultValue={'#f2f3f8'} type="color" name="header_bg_color" required />
											</div>
										</Form.Group>
									</Col>
									<Col md="12">
										<Form.Group className="form-group mb-2">
											<Form.Label htmlFor="header_text">Header Text</Form.Label>
											<div className="form-control-wrap">
												<Form.Control placeholder="Enter header text" as="textarea" name="header_text" required />
											</div>
										</Form.Group>
									</Col>
									<Col md="12">
										<Form.Group className="form-group mb-2">
											<Form.Label htmlFor="footer_text">Footer Text</Form.Label>
											<div className="form-control-wrap">
												<Form.Control placeholder="Enter footer text" defaultValue={'Thank you for your business!'} as="textarea" name="footer_text" required />
											</div>
										</Form.Group>
									</Col>
								</>
							)}
							<Col md="12">
								<Form.Group className="form-group">
									<Form.Label htmlFor="status">Status</Form.Label>
									<div className="form-control-wrap">
										<Form.Select defaultValue={data.status} onChange={(e) => setData({ ...data, status: e.target.value })} name="status" className="form-controll">
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</div>
								</Form.Group>
							</Col>

						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button className='btn-sm' variant="danger" onClick={() => closeModal()}>Close</Button>
						<Button className='btn-sm' variant="primary" type="submit" >{data.public_id ? 'Update' : 'Create'}</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Layout>
	)
}

export default DomainList;
