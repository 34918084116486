import React, { createContext, useContext, useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { sendRequest } from './utilities/api';
import QuickConnect from './pages/crm-manage/QuickConnect';
import Incall from './pages/Incall';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [call, setCall] = useState({});

  const quickAction = (status = false, data = {}) => {
    // Implement quickAction logic
  };

  const callInit = (id) => {
    setCall(id);
  };

  const login = (userData) => {
    setUser(userData);
    const tokenKey = window.location.pathname.includes('/portal') ? 'portalAuthToken' : 'authToken';
    localStorage.setItem(tokenKey, JSON.stringify(userData)); // Save user data to localStorage
  };

  const logout = () => {
    sendRequest(window.location.pathname.includes('/portal') ? 'portal/logout' : 'logout', 'POST', {})
      .then(response => {
        if (response.status === true) {
          // Handle successful logout
        }
      })
      .catch(error => {
        console.error('Delete request error:', error);
      });
    setUser(null);
    const tokenKey = window.location.pathname.includes('/portal') ? 'portalAuthToken' : 'authToken';
    localStorage.removeItem(tokenKey); // Clear the token from localStorage
  };

  const hasPermission = (requiredPermission) => {
    if (user && user.permissions.length === 0) {
      return true;
    }

    if (Array.isArray(requiredPermission)) {
      return requiredPermission.some(permission => user.permissions.includes(permission));
    }

    return user && user.permissions.includes(requiredPermission);
  };

  useEffect(() => {
    const tokenKey = window.location.pathname.includes('/portal') ? 'portalAuthToken' : 'authToken';
    const storedToken = localStorage.getItem(tokenKey);

    if (storedToken) {
      const userData = JSON.parse(storedToken); // Parse stored user data
      if (userData) {
        setUser(userData);
      }
    } else {
      setUser(null);
    }

    setIsLoading(false); // Set loading state to false after checking
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center my-5">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, hasPermission, callInit }}>
      <Incall />
      {call.type === 'call' && <QuickConnect quickAction={quickAction} connect={call} />}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
