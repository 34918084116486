import { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Card, Col, Form, Nav, Row, Tab, Spinner, Button, OverlayTrigger, Popover, Table, Modal } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import { capitalizeFirstLetter } from '../../utilities';
import Swal from 'sweetalert2';
import { HostUrl } from '../../utilities/config';
import InvoiceNav from '../nav/InvoiceNav';
import { Link } from 'react-router-dom';
function Payment() {
    const [data, setData] = useState({});
    const [tab, setTab] = useState("all");
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = (type) => {
        setLoading(false);
        sendRequest(`payment/setting/all`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(true);
                setLists(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to update this settings`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`payment/setting/${data.type}/update/${data.public_id}`, 'POST', submitData)
                    .then(response => {
                        if (response.status === true) {
                            Swal.fire(response.message);
                            closeModal();
                            fetchMeta();
                        }
                        setLoading(true);
                    })
                    .catch(error => {
                        setLoading(true);
                        console.error('POST request error:', error);
                    });

            }
        })
    };


    const openModal = (row = {}) => {
        setData(row);
        setTimeout(() => {
            setModal(true);
        }, 200);
    }

    const closeModal = () => {
        setData({});
        setModal(false);
    }
    const setDefault = async (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to set this payment setting as the default`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.value) {
                setLoading(false);
                sendRequest(`payment/setting/${data.type}/update/default/${data.public_id}`, 'POST', [])
                    .then(response => {
                        if (response.status === true) {
                            closeModal();
                            fetchMeta();
                        }
                        setLoading(true);
                    })
                    .catch(error => {
                        setLoading(true);
                        console.error('POST request error:', error);
                    });

            }
        })
    };
    return (
        <Layout title="Payment Setting" content="container-fluid">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Payment Setting</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Payment</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <InvoiceNav from="4" />
            <div className="paymentSetting">
                <Block>
                    <Card>
                        <Card.Body>
                            {
                                !loading && (
                                    <div className="d-flex justify-content-center my-5">
                                        <Spinner animation="border" variant="secondary"></Spinner>
                                    </div>
                                )
                            }

                            <Table className="table-bordered table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th>Method</th>
                                        <th className='text-center'>Default Status</th>
                                        <th className='text-center'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        lists.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <button onClick={() => openModal(row)} className='btn btn-link btn-sm p-0 '> {row.name}</button>

                                                    </td>
                                                    <td className='text-center'>
                                                        <span onClick={() => row.is_default == 0 && setDefault(row)} className={`cursor-pointer badge text-bg-${row.is_default == 1 ? "success-soft" : "danger-soft"}`
                                                        }>
                                                            {row.is_default == 1 ? 'Yes' : 'No'}
                                                        </span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span onClick={() => openModal(row)} className={`cursor-pointer badge text-bg-${row.status === "Active" ? "success-soft" : "danger-soft"}`
                                                        }>
                                                            {row.status == 'Active' ? 'Enabled' : 'Disabled'}
                                                        </span>
                                                    </td>

                                                </tr>

                                            )

                                        })
                                    }

                                </tbody>
                            </Table>




                            <Modal show={modal} onHide={() => closeModal()} size="lg">
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="event-title" as="h4">{data.name}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col lg={['stripe', 'authorize'].includes(data.type) ? 6 : 12}>
                                                <Row className="g-1">

                                                    <Col lg={12} className='d-none'>
                                                        <Form.Group className="form-group" >
                                                            <Form.Label>Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={data.name} name="name" placeholder="Name" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    {
                                                        data.type == 'stripe' && (
                                                            <>
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Secret Key</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='off' defaultValue={data.secret_key} type="password" name="secret_key" placeholder="sk_**********" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Publish key</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='off' defaultValue={data.publish_key} type="password" name="publish_key" placeholder="pk_*********" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>



                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Client Id (Optional)</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='off' defaultValue={data.client_id} type="text" name="client_id" placeholder="Client Id" />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Product Id (Auto Created)</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='Off' readOnly defaultValue={data.product_id} type="text" name="product_id" placeholder="" />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        data.type == 'authorize' && (
                                                            <>
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Login Id</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='off' defaultValue={data.login_id} type="password" name="login_id" placeholder="**********" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Transaction Key</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control autoComplete='off' defaultValue={data.transaction_key} type="password" name="transaction_key" placeholder="*********" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        )
                                                    }




                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="throttle_duration">Status</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select defaultValue={data.status} name="status" className="form-control">
                                                                    <option value="Active">Enabled</option>
                                                                    <option value="Inactive">Disabled</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className={['stripe', 'authorize'].includes(data.type) ? '' : 'd-none'} lg={['stripe', 'authorize'].includes(data.type) ? 6 : 12}>
                                                {
                                                    data.type == 'stripe' && (
                                                        <>
                                                            <button className="btn btn-color-primary btn-icon">
                                                                <Icon name="help"></Icon>
                                                                <span>Help</span>
                                                            </button>

                                                            <div className="">
                                                                <p className="mb-1">If you have not setup your stripe account <a target='_blank' href="https://dashboard.stripe.com/register">Click Here</a>  to setup.</p>
                                                                <p className="mb-1">You can get your stripe credentials by clicking on Account Setting and than API Keys</p>
                                                                <p className="mb-1">Add: <OverlayTrigger trigger="click" placement="top" overlay={
                                                                    <Popover id="popover-positioned-top">
                                                                        <Popover.Header as="h3">Webhook Url</Popover.Header>
                                                                        <Popover.Body>
                                                                            {`${HostUrl}/webhook/mail/statistics`}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }>
                                                                    <Button className='p-0' variant="link">This url </Button>
                                                                </OverlayTrigger>  in stripe under Developers Webhook and than Add endpoint.
                                                                    <a target='_blank' href='https://imgur.com/uBvfqF6'>See here</a></p>

                                                                <p className="mb-1">  If you have not setup your stripe client ID <a target='_blank' href="https://dashboard.stripe.com/login?redirect=%2Faccount%2Fapplications%2Fsettings">Click Here</a>  to setup.</p>
                                                                <p className="mb-1">You can get your stripe client ID by clicking on Account Setting and than Connect settings.</p>



                                                            </div>
                                                        </>
                                                    )
                                                }

                                                {
                                                    data.type == 'authorize' && (
                                                        <>
                                                            <button className="btn btn-color-primary btn-icon">
                                                                <Icon name="help"></Icon>
                                                                <span>Help</span>
                                                            </button>

                                                            <div className="">
                                                                <p className="mb-1">If you have not setup your authorize.net account <a target='_blank' href="https://www.authorize.net/sign-up/pricing.html">Click Here</a>  to setup.</p>
                                                                <p className="mb-1">After you have setup your account than find the api keys.</p>


                                                                <p className="mb-1">For help <a target='_blank' href="https://support.authorize.net/">Click Here</a></p>



                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='btn-sm' variant="danger" onClick={() => closeModal()}>Close</Button>
                                        <Button className='btn-sm' variant="primary" type="submit" >{data.public_id ? 'Update' : 'Create'}</Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default Payment;
