import { useEffect, useState, useRef } from 'react';
import Layout from '../../layout/default';
import { Card, Col, Form, Nav, Row, Tab, Spinner, Button } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import { capitalizeFirstLetter } from '../../utilities';
import Swal from 'sweetalert2';
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import DataTableComponent1 from '../../components/DataTable/DataTable';

function Table() {
    const [data, setData] = useState([]);
    const [tab, setTab] = useState("users");
    const [loading, setLoading] = useState(true);
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);
    const childRef2 = useRef(null);

    useEffect(() => {
        fetchTable();
    }, []);

    const fetchTable = () => {
        sendRequest('table/lists', 'POST', {}).then(response => {
            if (response.status === true) {
                setData(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };

    const createTable = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to create new table?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                sendRequest(`table/create`, 'POST', {})
                    .then(response => {
                        if (response.status === true) {
                            if (childRef2.current) {
                                childRef2.current.handleFilter(new FormData());
                            }
                        }
                    })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });

            }
        })
    };

    const userTableUpdate = async (table_name, user) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to change user lead table?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                const formData = new FormData();
                formData.set('lead_table', table_name);
                formData.set('id', user.public_id);
                sendRequest(`users/${capitalizeFirstLetter(user.type)}/table/update`, 'POST', formData)
                    .then(response => {
                        if (response.status === true) {
                            if (childRef.current) {
                                childRef.current.handleFilter(new FormData());
                            }
                        }
                    })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });

            }
        })
    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Full Name",
            key: 'full_name',
            selector: (row) => row.full_name,
            cell: (row) => (
                <span>{row.full_name}</span>
            ),
        },
        {
            name: "Email",
            key: 'email',
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
        },


        {
            name: <div className='w-100 text-center'>Status</div>,
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Pending" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },

        {
            name: "Lead",
            key: 'lead_table',
            selector: (row) => row.lead_table,
            cell: (row) => (
                <select
                    value={row.lead_table}
                    onChange={(e) => userTableUpdate(e.target.value, row)}
                    className="form-control"
                    name="table_lead"
                >
                    {data.map((lead) => {
                        return (
                            <option value={lead.name}> {lead.name} </option>
                        );
                    })}
                </select>
            ),
        },
    ];

    const tableColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.public_id}</span>
        //     ),
        // },
        {
            name: "Name",
            key: 'name',
            selector: (row) => row.name,
            cell: (row) => (
                <span>{row.name}</span>
            ),
        },
        {
            name: "Total",
            key: 'total_lead',
            selector: (row) => row.total_lead,
            cell: (row) => (
                <span>{row.total_lead}</span>
            ),
        },

        {
            name: "Dated",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },
    ];

    return (
        <Layout title="Table Setting" content="container-fluid">
            <div className="tableSetting">
                <Block>
                    <Card>
                        <Card.Body>
                            <Tab.Container onSelect={(e) => setTab(e)} id="custom-tabs-example" defaultActiveKey="users">
                                <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                    <Nav.Item>
                                        <Nav.Link eventKey="users">Leads Table</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="table">Table Lists</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                {
                                    !loading && (
                                        <div className="d-flex justify-content-center my-5">
                                            <Spinner animation="border" variant="secondary"></Spinner>
                                        </div>
                                    )
                                }



                                <Tab.Content>
                                    <Tab.Pane eventKey="users">
                                        {
                                            tab == 'users' && (
                                                <>
                                                    <Form ref={formRef} onSubmit={handleSubmit}>
                                                        <Row className="g-3">
                                                            <Col>
                                                                <Form.Select
                                                                    size="sm"
                                                                    onChange={(e) =>
                                                                        e.target.value == "Custom Date"
                                                                            ? setCustomDateFilter(true)
                                                                            : setCustomDateFilter(false)
                                                                    }
                                                                    name="date_range">
                                                                    <option value="">Filter by Date</option>
                                                                    <option value="Current Week">Current Week</option>
                                                                    <option value="Last Week">Last Week</option>
                                                                    <option value="Current Month">Current Month</option>
                                                                    <option value="Last Month">Last Month</option>
                                                                    <option value="Last 3 Months">Last 3 Months</option>
                                                                    <option value="Last 6 Months">Last 6 Months</option>
                                                                    <option value="Current Year">Current Year</option>
                                                                    <option value="Last Year">Last Year</option>
                                                                    <option value="Custom Date">Custom Date</option>
                                                                </Form.Select>
                                                            </Col>
                                                            {
                                                                customDateFilter && (
                                                                    <>
                                                                        <Col>
                                                                            <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                                                        </Col>

                                                                        <Col>
                                                                            <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                                                        </Col>
                                                                    </>
                                                                )
                                                            }
                                                            <Col>
                                                                <Form.Select size="sm" name="status" >
                                                                    <option value="">All </option>
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>
                                                                </Form.Select>
                                                            </Col>

                                                            <Col>
                                                                <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                                                <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                                            </Col>
                                                        </Row>
                                                    </Form>

                                                    <DataTableComponent
                                                        childRef={childRef}
                                                        selectableRows={false}
                                                        columns={userColumns}
                                                        tableClassName="data-table-head-light table-responsive"
                                                        slug={`users/Manager/lists`}
                                                    />
                                                </>
                                            )
                                        }
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="table">
                                        <div className='d-flex justify-content-end'>
                                            <Button onClick={() => createTable()} size="sm" type="button" variant='info' className='mr-5 btn btn-primary'>Create</Button>
                                        </div>
                                        {
                                            tab == 'table' && (
                                                <DataTableComponent1
                                                    childRef={childRef2}
                                                    selectableRows={false}
                                                    columns={tableColumns}
                                                    tableClassName="data-table-head-light table-responsive"
                                                    slug={`table/lists`}
                                                />
                                            )
                                        }

                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default Table;
