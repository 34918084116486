import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Dropdown, Button, Card, Col, Form, Image, Modal, Nav, Row, Spinner, Tabs, Tab, Table, Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import logo from "../../assets/images/default.png"
import { useParams } from 'react-router-dom';
import Layout from '../../layout/fullpage';
import { currencySign, uInfo } from '../../utilities';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { GOOGLE_AUTH_CLIENT_ID } from "../../store/site";
import { ApiUrl, HostUrl } from "../../utilities/config";
import { useAuth } from '../../AuthContext';
import { CreatedBy, FileUpload, Logo, Icon, MediaAction, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../components';

const Portal = (props) => {
    // const { id, coach_id } = useParams();
    const [id, coach_id] = useState({ 'id': 'MTc3', 'coach_id': 'Ng==' });
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [lists, setLists] = useState([]);
    const [key, setKey] = useState('jobs');
    const [connectedAccount, setConnectedAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState({});
    const { user, logout } = useAuth();
    const [jobs, setJobs] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [editingJob, setEditingJob] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            //await fetchRecordById();
            await fetchRequiredDoc();
            await fetchClientData();
            await fetchClientJobsData();
        };
        fetchData();
    }, []);

    const fetchClientData = async () => {
        await makePortalApiCall(ApiUrl + 'portal/me', 'post', {}).then(response => {
            setClient(response.data);
            console.log(client);
            setConnectedAccount(response.data.social_email)
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchClientJobsData = async () => {
        await makePortalApiCall(ApiUrl + 'portal/jobs-data', 'post', {}).then(response => {
            setJobs(response.data.data.lists);
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = async () => {
        await sendRequest(`crm/leads/${id}`, 'POST').then(response => {
            if (response.status === true) {

            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRequiredDoc = async () => {

        const tokenString = localStorage.getItem('portalAuthToken');

        let token = JSON.parse(tokenString);

        let client_id = token.public_id;

        await sendRequest(`fetch/required/doc/${client_id}/Ng==`, 'POST').then(response => {
            if (response.status === true) {
                setLists(response.data.lists);
            }
            setPageLoader(true);
        })
            .catch(error => {
                setPageLoader(true);
                console.error('POST request error:', error);
            });
    }

    const handleReset = () => {
        setPageLoader(false);
        fetchRequiredDoc();
    };

    const handleFileSubmit = (files, releatedId, relatedModule) => {
        // setLoader(true);
        const submitData = new FormData();

        if (Array.isArray(files)) {
            files.forEach((file, index) => {
                // Append each file with a unique key, e.g., 'file0', 'file1', etc.
                submitData.append(`files[]`, file);
            });
        } else {
            // If files is a single file, append it without iterating
            submitData.append('files[]', files);
        }

        submitData.append('id', releatedId);
        submitData.append('tab', relatedModule === 'jobs' ? 'jobs' : 'documents');

        if (relatedModule === 'jobs') {
            const tokenString = localStorage.getItem('portalAuthToken');
            let token = JSON.parse(tokenString);
            let client_id = token.public_id;

            submitData.append('client_id', client_id);
        }

        sendRequest(`upload/required/doc/${releatedId}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    // Swal.fire({
                    //     title: response.message,
                    //     icon: 'success',
                    // });
                    if (relatedModule === 'jobs') {
                        // closeShowModal();
                        fetchClientJobsData();
                    }
                    else {
                        fetchRequiredDoc();
                    }
                }
                // setLoader(false);
            })
            .catch(error => {
                setLoader(false);
                console.error('POST request error:', error);
            });
    }

    const disconnectAccount = async () => {
        let submitData = new FormData();

        submitData.append('target_table', 'client');

        Swal.fire({
            title: 'Are you sure?',
            html: `You want to disconnect <b>${connectedAccount}</b> account?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                makePortalApiCall(ApiUrl + 'disconnect-social-account', 'post', submitData).then(response => {
                    if (response.status === true) {
                        Swal.fire(response.message)
                    }
                    // setLoading(true);
                    setConnectedAccount(null);
                })
                    .catch(error => {
                        // setLoading(true);
                        console.error('POST request error:', error);
                    });
            }
        });
    }

    const makePortalApiCall = async (apiUrl, method = 'post', submitData = {}) => {
        // Retrieve JWT token from localStorage or wherever you store it
        const tokenString = localStorage.getItem('portalAuthToken');

        let token = JSON.parse(tokenString);

        // Prepare headers with JWT token
        const headers = {
            'Authorization': `Bearer ${token.access_token}`, // Include JWT token in the Authorization header
            'Content-Type': 'application/json',
        };

        // Make POST request using Axios
        const response = axios[method](apiUrl, submitData, { headers: headers });
        const responseData = await response;

        return responseData;
    };

    function closeShowModal() {
        setShowModal(false);
    }

    const deleteFile = async (id, key) => {
        let submitData = new FormData();

        submitData.append('id', id);
        submitData.append('file_key', key);

        Swal.fire({
            title: 'Are you sure?',
            html: `You want to delete this file?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                makePortalApiCall(ApiUrl + 'portal/remove-file', 'post', submitData).then(response => {
                    if (response.status === true) {
                        Swal.fire(response.message)
                    }
                    fetchClientJobsData();

                })
                    .catch(error => {
                        // setLoading(true);
                        console.error('POST request error:', error);
                    });
            }
        });

    }

    function QuickNavItem({ className, ...props }) {
        const compClass = classNames({
            "d-inline-flex": true,
            [className]: className,
        })
        return (
            <li className={compClass}>{props.children}</li>
        )
    }

    return (
        <Layout title="Lead Portal" >
            <div className="container-fluid my-2">
                <Row>
                    <Col lg={{ span: 10, offset: 1 }}>
                        <Card>
                            {
                                !pageLoader ? (
                                    <Spinner animation="grow" variant="primary"></Spinner>
                                ) : (
                                    <Card.Body className='p-0'>
                                        <header className="p-1" style={{ backgroundColor: '#fff' }}>
                                            <Row className='align-items-center'>
                                                <Col lg={8}>
                                                    <Image
                                                        src={logo}
                                                        alt={data.list?.domain?.name}
                                                        className="img-responsive"
                                                    />
                                                </Col>
                                                <Col lg={4} className="d-flex justify-content-end align-items-center">
                                                    {/* <p className='mb-0 me-3'>
                                                        <b> {client.name} </b>
                                                    </p>
                                                    <button className="btn btn-outline-primary btn-sm me-1" onClick={() => logout()}>Logout</button> */}
                                                    <Dropdown as={QuickNavItem}>
                                                        <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>


                                                            <div className="d-none d-sm-flex">
                                                                <Media size="lg" shape="circle" middle className="lead-image" style={{ border: '2px solid #000', padding: '2px', borderRadius: '50%', width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {client.image ? (
                                                                        <Image src={client.image} alt={client.initials} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                                                                    ) : (
                                                                        <b style={{ border: '2px solid #000', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '40px' }}>
                                                                            {client.initials}
                                                                        </b>
                                                                    )}
                                                                </Media>
                                                            </div>



                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                                            <div className="dropdown-content py-1 border-bottom border-light">
                                                                <MediaGroup>
                                                                    <Media size="sm" shape="circle" middle className="lead-image " >
                                                                        {client.image ? (
                                                                            <Image src={client.image} alt={client.initials} style={{ width: '30px', height: '30px' }} />
                                                                        ) : (
                                                                            <b>{client.initials}</b>
                                                                        )}
                                                                    </Media>
                                                                    <MediaText>
                                                                        <div className="lead-text">{client?.name}</div>
                                                                        <span className="sub-text">{client?.email}</span>
                                                                    </MediaText>
                                                                </MediaGroup>
                                                            </div>
                                                            <div className="dropdown-content py-1 border-bottom border-light">
                                                                <LinkList>
                                                                    {
                                                                        connectedAccount ?
                                                                            (
                                                                                <>
                                                                                    <h6>Connected Account</h6>
                                                                                    <div className="d-flex justify-content-between align-items-center border p-3 mb-2 bg-white rounded shadow-sm">
                                                                                        <span>{connectedAccount}</span>
                                                                                        <button className="btn btn-link text-danger" onClick={disconnectAccount}>
                                                                                            <Icon name="trash" className='text-danger' ></Icon>
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                                // <Alert variant="secondary">Account connected</Alert>
                                                                            ) : (
                                                                                <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                                                                                    <GoogleLogin
                                                                                        onSuccess={credentialResponse => {

                                                                                            const userData = jwtDecode(credentialResponse.credential);

                                                                                            const submitData = new FormData();

                                                                                            submitData.append('social_email', userData.email);
                                                                                            submitData.append('aud', userData.aud);
                                                                                            submitData.append('social_id', btoa(userData.sub.toString()));
                                                                                            submitData.append('social_type', 'google');
                                                                                            submitData.append('target_table', 'client');

                                                                                            makePortalApiCall(ApiUrl + 'connect-social-account', 'post', submitData).then(response => {
                                                                                                setConnectedAccount(response.data.data.social_email)
                                                                                            })
                                                                                        }}
                                                                                        onError={() => {
                                                                                            console.log('Login Failed');
                                                                                        }}
                                                                                    />
                                                                                </GoogleOAuthProvider>
                                                                            )
                                                                    }
                                                                </LinkList>
                                                            </div>
                                                            <div className="dropdown-content py-1">
                                                                <LinkList>
                                                                    <Button onClick={() => logout()} className="btn-soft px-0" variant="link"><Icon name="signout"></Icon><span>Log Out</span></Button>
                                                                </LinkList>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>



                                            </Row>
                                        </header>


                                        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 mt-4">
                                            <Tab eventKey="jobs" title="Jobs">
                                                <div className='my-2 mx-2'>
                                                    <h5 className='text-center'>My Jobs</h5>

                                                    <Col className="col-md-12 mt-4">
                                                        <div className="bio-block">
                                                            {jobs.length > 0 ? (
                                                                jobs.map((row, i) => (
                                                                    <div className="mb-1" key={i}>
                                                                        <div className="list-invoice">
                                                                            <div className="nk-schedule-content d-flex justify-content-between flex-column">
                                                                                <div className="d-flex justify-content-between pt-1">
                                                                                    <div className="job-info w-25">
                                                                                        <div className="d-flex">
                                                                                            <div className="nk-ibx-item-subject">
                                                                                                <button
                                                                                                    className="p-0 btn badge text-bg-info-soft"
                                                                                                    title={"view Job details"}
                                                                                                >
                                                                                                    Job #{row.id}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="p nk-ibx-item-message">
                                                                                            Due Date: {row.due_date_formated}
                                                                                        </div>
                                                                                        <div className="d-flex gap gx-3">
                                                                                            <div className="p nk-ibx-item-message">
                                                                                                Owner: {row.record_owner}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="p nk-ibx-item-message">
                                                                                            Service: {row.service_title}
                                                                                        </div>
                                                                                        <div className="p nk-ibx-item-message">
                                                                                            <span className="badge text-bg-success-soft">Amount: {currencySign}{row.amount_formated}</span>
                                                                                        </div>
                                                                                        {/* <div className="d-flex gap gx-3">
                                                                                            <div className="p nk-ibx-item-message">
                                                                                                <span className="p nk-ibx-item-message"> Milestone: {row.pipeline?.status_title}</span>
                                                                                            </div>
                                                                                        </div> */}
                                                                                    </div>

                                                                                    <div className="job-actions w-50">
                                                                                        <div className="">
                                                                                            <div className='drop-area- mx-2 mb-2'>
                                                                                                <FileUpload
                                                                                                    maxFiles={2}
                                                                                                    maxSize={52428800}
                                                                                                    preview={false}
                                                                                                    releatedId={row.id}
                                                                                                    relatedModule='jobs'
                                                                                                    iconName="files"
                                                                                                    onSubmitCallback={handleFileSubmit}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='w-25'>
                                                                                        {row.files.length > 0 ? (
                                                                                            <div className="document-file px-2 mb-1">
                                                                                                <ul className="list-unstyled m-0 p-0">
                                                                                                    {row.files.map((file, k) => (
                                                                                                        <li key={k} className="d-flex align-items-center mb-1">
                                                                                                            <a
                                                                                                                href={file.url}
                                                                                                                target="_blank"
                                                                                                                style={{
                                                                                                                    flexGrow: 1,
                                                                                                                    wordBreak: "break-all",
                                                                                                                }}
                                                                                                            >
                                                                                                                {file.key}
                                                                                                            </a>
                                                                                                            <span role='button' onClick={() => deleteFile(row.public_id, file.key)}>
                                                                                                                <Icon
                                                                                                                    name="trash"
                                                                                                                    className="text-danger ms-2"
                                                                                                                    style={{
                                                                                                                        marginLeft: "0.5rem",
                                                                                                                        cursor: "pointer",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span className='text-muted'>&nbsp;</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className='text-center'>No Jobs found.</div>
                                                            )}
                                                        </div>
                                                    </Col>

                                                </div>
                                            </Tab>
                                            <Tab eventKey="documents" title="Documents">
                                                <h5 className='text-center'>Upload Documents</h5>

                                                <div className='my-2 mx-2'>
                                                    {lists.length > 0 && (
                                                        <div className='doc-lists'>
                                                            {/* <h5 className='text-center'>Waiting on following documents for Contact</h5> */}
                                                            <div className='row'>
                                                                {lists.map((row, i) => {
                                                                    if (row.status === 'Pending') {
                                                                        return (
                                                                            <div key={i} className='col-lg-4'>
                                                                                <div className='border mb-2 eqalheight'>
                                                                                    <div className="folder mb-2">
                                                                                        <div className="d-flex w-100 justify-content-between">
                                                                                            <div className="name-checkbox">
                                                                                                <button type="button" className="p-0 btn-link text-white btn btn-outline-link btn-sm">{row.title}</button>
                                                                                            </div>
                                                                                            <div className="action-attachment">
                                                                                                <div role="group" className="dropdown btn-group">
                                                                                                    <div className='text-white pe-2 due-date'><b>Due:</b> {row.due_date}</div>
                                                                                                    <div className='pe-1'>{row.status}</div>
                                                                                                    <div className={`${uInfo().id ? '' : 'd-none'}`}>
                                                                                                        <CreatedBy
                                                                                                            title={'Response'}
                                                                                                            primaryKey={row.id}
                                                                                                            defaultValue={row.status}
                                                                                                            defaultTitle={row.status}
                                                                                                            handleReset={handleReset}
                                                                                                            tableName="document_requests"
                                                                                                            updatedKey="status"
                                                                                                            row={row}
                                                                                                            showIcon={true}
                                                                                                            meta={{
                                                                                                                coachPartners: [
                                                                                                                    { id: 'Pending', first_name: 'Pending' },
                                                                                                                    { id: 'Done', first_name: 'Done' },
                                                                                                                    { id: 'Not Applicable', first_name: 'Not Applicable' }
                                                                                                                ]
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {!loader && row.status === 'Pending' && (
                                                                                        <div className='drop-area- mx-2 mb-2'>
                                                                                            <FileUpload
                                                                                                maxFiles={2}
                                                                                                maxSize={52428800}
                                                                                                preview={false}
                                                                                                releatedId={row.id}
                                                                                                iconName="files"
                                                                                                onSubmitCallback={handleFileSubmit}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {lists.length === 0 ? (
                                                        <div className='text-center'>No Pending or Uploaded documents found</div>
                                                    ) : (
                                                        <div className='uplodaed-doc-lists doc-lists'>
                                                            {/* <h5 className='text-center'>Uploaded Documents</h5> */}
                                                            <div className='row'>
                                                                {lists.map((row, i) => {
                                                                    if (row.status !== 'Pending') {
                                                                        return (
                                                                            <div key={i} className='col-lg-4'>
                                                                                <div className='border mb-2 eqalheight'>
                                                                                    <div className="folder mb-1">
                                                                                        <div className="d-flex w-100 justify-content-between align-items-center">
                                                                                            <div className="name-checkbox">
                                                                                                <button type="button" className="p-0 btn-link text-white btn btn-outline-link btn-sm">{row.title}</button>
                                                                                            </div>
                                                                                            <div className="action-attachment">
                                                                                                <div role="group" className="dropdown btn-group">
                                                                                                    <div className='pe-1'>{row.status}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {row.files.length > 0 && (
                                                                                        <div className='document-file px-2 mb-1'>
                                                                                            <ul>
                                                                                                {row.files.map((file, k) => (
                                                                                                    <li key={k}>
                                                                                                        <a href={file.url} target='_blank'>{file.key}</a>
                                                                                                    </li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Tab>

                                        </Tabs>

                                        <Modal show={showModal} onHide={closeShowModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title className="event-title" as="h4">Upload Documents</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='drop-area- mx-2 mb-2'>
                                                    <FileUpload
                                                        maxFiles={2}
                                                        maxSize={52428800}
                                                        preview={false}
                                                        releatedId={client.id}
                                                        iconName="files"
                                                        onSubmitCallback={handleFileSubmit}
                                                    />
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>
                                        </Modal>
                                    </Card.Body>
                                )
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )

}

export default Portal;
