import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { toInitials } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";
import Swal from "sweetalert2";

function Embed(props) {
    const { id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        sendRequest('dropdown/tracking/code', 'GET', {}).then(response => {
            if (response.status === true) {
                setMeta(response.data.record);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`dropdown/tracking/code`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire(response.message)
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Setting" content="container-fluid">
            <div className="embed-code">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Setting</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Embed Code</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading && (
                            <div className="d-flex justify-content-center my-5">
                                <Spinner animation="border" variant="secondary"></Spinner>
                            </div>
                        )
                    }

                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Header Code</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={meta.header_code} as="textarea"  rows={5} name="header_code" placeholder="Header Seaction" />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Footer Code</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={meta.footer_code} as="textarea"  rows={5} name="footer_code" placeholder="Footer Seaction" />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-12">
                                            <Button type="submit" className="btn-sm">Update</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>

                </Block >
            </div>
        </Layout >
    )
}

export default Embed;