// PlivoService.js
import Plivo from 'plivo-browser-sdk';
import { uInfo } from '../../utilities';

const plivoService = {
    debug: 'OFF',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
};

const isSecureConnection = (window.location.protocol === 'https:' || window.location.hostname === 'localhost');
const plivoBrowserSdk = isSecureConnection ? new Plivo(plivoService) : null;

if (plivoBrowserSdk && uInfo().id) {
    plivoBrowserSdk.client.login(uInfo().endpoints.username, uInfo().id + '-' + uInfo().endpoints.alias);
}

export const addPlivoEventListeners = (
    onCallFailed,
    onCallTerminated,
    onCallAnswered,

) => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.on('onCallFailed', onCallFailed);
        plivoBrowserSdk.client.on('onCallTerminated', onCallTerminated);
        plivoBrowserSdk.client.on('onCallAnswered', onCallAnswered);
    }
};

export const removePlivoEventListeners = (
    onCallFailed,
    onCallTerminated,
    onCallAnswered,
) => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.off('onCallFailed', onCallFailed);
        plivoBrowserSdk.client.off('onCallTerminated', onCallTerminated);
        plivoBrowserSdk.client.off('onCallAnswered', onCallAnswered);
    }
};

export const makeCall = (dest, extraHeaders) => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.call(dest, extraHeaders);
    }
};

export const hangupCall = () => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.hangup();
    }
};

export const muteCall = () => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.mute();
    }
};

export const unmuteCall = () => {
    if (plivoBrowserSdk) {
        plivoBrowserSdk.client.unmute();
    }
};

