
import { useState, useEffect } from "react";

import { Link, useParams } from 'react-router-dom';

import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip, Table, Modal } from 'react-bootstrap';
import { sendRequest } from "../../utilities/api";
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { getToday } from "../../utilities";
import Swal from "sweetalert2";
import { countries } from "../../store/customData";


function IvrUpdate(props) {
    const [id] = useState(props.id);
    const [ivr_id] = useState(props.ivr_id);
    const [meta, setMeta] = useState(props.meta);
    const [data, setData] = useState(props.data);
    const [loading, setLoading] = useState(false);
    const [flow, setFlow] = useState(props.meta.ivrWidgets);

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {

    }, [])


    

    const fetchIvrFlow = async (event) => {
        sendRequest(`campaign/${id}/ivrflow`, 'POST', [])
            .then(response => {
                if (response.status === true) {
                    setMeta((prevState) => ({
                        ...prevState,
                        ivrFlows: response.data.records,
                    }))
                }
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    const renderUI = () => {
        return flow.map((el, i) => {
            if (el.w_id) {
                return <div className={`py-3 px-2`} key={i} id={`element_after_${i}`}>
                    <div className="row">

                        <input type={'hidden'} name={`wid[${i}][w_id]`} value={el.w_id} />

                        {
                            i == 0 ? (
                                <>
                                    <div className="col-md-3 mb-3">
                                        <div className="form-group">
                                            <label className="control-label">If no number is pressed, play IVR Greeting</label>
                                        </div>
                                    </div>

                                    <div className="col-md-1 mb-3">
                                        <select
                                            defaultValue={flow[i].repeat_time || '1'}
                                            className="form-control " name={`wid[${i}][keyopt]`}  >
                                            <option value="1">1</option>
                                            <option value="2" >2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>

                                    <div className="col-md-2 mb-3">
                                        <input readOnly type="button" value="times,then" className="form-control" />

                                        <input readOnly type="hidden" name={`wid[${i}][default]`} value="1" className="form-control" />
                                    </div>
                                </>

                            ) : (
                                <>
                                    <div className="col-md-3 mb-3">
                                        <div className="form-group">
                                            <label className="control-label">When the caller presses
                                                <button title="If you press this, it will be permanently deleted the row" onClick={(e) => removeFlow(e, i, el.w_id)} type="button" className="btn btn-link btn-icon btn-soft">
                                                    <Icon name={'cross-sm'} />
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-1 mb-3">
                                        <select defaultValue={flow[i].keypress || '1'} className="form-control " name={`wid[${i}][keyopt]`}  >
                                            <option value="1">1</option>
                                            <option value="2" >2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="0">0</option>
                                        </select>
                                    </div>

                                    <div className="col-md-1 mb-3">
                                        <input readOnly type="button" value="then" className="form-control" />
                                        <input readOnly name={`wid[${i}][default]`} type="hidden" value="0" className="form-control" />
                                    </div>

                                </>

                            )
                        }



                        <div className="col-md-2">
                            <div className="form-group">
                                <select className="select form-control"
                                    type="select"
                                    name={`wid[${i}][flow_type]`}
                                    onChange={(e) => handleChangeFlow(e, i)}
                                    defaultValue={flow[i].flow_type == 'Menu' ? 'Hangup' : flow[i].flow_type || ''}
                                    required
                                >
                                    <option value="">Select Call Flow</option>
                                    <optgroup label="IVR Options">
                                        <option value="Forward">Forward To</option>
                                        <option value="Voicemail">Voicemail</option>
                                        <option value="Greeting">Greeting</option>
                                        {
                                            i != 0 && (
                                                <option value="SMS">SMS</option>
                                            )
                                        }
                                        <option value="Connect_To_Agent">Connect To Agent</option>
                                        <option value="Unsubscribe">Unsubscribe</option>
                                        <option value="Hangup">Hangup/End Call</option>
                                    </optgroup>
                                    {/* <optgroup label="My Call Flows">
                                    <option value="1">IVR - Migration Campaign -56057 (IVR)</option>
                                </optgroup> */}
                                </select>
                            </div>
                        </div>

                        {addContent(flow, 'wid', i, flow[i].flow_type)}


                        {
                            i != 0 && (
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <select defaultValue={flow[i].tag_id} name={`wid[${i}][tag_id]`} className="form-control">
                                            <option value="">Select Tag</option>
                                            {
                                                meta.tags.map((row, i) => {
                                                    return <option key={'agent' + i} value={row.id}>{row.title}</option>
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            )}


                    </div>
                </div>
            }
        })
    }

    const addContent = (flow, name_key, i, type) => {
        if (type == 'Forward') {
            return (
                <>
                    <div className="col-md-1"  >
                        <Form.Select
                            required defaultValue={flow[i].country_code} name={`${name_key}[${i}][country_code]`}
                            className="select form-control"

                        >
                            <option value="">Select Code</option>
                            {countries.map((row, i) => {
                                return (
                                    <option key={'code' + i} value={`${row.code}`}>
                                        {row.code}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                    <div className="col-md-2">
                        <Form.Control
                            name={`${name_key}[${i}][content]`}
                            defaultValue={flow[i].content}
                            className="form-controll"
                            required
                            maxLength="10"
                        />
                    </div>
                </>
            )
        } else if (type == 'Voicemail') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].content} name={`${name_key}[${i}][content]`}
                            className="select form-control"

                        >
                            <option value="">Select Voicemail</option>
                            {
                                meta.voiceTemplates.map((row, i) => {
                                    return <option key={'voice_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'Greeting') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].content} name={`${name_key}[${i}][content]`}
                            className="select form-control"

                        >
                            <option value="">Select Greeting</option>
                            {
                                meta.voiceTemplates.map((row, i) => {
                                    return <option key={'greeting_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'SMS') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].content} name={`${name_key}[${i}][content]`}
                            className="select form-control"

                        >
                            <option value="">Select SMS</option>
                            {
                                meta.smsTemplates.map((row, i) => {
                                    return <option key={'sms_' + i} value={row.id}>{row.title}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        } else if (type == 'Connect_To_Agent') {
            return (
                <>
                    <div className="col-md-3" >
                        <Form.Select
                            required defaultValue={flow[i].content} name={`${name_key}[${i}][content]`}
                            className="select form-control"

                        >
                            <option value="">Select Agent</option>
                            {
                                meta.callerGroup.map((row, i) => {
                                    return <option key={'agent' + i} value={row.id}>{row.name}</option>
                                })
                            }
                        </Form.Select>
                    </div>
                </>
            )
        }
    }

    const handleChangeFlow = (e, i) => {
        const { name, value } = e.target;
        const updatedFlow = [...flow];
        updatedFlow[i] = { ...updatedFlow[i], [name]: value };
        updatedFlow[i] = { ...updatedFlow[i], flow_type: value };
        setFlow(updatedFlow);
    }

    const removeFlow = (e, i, w_id) => {
        const updatedFlow = [...flow];
        updatedFlow[i] = {};
        setFlow(updatedFlow);
        console.log(w_id, 'w_id')
        if (w_id != '0') {
            removeIvrWidget(w_id);
        }
    }

    const addFlow = () => {
        setFlow(prevBusinessFlow => [
            ...prevBusinessFlow,
            { flow_type: "", [`w_id`]: "0" }
        ]);
    }

    const removeIvrWidget = async (w_id) => {
        sendRequest(`campaign/${id}/IvrWidget/${w_id}`, 'DELETE')
            .then(response => {
                if (response.status === true) {
                  console.log(response.status)
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleSubmitIvr = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`campaign/${id}/ivrflow/${ivr_id}/update`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchIvrFlow();
                    Swal.fire('Success',response.message,'success')
                }
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    return (
        <div>
            <div className="bio-block">

                <Form onSubmit={handleSubmitIvr}>
                    <Row className="g-3">
                        <div className="row mb-3">
                            <label className="col-md-3">Title</label>
                            <div className="col-md-7">
                                <Form.Control defaultValue={meta.ivrFlows?.ivr_title} placeholder="Enter title" name="ivr_title" required className="form-controll" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-md-3">Greeting File</label>
                            <div className="col-md-7">
                                {meta.greeting && (
                                    <Form.Select
                                        required defaultValue={meta.greeting?.content_main} name={`content_main`}
                                        className="select form-control"

                                    >
                                        <option value="">Select Voicemail</option>
                                        {
                                            meta.voiceTemplates.map((row, i) => {
                                                return <option key={'greeting_' + i} value={row.id}>{row.title}</option>
                                            })
                                        }
                                    </Form.Select>
                                )}
                            </div>
                        </div>

                        {renderUI()}

                        <div className="w-25">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => addFlow()}>
                                New IVR Prompt
                            </button>
                        </div>




                        <Col className="col-12 text-center">
                            <Button type="submit" variant="primary" disabled={loading}>
                                {
                                    loading && (
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    )
                                }
                                <span className="ms-1">Submit</span>
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </div>
        </div>
    );
};


export default IvrUpdate;