import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Offcanvas, Spinner, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from '../../utilities/api';
import { Icon, LinkListItem } from "../../components";
import DataTableComponent from '../../components/DataTable/DataTable';
import { convertPhoneFormat } from '../../utilities';
import { useAuth } from '../../AuthContext';

function ReportsContacts(props) {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [meta, setMeta] = useState({});
	const [loading, setLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState(false);
	const [userColumns] = useState([
		{
			name: "Lead ID",
			key: 'id',
			selector: (row) => row.id,
			cell: (row) => (
				<span>{row.id}</span>
			),
		},
		{
			name: "Group",
			key: 'groups',
			selector: (row) => row.groups,
			cell: (row) => (
				<span>{row.groups}</span>
			),
		},
		{
			name: "Tag",
			key: 'tags',
			selector: (row) => row.tags,
			cell: (row) => (
				<span>{row.tags}</span>
			),
		},
		{
			name: "Full Name",
			key: 'name',
			selector: (row) => row.name,
			cell: (row) => (
				<span>{row.first_name} {row.last_name}</span>
			),
		},
		{
			name: "Email",
			key: 'email',
			minWidth: '280px',
			selector: (row) => row.email,
			cell: (row) => (
				<span>{row.email}</span>
			),
		},

		{
			name: "Phone",
			key: 'phone',
			minWidth: '260px',
			selector: (row) => row.phone,
			cell: (row) => (
				<span>{convertPhoneFormat(row.phone)}</span>
			),
		},

		{
			name: "Mobile",
			key: 'mobile',
			minWidth: '260px',
			selector: (row) => row.mobile,
			cell: (row) => (
				<span>{convertPhoneFormat(row.mobile)}</span>
			),
		},

		{
			name: "Date  of Birth",
			key: 'date_of_birth',
			selector: (row) => row.date_of_birth,
			cell: (row) => (
				<span>{row.date_of_birth}</span>
			),
		},

		{
			name: "Last Appt",
			key: 'last_appointment_date',
			selector: (row) => row.last_appointment_date,
			cell: (row) => (
				<span>{row.last_appointment_date}</span>
			),
		},

		{
			name: "Apt Status",
			key: 'last_appointment_status',
			selector: (row) => row.last_appointment_status,
			cell: (row) => (
				<span>{row.last_appointment_status}</span>
			),
		},
		{
			name: "Milestone",
			key: 'milestone',
			selector: (row) => row.pipeline?.status_title,
			cell: (row) => (
				<span>{row.pipeline?.status_title}</span>
			),
		},
		{
			name: "Owner",
			key: 'record_owner',
			selector: (row) => row.record_owner,
			cell: (row) => (
				<span>{row.record_owner}</span>
			),
		},


		{
			name: "City",
			key: 'city',
			selector: (row) => row.city,
			cell: (row) => (
				<span>{row.city}</span>
			),
		},

		{
			name: "State",
			key: 'state',
			selector: (row) => row.state,
			cell: (row) => (
				<span>{row.state}</span>
			),
		},
		{
			name: "Zip",
			key: 'zip',
			selector: (row) => row.zip,
			cell: (row) => (
				<span>{row.zip}</span>
			),
		},
		{
			name: "Address",
			key: 'address',
			selector: (row) => row.address,
			cell: (row) => (
				<span>{row.address}</span>
			),
		},
		{
			name: "website",
			key: 'website',
			selector: (row) => row.website,
			cell: (row) => (
				<span>{row.website}</span>
			),
		},
		{
			name: "Company",
			key: 'company_name',
			selector: (row) => row.company_name,
			cell: (row) => (
				<span>{row.company_name}</span>
			),
		},
	]);
	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'listPage')
		await sendRequest('crm/leads/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setLoading(false);
			setCustomDateFilter(false);
			if (searchFilter) {
				setColumnVisibility({
					name: true,
					email: true,
					lead_number: true,
					//address: true,
					//company_name: true,
					checkboxes: true,
					date_of_birth: true,
					last_appointment_title: true,
					last_appointment_date: true,
					last_appointment_status: true,
					milestone: true,
					record_owner: true,
				});

				setSearchFilter(false);
			}
			setTimeout(() => {
				setLoading(true);

			}, 100);
		}
	};


	// const userColumns = 

	const getInitialColumnVisibility = () => {
		const storedVisibility = localStorage.getItem('leadsReportsColumnVisibility');
		if (storedVisibility) {
			let visible = JSON.parse(storedVisibility);
			visible.checkboxes = true;
			return visible;
		}
		// Default visibility state
		return {
			// id: true,
			name: true,
			email: true,
			lead_number: true,
			//address: true,
			//	company_name: true,
			checkboxes: true,
			date_of_birth: true,
			last_appointment_title: true,
			last_appointment_date: true,
			last_appointment_status: true,
			milestone: true,
			record_owner: true,


		};
	};

	const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);
	useEffect(() => {
		localStorage.setItem('leadsReportsColumnVisibility', JSON.stringify(columnVisibility));
	}, [columnVisibility]);

	const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);
	const handlePipelines = (data) => {
		if (data.selected_columns != '') {
			setColumnVisibility(data.selected_columns);
		}
	};

	return (
		<Layout title="Report contact List" content="container-fluid">
			<div className="Report contact">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Reports</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{
									hasPermission('viewLeadsFilter') && (
										<Link className={`btn btn-sm btn-soft btn-secondary`} to={'/crm-manage/leads/filters/lists/leads'}>List & Filter</Link>
									)
								}

								{
									hasPermission('createLeadsFilter') && (
										<Link className={`btn btn-sm  ms-2 btn-primary`} to={'/crm-manage/leads/filters/create/leads'}>
											<Icon name="plus"></Icon> <span>Add Filter</span>
										</Link>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card>
								<Card.Body>

									<Form ref={formRef} onSubmit={handleSubmit}>
										<Row>
											<Col>
												<Form.Select
													size="sm"
													name="search_filter_id"
													onChange={() => {
														setSearchFilter(true);
														//document.getElementById('submitButton').click()
													}}>
													<option value="">All Records</option>

													<optgroup label="Select Filter">

														{
															meta.search_filters && meta.search_filters.map((row, i) => {
																return <option key={i} value={row.id}>{row.name}</option>
															})

														}
													</optgroup >
												</Form.Select>
											</Col>
											<Col>
												<Form.Select
													size="sm"
													onChange={(e) =>
														e.target.value == "Custom Date"
															? setCustomDateFilter(true)
															: setCustomDateFilter(false)
													}
													name="date_range">
													<option value="">Filter by Date</option>
													<option value="Current Week">Current Week</option>
													<option value="Last Week">Last Week</option>
													<option value="Current Month">Current Month</option>
													<option value="Last Month">Last Month</option>
													<option value="Last 3 Months">Last 3 Months</option>
													<option value="Last 6 Months">Last 6 Months</option>
													<option value="Current Year">Current Year</option>
													<option value="Last Year">Last Year</option>
													<option value="Custom Date">Custom Date</option>
												</Form.Select>
											</Col>
											{
												customDateFilter && (
													<>
														<Col>
															<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>

														<Col>
															<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>
													</>
												)
											}

											<Col>
												<Button id="submitButton" size="sm" type="submit" className='mr-5'>Filter</Button>
												<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

											</Col>
										</Row>
									</Form>



									<DataTableComponent
										childRef={childRef}
										selectableRows={false}
										columns={visibleColumns}
										tableClassName="data-table-head-light table-responsive LeadListTable"
										slug={'crm/leads/lists?status=1'}
										actions={true}
										defaultRow={1000}
										sendDataToParent={handlePipelines}
									/>
								</Card.Body>
							</Card>
						)
					}
				</Block>
			</div>
		</Layout>
	)
}

export default ReportsContacts;
