import React, { useState, useEffect } from 'react';
import { Col, Button, Form, Nav, Tab, Container, Modal } from 'react-bootstrap';

import Email from './setting-manage/Email';
import { sendRequest } from '../utilities/api';
import CalendarCreate from './calendar-manage/Create';
import EmailTemplateCreate from './template-manage/email/Create';
import LocationCreate from './location-manage/Create';

import { Icon, Select, TimeZone } from '../components';
import Swal from 'sweetalert2';

const SettingsWizard = () => {
    const [data, setData] = useState({});
    const [isShow, setIsShow] = useState(true);
    const [step, setStep] = useState(2);
    // const [hideWizardModal, setHideWizardModal] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    function fetchStep (record) {
        if (record.has_emailsettings && !record.has_calendar && !record.has_location && !record.has_emailtempates) {
            return 3;
        }
        else if (record.has_emailsettings && record.has_calendar && !record.has_location && !record.has_emailtempates) {
            return 4;
        }
        else if (record.has_emailsettings && record.has_calendar && record.has_location && !record.has_emailtempates) {
            return 5;
        }
        
        return 2;
    }

    useEffect(() => {
        const fetchData = async () => {
            await getUserDetail();
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('Form Data Submitted:', formData);
    };

    const getUserDetail = async (data) => {
        await sendRequest(`users/User/0?wizad_details=true`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);

                setStep(fetchStep(response.data.list));

                if (response.data.list.has_completed_wizard) {
                    const tokenString = localStorage.getItem('authToken');
                    let token = JSON.parse(tokenString);

                    if (token && token.user) {
                        token.user.has_completed_wizard = true;

                        const updatedTokenString = JSON.stringify(token);

                        localStorage.setItem('authToken', updatedTokenString);
                    }

                    Swal.fire({
                        title: 'Success',
                        text: 'You successfully completed the initial setup',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsShow(false);
                            // redirect to dashboard
                            window.location.href = '/';
                        }
                    });
                }
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const showWizardModal = () => {
        setIsShow(true);
    }

    const hideWizardModal = () => {
        if (true) {
            setIsShow(true);
        }
    }

    return (
        <Modal size="xl" show={isShow} onHide={hideWizardModal}>
            <Modal.Header closeButton={false}>
                <Modal.Title className="event-title" as="h4">Complete Settings Wizard</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '80vh', overflow: 'auto' }}>
                <Container className="mt-3 SettingsWizard" >
                    <Tab.Container activeKey={`step${step}`}>
                        <Nav variant="pills" className="justify-content-center mb-3">
                            {/* <Nav.Item>
                        <Nav.Link
                            eventKey="step1"
                            onClick={() => setStep(1)}
                            className={`wizard-step ${step === 1 ? 'active' : ''}`}
                        >
                            User Details
                        </Nav.Link>
                    </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="step2"
                                    onClick={() => setStep(2)}
                                    className={`wizard-step ${step === 2 ? 'active' : ''}`}
                                >
                                    Email Service Settings
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="step3"
                                    onClick={() => setStep(3)}
                                    className={`wizard-step ${step === 3 ? 'active' : ''}`}
                                >
                                    Calendar
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="step4"
                                    onClick={() => setStep(4)}
                                    className={`wizard-step ${step === 4 ? 'active' : ''}`}
                                >
                                    Location
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="step5"
                                    onClick={() => setStep(5)}
                                    className={`wizard-step ${step === 5 ? 'active' : ''}`}
                                >
                                    Email Template
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <hr className="my-0" />
                        <Tab.Content >
                            {/* <Tab.Pane eventKey="step1" className="p-5 bg-white rounded ">
                        <h2 className="mb-4">User Details </h2>
                        <Col>
                            <TimeZone main_key="main_zone" sub_key={"timezone"} mainZone={data?.main_zone} zone={data?.time_zone} />
                        </Col>
                        <Button
                            variant="primary"
                            className="wizard-btn mt-3"
                            style={{ bottom: '0px' }}
                            onClick={() => setStep(2)}
                        >
                            Next
                        </Button>
                    </Tab.Pane> */}
                            <Tab.Pane eventKey="step2" className="p-5 bg-white rounded ">
                                {/* <h2 className="mb-4">Email Service Settings</h2> */}
                                {data?.has_emailsettings ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '40vh', }}  // Full page height and light background
                                    >
                                        <p className="text-success" style={{ fontSize: '24px' }}>
                                            You have set up your email service.
                                        </p>
                                    </div>
                                ) : (
                                    // email service configs
                                    data ? <Email fullPageView={true} getUserDetail={getUserDetail} /> : <p>Loading...</p>
                                )}
                                {/* <Button
                            variant="secondary"
                            className="wizard-btn mt-3 me-3"
                            onClick={() => setStep(1)}
                        >
                            Previous
                        </Button> */}
                                <Button
                                    variant="primary"
                                    className="wizard-btn mt-3"
                                    onClick={() => setStep(3)}
                                >
                                    Next
                                </Button>
                            </Tab.Pane>
                            <Tab.Pane eventKey="step3" className="p-5 bg-white rounded ">
                                {data?.has_calendar ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '40vh', }}  // Full page height and light background
                                    >
                                        <p className="text-success" style={{ fontSize: '24px' }}>
                                            You have set up your calendar.
                                        </p>
                                    </div>
                                ) : (
                                    <CalendarCreate fullPageView={true} getUserDetail={getUserDetail} />
                                )}
                                <Button
                                    variant="secondary"
                                    className="wizard-btn mt-3 me-3"
                                    onClick={() => setStep(2)}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="primary"
                                    className="wizard-btn mt-3"
                                    onClick={() => setStep(4)}
                                >
                                    Next
                                </Button>
                            </Tab.Pane>
                            <Tab.Pane eventKey="step4" className="p-5 bg-white rounded ">
                                {data?.has_location ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '40vh', }}  // Full page height and light background
                                    >
                                        <p className="text-success" style={{ fontSize: '24px' }}>
                                            You have set up your location.
                                        </p>
                                    </div>
                                ) : (
                                    <LocationCreate fullPageView={true} getUserDetail={getUserDetail} />
                                )}

                                <Button
                                    variant="secondary"
                                    className="wizard-btn mt-3 me-3"
                                    onClick={() => setStep(3)}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="primary"
                                    className="wizard-btn mt-3"
                                    onClick={() => setStep(5)}
                                >
                                    Next
                                </Button>
                            </Tab.Pane>
                            <Tab.Pane eventKey="step5" className="p-5 bg-white rounded ">

                                {data?.has_emailtempates ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: '40vh', }}  // Full page height and light background
                                    >
                                        <p className="text-success" style={{ fontSize: '24px' }}>
                                            You have set up your email template.
                                        </p>
                                    </div>
                                ) : (
                                    <EmailTemplateCreate fullPageView={true} getUserDetail={getUserDetail} />
                                )}
                                <Button
                                    variant="secondary"
                                    className="wizard-btn mt-3 me-3"
                                    onClick={() => setStep(4)}
                                >
                                    Previous
                                </Button>
                                {/* <Button
                            variant="success"
                            className="wizard-btn mt-3"
                            onClick={handleSubmit}
                        >
                            Finish
                        </Button> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Container>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="danger" onClick={true}>Delete</Button>
                <Button variant="primary">Edit Event</Button>
            </Modal.Footer> */}
        </Modal>

    );
};

export default SettingsWizard;
