import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Form, Image, Modal, Nav, Row, Spinner, Tab, Table } from 'react-bootstrap';
import { CreatedBy, FileUpload, Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import logo from "../../assets/images/default.png"
import { useParams } from 'react-router-dom';
import Layout from '../../layout/fullpage';
import { currencySign, uInfo } from '../../utilities';
import { GOOGLE_AUTH_CLIENT_ID } from "../../store/site";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { ApiUrl, HostUrl } from "../../utilities/config";

const Portal = (props) => {
    const { id, slug } = useParams();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [lists, setLists] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // await fetchRequiredDoc();
            setPageLoader(true);
            console.log(id);
            console.log(slug);

        };
        fetchData();
    }, []);

    const makePortalApiCall = async (apiUrl, method = 'post', submitData = {}) => {
        // Retrieve JWT token from localStorage or wherever you store it
        const tokenString = localStorage.getItem('portalAuthToken');

        let token = JSON.parse(tokenString);

        // Prepare headers with JWT token
        const headers = {
            // 'Authorization': `Bearer ${token.access_token}`, // Include JWT token in the Authorization header
            'Content-Type': 'application/json',
        };

        // Make POST request using Axios
        const response = axios[method](apiUrl, submitData, { headers: headers });
        const responseData = await response;

        return responseData;
    };



    return (
        <Layout title="Lead Portal" >
            <div className="container-fluid my-2">
                <Row>
                    <Col lg={{ span: 10, offset: 1 }}>
                        <Card>
                            {
                                !pageLoader ? (
                                    <Spinner animation="grow" variant="primary"></Spinner>
                                ) : (
                                    <Card.Body className='p-0'>
                                        <header
                                            className="p-1"
                                            style={{
                                                backgroundColor: '#fff',
                                            }}
                                        >

                                            <Row className='align-items-center'>
                                                <Col lg={8}>
                                                    <Image
                                                        src={logo}
                                                        alt={data.list?.domain?.name}
                                                        className="img-responsive"
                                                    />
                                                </Col>
                                                <Col lg={4} className="text-end">
                                                    <p className='mb-0'>
                                                        <b> {data.first_name} {data.last_name} </b>
                                                    </p>

                                                </Col>
                                            </Row>
                                        </header>

                                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh', backgroundColor: '#f0f2f5', padding: '20px 0' }}>
                                            <div className="doc-lists border rounded p-4 shadow-lg" style={{ backgroundColor: '#ffffff', maxWidth: '500px', width: '100%', margin: '0 15px' }}>
                                                <h5 className="text-center mb-4" style={{ color: '#007bff' }}>Link Your Gmail Account</h5>
                                                <div className="row justify-content-center">
                                                    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                                                        <GoogleLogin
                                                            onSuccess={credentialResponse => {
                                                                const userData = jwtDecode(credentialResponse.credential);
                                                                const submitData = new FormData();

                                                                submitData.append('social_email', userData.email);
                                                                submitData.append('aud', userData.aud);
                                                                submitData.append('social_id', btoa(userData.sub.toString()));
                                                                submitData.append('social_type', 'google');
                                                                submitData.append('target_table', 'client');
                                                                submitData.append('auth_type', 'no_auth');
                                                                submitData.append('id', id);
                                                                submitData.append('coach_id', slug);

                                                                makePortalApiCall(ApiUrl + 'connect-social-account', 'post', submitData).then(response => {

                                                                    if (response.data.status === true) {
                                                                        localStorage.setItem('portalAuthToken', JSON.stringify(response.data.data));
                                                                        window.location.href = '/portal/dashboard';
                                                                        return;
                                                                    }
                                                                    if (response.data.status === false) {
                                                                        Swal.fire({
                                                                            title: 'Client Portal does not exist or Invalid Link!',
                                                                            icon: 'error',
                                                                        });

                                                                        return;
                                                                    }
                                                                    // setConnectedAccount(response.data.data.social_email)
                                                                })
                                                            }}
                                                            onError={() => {
                                                                console.log('Login Failed');
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                maxWidth: '300px',
                                                                backgroundColor: '#4285f4',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                padding: '10px 0',
                                                                fontSize: '16px'
                                                            }}
                                                        />
                                                    </GoogleOAuthProvider>
                                                </div>
                                            </div>
                                        </div>




                                    </Card.Body>
                                )
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )

}

export default Portal;
