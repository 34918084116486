// ProtectedRoute.js
import React from 'react';
import { Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
import { useAuth } from '../AuthContext'; 
import { validateTokenExpiration } from '../utilities';

function ProtectedRoute({ path, requiredPermission, element, isPublic = false }) {
    const { user, hasPermission } = useAuth();
    const navigate = useNavigate(); // Get the navigate function from react-router-dom
    const location = useLocation();
     // Get the navigate function from react-router-dom
    validateTokenExpiration();
    
    if (!user && !isPublic) {
        // If user is not logged in and the route is not public, navigate to login page
        return <Navigate to="/portal/login" />;
    }

    if (!isPublic && requiredPermission && !hasPermission(requiredPermission)) {
        // If user doesn't have required permission and the route is not public, navigate to not found page
        return <Navigate to="/not-found" />;
    }

    return React.cloneElement(element, { location, navigate, key: location.pathname })
}

export default ProtectedRoute;
