import { Col, Card, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { OverlineTitle } from '../components';
import Layout from '../layout/default';
import { sendRequest } from '../utilities/api';

function Blog() {

    const [blogs, setBlogs] = useState({ listing: [] });

    console.log(blogs)

    useEffect(() => {
        const fetchData = async () => {
            await fetchBlogs();
        };
        fetchData();
    }, []);

    const fetchBlogs = async () => {
        await sendRequest(`learning/blogs/fetch`, 'POST').then(response => {
            if (response.status === true) {
                setBlogs(response.data);
            }

        }).catch(error => {

            console.error('POST request error:', error);
        });
    }

    return (
        <Layout title="Blogs">
            <Col lg="12">
                <h1 className="nk-page-title">Blogs</h1>
            </Col>

            <Col lg="12">
                <Card className="h-100">
                    <Card.Body className="flex-grow-0 py-2">
                        <div className="card-title-group">
                            <div className="card-title">
                                <h5 className="title">Blogs Listing</h5>
                            </div>
                        </div>
                    </Card.Body>
                    <Table responsive className="table-middle mb-0">
                        <thead className="table-light">
                            <tr>
                                <th className="tb-col ">
                                    <OverlineTitle tag="span">ID</OverlineTitle>
                                </th>
                                <th className="tb-col ">
                                    <OverlineTitle tag="span">Title</OverlineTitle>
                                </th>
                                <th className="tb-col ">
                                    <OverlineTitle tag="span">Desscription</OverlineTitle>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                blogs && blogs.length > 0 ? (
                                    blogs.map((item, i) => {
                                        return <tr key={item.i}>
                                            <td className="tb-col ">
                                                <span className="small">{item.id}</span>
                                            </td>
                                            <td className="tb-col ">
                                                <span className="small">{item.title}</span>
                                            </td>
                                            <td className="tb-col ">
                                                <span className="small">{item.description}</span>
                                            </td>
                                        </tr>
                                    })
                                ) : (
                                    <tr key={1}><td colSpan={9} className="tb-col ">No record found</td></tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Layout>
    )
}

export default Blog;