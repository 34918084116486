import React, { useState } from 'react';
import { OverlayTrigger, Popover, Form, Button } from 'react-bootstrap';
import Select from '../Form/Select';
import Icon from '../Icon/Icon';
import { sendRequest } from '../../utilities/api';

const CreatedBy = ({ title, primaryKey, defaultValue, defaultTitle, meta, handleReset, tableName, updatedKey, showIcon = false }) => {
    const [showPopover, setShowPopoverLocal] = useState(false);

    const handleCrossClick = () => {
        setShowPopoverLocal(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('table_name', tableName);
        submitData.append('updated_key', updatedKey);
        submitData.append('primary_key', primaryKey);

        sendRequest('update/created/by/column', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    handleReset();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });


    };

    return (
        <div className='change-created-by'>
            <OverlayTrigger
                rootClose
                trigger="click"
                placement="top"
                overlay={
                    <Popover id={`popover-positioned-top-${primaryKey}`}>
                        <Popover.Header as="h3">{title}</Popover.Header>
                        <Popover.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className='d-flex justify-content-between'>
                                    <div className='sm w-75'>
                                        <Select name="updated_value" defaultValue={defaultValue}>
                                            <option value={""}>Select</option>
                                            {meta.coachPartners &&
                                                meta.coachPartners.map((partner, i) => (
                                                    <option key={i} value={partner.id}>
                                                        {partner.first_name} {partner.last_name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </div>

                                    <div className='action-btns'>
                                        <Button type='submit' className='ms-1 py-0 px-1' size='sm' variant='outline-light'>
                                            <Icon name={'check'} />
                                        </Button>
                                        <Button className=' py-0 px-1 ms-1' size='sm' type='button' variant='outline-light' onClick={handleCrossClick}>
                                            <Icon name={'cross'} />
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Popover.Body>
                    </Popover >
                }
                show={showPopover}
                onToggle={() => setShowPopoverLocal(!showPopover)}
            >
                <Button size='sm' variant="link" className='px-2 pe-1' onClick={() => setShowPopoverLocal(true)}>
                    {
                        showIcon ?
                            <Icon className={'text-white'} name={'setting-alt'} />
                            :
                            defaultTitle
                    }

                </Button>
            </OverlayTrigger >
        </div>
    );
};

export default CreatedBy;
