import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import { currencySign } from '../../utilities';

const InvoiceForm = (props) => {
    const [items, setItems] = useState([
        { name: '', description: '', quantity: 1, price: 0.0 },
    ]);

    const [products] = useState(props.products ? props.products : []);
    const [paymentMethods] = useState(props.paymentMethods ? props.paymentMethods : []);
    const [releatedId] = useState(props.releatedId ?? 0);
    const [type] = useState(props.type ?? 'leads');


    const [downPayment, setDownPayment] = useState(0.0);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [monthlyPlan, setMonthlyPlan] = useState(1)
    const [balanceDue, setBalanceDue] = useState(0)
    const [futurePayments, setFuturePayments] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [notes, setNotes] = useState("");
    const [systemNotes, setSystemNotes] = useState("");


    const addNewItemRow = () => {
        setItems([...items, { id: '', name: '', description: '', quantity: 1, price: 0.0 }]);
    };

    const updateItemField = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const deleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const calculateItemTotal = (item) => {
        return (parseFloat(item.price) || 0) * (parseInt(item.quantity) || 0);
    };

    const calculateGrandTotal = () => {
        let total = 0;
        items.forEach((item) => {
            total += calculateItemTotal(item);
        });
        setGrandTotal(total.toFixed(2));

    };

    const calculateBalanceDue = () => {
        setBalanceDue((parseFloat(grandTotal) - parseFloat(downPayment)).toFixed(2));
        updateFuturePayments();
    };

    useEffect(() => {
        calculateGrandTotal();
    }, [items]);

    useEffect(() => {
        calculateBalanceDue();
    }, [grandTotal, downPayment]);

    useEffect(() => {
        updateFuturePayments();
    }, [balanceDue, monthlyPlan]);

    const updateFuturePayments = () => {
        if (balanceDue > 0) {
            const currentDate = new Date();
            let currentMonth = currentDate.getMonth();
            const monthlyPayment = balanceDue / monthlyPlan;

            const updatedFuturePayments = [];
            for (let i = 1; i <= monthlyPlan; i++) {
                const nextMonthDate = new Date(currentDate);
                nextMonthDate.setMonth(currentMonth + i);

                updatedFuturePayments.push({
                    payment_date: nextMonthDate.toISOString().split('T')[0],
                    amount: parseFloat(monthlyPayment.toFixed(2)),
                });
            }

            setFuturePayments(updatedFuturePayments);
        } else {
            setFuturePayments([]);
        }
    };

    // Handle onChange for due_date input
    const handleDueDateChange = (index, value) => {
        const updatedFuturePayments = [...futurePayments];
        updatedFuturePayments[index].payment_date = value;
        setFuturePayments(updatedFuturePayments);
    };

    const renderFuturePayments = () => {
        if (balanceDue > 0) {
            return (
                <div>
                    <h4>Future Payments</h4>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Due Date</th>
                                <th>Amount  ({currencySign})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {futurePayments.map((payment, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Control
                                            type='date'
                                            defaultValue={payment.payment_date}
                                            name={`due_date[${index}]`}
                                            onChange={(e) => handleDueDateChange(index, e.target.value)}

                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            step={'any'}
                                            type='number'
                                            value={payment.amount}
                                            name={`amount[${index}]`}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            );
        }

        return null;
    };

    const handelSubmit = () => {
        const submitData = new FormData();
        submitData.append('meta_data', JSON.stringify(items));
        submitData.append('future_payments', JSON.stringify(futurePayments));
        submitData.append('releated_id', releatedId);
        submitData.append('type', type);
        submitData.append('total_amount', grandTotal);
        submitData.append('down_payment', downPayment);
        submitData.append('due_amount', balanceDue);
        submitData.append('installment', monthlyPlan);
        submitData.append('method', paymentMethod);
        submitData.append('notes', notes);
        submitData.append('internal_notes', systemNotes);


        sendRequest(`payments/create`, 'POST', submitData).then(response => {
            if (response.status === true) {
                props.triggerAction(true);
            }

        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const addProductToItem = (id, index) => {
        let product = products.find(item => item.id == id);
        if (product) {
            const newItems = [...items];
            newItems[index]['id'] = product.id;
            newItems[index]['name'] = product.title;
            newItems[index]['description'] = product.description;
            newItems[index]['quantity'] = 1;
            newItems[index]['price'] = product.amount;

            setItems(newItems);

        } else {
            console.log('Object not found');
        }
    }

    return (
        <div>
            <Card>
                <Card.Body>
                    {/* <h2>Invoice Form</h2> */}
                    <div className='items-lists'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td width={150}>
                                            <Form.Group size="sm" className="form-group sm">
                                                <Select className="sm" defaultValue={item.id} onChange={(id) => addProductToItem(id, index)}>
                                                    <option value={''}>Selct Product</option>
                                                    {
                                                        products && products.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                        })
                                                    }
                                                </Select>
                                                <div className="form-control-wrap">
                                                    <Form.Control size="sm" type='hidden' placeholder="Item Name" value={item.name} name='name[]' onChange={(e) => updateItemField(index, 'name', e.target.value)} />
                                                </div>
                                            </Form.Group>

                                        </td>
                                        <td>

                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control size="sm" placeholder="Description" value={item.description} name='description[]' onChange={(e) => updateItemField(index, 'description', e.target.value)} />
                                                </div>
                                            </Form.Group>


                                        </td>
                                        <td>



                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control
                                                        size="sm"
                                                        type='number'
                                                        min={1}
                                                        placeholder="Qty" value={item.quantity} name='quantity[]'
                                                        onChange={(e) => updateItemField(index, 'quantity', e.target.value)} />
                                                </div>
                                            </Form.Group>


                                        </td>

                                        <td>



                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control
                                                        size="sm"
                                                        placeholder="Price" value={item.price} name='price[]'
                                                        onChange={(e) => updateItemField(index, 'price', e.target.value)} />
                                                </div>
                                            </Form.Group>


                                        </td>
                                        <td>
                                            <div className='d-flex jsutify-content-between'>

                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control readOnly
                                                            size="sm"
                                                            placeholder="Price" value={calculateItemTotal(item).toFixed(2)} name='total[]'
                                                        />
                                                    </div>
                                                </Form.Group>

                                                {
                                                    index != 0 && (
                                                        <button className="btn btn-sm btn-link ms-1 p-0" onClick={() => deleteItem(index)}>
                                                            <Icon name={'trash'} />
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </td>

                                    </tr>

                                ))}

                            </tbody>
                        </Table>
                        <button className='btn btn-info btn-sm' onClick={addNewItemRow} type="button">
                            Add Item
                        </button>





                    </div>

                    <div className='row my-2'>
                        <div className='col-md-6'>
                            <Form.Group className="form-group">
                                {/* <Form.Label>Lead Note </Form.Label> */}
                                <div className="form-control-wrap">
                                    <Form.Control
                                        as={'textarea'}
                                        onChange={(e) => setNotes(e.target.value)}
                                        placeholder="Add some notes for leads"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="form-group">
                                {/* <Form.Label>Internal Note </Form.Label> */}
                                <div className="form-control-wrap">
                                    <Form.Control
                                        as={'textarea'}
                                        onChange={(e) => setSystemNotes(e.target.value)}
                                        placeholder="Add some notes for system"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <hr className='mb-1' />
                    <p id="grandTotal" className='text-end mb-0'>Grand Total: {currencySign}{grandTotal}</p>

                    <Row className='mb-3'>
                        <Col md="6" className="mb-2">
                            <Form.Group className="form-group">
                                <Form.Label>Down Payment  ({currencySign})</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control
                                        placeholder="Down Payment"
                                        value={downPayment}
                                        onChange={(e) => {
                                            const enteredValue = parseFloat(e.target.value) || 0;
                                            if (enteredValue > grandTotal) {
                                                Swal.fire("Issue", 'Your down payment is greater than your total payment', 'warning')
                                            } else {
                                                setDownPayment(e.target.value)
                                            }
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Form.Group className="form-group">
                                <Form.Label>Payment Method</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control
                                        as="select"
                                        required
                                        value={paymentMethod}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    >
                                        {/* <option value={''}>Select Method</option> */}
                                        {
                                            paymentMethods.map((row, i) => {
                                                return <option key={i} value={row.type}>{row.name}</option>
                                            })
                                        }

                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Form.Group className="form-group">
                                <Form.Label>Balance Due  ({currencySign})</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control
                                        type="text"
                                        readOnly
                                        placeholder="Balance Due"
                                        value={balanceDue}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Form.Group className="form-group">
                                <Form.Label>Pick A Monthly Plan</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control
                                        as="select"
                                        value={monthlyPlan}
                                        onChange={(e) => setMonthlyPlan(parseInt(e.target.value))}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {renderFuturePayments()}

                    <div className='btns'>
                        <button onClick={handelSubmit} className='btn btn-primary btn-sm '>Save</button>
                        <button onClick={() => props.triggerAction()} className='btn btn-secondary btn-sm ms-2'>Cancel</button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default InvoiceForm;
