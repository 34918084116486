import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";

function UserEdit(props) {
    const { id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta();
        fetchRecordById();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('groups/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = () => {
        sendRequest(`groups/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`groups/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/jobs/category/lists');
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Job Category & Service" content="container-fluid">
            <div className="category-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Job Category & Service</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/jobs/category/lists">Categories & Services</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Control type="hidden" name="type" value={data.type}/>
                                                </Col>
                                                

                                                <Col md="4">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Status</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Select defaultValue={data.status} name="status">
                                                                <option value="">Select Status</option>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>

                                                            </Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">Assign To</Form.Label>
                                                    {meta?.coachPartners.length > 0 && (
                                                        <Select placeholder="Select " defaultValue={data.permissions} name="permissions[]" multiple removeItemButton>
                                                            {
                                                                meta?.coachPartners.map((row, i) => {
                                                                    return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>

                                                <Col className="col-12">
                                                    <Button  type="submit" className="btn-sm">Update</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default UserEdit;