import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Alert, Spinner, Dropdown, Offcanvas, Accordion, Tab, Nav, OverlayTrigger, Popover, ButtonGroup } from 'react-bootstrap';
import Block from '../../components/Block/Block';
import Layout from '../../layout/default';
import { Image, Icon, Media, MediaGroup, Schedule, MediaAction, MediaText, Message, ChatMedia, DateRangePicker } from '../../components';

import { sendRequest } from "../../utilities/api";

import SimpleBar from 'simplebar-react';
import { useAuth } from "../../AuthContext";
import { currencySign } from '../../utilities';
import Swal from 'sweetalert2';
function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
function JobDetails(props) {
	let query = useQuery();
	const { callInit } = useAuth();

	const { id } = useParams();
	const [data, setData] = useState({ pipeline: {} });
	const [loading, setLoading] = useState(false);
	const [meta, setMeta] = useState({});
	const [customField, setCustomField] = useState([]);
	const [customFieldData, setCustomFieldData] = useState({});
	const [assignTags, setAssignTags] = useState([]);
	const [modal, setModal] = useState({ modal: false, record: {} });
	const [activeKey, setActiveKey] = useState('event');



	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
			await fetchRecordById();
		};
		fetchData();
	}, []);


	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'default')
		await sendRequest('jobs/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setCustomField(response.data.customFields.custom_fields);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}


	const fetchRecordById = async () => {
		const formData = new FormData();
		formData.set('type', 'detailPage')
		await sendRequest(`jobs/${id}`, 'POST', formData).then(response => {
			if (response.status === true) {
				setData(response.data.list);
				setCustomFieldData(response.data.list.custom_fields);
				setAssignTags(response.data.list.tags);
			}
			setLoading(true);
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}
	const removeContact = async (job_id, lead_id) => {

		Swal.fire({
			title: 'Are you sure?',
			text: `You want to un-assign/remove this contact`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`jobs/contact/remove/${job_id}/${lead_id}`, 'DELETE')
					.then(response => {
						if (response.status === true) {
							fetchRecordById();
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});
	}

	const tabChange = (tab) => {
		setActiveKey(tab);
	}


	return (
		<Layout content="container-fluid" title="Details" from="lead-detail">
			<div className='parentClasses row' id="chatWrap">
				<Col lg="8" className="pe-0">
					<Tab.Container defaultActiveKey="tabOne">
						<Block.HeadBetween>
							<div className="gap-col">
								<Nav variant="pills" className="nav-pills-border gap g-3">
									<Nav.Item>
										<Nav.Link eventKey="tabOne" style={{ padding: "5px" }}>Job Overview</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="gap-col">
								<ul className="d-flex gap g-2">
									<li>
										<Link to={`/jobs/edit/${data.public_id}`} className="btn btn-sm btn-primary btn-icon" style={{ color: "#fff" }}>
											<Icon name="edit"></Icon>
										</Link>
									</li>
								</ul>
							</div>
						</Block.HeadBetween>
						<Block className="mt-2">
							<Tab.Content>
								<Tab.Pane eventKey="tabOne">
									<Card className="card-gutter-md">
										<div className="card-row card-row-lg col-sep col-sep-lg">
											<div className="card-aside">
												<Card.Body>
													<div className="bio-block">
														<h4 className="bio-block-title">Details</h4>
														<ul className="list-group list-group-borderless small">
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Job ID:</span>
																<span className="text">{data.id}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Record Owner:</span>
																<span className="text">{data.record_owner}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Pipeline</span>
																<span className="text">{data.pipeline?.title}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Milestone</span>
																<span className="text">{data.pipeline_status?.title}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Amount</span>
																<span className="text">{currencySign}{data.amount}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Hours Open</span>
																<span className="text">{data.open_days_hours}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Estimated Hours</span>
																<span className="text">{data.estimated_hours}</span>
															</li>
															<li className="list-group-item">
																<span className="title fw-medium w-40 d-inline-block">Actual Hours</span>
																<span className="text">{data.actual_hours}</span>
															</li>
														</ul>
													</div>
													<div className="bio-block">
														<h4 className="bio-block-title mb-2">Categories</h4>
														<ul className="d-flex flex-wrap gap gx-1">
															{
																data.category_title_array && data.category_title_array.map((item, i) => {
																	return <li><a className="badge text-bg-secondary-soft">{item}</a></li>
																})
															}
														</ul>
													</div>
													<div className="bio-block">
														<h4 className="bio-block-title mb-2">Services</h4>
														<ul className="d-flex flex-wrap gap gx-1">
															{
																data.service_title_array && data.service_title_array.map((item, i) => {
																	return <li><a className="badge text-bg-secondary-soft">{item}</a></li>
																})
															}
														</ul>
													</div>
													<div className="bio-block">
														<h4 className="bio-block-title mb-2">Tags</h4>
														<ul className="d-flex flex-wrap gap gx-1">
															{
																data.tags && data.tags.map((row, i) => {
																	return (<li>
																		<span className="badge "
																			style={{ backgroundColor: row.bg_color, color: row.text_color, padding: '5px 10px', borderRadius: '5px' }}
																		>
																			{row.title}
																		</span>
																	</li>)
																})
															}
														</ul>
													</div>
													<div className="bio-block d-none">
														<h4 className="bio-block-title mb-3">Social</h4>
														<ul className="d-flex flex-wrap gap g-2">
															<li>
																<Media size="sm" to="/" variant="dark">
																	<Icon name="github-circle"></Icon>
																</Media>
															</li>
															<li>
																<Media size="sm" to="/" variant="danger">
																	<Icon name="dribbble"></Icon>
																</Media>
															</li>
															<li>
																<Media size="sm" to="/" variant="info">
																	<Icon name="twitter"></Icon>
																</Media>
															</li>
															<li>
																<Media size="sm" to="/" variant="pink">
																	<Icon name="linkedin"></Icon>
																</Media>
															</li>
														</ul>
													</div>
													<div className="bio-block d-none">
														<h4 className="bio-block-title mb-3">Suggestions</h4>
														<ul className="d-flex flex-column gap gy-2">
															<li>
																<MediaGroup>
																	<Media size="md">
																		<Image src="/images/avatar/a.jpg" alt="user" />
																	</Media>
																	<MediaText>
																		<div className="lead-text">Kamran Ahmed</div>
																		<span className="sub-text">Frontend Developer</span>
																	</MediaText>
																	<MediaAction end>
																		<Button size="sm" variant="secondary" className="btn-icon btn-soft">
																			<Icon name="user-add"></Icon>
																		</Button>
																	</MediaAction>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media size="md">
																		<Image src="/images/avatar/b.jpg" alt="user" />
																	</Media>
																	<MediaText>
																		<div className="lead-text">Harriett Adkins</div>
																		<span className="sub-text">UI/UX Designer</span>
																	</MediaText>
																	<MediaAction end>
																		<Button size="sm" variant="secondary" className="btn-icon btn-soft">
																			<Icon name="user-add"></Icon>
																		</Button>
																	</MediaAction>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media size="md">
																		<Image src="/images/avatar/c.jpg" alt="user" />
																	</Media>
																	<MediaText>
																		<div className="lead-text">George Whalen</div>
																		<span className="sub-text">Backend Developer</span>
																	</MediaText>
																	<MediaAction end>
																		<Button size="sm" variant="secondary" className="btn-icon btn-soft">
																			<Icon name="user-add"></Icon>
																		</Button>
																	</MediaAction>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media size="md">
																		<Image src="/images/avatar/d.jpg" alt="user" />
																	</Media>
																	<MediaText>
																		<div className="lead-text">Claudia Chandler</div>
																		<span className="sub-text">Node.js Developer</span>
																	</MediaText>
																	<MediaAction end>
																		<Button size="sm" variant="secondary" className="btn-icon btn-soft">
																			<Icon name="user-add"></Icon>
																		</Button>
																	</MediaAction>
																</MediaGroup>
															</li>
														</ul>
													</div>
													<div className="bio-block d-none">
														<h4 className="bio-block-title mb-3">Popular Posts</h4>
														<ul className="d-flex flex-wrap gap gy-3">
															<li>
																<MediaGroup>
																	<Media>
																		<Image src="/images/product/a.jpg" alt="post" />
																	</Media>
																	<MediaText>
																		<a href="#post" className="lead-text">How to get creative in your work</a>
																		<span className="sub-text">15 Dec 2021</span>
																	</MediaText>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media>
																		<Image src="/images/product/b.jpg" alt="post" />
																	</Media>
																	<MediaText>
																		<a href="#post" className="lead-text">Create FureStibe branding logo</a>
																		<span className="sub-text">13 Dec 2021</span>
																	</MediaText>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media>
																		<Image src="/images/product/c.jpg" alt="post" />
																	</Media>
																	<MediaText>
																		<a href="#post" className="lead-text">9 Degree Project Estimation</a>
																		<span className="sub-text">10 Dec 2021</span>
																	</MediaText>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media>
																		<Image src="/images/product/d.jpg" alt="post" />
																	</Media>
																	<MediaText>
																		<a href="#post" className="lead-text">Smartest Applications for Business</a>
																		<span className="sub-text">10 Dec 2021</span>
																	</MediaText>
																</MediaGroup>
															</li>
															<li>
																<MediaGroup>
																	<Media>
																		<Image src="/images/product/e.jpg" alt="post" />
																	</Media>
																	<MediaText>
																		<a href="#post" className="lead-text">A well-written bio allows viewers</a>
																		<span className="sub-text">20 Dec 2021</span>
																	</MediaText>
																</MediaGroup>
															</li>
														</ul>
														<div className="pt-3">
															<Link to="/" className="btn btn-sm btn-soft btn-primary">View All</Link>
														</div>
													</div>
												</Card.Body>
											</div>
											<div className="card-content col-sep">
												<Card.Body>
													<div className="bio-block">
														<h4 className="bio-block-title">Description</h4>
														<p dangerouslySetInnerHTML={{ __html: data.description }}></p>
														<Row className="g-gs pt-2">
															<Col lg="6">
																<h5 className="small">Job Site:</h5>
																<div className="small">{data.job_site}</div>
															</Col>
															<Col lg="6">
																<h5 className="small">Lookup Time:</h5>
																<div className="small">{data.lookup_time}</div>
															</Col>
															<Col lg="6">
																<h5 className="small">Site Address:</h5>
																<div className="small">{data.site_address}</div>
															</Col>
															<Col lg="6">
																<h5 className="small">Billing Address:</h5>
																<div className="small">{data.billing_address}</div>
															</Col>
														</Row>
													</div>
													<div className="bio-block">
														<h4 className="bio-block-title">Custom Fields</h4>
														<Row className="g-gs pt-2">
															{
																data.custom_fields_arr && data.custom_fields_arr.map((row, i) => {
																	return <Col lg="6">
																		<h5 className="small">{row.name}:</h5>
																		<div className="small">{row.val}</div>
																	</Col>
																})
															}
														</Row>
													</div>
												</Card.Body>
												<Card.Body>
													<div className="bio-block d-none">
														<h4 className="bio-block-title">Recent Activity</h4>
														<Schedule className="mt-4">
															<Schedule.Item symbol="active">
																<span className="smaller">2:12 PM</span>
																<div className="h6">Added 3 New Images</div>
																<ul className="d-flex flex-wrap gap g-2 pt-2">
																	<li>
																		<Media size="xxl">
																			<Image src="/images/product/a.jpg" alt="gallery-img" thumbnail />
																		</Media>
																	</li>
																	<li>
																		<Media size="xxl">
																			<Image src="/images/product/b.jpg" alt="gallery-img" thumbnail />
																		</Media>
																	</li>
																	<li>
																		<Media size="xxl">
																			<Image src="/images/product/c.jpg" alt="gallery-img" thumbnail />
																		</Media>
																	</li>
																</ul>
															</Schedule.Item>
															<Schedule.Item symbol="active">
																<span className="smaller">4:23 PM</span>
																<div className="h6">Invitation for creative designs pattern</div>
															</Schedule.Item>
															<Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border">
																<span className="smaller">10:30 PM</span>
																<div className="h6">Task report - uploaded weekly reports</div>
																<div className="list-group-dotted mt-3">
																	<div className="list-group-wrap">
																		<div className="p-3">
																			<MediaGroup>
																				<Media className="rounded-0">
																					<Image src="/images/icon/file-type-pdf.svg" alt="icon" />
																				</Media>
																				<MediaText className="ms-1">
																					<a href="#download" className="title">Modern Designs Pattern</a>
																					<span className="text smaller">1.6.mb</span>
																				</MediaText>
																			</MediaGroup>
																		</div>
																		<div className="p-3">
																			<MediaGroup>
																				<Media className="rounded-0">
																					<Image src="/images/icon/file-type-doc.svg" alt="icon" />
																				</Media>
																				<MediaText className="ms-1">
																					<a href="#download" className="title">cPanel Upload Guidelines</a>
																					<span className="text smaller">18kb</span>
																				</MediaText>
																			</MediaGroup>
																		</div>
																		<div className="p-3">
																			<MediaGroup>
																				<Media className="rounded-0">
																					<Image src="/images/icon/file-type-code.svg" alt="icon" />
																				</Media>
																				<MediaText className="ms-1">
																					<a href="#download" className="title">Weekly Finance Reports</a>
																					<span className="text smaller">10mb</span>
																				</MediaText>
																			</MediaGroup>
																		</div>
																	</div>
																</div>
															</Schedule.Item>
															<Schedule.Item symbol="active">
																<span className="smaller">3:23 PM</span>
																<div className="h6">Assigned you to new database design project</div>
															</Schedule.Item>
															<Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border flex-grow-1">
																<span className="smaller">5:05 PM</span>
																<div className="h6">You have received a new order</div>
																<Alert variant="info" className="mt-2">
																	<div className="d-flex">
																		<Icon size="lg" name="file-code" className="opacity-75"></Icon>
																		<div className="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
																			<div>
																				<h6 className="alert-heading mb-0">Business Template - UI/UX design</h6>
																				<span className="smaller">Shared information with your team to understand and contribute to your project.</span>
																			</div>
																			<div className="d-block mt-1">
																				<Button size="md" variant="info">
																					<Icon name="download"></Icon>
																					<span>Download</span>
																				</Button>
																			</div>
																		</div>
																	</div>
																</Alert>
															</Schedule.Item>
															<Schedule.Item symbol="active">
																<span className="smaller">2:45 PM</span>
																<div className="h6">Project status updated successfully</div>
															</Schedule.Item>
														</Schedule>
													</div>
												</Card.Body>
											</div>
										</div>
									</Card>
								</Tab.Pane>
							</Tab.Content>
						</Block>
					</Tab.Container>
				</Col >
				<Col lg="4">
					<div className='chatBodyClasses' id="chatBody">
						<SimpleBar className="nk-chat-panel">
							<div className="m-0">
								<Tab.Container id="details" onSelect={(selectedKey) => tabChange(selectedKey)} defaultActiveKey={'event'} activeKey={activeKey}>
									<Nav variant="tabs" className="nav-tabs-s1 mb-3">
										<Nav.Item>
											<Nav.Link eventKey="event">Contact(s)</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="documents">Documents</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="event">
											<div className="text-end me-3">
												<Button className="mb-2" size="sm" variant="primary" action="add"
													onClick={() => { props.navigate(`/jobs/edit/${data.public_id}`) }}
												>
													<span>Add new</span>
												</Button>
											</div>
											<div className='invoice mx-1'>
												{
													data.lead_array && data.lead_array.map((row, i) => {
														return <div className={"all-activity-area"} key={i}>
															<div className={"list-appointment"}>
																<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																	<div className="nk-schedule-item-inner list-invoice">
																		<div className="nk-schedule-content d-flex justify-content-between flex-column">
																			<div className="d-flex justify-content-between pt-1">
																				<div role="button" onClick={() => setModal({ modal: true, record: row })}>
																					<div className="d-flex gap gx-3">
																						<div class="nk-ibx-item-subject">{row.name}</div>

																					</div>
																					<div className="p nk-ibx-item-message">
																						{row.email}
																					</div>
																				</div>
																				<div className="">
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-success-soft"> {row.phone}</span>
																						</li>
																					</ul>
																					<div className="d-flex justify-content-end">
																						<div className="btns ViewDeleteBtn">

																							<button
																								onClick={() => { props.navigate(`/crm-manage/leads/dialer/${row.related_public_id}`) }}
																								className="p-0 btn"
																								title={"view contact details"}
																							>
																								<Icon name={'eye'} />
																							</button>
																							<button
																								onClick={() => removeContact(data.public_id, row.related_public_id)}
																								className="p-0 btn ms-1">
																								<Icon name={'trash'} />
																							</button>
																						</div>

																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</Message>
															</div>
														</div>
													})
												}
											</div>
										</Tab.Pane>
									</Tab.Content>
									<Tab.Content>
										<Tab.Pane eventKey="documents">
											<div className="container my-3">
												{data.files && data.files.length > 0 ? (
													<div className="document-file p-3 bg-light border rounded">
														<ul className="list-group list-group-flush">
															{data.files.map((file, k) => (
																<li key={k} className="list-group-item">
																	<a href={file.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-primary text-break">
																		{file.key}
																	</a>
																	<a href={file.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-primary text-break fs-5">
																		<Icon role="button" name={'download'} className="ms-2 text-primary cursor-pointer float-end" />
																	</a>
																	{/* <Icon role="button" name={'download'} className="ms-2 text-primary cursor-pointer float-end" /> */}
																	<div className="file-details mt-2">
																		<small className="text-muted">Added by: {file.added_by}</small>
																		<br />
																		<small className="text-muted">Added at: {new Date(file.added_at).toLocaleString()}</small>
																	</div>
																</li>
															))}
														</ul>
													</div>
												) : (
													<div className="alert alert-warning" role="alert">
														No documents available.
													</div>
												)}
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</div>
						</SimpleBar>
					</div>
				</Col>
			</div >
		</Layout >
	)
}

export default JobDetails;
