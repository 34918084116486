import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Dropdown, Form, ListGroup, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { sendRequest } from '../../../../utilities/api';
import { FileUpload, Icon } from '../../../../components';

const DocumentLists = (props) => {
    const [leadId] = useState(props.leadId);
    const [lists, setLists] = useState([]);
    const [showAddFolderForm, setShowAddFolderForm] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([
        { description: '', due_days: 1 },
    ]);
    const addNewItemRow = () => {
        setItems([...items, { description: '', due_days: 1, }]);
    };

    const updateItemField = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const deleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };


    useEffect(() => {
        const fetchData = async () => {
            await fetchDocumentList();
        };
        fetchData();
    }, []);

    const fetchDocumentList = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        await sendRequest('templates/document/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.records);
                setShowAddFolderForm(false);
                setData({});
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('documents', JSON.stringify(items));

        let url = "templates/document/create";
        if (data.public_id) {
            url = `templates/document/update/${data.public_id}`;
        }

        sendRequest(url, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchDocumentList();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };


    const deleteRecord = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this folder`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`templates/document/${id}`, 'Delete')
                    .then(response => {
                        if (response.status === true) {
                            fetchDocumentList();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });

    };    

    const reset = (edit = false, row) => {
        if (edit) {
            setData(row);
            setItems(JSON.parse(row.documents));
        } else {
            setItems([{ description: '', due_days: 1 }]);
        }
        setShowAddFolderForm(!showAddFolderForm);
    }


    return (
        <div className='doc-lists mb-2'>



            <Button size='sm' onClick={() => reset()} variant="outline-primary mt-1">  Add Template</Button>

            <div className='form my-2'>
                {
                    showAddFolderForm && (
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="form-group mb-1">
                                <div className="form-control-wrap">
                                    <Form.Control defaultValue={data.title ?? ''} type="text" name="title" placeholder='Template Name:' required />
                                </div>
                            </Form.Group>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Due In X Days</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>

                                            <td>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control required size="sm" placeholder="Description" value={item.description} name='description[]' onChange={(e) => updateItemField(index, 'description', e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </td>

                                            <td>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control required size="sm" type='number' placeholder="Number of days" value={item.due_days} name='due_days[]' onChange={(e) => updateItemField(index, 'due_days', e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </td>

                                            <td>
                                                {
                                                    index != 0 && (
                                                        <button type='button' className="btn btn-sm btn-link ms-1 p-0" onClick={() => deleteItem(index)}>
                                                            <Icon name={'trash'} />
                                                        </button>
                                                    )
                                                }
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <ButtonGroup size='sm' className='mt-2' aria-label="Basic example">
                                <Button type='submit' variant="outline-primary">Save</Button>
                                <Button onClick={() => addNewItemRow()} variant="outline-info">Add More</Button>
                                <Button onClick={() => reset()} variant="outline-secondary">Cancel</Button>
                            </ButtonGroup>

                        </Form>
                    )
                }
            </div>

            <div className='doc-list table-responsive'>
                {
                    !showAddFolderForm && (
                        <table className='table mb-1'>
                            <thead>
                                <tr>
                                    <td>Sr.No</td>
                                    <td>Name</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lists.map((row, i) => {
                                        return (
                                            <tr key={i} className='my-2'>
                                                <td>{i+1}</td>
                                                <td>{row.title}</td>
                                                <td>
                                                    <button className="btn p-0" onClick={() => reset(true, row)}>
                                                        <Icon name="edit"></Icon>
                                                    </button>

                                                    &nbsp;

                                                    <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                                        <Icon name="trash" className='text-danger'></Icon>
                                                    </button>

                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    )
                }


            </div>

        </div>
    )

}

export default DocumentLists;
