import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { toInitials } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";

function UserEdit(props) {
    const { id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        fetchMeta();
        fetchRecordById();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('groups/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = () => {
        sendRequest(`groups/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setSelectedOption(response.data.list.section);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`groups/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/tags-manage/lists');
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };

    const handleRadioChange = (e) => {
        let selectedOption = e.target.value;

        setSelectedOption(selectedOption)
    }

    return (
        <Layout title="Tags" content="container-fluid">
            <div className="group-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Tags</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/tags-manage/lists">Tags</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Status</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Select defaultValue={data.status} name="status">
                                                                <option value="">Select Status</option>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>

                                                            </Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                <Col md="3">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="bg_color">Background Color</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="color" name="bg_color" defaultValue={data.bg_color} required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="3">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="text_color">Text Color</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="color" name="text_color" defaultValue={data.text_color} required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {data.type === 'Tag' && (
                                                    <>
                                                        <Col md="6">
                                                            <Form.Label htmlFor="section">Related To Section</Form.Label>

                                                            <div>
                                                                <Form.Check
                                                                    inline
                                                                    type="checkbox"
                                                                    label="Contact"
                                                                    name="section"
                                                                    value="Contact"
                                                                    onChange={handleRadioChange}
                                                                    disabled
                                                                    checked
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    type="checkbox"
                                                                    label="Job"
                                                                    name="section"
                                                                    value="Job"
                                                                    defaultChecked={(selectedOption === 'Job' || selectedOption === 'Both') ? 'Job' : ''}
                                                                    onChange={handleRadioChange}
                                                                />

                                                                {/* <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label="Both"
                                                                    name="section"
                                                                    value="Both"
                                                                    checked={selectedOption === 'Both'}
                                                                    onChange={handleRadioChange}
                                                                /> */}
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}

                                                <input type="hidden" name="type" value={data.type} />

                                                <Col className="col-12">
                                                    <Button type="submit" className="btn-sm">Update</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default UserEdit;