import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';

import { Card, Button, Row, Col, Form, Spinner, InputGroup, ListGroup } from 'react-bootstrap';

import { Select } from '../../../components';

function BasicLeadCreate({ id, currentType, setCurrentType, coachPartners, type = '' }) {


    return (
        <>
            {type != 'primary' && (
                <Row className="g-3 my-2 mx-0">
                    {!id && (
                        <Col md="3">
                            <Form.Label htmlFor="type">Type</Form.Label>

                            <div>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Individual"
                                    name="type"
                                    value="0"
                                    checked={currentType == '0'}
                                    onChange={(e) => setCurrentType(e.target.value)}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Business"
                                    name="type"
                                    value="1"
                                    checked={currentType == '1'} onChange={(e) => setCurrentType(e.target.value)}
                                />
                            </div>
                        </Col>
                    )}

                    <Col md="3">
                        <Form.Label htmlFor="type">Record Type</Form.Label>

                        <div>
                            <Form.Check
                                inline
                                type="radio"
                                label="Lead"
                                name="record_type"
                                value="0"
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="Client"
                                name="record_type"
                                value="1"
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <Row className="g-3 mx-0">
                <Col md={type == 'primary' ? "12" : "4"}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="name">{currentType == '0' ? '' : 'Company'} Name</Form.Label>
                        <div className="form-control-wrap">
                            <Form.Control type="text" name="name" placeholder="Name" required />
                        </div>
                    </Form.Group>
                </Col>

                <Col md={type == 'primary' ? "12" : "4"}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <div className="form-control-wrap">
                            <Form.Control type="email" name="email" placeholder="Email" required />
                        </div>
                    </Form.Group>
                </Col>

                {currentType == '0' && (
                    <Col md={type == 'primary' ? "12" : "4"}>
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="date_of_birth">Date of Birth</Form.Label>
                            <div className="form-control-wrap">
                                <Form.Control type="date" name="date_of_birth" placeholder="Date of Birth" />
                            </div>
                        </Form.Group>
                    </Col>
                )}

                {type != 'primary' && (
                    <>
                        <Col md="4">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="Mobile">Mobile</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control type="number" name="mobile" placeholder="Mobile" />
                                </div>
                            </Form.Group>
                        </Col>

                        <Col md="4">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="Phone">Phone</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control type="number" name="phone" placeholder="Phone" />
                                </div>
                            </Form.Group>
                        </Col>

                        <Col md="4">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="role">Record Owner</Form.Label>
                                {coachPartners?.length > 0 && (
                                    <Select name="coach_partner_id" defaultValue={coachPartners?.coach_partner_params?.coach_partner_id} removeItemButton>
                                        {
                                            coachPartners.map((row, i) => {
                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Group>
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}

export default BasicLeadCreate