import { useEffect, useState, useRef, Fragment } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import { isObjectNotEmpty } from '../../utilities';
function CamapignList() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [responseData, setResponseData] = useState({});

    const [lists, setLists] = useState([]);
    const formRef = useRef(null);
    const childRef = useRef(null);

    useEffect(() => {
        fetchRecords(new FormData());
    }, []);

    const fetchRecords = (formData) => {

        if (isObjectNotEmpty) {
            Object.entries(formData).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }

        sendRequest('campaign/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        fetchRecords(formData);
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            fetchRecords(new FormData());
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`groups/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };

    useEffect(() => {
        // Listen for changes in expandedRows and trigger API calls
        Object.keys(expandedRows).forEach((public_id) => {
            if (expandedRows[public_id] && !responseData[public_id]) {
                fetchCampaignReport(public_id);
            }
        });
    }, [expandedRows, responseData]);

    const toggleExpand = (public_id) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [public_id]: !prevState[public_id],
        }));
    }

    const fetchCampaignReport = (id) => {
        const formData = new FormData();
        formData.append('id', id);
        sendRequest(`groups/${id}`, 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    setResponseData({ ...responseData, [id]: response.data.list });
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });
    }


    return (
        <Layout title="Campaign List" content="container-fluid">
            <div className="Campaign">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Campaign List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Campaigns</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                {
                                    hasPermission('createGroup') && (
                                        <li>
                                            <Link className='btn btn-primary d-md-inline-flex btn-sm' to="/campaign-manage/create">
                                                <Icon name="plus" />
                                                <span>Add</span>
                                            </Link>

                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                    </Col>

                                    <Col>
                                        <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                    </Col>

                                    <Col>
                                        <Form.Select size="sm" name="status" >
                                            <option value="">All </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>

                            <Table responsive className="table-bordered mt-2 Campaign table-sm table-striped"  >
                                <thead>
                                    <tr>
                                        <th className='d-none'>ID#</th>
                                        <th>Title</th>
                                        <th>Group</th>
                                        {/* <th>From Email</th> */}
                                        <th>Phone Number</th>
                                        <th className='text-center'>Status</th>
                                        <th>Created Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        lists.map((row, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <tr>
                                                        <td className='d-none'>
                                                            <button onClick={() => toggleExpand(row.public_id)} className="btn btn-color-primary btn-icon btn-soft btn-sm p-0">
                                                                {expandedRows[row.public_id] ?
                                                                    <Icon name="caret-down-fill"></Icon>
                                                                    :
                                                                    <Icon name="caret-right-fill"></Icon>
                                                                }
                                                            </button>

                                                        </td>
                                                        <td>{row.title}</td>
                                                        <td>{row.group_title}</td>
                                                        <td>{row.phone_formated}</td>
                                                        <td className='text-center'>{row.status}</td>
                                                        <td>{row.dated}</td>
                                                        <td>
                                                            <Link className="btn btn-link p-0" to={`/campaign-manage/details/${row.public_id}/info`}>
                                                                <Icon name="edit"></Icon>
                                                            </Link>
                                                            
                                                            &nbsp; | &nbsp;

                                                            <button className="btn btn-link p-0" onClick={() => deleteRecord(row.public_id)}>
                                                                <Icon name="trash" className='text-danger'></Icon>
                                                            </button>
                                                        </td>

                                                    </tr>
                                                    {expandedRows[row.public_id] && responseData[row.public_id] && (
                                                        <tr>
                                                            <td>{responseData[row.public_id].public_id}</td>
                                                            <td colSpan="6">{responseData[row.public_id].status}</td>

                                                        </tr>
                                                    )}
                                                </Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>


                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default CamapignList;
