import { useState } from 'react';
import Dropzone from 'react-dropzone';

import { Button } from 'react-bootstrap';
import Icon from '../Icon/Icon';

function FileUpload({ iconName, maxFiles, maxSize, errorText, onSubmitCallback, releatedId = 0, relatedModule = '', preview, ...props }) {
  const [files, setFiles] = useState([]);
  const [dropzoneError, setDropzoneError] = useState('');

  // convert file size bytes to MB
  const bytesToMegaBytes = bytes => bytes / (1024 ** 2);

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles, file) => {
    file(acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
    );

    setDropzoneError('');
  };

  // preview thumbs
  const thumbs = files.map(file => (
    <div className="dz-preview dz-processing dz-image-preview dz-complete" key={file.name}>
      <div className="dz-image">
        <img src={file.preview} alt="preview" />
      </div>
    </div>
  ));

  const handleDropRejected = (rejectedFiles) => {
    // Set dynamic error message based on rejection reasons
    const errorMessages = rejectedFiles.map((file) => {
      if (file.errors) {
        console.log(file)
        // return `File is larger than ${bytesToMegaBytes(file.file.size).toFixed(2)} MB`;
        return "The file '"+  file.file.name + "' " + file.errors[0].message.replace('File', '') + ' and cannot be uploaded' ?? ` File is larger than ${bytesToMegaBytes(maxSize).toFixed(2)} MB`

      }
      return 'File rejected for an unknown reason.';
    });

    setDropzoneError(`File upload failed: </br> ${errorMessages.join('<br>')}`);
  };

  const handleDropAccepted = (acceptedFiles) => {
    if (
      acceptedFiles.length > 0 &&
      (acceptedFiles.length <= (maxFiles || Infinity) &&
        acceptedFiles.every((file) => file.size <= (maxSize || Infinity)))
    ) {
      onSubmitCallback(acceptedFiles, releatedId, relatedModule);
    }
  };

  return (
    <>
      <Dropzone
        onDrop={acceptedFiles => handleDropChange(acceptedFiles, setFiles, handleDropAccepted(acceptedFiles))}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={(rejectedFiles) => handleDropRejected(rejectedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone dz-clickable">
            <input {...getInputProps()} />
            {/* {files.length === 0 && ( */}
              <div className="dz-message">
                <div className="dz-message-icon">
                  <Icon size="md" name={iconName} />
                </div>
                <span className="dz-message-text">Click here or Drop Files <small>{maxSize && 'Max ' + bytesToMegaBytes(maxSize).toFixed(2) + ' MB'}</small></span>
                <div className="dz-message-btn mt-2 d-none">
                  <Button size="md" vaiant="primary">Upload</Button>
                </div>
              </div>
            {/* )} */}



            {dropzoneError && <div className="error-message text-danger w-100" dangerouslySetInnerHTML={{ __html: dropzoneError }} />}


            {preview && thumbs}
          </div>
        )}
      </Dropzone>
    </>
  )
}

export default FileUpload