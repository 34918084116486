import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Table, Modal, Tabs, Tab, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from "../../../../utilities/api";
import $ from "jquery";
import Swal from "sweetalert2";
import { Icon, Image, Media, Select, Tinymce } from "../../../../components";
import MediaManager from "../../../MediaManager";
window.jQuery = $;
window.$ = $;

require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')

function LeadFormBuilder(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false);
    const [modal, setModal] = useState({ status: false });
    const [showMedia, setShowMedia] = useState({ releatedId: 0, show: false });
    const [activeTab, setActiveTab] = useState("step0"); // State to track active tab
    const [formBuilders, setFormBuilders] = useState([]);
    const [tabsData, setTabsData] = useState([]); // Define tabsData state
    const [mappingFields, setMappingFields] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
        };
        fetchData();
    }, []);


    const fetchRecordById = async () => {
        await sendRequest(`crm/leads/funnels/${id}`, 'POST').then(response => {
            if (response.status === true) {
                let datam = response.data.list;
                datam.mapping_field = response.data.list.metadata.flat();
                setMappingFields(response.data.list.metadata.flat());
                setData(datam);
                setTabsData(generateInitialTabs(response.data.list.metadata.length));
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const generateInitialTabs = (numberOfTabs) => {
        const initialTabs = [];
        for (let i = 0; i < numberOfTabs; i++) {
            const newKey = `step${i}`
            const newTitle = `Step`;
            initialTabs.push({ key: newKey, title: newTitle });
        }
        return initialTabs;
    };

    const addTab = () => {
        const timestamp = new Date().getTime(); // Get current timestamp
        const newKey = `step${tabsData.length + 1}_${timestamp}`
        const newTitle = `Step`;

        const newData = { ...data, metadata: [...data.metadata, []] };
        setData(newData);

        const newTabsData = [...tabsData, { key: newKey, title: newTitle }];
        setActiveTab(newKey);
        setTabsData(newTabsData);
    };

    const handleTabRemove = (keyToRemove, indexToRemove) => {

        const newData = { ...data };
        const newFormBuilders = [...formBuilders];
        // Check if the index is within the valid range of indices in the metadata array
        if (indexToRemove >= 0 && indexToRemove < newData.metadata.length) {
            // Remove the specified index from the metadata array
            newData.metadata.splice(indexToRemove, 1);
            newFormBuilders.splice(indexToRemove, 1);
            setFormBuilders(newFormBuilders);
            // Update the state with the modified data object
            setData(newData);
        } else {
            console.error("Invalid index to remove from metadata array.");
        }


        const updatedTabsData = tabsData.filter(tab => tab.key !== keyToRemove);
        setTabsData(updatedTabsData);
    }

    const renderFormBuilder = (tabIndex) => {
        const formBuilder = formBuilders[tabIndex];
        if (!formBuilder) {
            var fields = [{
                label: "Signature",
                type: "textarea",
                subtype: "signature",
                value: false,
                icon: "🖊️",
                name: "signature-" + new Date().getTime()
            }];
            const fbOptions =
            {
                formData: data.metadata[tabIndex] ?? "",
                disabledAttrs: ["value", "placeholder", "access", "name"],
                disabledActionButtons: ['data', 'save'],
                disableFields: ['autocomplete', 'file', 'hidden'],
                subtypes: {
                    textarea: ['signature'],
                },
                disabledSubtypes: {
                    textarea: ['tinymce', 'quill'],
                },
                typeUserDisabledAttrs: {
                    'textarea': [
                        'subtype', 'maxlength', 'rows', 'value'
                    ]
                },
                inputSets: [
                    {
                        label: 'Contact Info',
                        name: 'info',
                        showHeader: true,
                        icon: "👤",
                        fields: [
                            {
                                type: 'text',
                                label: 'First Name',
                                className: 'form-control',
                                name: "firstName-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'Last Name',
                                className: 'form-control',
                                name: "lastName-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'Email',
                                className: 'form-control',
                                name: "email-" + new Date().getTime(),
                                subtype: "email",
                            },
                            {
                                type: 'text',
                                label: 'Phone',
                                className: 'form-control',
                                name: "phone-" + new Date().getTime()
                            }
                        ]
                    },
                    {
                        label: 'Address',
                        name: 'address',
                        showHeader: true,
                        icon: "📬",
                        fields: [
                            {
                                type: 'text',
                                label: 'Address',
                                className: 'form-control',
                                name: "address-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'Address Line2',
                                className: 'form-control',
                                name: "address2-" + new Date().getTime()

                            },
                            {
                                type: 'text',
                                label: 'City',
                                className: 'form-control',
                                name: "city-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'State',
                                className: 'form-control',
                                name: "state-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'Zip',
                                className: 'form-control',
                                name: "zip-" + new Date().getTime()
                            },
                            {
                                type: 'text',
                                label: 'Country',
                                className: 'form-control',
                                name: "country-" + new Date().getTime()
                            },
                        ]
                    }

                ],
                fields,
            };
            const newFormBuilder = $('.fb-editor').eq(tabIndex).formBuilder(fbOptions);
            setFormBuilders(prevFormBuilders => [...prevFormBuilders, newFormBuilder]);
        }
    };


    useEffect(() => {
        tabsData.forEach((tab, index) => {
            renderFormBuilder(index);
        });
    }, [tabsData]);


    const handleSaveAllClick = () => {
        const formDataArray = [];
        formBuilders.forEach(formBuilder => {
            formDataArray.push(formBuilder.actions.getData());
        });
        const formData = new FormData();
        formData.append('metadata', JSON.stringify(formDataArray));
        formData.append('id', id);
        sendRequest(`crm/leads/funnels/updateMetadata/${id}/metadata`, 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    setMappingFields(response.data.record.metadata.flat());

                    Swal.fire({ icon: 'success', title: response.message })
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const openModal = (data) => {
        setModal(data);
    }

    const closeModal = () => {
        setModal({ status: false });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const submitData = new FormData();

        const formData = new FormData(event.currentTarget);
        for (let [key, value] of formData.entries()) {
            if (key === 'text') {
                setData(prevData => ({
                    ...prevData,
                    [modal.type]: {
                        ...prevData[modal.type],
                        [key]: value
                    }
                }));
            } else {

                submitData.append(key, value);
                setData(prevData => ({
                    ...prevData,
                    [key]: value
                }));

            }
        }

        if (modal.type == 'header_section') {
            submitData.append('header_section', JSON.stringify(data.header_section));
        }

        if (modal.type == 'footer_section') {
            submitData.append('footer_section', JSON.stringify(data.footer_section));
        }

        if (modal.type == 'theme') {
            submitData.append('theme', JSON.stringify(data.theme));
        }

        submitData.append('id', id);

        sendRequest(`crm/leads/funnels/updateMetadata/${id}/${modal.type}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire({ icon: 'success', title: response.message })
                    closeModal();
                    if (modal.type == 'mapped_data') {
                        fetchRecordById();
                    }
                };
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };

    const handleChange = (section, event) => {
        const { name, value, checked } = event.target;

        setData(prevData => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [name]: value === 'on' ? checked : value
            }
        }));

    }

    const handleMediaManager = (status, data = '') => {
        if (status) {

            setData(prevData => ({
                ...prevData,
                [modal.type]: {
                    ...prevData[modal.type],
                    [showMedia.releatedId]: data
                }
            }));

            // if (modal.type == 'header_section') {
            //     setData(prevData => ({
            //         ...prevData,
            //         header_section: {
            //             ...prevData.header_section,
            //             image_url: data
            //         }
            //     }));
            // }

        }
        setShowMedia({ releatedId: 0, show: false })
    }







    return (
        <Layout title="Funnel Builder" content="container">
            <div className="funnel-edit">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="h5 breadcrumb-item"><Link to="/crm-manage/leads/funnels/lists">Forms</Link></li>
                                    <li className="h5 breadcrumb-item active" aria-current="page">{data.name}</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <div className="btns-nav">
                                <Button onClick={() => openModal({ status: true, type: 'header_section', name: 'Header Section' })} size={"sm"} variant="light">Header</Button>
                                <Button onClick={() => openModal({ status: true, type: 'footer_section', name: 'Footer Section' })} size={"sm"} variant="light">Footer</Button>
                                <Button onClick={() => openModal({ status: true, type: 'redirect_url', name: 'Redirect Url' })} size={"sm"} variant="light">Redirect Url</Button>
                                <Button onClick={() => openModal({ status: true, type: 'welcome_text', name: 'Welcome Page Text' })} size={"sm"} variant="light">Welcome Page Text</Button>
                                <Button onClick={() => openModal({ status: true, type: 'thankyou_text', name: 'Thank You Page' })} size={"sm"} variant="light">Thank You Page</Button>
                                <Button onClick={() => openModal({ status: true, type: 'cta', name: 'CTA Section' })} size={"sm"} variant="light">CTA</Button>
                                <Button onClick={() => openModal({ status: true, type: 'mapped_data', name: 'Fields Mapping' })} size={"sm"} variant="light">Fields Mapping</Button>

                                <Button onClick={() => openModal({ status: true, type: 'theme', name: 'Theme' })} size={"sm"} variant="light">
                                    <Icon name={'color-palette-fill'} />
                                </Button>
                                <Button onClick={() => openModal({ status: true, type: 'embed_code', name: ' Embed Code' })} size={"sm"} variant="light">
                                    <Icon name={'eye'} />
                                </Button>
                            </div>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <div className="d-flex">
                                    <div className="editor-tabs">
                                        <Tabs
                                            activeKey={activeTab}
                                            onSelect={(key) => setActiveTab(key)}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                        >
                                            {
                                                tabsData.map((tab, i) => {
                                                    return (
                                                        <Tab key={tab.key} eventKey={tab.key} title={
                                                            <div>
                                                                {tab.title}
                                                                {
                                                                    i > 0 && (
                                                                        <button className="p-0 btn btn-color-danger  btn-sm btn-icon btn-soft" onClick={() => handleTabRemove(tab.key, i)}>
                                                                            <Icon name="cross"></Icon>
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        }>
                                                            <div id={"fb-editor" + i} className={"fb-editor"} />
                                                        </Tab>
                                                    )
                                                })
                                            }
                                        </Tabs>
                                    </div>
                                    <div className="extraEditorButton me-5">
                                        <Button className="me-2" variant="info" size="sm" onClick={addTab}>Add Step</Button>
                                        <Button size="sm" variant="primary" onClick={handleSaveAllClick}>Save</Button>
                                    </div>
                                </div>
                            </div>



                        </Card.Body>
                    </Card>

                </Block>

                <Modal show={modal.status} onHide={() => closeModal()} size="lg">
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title className="event-title" as="h4">{modal.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                modal.type == 'header_section' && (
                                    <Row className="g-3">
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="name">Logo Text</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control onChange={(e) => handleChange('header_section', e)} type="text" name="logo_text" required defaultValue={data.header_section?.logo_text} />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="name">Text Color</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control onChange={(e) => handleChange('header_section', e)} type="color" name="text_color" required defaultValue={data.header_section?.text_color} />
                                                </div>
                                            </Form.Group>
                                        </Col>



                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <div className="d-flex justify-content-between">
                                                    <Form.Label htmlFor="name">Phone Number
                                                    </Form.Label>
                                                    <Form.Check defaultChecked={data.header_section?.phone_status} onChange={(e) => handleChange('header_section', e)} name="phone_status" label="On / Off" type="checkbox"></Form.Check>
                                                </div>
                                                <div className="form-control-wrap ">
                                                    <Form.Control onChange={(e) => handleChange('header_section', e)} type="text" name="phone_number" required defaultValue={data.header_section?.phone_number} />


                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group d-flex justify-content-end">
                                                <Form.Label htmlFor="name"></Form.Label>
                                                <div className="form-control-wrap mt-1">
                                                    <Form.Check defaultChecked={data.header_section?.status} onChange={(e) => handleChange('header_section', e)} name="status" label="Header On / Off" type="checkbox"></Form.Check>

                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <button type="button" className="btn btn-light btn-sm mb-1"
                                                    onClick={() => setShowMedia({ releatedId: 'image_url', show: true })}
                                                >
                                                    Select Logo
                                                </button>
                                                <br />

                                                <div className="design_type" >
                                                    <img src={data.header_section?.image_url} alt={''} />
                                                </div>


                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'footer_section' && (
                                    <Row className="g-3">
                                        <Col lg="12">
                                            <Tinymce
                                                leadToken={false}
                                                userToken={false}
                                                height={300}
                                                initialValue={data.footer_section?.text} textareaName={'text'} toolbar={true}
                                            />
                                        </Col>

                                        <Col md="4">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="name"></Form.Label>
                                                <div className="form-control-wrap mt-1">
                                                    <Form.Check defaultChecked={data.footer_section?.status} onChange={(e) => handleChange('footer_section', e)} name="status" label="Footer On / Off" type="checkbox"></Form.Check>

                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'redirect_url' && (
                                    <Row className="g-3">
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="name">Redirect Url</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.redirect_url} type="url" name="redirect_url" required />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'welcome_text' && (
                                    <Row className="g-3">
                                        <Col lg="12">
                                            <Tinymce
                                                leadToken={false}
                                                userToken={false}
                                                height={300}
                                                initialValue={data.welcome_text} textareaName={'welcome_text'} toolbar={true}
                                            />
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'thankyou_text' && (
                                    <Row className="g-3">
                                        <Col lg="12">
                                            <Tinymce
                                                leadToken={false}
                                                userToken={false}
                                                height={300}
                                                initialValue={data.thankyou_text} textareaName={'thankyou_text'} toolbar={true}
                                            />
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'cta' && (
                                    <Row className="g-3">
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="name">CTA Text</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control maxLength={20} defaultValue={data.cta} type="text" name="cta" required />
                                                    <p className="small note">(Up To 20 characters.)</p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'theme' && (
                                    <Row className="FormRightSide">
                                        <Col lg="6">
                                            <Row className="g-3">
                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">Design Type</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Check value={"full"} onChange={(e) => handleChange('theme', e)} name="design_type" defaultChecked={data.theme?.design_type === 'full'} inline label="Full Screen" type="radio" ></Form.Check>
                                                            <Form.Check value={"boxed"} onChange={(e) => handleChange('theme', e)} name="design_type" defaultChecked={data.theme?.design_type === 'boxed'} inline label="Boxed Design" type="radio" ></Form.Check>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {
                                                    data.theme?.design_type === 'full' && (
                                                        <Col lg="12">
                                                            <Row className="g-1">
                                                                <Col lg="6">

                                                                    <button type="button" className="btn btn-light btn-sm"
                                                                        onClick={() => setShowMedia({ releatedId: 'left_image_url', show: true })}
                                                                    >
                                                                        Left Image
                                                                    </button>

                                                                    <br />
                                                                    <span className="note text-secondary">Dimension : (461 X 761)</span>

                                                                    <div className="design_type" >

                                                                        <img src={data.theme?.left_image_url} alt={''} />
                                                                    </div>

                                                                </Col>
                                                                <Col lg="6">
                                                                    <button type="button" className="btn btn-light btn-sm"
                                                                        onClick={() => setShowMedia({ releatedId: 'right_image_url', show: true })}
                                                                    >
                                                                        Right Image
                                                                    </button>

                                                                    <br />
                                                                    <span className="note text-secondary">Dimension : (461 X 761)</span>

                                                                    <div className="design_type" >

                                                                        <img src={data.theme?.right_image_url} alt={''} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }

                                                {
                                                    data.theme?.design_type === 'boxed' && (
                                                        <Col lg="12">
                                                            <Row className="g-1">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="name">Featured Image</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Check value={"image"} onChange={(e) => handleChange('theme', e)} name="feature_type" defaultChecked={data.theme?.feature_type === 'image'} inline label="Image" type="radio" ></Form.Check>
                                                                        <Form.Check value={"video"} onChange={(e) => handleChange('theme', e)} name="feature_type" defaultChecked={data.theme?.feature_type === 'video'} inline label="Video" type="radio" ></Form.Check>
                                                                    </div>
                                                                </Form.Group>

                                                                {
                                                                    data.theme?.feature_type === 'image' && (

                                                                        <Col lg="12">
                                                                            <button type="button" className="btn btn-light btn-sm"
                                                                                onClick={() => setShowMedia({ releatedId: 'feature_url', show: true })}
                                                                            >
                                                                                Select Image
                                                                            </button>
                                                                            <br />
                                                                            <span className="note text-secondary">Dimension : (461 X 761)</span>

                                                                            <div className="design_type" >
                                                                                <img src={data.theme?.feature_url} alt={''} />
                                                                            </div>

                                                                        </Col>
                                                                    )
                                                                }

                                                                {
                                                                    data.theme?.feature_type === 'video' && (
                                                                        <Col lg="12">
                                                                            <Form.Group className="form-group">
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control placeholder="Enter video url" defaultValue={data.theme?.feature_url} type="url" onChange={(e) => handleChange('theme', e)} name="feature_url" />
                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Row>
                                                        </Col>
                                                    )
                                                }

                                                <Col lg="12">

                                                    <Row className="g-3">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Background Image/Color</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Check value={"image"} onChange={(e) => handleChange('theme', e)} name="background_type" defaultChecked={data.theme?.background_type === 'image'} inline label="Image" type="radio" ></Form.Check>
                                                                <Form.Check value={"color"} onChange={(e) => handleChange('theme', e)} name="background_type" defaultChecked={data.theme?.background_type === 'color'} inline label="Color" type="radio" ></Form.Check>
                                                            </div>
                                                        </Form.Group>

                                                        {
                                                            data.theme?.background_type === 'image' && (

                                                                <Col lg="12">
                                                                    <button type="button" className="btn btn-light btn-sm"
                                                                        onClick={() => setShowMedia({ releatedId: 'background_image_color', show: true })}
                                                                    >
                                                                        Select Image
                                                                    </button>
                                                                    <br />
                                                                    <span className="note text-secondary">Dimension : (1300 X 900)</span>

                                                                    <div className="design_type" >
                                                                        <img src={data.theme?.background_image_color} alt={''} />
                                                                    </div>

                                                                </Col>
                                                            )
                                                        }

                                                        {
                                                            data.theme?.background_type === 'color' && (
                                                                <Col lg="12">
                                                                    <Form.Group className="form-group">
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.theme?.background_image_color} type="color" onChange={(e) => handleChange('theme', e)} name="background_image_color" />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            )
                                                        }

                                                        <Col lg="12">

                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="name">Overlay:  <b>{data.theme?.overlay / 100}</b></Form.Label>
                                                                <div className="form-control-wrap">

                                                                    <Form.Range bsPrefix={'form-range'} step={10} defaultValue={data.theme?.overlay} onChange={(e) => handleChange('theme', e)} name="overlay" />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col lg="6">
                                            <Row className="g-1">
                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">Pick your color scheme</Form.Label>
                                                        <div className="form-control-wrap">

                                                            <Form.Control type="color" defaultValue={data.theme?.color_scheme} onChange={(e) => handleChange('theme', e)} name="color_scheme" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <b>Create your custom scheme :</b>
                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">Question Heading</Form.Label>
                                                        <div className="form-control-wrap">

                                                            <Form.Control type="color" defaultValue={data.theme?.question_heading} onChange={(e) => handleChange('theme', e)} name="question_heading" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">Answers</Form.Label>
                                                        <div className="form-control-wrap">

                                                            <Form.Control type="color" defaultValue={data.theme?.answer} onChange={(e) => handleChange('theme', e)} name="answer" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">Buttons</Form.Label>
                                                        <div className="form-control-wrap">

                                                            <Form.Control type="color" defaultValue={data.theme?.button} onChange={(e) => handleChange('theme', e)} name="button" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="name">CTA Color</Form.Label>
                                                        <div className="form-control-wrap">

                                                            <Form.Control type="color" defaultValue={data.theme?.cta_color} onChange={(e) => handleChange('theme', e)} name="cta_color" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'mapped_data' && (
                                    <Row className="g-3">
                                        <Col lg="12">

                                            {
                                                mappingFields.length < 1 && (
                                                    <Alert variant="danger">
                                                        First create form fields.
                                                    </Alert>
                                                )
                                            }
                                            {
                                                mappingFields.length > 0 && (
                                                    <div className="border py-3 px-3">
                                                        <div className="d-flex space-between">
                                                            <Table className="table table-light mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Label</th>
                                                                        <th>System Map Columns</th>
                                                                    </tr>
                                                                    {
                                                                        mappingFields && mappingFields.map((row, index) => {
                                                                            let label = row.label.replace(/(<([^>]+)>)/gi, "");
                                                                            var pattern = /^signature-\d+$/;
                                                                            if (row.name && !row.name.match(pattern)) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{label.length > 30 ? label.substring(0, 30) + '...' : label}</td>
                                                                                        <td>
                                                                                            <Select
                                                                                                type="select"
                                                                                                name={`fields[${row.name}]`}
                                                                                                value={data.mapped_data[row.name]}
                                                                                                defaultValue={data.mapped_data[row.name]}
                                                                                                at={row.label}
                                                                                                a={index}
                                                                                            >
                                                                                                <option value="">Map Column</option>
                                                                                                <optgroup label="Default Fields">
                                                                                                    {data.fields.default_fields.map((column, c) => {
                                                                                                        return (
                                                                                                            <option a={column.key}
                                                                                                                value={`${column.key}`}>{column.label}</option>
                                                                                                        )
                                                                                                    })}
                                                                                                </optgroup>
                                                                                                <optgroup label="Custom Fields">
                                                                                                    {data.fields.custom_fields.map((column, c) => {
                                                                                                        return (
                                                                                                            <option a={column.key}
                                                                                                                value={`${column.key}`}>{column.label}</option>
                                                                                                        )
                                                                                                    })}
                                                                                                </optgroup>
                                                                                            </Select>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </thead>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                                modal.type == 'embed_code' && (
                                    <div className="embed">
                                        <div className="url">
                                            <a
                                                href={`${data.domain_url}`}
                                                target="_blank"
                                                className="pr-5 mr-5"
                                            >
                                                {data.domain_url}
                                            </a>

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="overlay-example">
                                                        Copy Form url
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-link btn-md"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`${data.domain_url}`);
                                                        Swal.fire("Action performed successfully");
                                                    }}
                                                >
                                                    <Icon name="copy"></Icon>
                                                </button>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="url d-flex">
                                            <label className="pt-3">Iframe Embed Code </label>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="overlay-example">
                                                        Copy iframe Embed Code
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-link btn-md"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${data.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
                                                        Swal.fire("Action performed successfully");
                                                    }}
                                                >
                                                    <Icon name="copy"></Icon>
                                                </button>
                                            </OverlayTrigger>

                                        </div>
                                        <textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${data.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>
                                    </div>
                                )
                            }


                        </Modal.Body>
                        <Modal.Footer>
                            <Button size='sm' variant="danger" onClick={() => { closeModal(); }}>Close</Button>
                            {
                                modal.type != 'embed_code' && (
                                    <Button disabled={(modal.type == 'mapped_data' && mappingFields.length < 1)} type="submit" className="btn-sm">Save</Button>
                                )
                            }
                        </Modal.Footer>
                    </Form>
                </Modal>

                {
                    showMedia.show && (
                        <MediaManager selectionType="single" data={showMedia} handleMediaManager={handleMediaManager} />
                    )
                }
            </div>
        </Layout>
    )
}

export default LeadFormBuilder;