import { useLayoutEffect, useRef } from 'react'
import { Form } from 'react-bootstrap';
import Choices from 'choices.js';

function Select({ onChange, ...props }) {

  const { allowHTML = false, name, multiple, disabled, className, placeholder, removeItemButton, shouldSort, searchEnabled, searchPlaceholder, defaultValue } = props;

  const selectInput = useRef(null);
  useLayoutEffect(() => {
    const choicesInstance = new Choices(selectInput.current, {
      silent: true,
      allowHTML: allowHTML,
      searchEnabled: false || searchEnabled,
      placeholder: true,
      placeholderValue: placeholder,
      searchPlaceholderValue: 'Search' || searchPlaceholder,
      shouldSort: false || shouldSort,
      removeItemButton: false || removeItemButton,

    });

    const handleChange = event => {
      const selectedOptions = event.target.selectedOptions;
      const selectedIds = Array.from(selectedOptions).map(option => option.value);

      if (onChange) {
        onChange(selectedIds);
      }
    };

    selectInput.current.addEventListener('change', handleChange);

    return () => {
      selectInput.current.removeEventListener('change', handleChange);
      //  choicesInstance.destroy();
    };
  }, [onChange])

  return (
    <>
      <Form.Select name={name} defaultValue={defaultValue} className={className} ref={selectInput} multiple={multiple} disabled={disabled}>
        {/* {placeholder && <option value=''>{placeholder}</option> } */}
        {props.children}
      </Form.Select>
    </>
  )
}

export default Select