import React, { useEffect, useState } from 'react';
import { sendRequest } from '../../../../utilities/api';

import Swal from 'sweetalert2';
import { Tab, Nav, OverlayTrigger, Card, Tooltip } from 'react-bootstrap';
import * as SignaturePad from 'signature_pad';
import { sleep } from '../../../../utilities';

const FormWizard = ({ steps, currentStep, onNext, onPrev, onSubmit, onChange, formData, cta, ctaButtonColor, ctaColor, question,
  answer, theme, layout }) => {
  const totalSteps = steps.length;

  const [tab, setTab] = useState("");
  const [textSig, setTextSig] = useState("");
  const [drawSig, setDrawSig] = useState([]);
  const [requiredSig, setRequiredSig] = useState(false);

  useEffect(() => {
    // Update requiredSig state based on metadata
    const pattern = /^signature-\d+$/;
    let merge = steps.flat();
    if (merge && merge.some(row => row.type === "textarea" && row.name.match(pattern))) {
      const row = merge.find(row => row.type === "textarea" && row.name.match(pattern));
      setRequiredSig(row.required);
      setTab("type_it");
    } else {
      setRequiredSig(false); // Reset requiredSig if no matching rows found
    }
  }, [steps]); // Run this effect whenever metadata changes

  const toggle = (index) => {
    if (tab !== index) {
      setTab(index);
    }
  };

  let signaturePad = null;
  if (document.querySelector('canvas') && tab == 'draw_it') {
    var canvas = document.querySelector('canvas');
    signaturePad = new SignaturePad.default(canvas, { backgroundColor: 'rgb(255, 255, 255)', width: '100%' });
    signaturePad.addEventListener("beginStroke", () => {
      handleChange({ target: { name: 'drawing', value: 'true' } });
      setDrawSig(signaturePad.toData());
    }, { once: true });


    document.getElementById('clear').addEventListener('click', function () {
      signaturePad.clear();
      setDrawSig([]);
    });

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signaturePad) {
      onSubmit(signaturePad.toDataURL('image/jpeg'));
    } else {
      onSubmit();
    }
  };

  const handleChange = async (e, type = "") => {
    const { name, value } = e.target;
    let updateName = name.replace(/\[\]/g, "");
    onChange(updateName, value, type);
    const pattern = /^signature-\d+$/;
    const stepFields = steps[currentStep];
    if (stepFields.some(row => row.type === "textarea" && row.name.match(pattern))) {
      const row = stepFields.find(row => row.type === "textarea" && row.name.match(pattern));
      if (tab === 'type_it') {
        onChange(row.name, 'initial');
        let object = {
          'digital_signature': 'initial',
          'canvas_jason': '',
          'signature_type': 'type_it',
          'initial': value
        }
        onChange('signature', object);
      } else if (tab === 'draw_it') {
        onChange(row.name, 'drawing');
        let object = {
          'digital_signature': '',
          'canvas_jason': JSON.stringify(drawSig),
          'signature_type': 'draw_it',
          'digital_signature_image_url': signaturePad.toDataURL('image/jpeg')
        }
        onChange('signature', object);

      }
    }
  };

  const renderStep = (stepData) => {
    return stepData && stepData.map((row, i) => {
      if (row.type == "text" || row.type == "date" || row.type == "number") {
        return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
          <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
            {row.required && (<span className='text-danger'>*</span>)}
            {row.description && (
              <OverlayTrigger
                key={row.type + '--' + i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    {row.description}
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle pl-3"></i>
              </OverlayTrigger>
            )}
          </label>
          <input
            style={{ color: answer }}
            type={row.type}
            placeholder={row.placeholder}
            name={row.name}
            // defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
            //readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
            maxLength={row.maxlength ? row.maxlength : ''}
            className={row.className}
            id={row.name}
            title={row.description}
            required={row.required}
            value={formData[row.name] || ''}
            onChange={handleChange}

          />
        </div>
      } else if (row.type == "select") {
        return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.name}>`}>
          <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
            {row.required && (<span className='text-danger'>*</span>)}
            {row.description && (
              <OverlayTrigger
                key={row.type + '--' + i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    {row.description}
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle pl-3"></i>
              </OverlayTrigger>
            )}
          </label>
          <select style={{ color: answer }} className={row.className} name={row.name}
            value={formData[row.name] || ''}
            onChange={handleChange}
          // disabled={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}

          >
            {row.values.map((row, i) => {
              return <option key={row.value + '--' + i} value={row.value}


              //selected={data.lead_rec_array[data.mapped_data[row.name]] == row.value ? 'selected' : ''}
              //disabled={row.value == data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
              >{row.label}</option>

            })}
          </select>
        </div>

      } else if (row.type == "radio-group") {
        return <div key={row.type + '--' + i} className={`${row.inline && ('d-flex align-items-center')}  fb-select mb-3 form-group field-${row.name}>`}>
          <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label pe-3`}>{row.label}
            {row.required && (<span className='text-danger'>*</span>)}
            {row.description && (
              <OverlayTrigger
                key={row.type + '--' + i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    {row.description}
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle pl-3"></i>
              </OverlayTrigger>
              // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
            )}
          </label>
          {row.values.map((option, i) => {
            return <div key={option.value + '--' + i} className={` form-check ${row.className} ${row.inline && ('mb-0 form-check-inline')}`}>
              <input style={{ color: answer }}
                checked={formData[row.name] === option.value || ''}
                onChange={handleChange}
                name={row.name} type="radio"
                className="form-check-input"
                value={option.value}
              />
              <label className="form-check-label">{`${option.label}`}</label>
            </div>
          })}
        </div>
      } else if (row.type == "checkbox-group") {
        return <div key={row.type + '--' + i} className={`${row.inline && ('d-flex align-items-center')} fb-select mb-3 form-group field-${row.name}>`}>
          <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label pe-3 `}>{row.label}
            {row.required && (<span className='text-danger'>*</span>)}
            {row.description && (
              <OverlayTrigger
                key={row.type + '--' + i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    {row.description}
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle pl-3"></i>
              </OverlayTrigger>
            )}
          </label>
          {row.values.map((option, i) => {
            return <div key={option.value + '--' + i} className={` form-check ${row.className} ${row.inline && ('mb-0 form-check-inline')}`}>
              <input style={{ color: answer }}
                name={`${row.name}[]`} type="checkbox"
                className=" form-check-input"
                checked={formData[row.name] && formData[row.name].includes(option.value)}
                // checked={formData[row.name] === option.value || ''}
                onChange={(e) => handleChange(e, 'checkbox')}
                value={`${option.value}`}
              />
              <label className="form-check-label">{`${option.label}`}</label>
            </div>
          })}
        </div>
      } else if (row.type == "textarea") {
        var pattern = /^signature-\d+$/;

        if (row.name.match(pattern)) {
          return <div key={row.type + '--' + i}>
            <Tab.Container id="details" onSelect={(selectedKey) => toggle(selectedKey)} defaultActiveKey={tab} activeKey={tab}>
              <Nav variant="tabs" className="nav-tabs-s1 bg-white">
                <Nav.Item>
                  <Nav.Link eventKey="type_it" className='px-3'>Type it</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="draw_it" className='px-3'>Draw it</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane className='mb-3 px-3 bg-white border border-top-0' eventKey="type_it">
                  <div className="pt-20">
                    <div className="row">
                      <div className="col-md-6">
                        <label className={`mt-3`}>
                          Type Your Signature (Full Name)
                        </label>
                        {row.required && (<span className='text-danger'>*</span>)}
                        <div className={`mb-3 form-group`}>
                          <input
                            id="text-sig"
                            type="text"
                            className="form-control"
                            value={textSig}
                            name={row.name}
                            required={tab == '1' ? true : false}
                            onChange={(e) => {
                              setTextSig(e.target.value);
                              handleChange(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className={`mt-3`}>

                        </label>
                        <div className="outer_type_it">
                          <div className="type_it">{textSig}</div>

                        </div>

                      </div>
                    </div>

                  </div>
                </Tab.Pane>
                <Tab.Pane className='mb-3 p-3  bg-white border border-top-0' eventKey="draw_it">
                  <div>
                    <div className='d-flex'>
                      <div className="canvas-wrapper" style={{ border: '2px solid #000', width: '300px' }}>
                        <canvas id="signature-pad" className="signature-pad" ></canvas>
                      </div>

                      <button className="btn btn-link" type="button" id="clear">Clear</button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

          </div>

        } else {
          return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
            <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
              {row.required && (<span className='text-danger'>*</span>)}
              {row.description && (
                <OverlayTrigger
                  key={row.type + '--' + i}
                  placement={'auto'}
                  overlay={
                    <Tooltip>
                      {row.description}
                    </Tooltip>
                  }
                >
                  <i className="fas fa-question-circle pl-3"></i>
                </OverlayTrigger>
              )}
            </label>
            <textarea style={{ color: answer }}
              type={row.type}
              placeholder={row.placeholder}
              name={row.name}
              //defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
              //readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
              maxLength={row.maxlength ? row.maxlength : ''}
              className={row.className}
              id={row.name}
              title={row.description}
              required={row.required}
              value={formData[row.name] || ''}
              onChange={handleChange}

            >

            </textarea>

          </div>
        }
      } else if (row.type == 'file') {
        return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
          <label style={{ color: question }} htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
            {row.required && (<span className='text-danger'>*</span>)}
            {row.description && (
              <OverlayTrigger
                key={row.type + '--' + i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    {row.description}
                  </Tooltip>
                }
              >
                <i className="fas fa-question-circle pl-3"></i>
              </OverlayTrigger>
            )}
          </label>
          <input style={{ color: answer }}
            type={row.type}
            placeholder={row.placeholder}
            name={row.name}
            className={row.className}
            id={row.name}
            title={row.description}
            required={row.required}
          />
        </div>
      } else if (row.type == 'paragraph') {
        return <div key={row.type + '--' + i} className="fb-text  form-group "><div style={{ color: theme }} dangerouslySetInnerHTML={{ __html: row.label }}></div></div>
      } else if (row.type == 'header') {
        return <div key={row.type + '--' + i} className="fb-text  form-group "><p style={{ color: theme }} className={row.subtype}>{row.label}</p></div>
      } else if (row.type == 'button') {
        return <p></p>
      }
    });

  };

  return (
    <div>
      {/* <h2>{steps[currentStep][0].label}</h2> */}
      <form className={`UserFormFunnel ${layout}`} onSubmit={handleSubmit}>
        {renderStep(steps[currentStep])}


        {/* <div className='col-lg-12'>
          <span className="input-group-btn" id="button_1">
            <button className="button button--tamaya button--border-thick" 
            data-text="Lets Go!"
             type="button" onclick="move_to_next1.call(this,'mdf_14');">Lets Go!<Icon name="arrow-right"></Icon></button>
          </span>
        </div> */}
        <div>
          {currentStep > 0 && (
            <button className='btn btn-md me-1' style={{ backgroundColor: theme, color: '#fff', border: theme }} type="button" onClick={() => { onPrev(); setTab('type_it') }}>
              Previous
            </button>
          )}
          {currentStep < totalSteps - 1 && (
            <button className='btn btn-md ' style={{ backgroundColor: ctaButtonColor, color: ctaColor, border: ctaColor }} type="button" onClick={() => { onNext(); setTab('type_it') }}>
              {currentStep == 0 ? cta : 'Next'}
            </button>
          )}
          {currentStep === totalSteps - 1 && (
            <button style={{ backgroundColor: ctaButtonColor, color: ctaColor, border: ctaColor }} className='btn btn-md' type="submit">Submit</button>
          )}
        </div>
      </form >
    </div >
  );
};

export default FormWizard;
