import { useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Button, Offcanvas, Alert, Modal, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { Logo, Image, Icon, MediaAction, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components';
import Menu from './Menu'
import ToggleSidebar from '../Toggle/Sidebar'
import ToggleNavbar from '../Toggle/Navbar'

import { useLayout, useLayoutUpdate } from './../LayoutProvider'

import { useAuth } from '../../../AuthContext';
import { uInfo, uPublicId, convertPhoneFormat } from '../../../utilities';
import { sendRequest } from '../../../utilities/api';
import Swal from 'sweetalert2';

function QuickNav({ className, ...props }) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({ className, ...props }) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
    return (
        <li className={compClass}>{props.children}</li>
    )
}

function Header() {
    const { user, logout } = useAuth();
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [lists, setLists] = useState([]);
    const [modal, setModal] = useState(false);
    const [newLocation, setNewLocation] = useState({ location_id: 0, location_name: '' });

    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    });
    const [existing, setExisting] = useState([]);
    const [isClient, setIsClient] = useState(true);
    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    });

    // offcanvas
    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const handleOffcanvasShow = () => setShowOffcanvas(true);

    const fetchLocation = () => {
        sendRequest(`assigned/location/${uInfo().id}`, 'GET').then(response => {
            if (response.status === true) {
                setLists(response.data.lists);
                setModal(true);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });

    }

    const updateUserDefaultLocation = (location_id) => {
        const formData = new FormData();
        formData.append('location_id', location_id)
        sendRequest(`users/update/location/${uPublicId()}`, 'POST', formData).then(response => {
            if (response.status === true) {
                Swal.fire(response.message);
                const localStorageData = JSON.parse(localStorage.getItem('authToken'));
                if (localStorageData) {
                    localStorageData.user.location_name = response.data.location_name;
                    localStorageData.user.location_id = response.data.location_id;

                }
                localStorage.setItem('authToken', JSON.stringify(localStorageData));
                setNewLocation(response.data);
                setModal(false);
                window.location.href = '/home';

            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }
    const searchClient = (search) => {
        if (search != "") {
            const formData = new FormData();
            formData.append('filter_value', search);
            formData.append('type', 'search');
            sendRequest(`crm/leads/lists`, 'POST', formData).then(response => {
                if (response.status === true) {
                    setExisting(response.data.records);
                    setIsClient(response.data.records.length > 0 ? true : false);
                }
            })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        } else {
            setExisting([]);
            setIsClient(true);
        }
    };
    return (
        <>
            <div className={compClass}>
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu' />
                            <ToggleNavbar className="me-2" />
                            <Logo />
                        </div>
                        {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        <nav className={navClass}>

                            {
                                (uInfo().type == 'Manager' || uInfo().type == 'SubManager') && (
                                    <span className='LocationName'><span className='currentLocation'>Current Location : &nbsp;</span>  {newLocation.location_name != '' ? newLocation.location_name : uInfo().location_name}
                                        <Button onClick={() => fetchLocation()} className='p-1' variant='link'><Icon name={'edit'} /></Button>
                                    </span>
                                )
                            }


                            <Modal show={modal} onHide={() => setModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Change Location
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Form.Select
                                        name="note_type"
                                        defaultValue={newLocation.location_id != 0 ? newLocation.location_id : uInfo().location_id}
                                        onChange={(e) => updateUserDefaultLocation(e.target.value)}
                                    >
                                        {
                                            lists.map((row, i) => {
                                                return <option value={row.id} key={i}>{row.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Modal.Body>
                            </Modal>

                            {/* <Menu /> */}
                        </nav>
                        <div className="nk-header-tools">
                            <QuickNav>
                                {
                                    (uInfo().type == 'Manager' || uInfo().type == 'SubManager') && (
                                        <Dropdown as={QuickNavItem}>
                                            <Dropdown.Toggle variant="zoom" size="sm" bsPrefix className="btn-icon d-sm-none">
                                                <Icon name="search"></Icon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Toggle variant="zoom" size="md" bsPrefix className="btn-icon d-none d-sm-inline-flex">
                                                <Icon name="search"></Icon>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropdown-menu-lg">
                                                <div className="dropdown-content dropdown-content-x-lg py-1">
                                                    <div className="search-inline">
                                                        <div className="form-control-wrap flex-grow-1">
                                                            <input
                                                                placeholder="Search Contact..."
                                                                type="text"
                                                                onChange={(e) => searchClient(e.target.value)}
                                                                onBlur={() =>
                                                                    setTimeout(() => {
                                                                        setExisting([]);
                                                                    }, 1000)
                                                                }
                                                                className="form-control-plaintext" />
                                                        </div>
                                                        <LinkListItem to={`/crm-manage/leads/create`}>
                                                            <Icon name="plus" size="sm"></Icon>
                                                        </LinkListItem>
                                                    </div>
                                                </div>
                                                <Dropdown.Divider className="m-0"></Dropdown.Divider>
                                                {existing.length > 0 && (
                                                    <div className="dropdown-content dropdown-content-x-lg py-3">
                                                        <ul className="dropdown-list gap gy-2">
                                                            {!isClient && <li className="text-primary">No data found</li>}
                                                            {existing.map((row, i) => {
                                                                return (
                                                                    <li key={i}>
                                                                        <MediaGroup>
                                                                            <MediaText>
                                                                                <div className="lead-text">{row.first_name} {row.last_name}</div>
                                                                                <span className="sub-text">{convertPhoneFormat(row.lead_number)}</span>
                                                                                <span className="sub-text">{row.email}</span>
                                                                            </MediaText>
                                                                            <MediaAction end>
                                                                                <Link
                                                                                    to={
                                                                                        "/crm-manage/leads/dialer/" + row.public_id
                                                                                    }
                                                                                    target="_parent"
                                                                                >
                                                                                    <Button size="md" variant="zoom" className="btn-icon me-n1"><Icon name="eye"></Icon></Button>
                                                                                </Link>
                                                                            </MediaAction>
                                                                        </MediaGroup>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                                }
                                {/* <QuickNavItem>
                                    <button className="btn-icon btn btn-zoom btn-sm d-sm-none" onClick={handleOffcanvasShow}>
                                        <Icon name="bell"></Icon>
                                    </button>
                                    <button className="btn-icon btn btn-zoom btn-md d-none d-sm-inline-flex" onClick={handleOffcanvasShow}>
                                        <Icon name="bell"></Icon>
                                    </button>
                                </QuickNavItem> */}
                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>

                                        <div className="d-inline-flex d-sm-none">
                                            <Media shape="circle" size="md">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                            </Media>
                                        </div>
                                        <div className="d-none d-sm-flex">
                                            <Media shape="circle">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                            </Media>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div className="dropdown-content py-1 border-bottom border-light">
                                            <MediaGroup>
                                                <Media size="xl" shape="circle">
                                                    <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                                </Media>
                                                <MediaText>
                                                    <div className="lead-text">{user?.user?.name}</div>
                                                    <span className="sub-text">{user?.user?.email}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="dropdown-content py-1 border-bottom border-light">
                                            <LinkList>
                                                <LinkListItem to={`/settings/profile/${uPublicId()}`}><Icon name="user"></Icon><span>My Profile</span></LinkListItem>
                                                {/* <LinkListItem to="/admin/profile"><Icon name="contact"></Icon><span>My Contacts</span></LinkListItem>
                                                <LinkListItem to="/admin/profile-settings"><Icon name="setting-alt"></Icon><span>Account Settings</span></LinkListItem> */}
                                            </LinkList>
                                        </div>
                                        <div className="dropdown-content py-1">
                                            <LinkList>
                                                <Button onClick={() => logout()} className="btn-soft px-0" variant="link"><Icon name="signout"></Icon><span>Log Out</span></Button>
                                            </LinkList>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SimpleBar>
                        <Schedule>
                            <Schedule.Item symbol="active">
                                <span className="smaller">2:12 PM</span>
                                <div className="h6">Added 3 New Images</div>
                                <ul className="d-flex flex-wrap gap g-2 pt-2">
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/a.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/b.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/c.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                </ul>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">4:23 PM</span>
                                <div className="h6">Invitation for creative designs pattern</div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border">
                                <span className="smaller">10:30 PM</span>
                                <div className="h6">Task report - uploaded weekly reports</div>
                                <div className="list-group-dotted mt-3">
                                    <div className="list-group-wrap">
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-pdf.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Modern Designs Pattern</a>
                                                    <span className="text smaller">1.6.mb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-doc.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Cpanel Upload Guidelines</a>
                                                    <span className="text smaller">18kb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-code.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Weekly Finance Reports</a>
                                                    <span className="text smaller">10mb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                    </div>
                                </div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">3:23 PM</span>
                                <div className="h6">Assigned you to new database design project</div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border flex-grow-1">
                                <span className="smaller">5:05 PM</span>
                                <div className="h6">You have received a new order</div>
                                <Alert variant="info" className="mt-2">
                                    <div className="d-flex">
                                        <Icon size="lg" name="file-code" className="opacity-75"></Icon>
                                        <div className="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
                                            <div>
                                                <h6 className="alert-heading mb-0">Business Template - UI/UX design</h6>
                                                <span className="smaller">Shared information with your team to understand and contribute to your project.</span>
                                            </div>
                                            <div className="d-block mt-1">
                                                <Button size="md" variant="info">
                                                    <Icon name="download"></Icon>
                                                    <span>Download</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">2:45 PM</span>
                                <div className="h6">Project status updated successfully</div>
                            </Schedule.Item>
                        </Schedule>
                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header