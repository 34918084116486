import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import { currencySign } from '../../utilities';
// import InvoiceDetails from './Details';
function ReportsPayment() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const [meta, setMeta] = useState({});
    const formRef = useRef(null);
    const childRef = useRef(null);
    const [viewinvoice, setViewInvoice] = useState({ modal: false, invoiceId: 0, history: {} });
    const [userColumns] = useState([
        {
            name: "Invoice No",
            key: 'history_no',
            selector: (row) => row.history_no,
            cell: (row) => (
                <span>{row.history_no}</span>
            ),
        },

        {
            name: "Invoice For",
            key: 'payment.invoice_for.first_name',
            selector: (row) => row.payment?.invoice_for?.first_name,
            cell: (row) => (
                <span>{row.payment?.invoice_for?.first_name} {row.payment?.invoice_for?.last_name}</span>
            ),
        },

        {
            name: "Type",
            key: 'payment.method_name',
            selector: (row) => row.payment?.method_name,
            cell: (row) => (
                <span>{row.payment?.method_name}</span>
            ),
        },
        {
            name: "Amount",
            key: 'amount_formated',
            selector: (row) => row.amount_formated,
            cell: (row) => (
                <span className='price-end'>{currencySign}{row.amount_formated}</span>
            ),
        },
        {
            name: <div className='w-100 text-center'>Status</div>,
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span >
                    {row.status}
                </span>
            ),
        },
        {
            name: <div className='w-100 text-start'>Dated</div>,
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },
        // {
        //     name: "Action",
        //     key: 'action',
        //     selector: (row) => row.action,
        //     cell: (row) => (
        //         <div className="text-center w-100">
        //             <LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">
        //                 <button className="btn p-0" onClick={() => viewInvoice(row.payment?.public_id, row)}>
        //                     <Icon name="eye"></Icon>
        //                 </button>
        //             </LinkList>
        //         </div>
        //     ),
        // },
    ]);


    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
        };
        fetchData();
    }, []);



    const fetchMeta = async (formData = new FormData()) => {
        await sendRequest('payments/history/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            fetchMeta(formData);
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                fetchMeta();
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };
    
    return (
        <Layout title="Payment List" content="container-fluid">
            <div className="Payments">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Payment List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Payments</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>

                <Card className='mb-2'>
                    <Card.Body >
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <Row className="g-3">
                                <Col>
                                    <Form.Select
                                        size="sm"
                                        onChange={(e) =>
                                            e.target.value == "Custom Date"
                                                ? setCustomDateFilter(true)
                                                : setCustomDateFilter(false)
                                        }
                                        name="date_range">
                                        <option value="">Filter by Date</option>
                                        <option value="Current Week">Current Week</option>
                                        <option value="Last Week">Last Week</option>
                                        <option value="Current Month">Current Month</option>
                                        <option value="Last Month">Last Month</option>
                                        <option value="Last 3 Months">Last 3 Months</option>
                                        <option value="Last 6 Months">Last 6 Months</option>
                                        <option value="Current Year">Current Year</option>
                                        <option value="Last Year">Last Year</option>
                                        <option value="Custom Date">Custom Date</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                </Col>

                                <Col>
                                    <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                </Col>

                                <Col>
                                    <Form.Select size="sm" name="method" >
                                        <option value="">All </option>
                                        {
                                            meta.paymentMethods && meta?.paymentMethods.map((row, i) => {
                                                return <option key={i} value={row.type}>{row.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>

                                <Col>
                                    <Form.Select size="sm" name="status" >
                                        <option value="">All </option>
                                        <option value="Due">Due/Unpaid</option>
                                        <option value="Paid">Paid</option>
                                        {/* <option value="Unpaid">Unpaid</option> */}
                                        <option value="Failed">Failed</option>
                                        <option value="Refund">Refund</option>

                                    </Form.Select>
                                </Col>

                                <Col>
                                    <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                    <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>


                <Row>
                    {
                        meta.logs && meta.logs.map((row, i) => {
                            return <Col lg="2" className='mb-2' key={i}>
                                <Card>
                                    <Card.Body className='p-2 pb-0'>
                                        <div className="card-title">
                                            <h6 className="title mb-1">
                                                {row.name}
                                            </h6>
                                        </div>
                                        <div className="my-2">
                                            <div className="amount h5 fw-bold text-primary">
                                                {currencySign}{row.value}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })
                    }
                </Row>



                <Block>
                    <Card>
                        <Card.Body>


                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive noWordBreak"
                                slug={'payments/history/lists'}
                                actions={true}
                                defaultRow={1000}
                            />

                        </Card.Body>
                    </Card>
                </Block>

                {/* {
                    viewinvoice.modal && (
                        <InvoiceDetails viewinvoiceAction={viewinvoiceAction} params={viewinvoice} />
                    )
                } */}
            </div>
        </Layout>
    )
}

export default ReportsPayment;
