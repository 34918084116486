import React, { useEffect, useState } from "react"
import { Device } from "twilio-client";
import { sendRequest } from "../../utilities/api";
import { Offcanvas } from "react-bootstrap";
import { Icon } from "../../components";
import Incoming from "./Incoming";

const Incall = props => {
    const [conn, setConn] = useState(false);
    const [device, setDevice] = useState(false);
    const [page2Class, setPage2Class] = useState('');
    const [displayMessage, setdisplayMessage] = useState('');
    const [showSlider, setShowSlider] = useState(false);
    const [state, setStateVal] = useState("Connecting");

    useEffect(() => {
        handleOutCall();
    }, []);



    const handleOutCall = async () => {
        await sendRequest(`voice/token`, 'GET', []).then(response => {
            if (response.status === true) {
                setConn(true);


                const device = new Device();
                device.setup(response.data.token, { debug: false });

                device.on("ready", () => {
                    setDevice(device);
                    //setStateVal("Ready");
                });
                device.on("connect", (connection) => {
                    console.log("Connect event");
                    setShowSlider(true);
                    setConn(connection);
                    setStateVal("ON_CALL");
                    setdisplayMessage("Connected with phone number  " + connection.parameters.From);
                });
                device.on("disconnect", () => {
                    //setStateVal("Ready");
                    //setConn(null);
                    console.log("disconnect", 'abc');
                    setdisplayMessage("Incoming call disconnected");
                    setStatusOnReject();
                    device.destroy();
                    setDevice(null);
                });
                device.on("incoming", (connection) => {
                    setStateVal("Incoming");
                    setConn(connection);
                    setShowSlider(true);

                    connection.on("reject", () => {
                        //setStateVal("Ready");
                        //setConn(null);
                        console.log("incoming_rejct", 'abc');
                        setdisplayMessage("Incoming call rejected");
                        sliderOut();
                        device.destroy();
                        setDevice(null);
                    });
                });
                device.on("cancel", () => {
                    console.log("cancel", 'abc');
                    setStateVal("CANCEL");

                    //setConn(null);
                    setdisplayMessage("Incoming call canceled");
                    // setStatusOnReject();
                    device.destroy();
                    setDevice(null);
                });
                device.on("reject", () => {
                    //setStateVal("Ready");
                    //setConn(null);
                    console.log("reject", 'abc');
                    setdisplayMessage("Incoming call rejected");
                    sliderOut();
                    device.destroy();
                    setDevice(null);
                });

                return () => {
                    device.destroy();
                    //setConn(null);
                    setDevice(null);
                    //setStateVal("Offline");
                };
            }
        })
            .catch(error => {
                console.error('GET request error:', error);
            });
    };

    const sliderOut = () => {
        setShowSlider(false);
        setConn(null);
        window.location.reload(false);
    }
    const setStatusOnReject = () => {
        setStateVal("ON_CALL");
    }

    return (
        <>
            {showSlider && (
                <>
                    {conn && (
                        <>
                            <Offcanvas className="offcanvas-size-lg" placement="end" show={showSlider} >
                                {(state === "Incoming") && (
                                    <Offcanvas.Header className="border-bottom border-light">
                                        <Offcanvas.Title>Incoming Call</Offcanvas.Title>
                                        <button onClick={() => { sliderOut(); }} className="btn btn-link">
                                            <Icon name={'cross'} />
                                        </button>
                                    </Offcanvas.Header>
                                )}

                                <Offcanvas.Body>
                                    {
                                        conn.parameters && (
                                            <Incoming state={state} displayMessage={displayMessage} connection={conn} sliderOut={sliderOut} setStatusOnReject={setStatusOnReject}></Incoming>
                                        )
                                    }
                                </Offcanvas.Body>
                            </Offcanvas>
                        </>
                    )}
                </>
            )}

        </>
    );
}
export default Incall
