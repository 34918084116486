import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal, ButtonGroup, ButtonToolbar, InputGroup, FormLabel, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, Select } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import Swal from "sweetalert2";
import { countries } from '../../store/customData';
import { convertCentToDoller, isObjectNotEmpty, sleep, uInfo } from '../../utilities';

function ConsumptionCriteria() {
    const { hasPermission } = useAuth();
    const [meta, setMeta] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [countryListArray, setCountryListArray] = useState([]);
    const [allListArray, setAllListArray] = useState([]);
    const [showModal2, setShowModal2] = useState(false);
    const [list, setList] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
        };
        fetchData();
    }, []);

    useEffect(() => {
        countryChange(0);
    }, [meta]);

    const fetchMeta = async () => {
        await sendRequest(`credits/${uInfo().id}/consumption/rates`, 'POST', []).then(response => {
            if (response.status === true) {
                let rates = JSON.parse(response.data.rates);
                setMeta(rates);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const countryChange = async (id) => {
        let country = countries[id];
        setSelectedCountry((prevState) => ({
            ...country,
            index: id,
        }));

        var list = meta["c"];
        var array = [];
        for (var key in list) {
            if (list[key][country.id] != undefined) {
                array.push({
                    label: list[key].text,
                    value: list[key][country.id],
                    all: list[key]["all"],
                    key: key,
                });
            }
        }

        var list2 = meta["all"];
        var array2 = [];
        for (var key in list2) {
            array2.push({
                label: list2[key].text,
                value: "",
                all: list2[key]["all"],
                key: key,
            });
        }
        setCountryListArray(array);
        setAllListArray(array2);
    };


    const handleSubmitCountry = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const file = { ...meta };
        const list = { ...file.c };
        var country = selectedCountry.id;
        for (const [key, value] of formData.entries()) {
            if (list[key] && list[key][country] !== undefined) {
                list[key][country] = value;
            }
        }
        file.c = list;
        handleSubmit(file);
    };

    const handleSubmitAll = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const file = { ...meta };
        const list = { ...file.all };
        for (const [key, value] of formData.entries()) {
            if (list[key] && list[key]['all'] !== undefined) {
                list[key]['all'] = value;
            }
        }
        file.all = list;
        handleSubmit(file);
    };


    const handleSubmit = async (file) => {
        const formData = new FormData();
        formData.append('file', JSON.stringify(file))
        await sendRequest(`credits/${uInfo().id}/consumption/rates/update`, 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    countryChange(selectedCountry.index)
                    setShowModal(false);
                    setShowModal2(false);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };



    return (
        <Layout title="Consumption Criteria" content="container">
            <div className="Consumption Criteria">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Consumption Criteria</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/credit-manage/consumption/criteria">Consumption Criteria</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Credit </li>
                                </ol>
                            </nav>
                        </Block.HeadContent>

                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>

                            <Row>
                                <Col lg={1}>
                                    <FormLabel>Country:</FormLabel>
                                </Col>
                                <Col lg={7}>
                                    <Select
                                        className="sm"
                                        defaultValue={selectedCountry.index}
                                        onChange={(selectedId) => {
                                            countryChange(selectedId)

                                        }}
                                        name="country">
                                        {countries.map((row, i) => {
                                            return <option key={i} value={i}>{row.label}</option>
                                        })}
                                    </Select>

                                </Col>
                                <Col lg={4}>
                                    <Button onClick={() => setShowModal(true)} variant='primary'>Edit Price For ({selectedCountry?.id.toUpperCase()})</Button>
                                </Col>

                                <Col lg="12">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Amount </th>
                                                <th>For All</th>
                                                <th className="hidden-xs"> Action </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={4}>
                                                    Prices For country (
                                                    {selectedCountry?.id.toUpperCase()})
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {countryListArray.map((list, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{list.label}</td>
                                                        <td>{convertCentToDoller(list.value)}</td>
                                                        <td>
                                                            {/* {convertCentToDoller(list.all)} */}
                                                        </td>
                                                        <td className="hidden-xs"></td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <th className="border-0" colSpan={3}>For All Countries</th>
                                            </tr>
                                            {allListArray.map((list, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{list.label}</td>
                                                        <td>{convertCentToDoller(list.value)}</td>
                                                        <td>{convertCentToDoller(list.all)}</td>
                                                        <td className="hidden-xs">
                                                            <Button size='sm' variant='primary' onClick={() => { setShowModal2(true); setList(list); }}>
                                                                <Icon name={'edit'}></Icon>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Block>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> Prices For Country ({selectedCountry?.id.toUpperCase()}) </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitCountry}>
                        <Row className="g-3">
                            <Col lg="12">
                                {countryListArray.map((list, i) => {
                                    return (
                                        <Form.Group className="form-group" key={i}>
                                            <Form.Label htmlFor="title">{list.label}</Form.Label>
                                            <InputGroup size="md">
                                                <InputGroup.Text>(&cent;)</InputGroup.Text>
                                                <Form.Control step={'any'} type='number' required name={list.key}
                                                    defaultValue={list.value}
                                                    placeholder={`Enter ${list.label}`}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    )
                                }
                                )}

                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button type='submit' variant="primary"className='btn-sm' >Save</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn btn-sm" onClick={() => setShowModal(false)}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {list.label} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitAll}>
                        <Row className="g-3">
                            <Col lg="12">

                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="title">{list.label}</Form.Label>
                                    <InputGroup size="md">
                                        <InputGroup.Text>(&cent;)</InputGroup.Text>
                                        <Form.Control step={'any'} type='number' required
                                            name={list.key}
                                            defaultValue={list.all}
                                            placeholder={`Enter ${list.label}`}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button type='submit' variant="primary" className='btn-sm'>Save</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn btn-sm" onClick={() => setShowModal2(false)}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout >
    )
}

export default ConsumptionCriteria;
