import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { currencySign, uInfo } from '../../utilities';
import { sendRequest } from '../../utilities/api';
import { Card, Button, Row, Col, Form, Spinner, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { Image, Icon, Media, Select, MediaGroup, MediaText } from '../../components';
import ReactSelect from 'react-select'

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EditableField({
    value: initialValue,
    type,
    options = [],
    onUpdate,
    name = '',
    currentItem: initialCurrentItem,
    fieldType: initialFieldType,
    leadArrays: initialLeadArrays,
    activeMasterId: initialActiveMasterId,
    activeStatusId: initialActiveStatusId,
    masterPipelines = [],
}) {
    let query = useQuery();

    const [value, setValue] = useState(initialValue);
    const [currentItem, setCurrentItem] = useState(initialCurrentItem);
    const [fieldType, setFieldType] = useState(initialFieldType);
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({ contact_type: uInfo().id ? 'existing' : 'new', related_id: 0, lead_name: '' });
    const [activeMasterId, setActiveMasterId] = useState(initialActiveMasterId ? initialActiveMasterId : 'MA==');
    const [activeStatusId, setActiveStatusId] = useState(initialActiveStatusId ? initialActiveStatusId : 0);
    const [listsMileston, setListsMileston] = useState(options ? options : []);
    const [masterPipelinesArray, setMasterPipelines] = useState(masterPipelines ? masterPipelines : []);
    const [existingLead, setExistingLead] = useState([]);
    const [leadArrays, setLeadArrays] = useState(initialLeadArrays);

    const inputRef = useRef(null);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }

    }, [isEditing]);

    // Handle double-clicking the span to enter edit mode
    const handleSpanDoubleClick = () => {
        if (name === 'related_to_name' || name === 'pipeline' || name === 'milestone') {
            setShowModal(true);
        } else {
            setIsEditing(true);
        }
    };

    // Handle changes in input/select elements
    const handleChange = (event) => {
        let selectedValues = '';

        if (type === 'multi-select') {
            if (name === 'category_title' || name === 'service_title' || name === 'tag_title') {
                selectedValues = Array.from(event, option => option.value);
            }
            else {
                selectedValues = Array.from(event.target.selectedOptions, option => option.value);
            }
            setValue(selectedValues);
        } else if (type === 'select') {
            selectedValues = event['value'];
            setValue(selectedValues);
        } else {
            selectedValues = event.target.value;
            setValue(selectedValues);
        }

        if (name === 'milestone' || name === 'pipeline') {
            setActiveStatusId(parseInt(selectedValues));
        }

        const formData = new FormData();

        formData.append('key', name);
        formData.append('value', selectedValues);
        formData.append('job_id', currentItem);
        formData.append('field_type', fieldType);
        formData.append('type', type);
        formData.append('active_pipeline_id', activeMasterId);

        submitForm(formData);
    };

    // Handle on blur event
    const handleBlur = () => {
        if (value)  // this check is to prevent blur when we show error message alert. 
        {
            setIsEditing(false);
        }
        // if (type === 'select' || type === 'multi-select') {
        //     // For select and multi-select, validate the current value
        //     if (!options.some(option => option.id === value)) {
        //         setValue(initialValue);
        //     }
        // }
    };

    // Handle key press event
    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
            if (typeof onUpdate === 'function') {
                onUpdate(value);
            }

            const formData = new FormData();

            formData.append('key', name);
            formData.append('value', value);
            formData.append('job_id', currentItem);
            formData.append('field_type', fieldType);
            formData.append('type', type);

            submitForm(formData);
        }
    };

    const submitForm = async (formData) => {
        await sendRequest(`jobs/update/job/fields`, 'POST', formData).then(response => {
            if (response.status === true) {
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    // Function to find option text based on the current value
    const findOptionText = () => {

        if (type === 'multi-select') {

            let selectedValues;
            let source = '';
            let selectedOptions = [];

            if (typeof value === 'string') {
                selectedValues = value.split(' | ');
            } else {
                selectedValues = value && Array.isArray(value) ? value?.map(Number) : [];
                source = 'edit';
            }

            if (name === 'category_title' || name === 'service_title' || name === 'tag_title') {
                selectedOptions = options.filter(option => selectedValues.includes(source == 'edit' ? option.value : option.title));
            }
            else {
                selectedOptions = options.filter(option => selectedValues.includes(source == 'edit' ? option.id : option.title));
            }

            return selectedOptions && selectedOptions.length > 0 ? selectedOptions?.map(option => `${(name === 'category_title' || name === 'service_title' || name === 'tag_title') ? option.label : option.title}`)?.join(' | ') : '-';
        }

        if (name === 'milestone') {
            const filteredRecords = listsMileston.filter(item => {
                return item.pipeline_status.some(status => status.id == activeStatusId);
            });

            return (filteredRecords[0]?.pipeline_status.find(status => status.id == activeStatusId)?.title) || '-';
        }

        if (name === 'pipeline') {

            const filteredRecords = masterPipelinesArray.find(item => item.id == activeMasterId);

            return filteredRecords ? filteredRecords['title'] : '-';
        }

        const selectedOption = options.find(item => item.value == value);

        return (selectedOption && name == 'record_owner') ? `${selectedOption.label}` : (value ? value : '-');
    };

    const searchLead = (search) => {
        setData({ ...data, related_id: 0, lead_name: `${search}` });
        if (search != "") {
            const formData = new FormData();
            formData.append('filter_value', search);
            formData.append('type', 'search');
            sendRequest(`crm/leads/lists`, 'POST', formData).then(response => {
                if (response.status === true) {
                    setExistingLead(response.data.records);
                    if (response.data.records.length < 1) {
                    }
                }
            })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        } else {
            setExistingLead([]);
        }
    };

    const addNewALeadrray = (related_id, name) => {
        const isRelatedIdExist = leadArrays.some(array => array.related_id === related_id);
        if (!isRelatedIdExist) {
            const newArray = [...leadArrays, { related_id: related_id, name: name }];
            setLeadArrays(newArray);

            const __values = newArray?.map(item => item.related_id)

            setValue(__values)

            const formData = new FormData();

            formData.append('key', 'related_to_name');
            formData.append('value', __values.length > 0 ? __values : 'empty');
            formData.append('job_id', currentItem);
            formData.append('field_type', fieldType);
            formData.append('type', type);

            submitForm(formData);
        }
        setData({ ...data, related_id: 0, lead_name: `` });
    };

    const unsetLeadArray = (related_id) => {
        const filteredArrays = leadArrays.filter(array => array.related_id !== related_id);
        setLeadArrays(filteredArrays);

        const __values = filteredArrays?.map(item => item.related_id)

        const formData = new FormData();

        formData.append('key', 'related_to_name');
        formData.append('value', __values.length > 0 ? __values : 'empty');
        formData.append('job_id', currentItem);
        formData.append('field_type', fieldType);
        formData.append('type', type);

        submitForm(formData);
    };

    const formatSelectedContactNames = () => {
        const names = leadArrays?.map(item => {
            // if (item.name) {
            const nameParts = item.name.split('#');
            return nameParts; // If the name is null, return an empty string
            // }
        });

        const formattedNames = names?.join(' | ');

        return formattedNames.length > 0 ? formattedNames : '-';
    }

    const getSubPipeline = async (pipeline_id) => {

        // setValue(pipeline_id);

        // name = 'milestone';

        if (pipeline_id == '') {
            setListsMileston([]);
            setActiveMasterId('MA==');
            setActiveStatusId(0);
        }
        const formData = new FormData();
        await sendRequest(`pipelines/${pipeline_id}`, 'POST', formData).then(response => {
            if (response.status === true) {
                setListsMileston(response.data.lists);
                setActiveMasterId(pipeline_id);
                // setActiveStatusId(response.data.active_status_id);
            } else {
                setListsMileston([]);
                setActiveMasterId('MA==');
                setActiveStatusId(0);
            }


        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    var selectedItemsKeyValue = [];

    if (name === 'category_title' || name === 'service_title' || name === 'tag_title') {

        const selectedItemsIds = value && Array.isArray(value) ? value.map(Number) : [];

        const selectedItems = options.filter(option => selectedItemsIds.includes(option.value));

        selectedItemsKeyValue = selectedItems?.map(item => ({
            value: item.value,
            label: item.label
        }));
    }

    return (
        <div>
            {isEditing ? (
                type === 'multi-select' ? (

                    <ReactSelect
                        name={name}
                        options={options}
                        isMulti
                        defaultValue={options.filter(item => selectedItemsKeyValue.some(option => option.value === item.value))}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={handleChange}
                        menuPortalTarget={document.body}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: '0.8rem' // Adjust the font size as needed
                            })
                        }}
                    />

                    // <select
                    //     className='form-control'
                    //     ref={inputRef}
                    //     value={value}
                    //     onChange={handleChange}
                    //     onBlur={handleBlur}
                    //     onKeyPress={handleKeyPress}
                    //     name={name}
                    //     multiple
                    //     style={{
                    //         border: '2px solid #ccc',
                    //         borderRadius: '5px',
                    //         // padding: '8px',
                    //         fontFamily: 'Arial, sans-serif',
                    //         // fontSize: '16px',
                    //         color: '#555',
                    //         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    //         cursor: 'pointer',
                    //         position: 'relative',
                    //         zIndex: 1,
                    //         boxSizing: 'border-box',
                    //         width: '100%',
                    //     }}
                    // >
                    //     {options.map((option, index) => (
                    //         <option key={index} value={option.id}>
                    //             {name === 'record_owner' || name === 'related_to_name'
                    //                 ? `${option.first_name} ${option.last_name}`
                    //                 : (option.option || option.title)
                    //             }
                    //         </option>
                    //     ))}
                    // </select>
                ) : type === 'select' ? (


                    <ReactSelect
                        name={name}
                        options={options}
                        value={options.find(option => option.value === value)}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        onChange={handleChange}
                        menuPortalTarget={document.body}
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: '0.8rem' // Adjust the font size as needed
                            })
                        }}
                    />

                    // <select
                    //     className='form-control'
                    //     ref={inputRef}
                    //     value={value}
                    //     onChange={handleChange}
                    //     onBlur={handleBlur}
                    //     onKeyPress={handleKeyPress}
                    //     name={name}
                    //     style={{
                    //         border: '2px solid #ccc',
                    //         borderRadius: '5px',
                    //         // padding: '8px',
                    //         fontFamily: 'Arial, sans-serif',
                    //         // fontSize: '16px',
                    //         color: '#555',
                    //         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    //         cursor: 'pointer',
                    //         position: 'relative',
                    //         zIndex: 1,
                    //         boxSizing: 'border-box',
                    //         width: '100%',
                    //     }}
                    // >
                    //     <>

                    //         {name == 'milestone' ? (

                    //             options.length > 0 && options.map((row, i) => {
                    //                 return (
                    //                     <>
                    //                         <optgroup label={row.title}>
                    //                             {
                    //                                 row.pipeline_status.length > 0 && row.pipeline_status.map((status, k) => {
                    //                                     return (
                    //                                         <option key={k} value={status.id}>{status.title}</option>
                    //                                     )
                    //                                 })
                    //                             }
                    //                         </optgroup>
                    //                     </>
                    //                 )
                    //             })
                    //         ) : (
                    //             options.map((option, index) => (
                    //                 <option key={index} value={option.id}>
                    //                     {name === 'record_owner'
                    //                         ? `${option.first_name} ${option.last_name}`
                    //                         : option.option
                    //                     }
                    //                 </option>
                    //             ))
                    //         )}


                    //     </>

                    // </select>
                ) : (
                    <input
                        className='form-control'
                        ref={inputRef}
                        type={type === 'number' ? 'number' : 'text'}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                        name={name}
                        style={{
                            border: '2px solid #ccc',
                            borderRadius: '5px',
                            padding: '15px 2px',
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '14px',
                            color: '#555',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'box-shadow 0.3s ease',
                            outline: 'none',
                            width: '100%',
                            height: '30px',
                        }}
                    />
                )
            ) : (
                <>
                    <span
                        onClick={handleSpanDoubleClick}
                        style={{
                            display: 'flex',
                            minWidth: '155px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            maxWidth: '155px',
                        }}
                    >
                        {(type === 'multi-select' || type === 'select') ? findOptionText() : (name === 'amount_formated' ? currencySign + value : (value ? (name == 'related_to_name' ? formatSelectedContactNames() : value) : '-'))}
                        
                        {(type === 'multi-select' || type === 'select') && (
                            <Icon className={'fs-3'} name="chevron-down"></Icon>
                        )}
                    </span>

                    {name === 'related_to_name' && (
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Related to</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form.Group className="form-group">
                                    <div className="form-control-wrap">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="Phone">Related to (Contacts)</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="text" size="sm" name="search_contact"
                                                    placeholder="Search Contact..."
                                                    value={data.lead_name}
                                                    onChange={(e) => searchLead(e.target.value)}
                                                    readOnly={query.has('related_id')}
                                                    onBlur={() =>
                                                        setTimeout(() => {
                                                            setExistingLead([]);
                                                        }, 1000)
                                                    }
                                                />
                                            </div>
                                        </Form.Group>

                                        {existingLead.length > 0 && (
                                            <ListGroup className="my-1">
                                                {existingLead?.map((row, i) => {
                                                    return (
                                                        <ListGroup.Item key={i} style={{ cursor: 'pointer' }} onClick={() => {
                                                            setData({ ...data, related_id: row.id, lead_name: `${row.id}# ${row.first_name} ${row.last_name}` })
                                                            addNewALeadrray(row.id, `${row.id}#${row.first_name} ${row.last_name}`);
                                                        }
                                                        } >
                                                            <MediaGroup>
                                                                <MediaText>
                                                                    <div className="lead-text">{row.first_name} {row.last_name}</div>
                                                                    <span className="sub-text">{row.email}</span>
                                                                </MediaText>
                                                            </MediaGroup>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                            </ListGroup>
                                        )}

                                        {(uInfo().id && data.contact_type != 'new') && (
                                            <>
                                                <ul className="nk-list-option pt-0 w-100 text-start align-items-start">
                                                    <li>
                                                        <div className="mt-md-0 text-start">
                                                            <ul className="d-flex flex-wrap gap gx-1">
                                                                {leadArrays?.map((row, index) => (
                                                                    <li key={index} onClick={() => unsetLeadArray(row.related_id)} style={{ cursor: 'pointer' }}>
                                                                        <span className="badge text-bg-secondary-soft"><Icon name={'cross-circle-fill'}
                                                                        ></Icon> {row.name}</span>
                                                                    </li>

                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </>
                                        )}

                                    </div>
                                </Form.Group>

                            </Modal.Body>
                        </Modal>
                    )}

                    {(name === 'pipeline' || name === 'milestone') && (
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Fields</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form.Group className="form-group">
                                    <div className="form-control-wrap">
                                        <Row className="g-3 my-2 mx-0">
                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="pipeline_id">Pipeline</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Select defaultValue={activeMasterId} onChange={(e) => { getSubPipeline(e.target.value); }} placeholder="Type" name="pipeline_id">
                                                            <option value="">Select Pipeline</option>
                                                            {masterPipelinesArray?.map((row, i) => {
                                                                return (
                                                                    <option key={i} value={row.id}>{row.title}</option>
                                                                )
                                                            }
                                                            )}
                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="country_code">Milestone</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Select defaultValue={activeStatusId} onChange={(e) => { handleChange(e); }} placeholder="Type" name="status_id">
                                                            <option value="0">Select Milestone</option>
                                                            {
                                                                listsMileston?.length > 0 && listsMileston?.map((row, i) => {
                                                                    return (
                                                                        <>
                                                                            <optgroup label={row.title}>
                                                                                {
                                                                                    row?.pipeline_status?.length > 0 && row?.pipeline_status?.map((status, k) => {
                                                                                        return (
                                                                                            <option key={k} value={status.id}>{status.title}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form.Group>

                            </Modal.Body>
                        </Modal>
                    )}

                </>
            )
            }
        </div >
    );
}

export default EditableField;
