import { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "../../AuthContext";

export const TopNav = (props) => {
    const [active] = useState(props.active ?? 'info');
    const [id] = useState(props.campaign_id);
    const { hasPermission } = useAuth();
    const navigation = useNavigate();
    return (
        <div>
            <Nav
                fill variant="tabs" defaultActiveKey={active}
                onSelect={(selectedKey) => navigation(`/campaign-manage/details/${id}/${selectedKey}`)}
                as="ul" className="mb-2"
            >
                <Nav.Item as='li'>
                    <Nav.Link eventKey="info">Basic Info</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="inbound">Inbound Setting</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="outbound">Outbound Setting</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="broadcasts">Broadcasts</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="automations">Automations</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="batches">Batches</Nav.Link>
                </Nav.Item>

                <Nav.Item as='li'>
                    <Nav.Link eventKey="reporting">Reporting</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};