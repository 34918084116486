import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import Phone from '../InitiateCall/Phone';
import { Button, ButtonGroup } from 'react-bootstrap';
import CallComponent from '../Plivo/Call';
import { Icon } from '../../components';

const CallWidget = (props) => {
    const { callInit } = useAuth();
    const [isMaximized, setIsMaximized] = useState(props.isMaximized ?? false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [callToken, setCallToken] = useState(false);
    const [onCall, setonCall] = useState(false);
    const [callStatus, setCallStatus] = useState("idle");


    const toggleChat = () => {
        if (isMaximized) {
            setIsMaximized(false);
            setIsMinimized(true);
        } else {
            setIsMaximized(true);
            setIsMinimized(false);
        }
    };

    const minimizeChat = () => {
        callInit({ type: '' })
    };

    const maximizeChat = () => {
        setIsMaximized(true);
        setIsMinimized(false);
    };



    const getCallSid = (call_sid) => {
        if (call_sid) {
            setonCall(true);
        } else {
            setonCall(false);
        }
    };

    const getCallStatus = (status) => {
        setCallStatus(status);
    };

    return (
        <div className={`chat-widget plivo ${callStatus} ${isMaximized ? 'maximized' : isMinimized ? 'minimized' : 'closed'}`}>
            <CallComponent paramsters={props.paramsters} getCallSid={getCallStatus} />

            <div className="chat-icon">
                {
                    (callStatus == 'answered' || callStatus == 'ended') && (
                        <Button size='sm' variant='info' className='close-btn' onClick={toggleChat}>
                            {isMinimized ? (
                                <Icon name={'external-alt'} />
                            ) : (
                                <Icon name={'minus'} />
                            )}
                        </Button>
                    )

                }

                {
                    callStatus != 'answered' && (

                        <Button size='sm' variant='danger' className='close-btn ms-1' onClick={minimizeChat}>
                            <Icon name="cross" />
                        </Button>
                    )
                }


            </div>
        </div>
    );
};

export default CallWidget;
