import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import MultiLevelTreeView from "./MultilLevelTreeView";
import { uInfo } from "../../utilities";

function RoleEdit(props) {
    const { id } = useParams();
    const [pages, setPages] = useState([]);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        //fetchPages();
        fetchRecordById();
    }, [])

    const fetchPages = (type = 'Client') => {
        sendRequest(`dropdown/pages/${uInfo().id}/${type}`, 'GET').then(response => {
            if (response.status === true) {
                setPages(response.data.lists);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = async () => {
        await sendRequest(`roles/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                fetchPages(response.data.list.master_type);
                setPermissions(response.data.list.permissions)
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest(`roles/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(-1);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Roles Edit" content="container-fluid">
            <div className="role-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Roles</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/role-manage/list">Role Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            {
                                loading && (
                                    <div className="bio-block">

                                        <Form onSubmit={handleSubmit}>

                                            <Form.Group className="form-group mb-4">
                                                <Form.Label htmlFor="exampleFormControlInputText1">Name</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.name} type="text" name="name" placeholder="Role Name" required />
                                                </div>
                                            </Form.Group>


                                            <Form.Group className="form-group mb-4">
                                                <Form.Label htmlFor="exampleFormControlInputText1">Description</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.description} as="textarea" placeholder="Role Description" name="description" rows="3"></Form.Control>
                                                </div>
                                            </Form.Group>



                                            <MultiLevelTreeView data={pages} initialCheckedNodes={permissions} />
                                            
                                            {
                                                (props.location?.search == null || props.location?.search == "") && (

                                                    <Col md="6">
                                                        <Form.Group className="form-group mb-3">
                                                            <Form.Label htmlFor="phone">Status</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select defaultValue={data.status} name="status">
                                                                    <option value="">Select Status</option>
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>

                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            }



                                            <Col className="col-12">
                                                <Button type="submit" className="btn-sm">Update Role</Button>
                                            </Col>
                                        </Form>
                                    </div>
                                )
                            }
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default RoleEdit;