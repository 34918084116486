import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../../../components';
import { currencySign, toInitials } from "../../../../utilities";
import { sendRequest } from "../../../../utilities/api";
import { countries } from "../../../../store/customData";

function FormCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [], groups: [], checkouts: [], disclaimers: [] });
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('crm/leads/forms/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest('crm/leads/forms/create', 'POST', submitData)
            .then(response => {
                console.log(response.data.record.public_id, 'public_id')
                if (response.status === true) {
                    props.navigate('/crm-manage/leads/forms/edit/' + response.data.record.public_id);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };


    const steps = ['Basic Infromation', 'Build Form', 'Map Fields'];
    return (
        <Layout title="Location" content="container">
            <div className="location-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Add Form</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/crm-manage/leads/forms/lists">Contact Forms</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>

                                            <Row className="g-3 mb-3">
                                                {steps.map((step, index) => (
                                                    <div
                                                        key={step}
                                                        className={`step ${activeStep === index ? 'active' : ''}`}
                                                    >
                                                        <span className="step-count">{index + 1}</span>
                                                        <p className="step-name">{step}</p>
                                                    </div>
                                                ))}
                                            </Row>


                                            <div className={`bio-block ${activeStep == 0 ? '' : 'd-none'} `}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="name" required={activeStep === 0} />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="email">Disclaimer</Form.Label>
                                                            {meta?.disclaimers.length > 0 ? (
                                                                <Select name="disclaimer">
                                                                    {
                                                                        meta.disclaimers.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title} | {row.sub_type}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="disclaimer"  >
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                            
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Groups</Form.Label>
                                                            {meta?.groups.length > 0 ? (
                                                                <Select name="group_id[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta?.groups.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) : (
                                                                <Select name="group_id[]">
                                                                    <option value="">Select</option>
                                                                </Select>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Assign To</Form.Label>
                                                            {meta?.coachPartners.length > 0 ? (
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.coachPartners.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Checkout Link</Form.Label>
                                                            {meta?.checkouts.length > 0 ? (
                                                                <Select name="product_id" removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.checkouts.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title} | {currencySign}{row.amount_formated}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="product_id" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Status</Form.Label>
                                                            <Select name="status" defaultValue={'Active'}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Select>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="col-12 text-end">
                                                        {/* <Button className="btn-sm mr-5" type="button" onClick={() => setActiveStep(activeStep - 1)}>Prev</Button> */}
                                                        <Button type="submit" className="btn-sm">Create</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default FormCreate;