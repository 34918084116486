import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

export const NavTemplates = (props) => {
    const [active] = useState(props.active ?? 'email');
    const { hasPermission } = useAuth();
    const navigation = useNavigate();
    return (
        <div>
            <Nav
                fill variant="tabs" defaultActiveKey={active}
                onSelect={(selectedKey) => navigation(`/template-manage/${selectedKey}/lists`)}
                as="ul"
            >

                {
                    hasPermission('viewEmailTemplate') && (
                        <Nav.Item as='li'>
                            <Nav.Link eventKey="email">Email</Nav.Link>
                        </Nav.Item>
                    )
                }

                {
                    hasPermission('viewSMSTemplate') && (
                        <Nav.Item as='li'>
                            <Nav.Link eventKey="sms">SMS</Nav.Link>
                        </Nav.Item>
                    )
                }
                {
                    hasPermission('viewVoicemailTemplate') && (
                        <Nav.Item as='li'>
                            <Nav.Link eventKey="voicemail">Voicemail</Nav.Link>
                        </Nav.Item>
                    )
                }
                {
                    hasPermission('viewScriptTemplate') && (
                        <Nav.Item as='li'>
                            <Nav.Link eventKey="script">Script</Nav.Link>
                        </Nav.Item>
                    )
                }
            </Nav>
        </div>
    );
};