import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
function UserList() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`users/Reseller/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Full Name",
            key: 'name',

            selector: (row) => row.name,
            cell: (row) => (
                hasPermission('editRole') ? (
                    <Link to={`/role-manage/edit/${row.role_id}?from=MA==`}>
                        <span>{row.first_name} {row.last_name}</span>

                    </Link>
                )
                    : (

                        <span>{row.first_name} {row.last_name}</span>
                    )
            ),
        },
        {
            name: "Email",
            key: 'email',
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
        },

        {
            name: "Phone",
            key: 'phone',
            selector: (row) => row.phone,
            cell: (row) => (
                <span>{row.country_code} - ({row.phone})</span>
            ),
        },

        {
            name: "Status",
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Pending" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },
        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">
                    <div className="dropdown-content ">

                        <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
                            {
                                hasPermission('editReseller') && (
                                    <LinkListItem to={`/user-manage/reseller/edit/${row.public_id}`}>
                                        <Icon name="edit" />
                                    </LinkListItem>
                                )
                            }

                            {
                                hasPermission('deleteReseller') && (
                                    <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                        <Icon className='text-danger' name="trash"></Icon>
                                    </button>
                                )
                            }

                        </LinkList>

                    </div>

                </div>
            ),
        },

    ];

    return (
        <Layout title="Reseller List" content="container">
            <div className="reseller">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Reseller List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/user-manage/reseller/lists">Reseller Manage</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reseller</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                {
                                    hasPermission('createReseller') && (
                                        <li>
                                            <Link className='btn btn-primary d-md-inline-flex btn-sm' to="/user-manage/reseller/create">
                                                <Icon name="plus" />
                                                <span>Add Reseller</span>
                                            </Link>

                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>

                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {
										customDateFilter && (
											<>
												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>
										)
									}

                                    <Col>
                                        <Form.Select size="sm" name="status" >
                                            <option value="">All </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>

                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive"
                                slug={'users/Reseller/lists'}

                            />
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default UserList;
