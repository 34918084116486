import { Link } from 'react-router-dom';
import logo from '../../../src/assets/images/default.png'
function Logo() {
	return (
		<Link to="/" className="logo-link">
			<div className="logo-wrap">
			  <img src={logo} alt='logo'></img>
			</div>
		</Link>
	)
}

export default Logo;
