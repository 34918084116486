import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from '../../../../utilities/api';
import { Icon, LinkList, LinkListItem, Select } from "../../../../components";
import { useAuth } from '../../../../AuthContext';
import DataTableComponent from '../../../../components/DataTable/DataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';

function LeadList(props) {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);
	const [clientForm, setClientForm] = useState(false);
	const [modalClone, setModalClone] = useState(false);
	const [meta, setMeta] = useState({ locations: [] });
	const [loading, setLoading] = useState(false);
	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		const formData = new FormData();
		await sendRequest('crm/leads/forms/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};


	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to clone this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, clone it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`crm/leads/forms/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};

	const userColumns = [
		{
			name: "Name",
			key: 'name',

			selector: (row) => row.name,
			cell: (row) => (
				<span>
					{
						hasPermission('editLeadsForms') ? (
							<Link to={`/crm-manage/leads/forms/edit/${row.public_id}`}>
								{row.name}
							</Link>
						) : (
							row.name
						)
					}
				</span>
			),
		},
		{
			name: "Group",
			key: 'group_title',

			selector: (row) => row.group_title,
			cell: (row) => (
				<span>{row.group_title}</span>
			),
		},

		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},

		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">

							{
								hasPermission('viewLeadsForms') && (
									<button className="btn p-0" onClick={() => { setClientForm(row); setModalClone(true) }}>
										<Icon name="copy" className='text-success'></Icon>
									</button>
								)
							}

							&nbsp; | &nbsp;

							{
								hasPermission('viewLeadsForms') && (
									<button className="btn p-0" onClick={() => props.navigate(`/crm-manage/leads/forms/view/${row.public_id}/MA==`)}>
										<Icon name="eye"></Icon>
									</button>

								)
							}

							&nbsp; | &nbsp;

							<button className="btn p-0" onClick={() => { setModal(true); setClientForm(row) }}>
								<Icon name="link" className='text-primary'></Icon>
							</button>

							&nbsp; | &nbsp;


							{
								hasPermission('deleteLeadsForms') && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}

						</LinkList>
					</div>
				</div>
			),
		},

	];

	const cloneForm = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}


		sendRequest(`crm/leads/forms/clone/${clientForm.public_id}`, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					setClientForm({});
					setModalClone(false);
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});
	};

	return (
		<Layout title="Contact List" content="container">
			<div className="Leads">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Contact Forms List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/crm-manage/leads/lists">Contacts</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Forms</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{
									hasPermission('createLeadsForms') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/crm-manage/leads/forms/create">
												<Icon name="plus" />
												<span>Add Form</span>
											</Link>

										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>

							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									<Col>
										<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
									</Col>

									<Col>
										<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
									</Col>

									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive LeadListTable table-striped"
								slug={'crm/leads/forms/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>

				<Modal show={modalClone} onHide={() => { setClientForm({}); setModalClone(false); }} size="lg">
					<Form onSubmit={cloneForm}>
						<Modal.Header closeButton>
							<Modal.Title className="event-title" as="h4">Clone Form</Modal.Title>
						</Modal.Header>
						<Modal.Body>


							<Form.Group className="form-group">
								<Form.Label htmlFor="role">Select Location To Copy</Form.Label>
								{meta.locations && meta?.locations.length > 0 ? (
									<Select name="location_ids[]" multiple removeItemButton>
										<option value="">Select</option>
										{
											meta.locations.map((row, i) => {
												return <option key={i} value={row.id}>{row.name}</option>
											})
										}
									</Select>
								) :
									<Select name="location_ids[]" multiple removeItemButton>
										<option value="">Select</option>
									</Select>

								}
							</Form.Group>



						</Modal.Body>
						<Modal.Footer>
							<Button size={'sm'} variant="danger" onClick={() => { setClientForm({}); setModalClone(false); }}>Close</Button>
							<Button size={'sm'} variant="primary" type="submit" >Clone</Button>

						</Modal.Footer>
					</Form>
				</Modal>

				<Modal show={modal} onHide={() => setModal(false)} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">Contact Form:{clientForm.name} Embed Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div className="url">
							<a
								href={`${clientForm.domain_url}`}
								target="_blank"
								className="pr-5 mr-5"
							>
								{clientForm.domain_url}
							</a>

							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="overlay-example">
										Copy Form url
									</Tooltip>
								}
							>
								<button
									className="btn btn-link btn-md"
									onClick={() => {
										navigator.clipboard.writeText(`${clientForm.domain_url}`);
									}}
								>
									<Icon name="copy"></Icon>
								</button>
							</OverlayTrigger>
						</div>

						<div className="url d-flex">
							<label className="pt-3">Iframe Embed Code </label>
							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="overlay-example">
										Copy iframe Embed Code
									</Tooltip>
								}
							>
								<button
									className="btn btn-link btn-md"
									onClick={() => {
										navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${clientForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
									}}
								>
									<Icon name="copy"></Icon>
								</button>
							</OverlayTrigger>

						</div>
						<textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${clientForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>

					</Modal.Body>
					<Modal.Footer>
						<Button size={'sm'} variant="danger" onClick={() => { setClientForm({}); setModal(false); }}>Close</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</Layout>
	)
}

export default LeadList;
