import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Modal, Nav, Row, Spinner, Tab, Table } from 'react-bootstrap';
import { Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';

import logo from "../../assets/images/default.png"
import { useLocation, useParams } from 'react-router-dom';
import Layout from '../../layout/fullpage';
import { currencySign } from '../../utilities';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckoutView = (props) => {
    let query = useQuery();
    const { id, slug } = useParams();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [fromData, setFromData] = useState({ name: '', email: '' });

    const [releatedId] = useState(query.has('releatedId') ? query.get('releatedId') : 'MA==');

    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
        };
        fetchData();
    }, []);

    const fetchRecordById = async () => {
        setPageLoader(false);
        const submitData = new FormData();
        submitData.append('releatedId', releatedId);
        await sendRequest(`checkout/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                setData(response.data);
                setFromData(response.data.from);
            }
            setPageLoader(true)
        })
            .catch(error => {
                setPageLoader(false)
                console.error('POST request error:', error);
            });
    }

    const handlePayment = async (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('releatedId', releatedId);

        await sendRequest(`checkout/make/${id}`, 'POST', submitData).then(response => {
            if (response.status === true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.message,
                });
                fetchRecordById();
            }
            setLoader(false);
        })
            .catch(error => {
                setLoader(false);
                console.error('POST request error:', error);
            });


    }



    return (
        <Layout title="Invoice" >
            <div className="container-fluid my-2">
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <Card>
                            {
                                !pageLoader ? (
                                    <Spinner animation="grow" variant="primary"></Spinner>
                                ) : (
                                    <Card.Body className='p-0'>
                                        <div className="receipt-header">
                                            <header
                                                className="p-1"
                                                style={{
                                                    backgroundColor: data.list?.domain?.other_settings?.header_bg_color,
                                                    color: data.list?.domain?.other_settings?.header_text_color
                                                }}
                                            >

                                                <Row className='align-items-center'>
                                                    <Col lg={8}>
                                                        <Image
                                                            src={data.list?.domain?.other_settings?.header_logo}
                                                            alt={data.list?.domain?.name}
                                                            className="img-responsive"
                                                        />
                                                    </Col>
                                                    <Col lg={4} className="text-right">
                                                        <p className='mb-0'>
                                                            <b>PAYMENT #: </b> C-000{data.list?.id}
                                                        </p>
                                                        <p className='mb-0'>
                                                            <b>Date:</b> {data.list?.dated}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </header>
                                        </div>
                                        <div className='px-3'>

                                            <div className="my-2 ms-2">
                                                <Row>
                                                    {/* Customer Info and Invoice Number */}
                                                    <Col lg={8}>
                                                        <div class="">
                                                            <span class="h5">From</span>
                                                            <p>{fromData.name}</p>
                                                        </div>

                                                    </Col>
                                                    <Col lg={4}>
                                                        <div class="amount-wrap text-end">
                                                            <span class="h5">To</span>
                                                            <p>{data.record?.first_name} {data.record?.last_name}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Table bordered size='sm'>
                                                    <thead>
                                                        <tr>
                                                            <th>Items</th>
                                                            <th>Quantity</th>
                                                            <th>Rate</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.list?.meta_data.map((row, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{row.name}</td>
                                                                        <td>{row.quantity}</td>
                                                                        <td>{currencySign}{row.price}</td>
                                                                        <td>{currencySign}{row.price * row.quantity}</td>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                            <td className="border-0 border-width-0" colSpan={2}></td>
                                                            <td className="border-0 border-width-0" >Sub Total:	</td>
                                                            <td className="border-0 border-width-0" >{currencySign}{data.list?.total_amount}	</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <Tab.Container id="details" defaultActiveKey="card">
                                                {/* <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="card">Credit Card</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="history">Payments</Nav.Link>
                                                    </Nav.Item>
                                                </Nav> */}
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="card">
                                                        <Form onSubmit={handlePayment}>
                                                            <Row className="g-3">

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="full_name">Full Name</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="name"
                                                                                defaultValue={fromData.first_name}
                                                                                placeholder="Enter card holder name"
                                                                                onChange={(e) => setFromData({ ...fromData, name: e.target.value })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="email"
                                                                                name="email"
                                                                                defaultValue={fromData.email}
                                                                                placeholder="Email"
                                                                                onChange={(e) => setFromData({ ...fromData, email: e.target.value })}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="phone">Credit Card</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="number"
                                                                                name="cardNumber"
                                                                                placeholder="1234 5678 9012 3456"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="month">Expiration Year</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="number"
                                                                                name="year"
                                                                                placeholder="2025"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="month">Month</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="number"
                                                                                name="month"
                                                                                placeholder="05"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="phone">CVC</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control
                                                                                type="number"
                                                                                placeholder="123"
                                                                                name="cvv"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                {
                                                                    data.list?.can_client_memo == 1 && (
                                                                        <Col md="12">
                                                                            <Form.Group className="form-group">
                                                                                <Form.Label htmlFor="phone">
                                                                                    Write Your Comments Here
                                                                                </Form.Label>
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        name="message"
                                                                                        placeholder="Enter you comment here"
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    )
                                                                }


                                                                <Col className="col-12">
                                                                    <Button disabled={loader} type="submit" className="btn-sm">
                                                                        Pay Now
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="history">
                                                        <Table size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <td>Payment#</td>
                                                                    <td>Amount</td>
                                                                    <td>Due Date</td>
                                                                    <td>Status</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.paymentHistory && data.paymentHistory.map((row, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{row.history_no}</td>
                                                                                <td>{currencySign}{row.amount}</td>
                                                                                <td>{row.payment_date}</td>
                                                                                <td>
                                                                                    <span className={`badge text-bg-${row.status === "Paid" ? "success-soft"
                                                                                        : row.status === "Unpaid" ? "warning-soft"
                                                                                            : row.status === "Due" ? "secondary-soft"
                                                                                                : row.status === "Failed" ? "danger-soft"
                                                                                                    : "primary-soft"
                                                                                        }`
                                                                                    }>
                                                                                        {row.status ? row.status : 'General'}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Tab.Pane>

                                                </Tab.Content>
                                            </Tab.Container>




                                            <Row>
                                                {/* Totals and Footer */}
                                                <Col lg={12}>
                                                    <div className="text-center mb-2">
                                                        <h5>Thank you for your business!</h5>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Card.Body>
                                )
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )

}

export default CheckoutView;
