import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Modal, Nav, Row, Spinner, Tab, Table } from 'react-bootstrap';
import { CreatedBy, FileUpload, Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import logo from "../../assets/images/default.png"
import { useParams } from 'react-router-dom';
import Layout from '../../layout/fullpage';
import { currencySign, uInfo } from '../../utilities';

const Portal = (props) => {
    const { id, coach_id } = useParams();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [lists, setLists] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            //await fetchRecordById();
            await fetchRequiredDoc();

        };
        fetchData();
    }, []);

    const fetchRecordById = async () => {
        await sendRequest(`crm/leads/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRequiredDoc = async () => {
        await sendRequest(`fetch/required/doc/${id}/${coach_id}`, 'POST').then(response => {
            if (response.status === true) {
                setLists(response.data.lists);
            }
            setPageLoader(true);
        })
            .catch(error => {
                setPageLoader(true);
                console.error('POST request error:', error);
            });
    }

    const handleReset = () => {
        setPageLoader(false);
        fetchRequiredDoc();
    };

    const handleFileSubmit = (files, releatedId) => {
        setLoader(true);
        const submitData = new FormData();

        if (Array.isArray(files)) {
            files.forEach((file, index) => {
                // Append each file with a unique key, e.g., 'file0', 'file1', etc.
                submitData.append(`files[]`, file);
            });
        } else {
            // If files is a single file, append it without iterating
            submitData.append('files[]', files);
        }

        submitData.append('id', releatedId);


        sendRequest(`upload/required/doc/${releatedId}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire({
                        title: response.message,
                        icon: 'success',
                    });
                    fetchRequiredDoc();
                }
                setLoader(false);
            })
            .catch(error => {
                setLoader(false);
                console.error('POST request error:', error);
            });
    }



    return (
        <Layout title="Lead Portal" >
            <div className="container-fluid my-2">
                <Row>
                    <Col lg={{ span: 10, offset: 1 }}>
                        <Card>
                            {
                                !pageLoader ? (
                                    <Spinner animation="grow" variant="primary"></Spinner>
                                ) : (
                                    <Card.Body className='p-0'>
                                        <header
                                            className="p-1"
                                            style={{
                                                backgroundColor: '#fff',
                                            }}
                                        >

                                            <Row className='align-items-center'>
                                                <Col lg={8}>
                                                    <Image
                                                        src={logo}
                                                        alt={data.list?.domain?.name}
                                                        className="img-responsive"
                                                    />
                                                </Col>
                                                <Col lg={4} className="text-end">
                                                    <p className='mb-0'>
                                                        <b> {data.first_name} {data.last_name} </b>
                                                    </p>

                                                </Col>
                                            </Row>
                                        </header>

                                        <div className=' my-2 mx-2'>

                                            {
                                                lists.length > 0 && (
                                                    <div className='doc-lists'>
                                                        <h5 className='text-center'>Waiting on following documents for Contact</h5>
                                                        <div className='row'>
                                                            {
                                                                lists.map((row, i) => {
                                                                    if (row.status == 'Pending') {
                                                                        return (
                                                                            <div key={i} className='col-lg-4'>
                                                                                <div className='border mb-2 eqalheight'>
                                                                                    <div class="folder mb-2">
                                                                                        <div class="d-flex w-100 justify-content-between">
                                                                                            <div class="name-checkbox">
                                                                                                <button type="button" class="p-0 btn-link text-white btn btn-outline-link btn-sm">{row.title}</button>
                                                                                            </div>
                                                                                            <div class="action-attachment">

                                                                                                <div role="group" class="dropdown btn-group">
                                                                                                    <div className='text-white pe-2 due-date'> <b>Due:</b> {row.due_date}</div>
                                                                                                    <div className='pe-1'>{row.status}</div>
                                                                                                    <div className={`${uInfo().id ? '' : 'd-none'}`}>
                                                                                                        <CreatedBy
                                                                                                            title={'Response'}
                                                                                                            primaryKey={row.id}
                                                                                                            defaultValue={row.status}
                                                                                                            defaultTitle={row.status}
                                                                                                            handleReset={handleReset}
                                                                                                            tableName="document_requests"
                                                                                                            updatedKey="status"
                                                                                                            row={row}
                                                                                                            showIcon={true}

                                                                                                            meta={
                                                                                                                {
                                                                                                                    coachPartners: [
                                                                                                                        {
                                                                                                                            id: 'Pending',
                                                                                                                            first_name: 'Pending',
                                                                                                                        },
                                                                                                                        {
                                                                                                                            id: 'Done',
                                                                                                                            first_name: 'Done',
                                                                                                                        },
                                                                                                                        {
                                                                                                                            id: 'Not Applicable',
                                                                                                                            first_name: 'Not Applicable',
                                                                                                                        }
                                                                                                                    ]
                                                                                                                }
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (!loader && row.status == 'Pending') && (
                                                                                            <div className='drop-area- mx-2 mb-2'>
                                                                                                <FileUpload maxFiles={2} maxSize={52428800} preview={false} releatedId={row.id} iconName="files" onSubmitCallback={handleFileSubmit} />
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}


                                                        </div>
                                                    </div>
                                                )
                                            }


                                            {
                                                lists.length > 0 && (
                                                    <div className='uplodaed-doc-lists doc-lists'>
                                                        <h5 className='text-center'>Uploaded Documents</h5>
                                                        <div className='row'>
                                                            {
                                                                lists.map((row, i) => {
                                                                    if (row.status != 'Pending') {
                                                                        return (
                                                                            <div key={i} className='col-lg-4'>
                                                                                <div className='border mb-2 eqalheight'>
                                                                                    <div class="folder mb-1">
                                                                                        <div class="d-flex w-100 justify-content-between align-items-center">
                                                                                            <div class="name-checkbox">
                                                                                                <button type="button" class="p-0 btn-link text-white btn btn-outline-link btn-sm">{row.title}</button>
                                                                                            </div>
                                                                                            <div class="action-attachment">

                                                                                                <div role="group" class="dropdown btn-group">
                                                                                                    {/* <div className='text-white pe-2 fs-6 '> <b>Due:</b> {row.due_date}</div> */}
                                                                                                    <div className='pe-1'>{row.status}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                   

                                                                                    {
                                                                                        row.files.length > 0 && (
                                                                                            <div className='document-file px-2 mb-1'>
                                                                                                <ul>
                                                                                                    {
                                                                                                        row.files.map((file, k) => {
                                                                                                            return <li key={k}>
                                                                                                                <a href={file.url} target='_blank'>
                                                                                                                    {file.key}
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        })
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}


                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Card.Body>
                                )
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )

}

export default Portal;
