import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { mainTimeZones } from "../../store/customData";
import { Asia, America, Australia, Europe, Indian } from "../../store/timezones";
import Select from "../Form/Select";

function TimeZone({ main_key, sub_key, mainZone = '', zone = '', ...props }) {
    const [timezone, setTimeZone] = useState([]);
    const [loader, setLoader] = useState(true);

    const changeZone = (zone) => {
        setLoader(false);
        setTimeout(() => {
            if (zone == "Australia") {
                setTimeZone(Australia);
            } else if (zone == "America") {
                setTimeZone(America);
            } else if (zone == "Asia") {
                setTimeZone(Asia);
            } else if (zone == "Europe") {
                setTimeZone(Europe);
            } else if (zone == "Indian") {
                setTimeZone(Indian);
            } else {
                setTimeZone(Asia);
            }
            setLoader(true);
        }, 100);
    }

    useEffect(() => {
        setTimeZone(changeZone(mainZone));
    }, [mainZone]);

    // changeZone(mainZone)

    return (
        <Row>
            <Col md="6">
                <Form.Group className="form-group">
                    <Form.Label htmlFor="main_zone">Main Timezone</Form.Label>
                    <div className="form-control-wrap">
                        <Select defaultValue={mainZone} name={main_key} onChange={(e) => changeZone(e[0] ?? 'Asia')}>
                            <option value="">Select Zone</option>
                            {
                                mainTimeZones.map((row, i) => {
                                    return <option key={i} value={row.id}>{row.label}</option>
                                })
                            }
                        </Select>
                    </div>
                </Form.Group>
            </Col>

            <Col md="6">
                <Form.Group className="form-group">
                    <Form.Label htmlFor="timezone">Timezone</Form.Label>
                    {
                        loader ? (
                            <div key={loader} className="form-control-wrap">
                                <Select defaultValue={zone} name={sub_key} >
                                    <option value="">Select Zone</option>

                                    {
                                        timezone && timezone.map((row, i) => {
                                            return <option key={i} value={row.id}>{row.label}</option>
                                        })
                                    }
                                </Select>

                            </div>
                        ) : (
                            <div key={loader} className="form-control-wrap">
                                <Select defaultValue={zone} name={sub_key} >
                                    <option value="">Select Zone</option>

                                </Select>

                            </div>
                        )
                    }
                </Form.Group>
            </Col>
        </Row>
    )
}

export default TimeZone;
