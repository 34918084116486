import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";

function StaffCreate(props) {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetchPages();
    }, []);

    const fetchPages = () => {
        const formData = new FormData();
        formData.set('type', 'default')
        formData.append('master_type','Staff');
        sendRequest('roles/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setRoles(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('users/Staff/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/user-manage/staff/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Staff" content="container-fluid">
            <div className="staff-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Staff</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/user-manage/staff/lists">Staff Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="first_name">First Name</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="first_name" placeholder="First Name" required />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="last_name" placeholder="Last Name" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="email" name="email" placeholder="Email" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="phone">Password</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="password" name="password" placeholder="Password" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Select name="country_code">
                                                        <option value="">Select Country</option>
                                                        {
                                                            countries.map((row, i) => {
                                                                return <option key={i} value={row.code}>{row.label}</option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="phone">Phone</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="number" name="phone" placeholder="Phone" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Role</Form.Label>
                                            {roles.length > 0 && (
                                                <Select name="role_id">
                                                    <option value="">Select Role</option>
                                                    {
                                                        roles.map((row, i) => {
                                                            return <option key={i} value={row.public_id}>{row.name}</option>
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Group>

                                        <Col className="col-12">
                                            <Button  type="submit" className='btn-sm'>Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default StaffCreate;