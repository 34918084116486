import { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Card, Col, Form, Nav, Row, Tab, Spinner, Button } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import { capitalizeFirstLetter } from '../../utilities';
import Swal from 'sweetalert2';
function Voip() {
    const [data, setData] = useState({});
    const [tab, setTab] = useState("plivo");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta(tab);
    }, [tab]);

    const fetchMeta = (tab) => {
        setLoading(false);
        sendRequest(`service/${tab}`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(true);
                setData(response.data.record);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }


        if (data.status != 'Active' && tab != 'rvm') {
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to set ${capitalizeFirstLetter(tab)} as default service?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.value) {
                    sendRequest(`service/${tab}/update/${data.id}`, 'POST', submitData)
                        .then(response => {
                            if (response.status === true) {
                                Swal.fire(response.message)
                            }
                        })
                        .catch(error => {
                            console.error('POST request error:', error);
                        });

                }
            })
        } else {
            sendRequest(`service/${tab}/update/${data.id}`, 'POST', submitData)
                .then(response => {
                    if (response.status === true) {
                        Swal.fire(response.message)
                    }
                })
                .catch(error => {
                    console.error('POST request error:', error);
                });
        }




    };

    return (
        <Layout title="Reseller Free Credits" content="container-fluid">
            <div className="Reseller-Free-Credits">
                <Block>
                    <Card>
                        <Card.Body>
                            <Tab.Container onSelect={(e) => setTab(e)} id="custom-tabs-example" defaultActiveKey="plivo">
                                <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="twilio">Twilio</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="plivo">Plivo</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="telnyx">Telnyx</Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="rvm">RVM</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>

                                {
                                    !loading ? (
                                        <div className="d-flex justify-content-center my-5">
                                            <Spinner animation="border" variant="secondary"></Spinner>
                                        </div>
                                    ) : (

                                        <Tab.Content>
                                            {/* <Tab.Pane eventKey="twilio">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Row className="g-3">
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Twilio SID</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.twilio_sid} type="password" name="twilio_sid" placeholder="************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Twilio Auth Token</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.twilio_auth_token} type="password" name="twilio_auth_token" placeholder="***************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Primary Customer Profile SID</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.profile_sid} type="password" name="profile_sid" placeholder="***************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                {
                                                                    data.status == 'Active' ? (
                                                                        <Col className="col-12">
                                                                            <Button type="submit">Update</Button>
                                                                        </Col>
                                                                    ) :
                                                                        (
                                                                            <Col className="col-12">
                                                                                <Button variant={'warning'} type="submit">Set as default service </Button>
                                                                            </Col>
                                                                        )
                                                                }
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <button className="btn btn-color-primary btn-icon">
                                                            <Icon name="help"></Icon>
                                                            <span>Help</span>
                                                        </button>

                                                        <div className="">
                                                            <p className="mb-1">Get new Twilio account <a target='_blank' href="https://www.twilio.com/try-twilio">Signup</a></p>
                                                            <p className="mb-1">Make sure to upgrade from your free Twilio account to paid account, so that your user start using services.</p>
                                                            <p className="mb-1">After successfully creating new account <a target='_blank' href='https://www.twilio.com/login'>click here </a> to get the account credentials.</p>
                                                            <p className="mb-1">Twilio TrustHub Console: <a target='_blank' href='https://www.twilio.com/docs/trust-hub/trusthub-rest-api/console-create-a-primary-customer-profile'>Click here</a> to Create a Primary Customer Profile and add valid
                                                                Customer Business Profile SID.</p>
                                                        </div>


                                                    </Col>

                                                </Row>
                                            </Tab.Pane> */}
                                            <Tab.Pane eventKey="plivo">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Row className="g-3">
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Auth Id</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.auth_id} type="password" name="auth_id" placeholder="************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Auth Token</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.auth_token} type="password" name="auth_token" placeholder="***************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                {
                                                                    data.status == 'Active' ? (
                                                                        <Col className="col-12">
                                                                            <Button type="submit" className='btn-sm'>Update</Button>
                                                                        </Col>
                                                                    ) :
                                                                        (
                                                                            <Col className="col-12">
                                                                                <Button variant={'warning'} type="submit" className='btn-sm'>Set as default service </Button>
                                                                            </Col>
                                                                        )
                                                                }
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <button className="btn btn-color-primary btn-icon">
                                                            <Icon name="help"></Icon>
                                                            <span>Help</span>
                                                        </button>

                                                        <div className="">
                                                            <p className="mb-1">Get new Plivo account <a target='_blank' href="https://console.plivo.com/accounts/register/">Signup</a></p>
                                                            <p className="mb-1">Check your inbox for an activation email message from Plivo. Click on the link in the message to activate your account.</p>
                                                            <p className="mb-1">Enter your mobile number to complete the phone verification step.</p>
                                                        </div>


                                                    </Col>

                                                </Row>
                                            </Tab.Pane>
                                            {/* <Tab.Pane eventKey="telnyx">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Row className="g-3">
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>API Key</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.api_key} type="password" name="api_key" placeholder="************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Public Key</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.public_key} type="password" name="public_key" placeholder="***************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                {
                                                                    data.status == 'Active' ? (
                                                                        <Col className="col-12">
                                                                            <Button type="submit">Update</Button>
                                                                        </Col>
                                                                    ) :
                                                                        (
                                                                            <Col className="col-12">
                                                                                <Button variant={'warning'} type="submit">Set as default service </Button>
                                                                            </Col>
                                                                        )
                                                                }


                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <button className="btn btn-color-primary btn-icon">
                                                            <Icon name="help"></Icon>
                                                            <span>Help</span>
                                                        </button>

                                                        <div className="">
                                                            <p className="mb-1">Get new Telnyx account <a target='_blank' href="https://telnyx.com/sign-up">Signup</a></p>
                                                            <p className="mb-1">Make sure to upgrade from your free Telnyx account to paid account, so that your user start using services.</p>
                                                            <p className="mb-1">After successfully creating new account <a target='_blank' href='https://portal.telnyx.com/#/app/home'>click here </a> to get the account credentials.</p>
                                                        </div>


                                                    </Col>

                                                </Row>
                                            </Tab.Pane> 
                                            <Tab.Pane eventKey="rvm">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Row className="g-3">
                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Email Address</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.email_address} type="email" name="email_address" placeholder="Email Address" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg={12}>
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label>Password</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.password} type="password" name="password" placeholder="***************" required />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>


                                                                <Col className="col-12">
                                                                    <Button type="submit">Update</Button>
                                                                </Col>


                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <button className="btn btn-color-primary btn-icon">
                                                            <Icon name="help"></Icon>
                                                            <span>Help</span>
                                                        </button>

                                                        <div className="">
                                                            <p className="mb-1">Get new Telnyx account <a target='_blank' href="https://telnyx.com/sign-up">Signup</a></p>
                                                            <p className="mb-1">Make sure to upgrade from your free Telnyx account to paid account, so that your user start using services.</p>
                                                            <p className="mb-1">After successfully creating new account <a target='_blank' href='https://portal.telnyx.com/#/app/home'>click here </a> to get the account credentials.</p>
                                                        </div>


                                                    </Col>

                                                </Row>
                                            </Tab.Pane>*/}
                                        </Tab.Content>
                                    )
                                }

                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default Voip;
