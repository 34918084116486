import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, Nav, Offcanvas, Tab } from 'react-bootstrap';
import { FileUpload, Icon } from '../components';
import { sendRequest } from '../utilities/api';
import Swal from 'sweetalert2';



const MediaManager = (props) => {
    const [show, setShow] = useState(props.data.show);
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [showEditFolderForm, setShowEditFolderForm] = useState(false);
    const [data, setData] = useState({});

    const handleClose = () => props.handleMediaManager(false)

    const handleFileSubmit = (files, releatedId) => {
        setLoading(true);
        const submitData = new FormData();

        if (Array.isArray(files)) {
            files.forEach((file, index) => {
                // Append each file with a unique key, e.g., 'file0', 'file1', etc.
                submitData.append(`files[]`, file);
            });
        } else {
            // If files is a single file, append it without iterating
            submitData.append('files[]', files);
        }

        submitData.append('folder_id', 0);
        submitData.append('lead_id', 0);


        sendRequest('resource/file/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire({
                        title: response.message,
                        icon: 'success',
                    });
                }
                setLoading(false);
                fetchMedia();
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchMedia();
        };
        fetchData();
    }, []);

    const fetchMedia = async (search = '') => {
        const formData = new FormData();
        formData.set('folder_id', 'MA==');
        formData.set('lead_id', 'MA==');
        formData.set('file_type', 'image');

        formData.set('filter_value', search);

        await sendRequest('resource/file/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.records);
                setCheckedRows([]);
                setData({});
                setShowEditFolderForm(false);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const toggleCheckbox = (mainId) => {
        setCheckedRows((prevCheckedRows) => {
            const newCheckedRows = { ...prevCheckedRows };
            newCheckedRows[mainId] = !newCheckedRows[mainId];
            return newCheckedRows;
        });
    };

    const deleteSelectedFile = (hypenIds) => {
        let ids = hypenIds.map(element => '-' + element);

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove all selected files`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                const submitData = new FormData();
                submitData.append('ids', ids);
                sendRequest(`resource/file/bulk/delete`, 'POST', submitData)
                    .then(response => {
                        if (response.status === true) {
                            fetchMedia();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });
    }

    const insertSelectedFile = (ids) => {
        if (props.selectionType == 'single' && ids.length > 1) {
            alert('Please select only one image');
            return;
        }

        // Filter objects based on the idArray
        let resultArray = ids.map(id => {
            let matchingObject = lists.find(obj => obj.id == id);
            return matchingObject ? matchingObject.file_path : null;
        });

        if (props.selectionType == 'single') {
            props.handleMediaManager(true, resultArray[0])
        } else {
            props.handleMediaManager(true, resultArray)

        }
    }

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('resource/file/update/' + data.public_id, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchMedia();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const keysWithHyphenAndTrue = Object.keys(checkedRows)
        .filter((key) => checkedRows[key]);
    return (
        <div >
            <Tab.Container defaultActiveKey={"media"} >

                <Offcanvas style={{ zIndex: 9999 }} className="w-75" show={show} onHide={handleClose} placement={'end'}>
                    <Offcanvas.Header className='py-1' closeButton>
                        <Nav fill variant="tabs" defaultActiveKey="media">
                            <Nav.Item>
                                <Nav.Link eventKey="media">Media Library</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="upload" >Upload Image </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='py-1'>

                        <Tab.Content>
                            <Tab.Pane eventKey="media">
                                <div className='d-flex'>
                                    <Form.Group className="form-group">
                                        <div className="form-control-wrap">
                                            <Form.Control placeholder='Search by name' type='search' size="sm" onChange={(e) => fetchMedia(e.target.value)} />
                                        </div>
                                    </Form.Group>
                                    {
                                        keysWithHyphenAndTrue.length > 0 && (
                                            <div className='action-btn px-3'>
                                                <button onClick={() => deleteSelectedFile(keysWithHyphenAndTrue)} className='btn btn-sm btn-danger me-1'>Delete Selected Images</button>
                                                <button onClick={() => insertSelectedFile(keysWithHyphenAndTrue)} className='btn btn-sm btn-warning ms-1'>Insert Selected Images</button>

                                            </div>
                                        )
                                    }
                                </div>
                                <div className="MediaManegerPopup my-2">
                                    <div className='gallery'>
                                        {
                                            lists.map((row, i) => {
                                                return (
                                                    <div key={i} className="position-relative">

                                                        <div className='image-gallery'> <div className='InputCheckBox'>
                                                            <input type='checkbox'
                                                                checked={checkedRows[`${row.id}`] || false}
                                                                onChange={() => toggleCheckbox(`${row.id}`)}
                                                            />
                                                        </div>
                                                            <img id="image-result" src={row.file_path} alt={row.file_name} /></div>
                                                        <div className='ImgEditandDelete'>
                                                            {
                                                                (showEditFolderForm && row.id == data.id) ? (
                                                                    <div className='edit-form px-2 pt-1'>
                                                                        <Form onSubmit={handleEditSubmit}>

                                                                            <Form.Group className="form-group">
                                                                                <div className="form-control-wrap">
                                                                                    <Form.Control className='rounded-0' defaultValue={data.file_name} type="text" name="file_name" placeholder='Enter folder name' required />
                                                                                </div>
                                                                            </Form.Group>

                                                                            <ButtonGroup size='sm' className='mt-1 mb-1 w-100' aria-label="Basic example">
                                                                                <Button type='submit' variant="primary">Save</Button>
                                                                                <Button onClick={() => { setShowEditFolderForm(false); setData({}) }} variant="secondary">Cancel</Button>
                                                                            </ButtonGroup>

                                                                        </Form>
                                                                    </div>

                                                                ) : (
                                                                    <span>{row.file_name}</span>
                                                                )
                                                            }

                                                            {
                                                                !(showEditFolderForm && row.id == data.id) && (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <a href={row.file_path} target='_blank' className='p-0 bg-transparent text-white border-0 me-1'><Icon name="eye"></Icon></a>
                                                                        <button onClick={() => { setData(row); setShowEditFolderForm(!showEditFolderForm) }} className='p-0 bg-transparent text-white border-0'><Icon name="pen"></Icon></button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>


                            </Tab.Pane>
                            <Tab.Pane eventKey="upload">
                                {
                                    !loading && (
                                        <FileUpload maxFiles={20} maxSize={52428800} preview={false} releatedId={0} iconName="files" onSubmitCallback={handleFileSubmit} />
                                    )
                                }

                            </Tab.Pane>
                        </Tab.Content>

                    </Offcanvas.Body>
                </Offcanvas>
            </Tab.Container>
        </div>
    );
};

export default MediaManager;
