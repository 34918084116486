import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import FullPageLayout from '../../layout/fullpage';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone, TimePicker } from '../../components';
import { sendRequest } from "../../utilities/api";

function CalendarCreate(props) {
    const fullPageView = props.fullPageView;

    const [meta, setMeta] = useState({ coachPartners: [], calendars: [] });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        business_timing: [
            {
                "index": 0,
                "day": "Sunday",
                "availability": false,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 1,
                "day": "Monday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 2,
                "day": "Tuesday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 3,
                "day": "Wednesday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 4,
                "day": "Thursday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 5,
                "day": "Friday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 6,
                "day": "Saturday",
                "availability": false,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            }
        ]
    });
    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('location/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('business_timing', JSON.stringify(data.business_timing))
        sendRequest('calendar/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (fullPageView) {
                        props.getUserDetail();
                        return;
                    }
                    props.navigate('/calendar-manage/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleChange = (index, field, value) => {
        setData(prevData => {
            const newData = { ...prevData };
            newData.business_timing[index][field] = value;
            return { ...prevData, ...newData };
        });
    };

    const calendarUI = () => {
        return <div className="calendar-create">
            <Block.Head>
                <Block.HeadContent>
                    <Block.Title tag="h2">Calendar</Block.Title>
                    <nav>
                        <ol className="breadcrumb breadcrumb-arrow mb-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/calendar-manage/lists">Calendar</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Create</li>
                        </ol>
                    </nav>
                </Block.HeadContent>
            </Block.Head>
            <Block>
                {
                    !loading ? (
                        <Spinner animation="grow" variant="primary"></Spinner>
                    ) : (
                        <Card className="card-gutter-md">

                            <Card.Body>
                                <div className="bio-block">
                                    <Form onSubmit={handleSubmit}>
                                        <div className={`bio-block`}>
                                            <Row className="g-3">
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="text" name="title" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="sub_title">Sub-title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="text" name="sub_title" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Row>
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="title_color">Title Color</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control className="w-100" type="color" name="title_color" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="sub_title_color">Sub-title</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control className="w-100" type="color" name="sub_title_color" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="4">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="header_bg_color">Header BG</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control className="w-100" type="color" name="header_bg_color" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="redirect_url">Redirect URL</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="text" name="redirect_url" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col md="12">
                                                <TimeZone main_key="main_zone" sub_key="time_zone" mainZone='' zone='' />
                                            </Col> */}
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="thankyou_msg">Thankyou Message</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control placeholder="Thank you for choosing our services. Your booking has been confirmed" as="textarea" name="thankyou_msg" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="appointment_duration">Appointment duration</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select name="appointment_duration" className="form-controll">
                                                                <option value="15">15 minutes </option>
                                                                <option value="30">30 minutes</option>
                                                                <option value="60">60 minutes</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                <Col md="12">
                                                    <b>Business Hours</b>
                                                    <p>Enter hours of operation for the days your business is open</p>
                                                    {
                                                        data.business_timing.map((row, i) => {
                                                            return <Row className="mb-3" key={i}>
                                                                <Col md="4">
                                                                    <Form.Check onChange={(e) => handleChange(i, 'availability', e.target.checked)} defaultChecked={row.availability} type="switch" id="flexSwitchDefault" label={row.day} />
                                                                </Col>
                                                                <Col md="4">
                                                                    <TimePicker onChange={(value) => handleChange(i, 'start_time', value)} readOnly={!row.availability} required={row.availability} format={12} value={row.start_time} placeholder="hh:mm" />
                                                                </Col>
                                                                <Col md="4">
                                                                    <TimePicker onChange={(value) => handleChange(i, 'end_time', value)} readOnly={!row.availability} required={row.availability} format={12} value={row.end_time} placeholder="hh:mm" />
                                                                </Col>
                                                            </Row>
                                                        })
                                                    }
                                                </Col>

                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="role">Assign locations</Form.Label>
                                                        {meta?.locations.length > 0 && (
                                                            <Select name="location_ids[]" multiple removeItemButton>
                                                                <option value="">Select</option>
                                                                {
                                                                    meta?.locations.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.name} | {row.short_name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>


                                                <Col md={6}>
                                                    <label className="form-label">Contact View Default Title:</label>
                                                    <div className="d-flex">
                                                        <div className="form-control-wrap w-75">
                                                            <Form.Control onChange={(e) => setMeta({ ...meta, lead_default_title: e.target.value })} placeholder="Contact Default Title" value={meta.lead_default_title} name="lead_default_title" required />
                                                        </div>
                                                        <div className="ms-1 w-25">
                                                            <Select className="as" removeItemButton searchEnabled onChange={(e) => {
                                                                if (e[0] && e[0] != '') {
                                                                    setMeta({ ...meta, lead_default_title: meta.lead_default_title + ' ' + e[0] });
                                                                }
                                                            }}>
                                                                <option value=''>Select Token</option>
                                                                <optgroup label="Extras Fields">
                                                                    {
                                                                        meta?.tokens?.extras && meta.tokens.extras.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                                <optgroup label="Default Fields">
                                                                    {
                                                                        meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                                <optgroup label="Custom Fields">
                                                                    {
                                                                        meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <label className="form-label">Calendar View Default Title:</label>

                                                    <div className="d-flex">
                                                        <div className="form-control-wrap w-75">
                                                            <Form.Control onChange={(e) => setMeta({ ...meta, default_title: e.target.value })} placeholder="Calendar View Default Title" value={meta.default_title} name="default_title" required />
                                                        </div>
                                                        <div className="ms-1 w-25">
                                                            <Select className="as" removeItemButton searchEnabled onChange={(e) => {
                                                                if (e[0] && e[0] != '') {
                                                                    setMeta({ ...meta, default_title: meta.default_title + ' ' + e[0] });
                                                                }
                                                            }}>
                                                                <option value=''>Select Token</option>
                                                                <optgroup label="Extras Fields">
                                                                    {
                                                                        meta?.tokens?.extras && meta.tokens.extras.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                                <optgroup label="Default Fields">
                                                                    {
                                                                        meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                                <optgroup label="Custom Fields">
                                                                    {
                                                                        meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
                                                                            return <option key={row.key} value={`{${row.key}}`}>{row.label}</option>;
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="appointment_duration">Status</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select name="status" className="form-controll">
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="role">Assign To</Form.Label>
                                                        {meta?.coachPartners.length > 0 && (
                                                            <Select name="permissions[]" multiple removeItemButton>
                                                                <option value="">Select</option>
                                                                {
                                                                    meta?.coachPartners.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col className="col-12 text-end">
                                                    <Button type="submit" className="btn-sm">Create</Button>

                                                </Col>
                                            </Row>
                                        </div>

                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    )
                }
            </Block>
        </div>
    }

    return (
        <>
            {fullPageView === true ? (
                <FullPageLayout title="Calendar" content="container-fluid" nkWrap={false}>
                    {calendarUI()}
                </FullPageLayout>
            ) : (
                <Layout title="Calendar" content="container-fluid">
                    {calendarUI()}
                </Layout>
            )}
        </>
    )
}

export default CalendarCreate;