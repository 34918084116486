import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, InputGroup, Dropdown, Table, ButtonGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, LinkList, CustomDropdownToggle, CustomDropdownMenu } from '../../components';
import { toInitials } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";
import Swal from "sweetalert2";
import DataTableComponent from "../../components/DataTable/DataTable";

function PhoneNumbers(props) {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("my-number");
    const childRef = useRef(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const [name, setName] = useState("");
    const [country, setCountry] = useState("US");
    const [phoneType, setPhoneType] = useState("local");

    const [flow, setFlow] = useState({});



    const fetchActiveNumber = async (data) => {
        await sendRequest(`settings/phone/numbers/available`, 'POST', data).then(response => {
            if (response.status === true) {
                setData(response.data.records);
                setSelectedValues([]);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }

    const unlockFlow = async (data) => {
        setLoading(false);
        await sendRequest(`settings/phone/numbers/unlock/flow`, 'POST', data).then(response => {
            if (response.status === true) {
                setFlow(response.data);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }


    const submitUnlockFlow = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('type', 'submit');
        unlockFlow(submitData);

    }

    const filterNumber = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetchActiveNumber(submitData);

    }


    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedValues((prevValues) => [...prevValues, value]);
        } else {
            setSelectedValues((prevValues) => prevValues.filter((item) => item !== value));
        }
    };

    const purchaseNumber = async () => {


        setLoading(false);

        let formData = new FormData();
        formData.append('name', name);
        formData.append('number', selectedValues);
        formData.append('country', country);
        formData.append('phone_type', phoneType);

        await sendRequest(`settings/phone/numbers/purchase`, 'POST', formData).then(response => {
            if (response.status === true) {
                document.querySelectorAll('input[name="selected_numbers"]').forEach((checkbox) => (checkbox.checked = false));
                setSelectedValues([]);
                Swal.fire({
                    title: response.message,
                });
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }
    const toggle = async (tab) => {

        switch (tab) {
            case 'get-number':
                setLoading(false);
                await fetchActiveNumber(new FormData());
                break;
            case 'delete-number':

                break;
            case 'unlock-flow':
                setLoading(false);
                await unlockFlow(new FormData());
                break;
            default:
                //  await fetchActiveNumber();
                break;

        }
        setTab(tab);

    }

    const unlockPhone = async (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to unlock this number`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Sure!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`settings/phone/numbers/unlock/number/${id}`, 'POST', {}).then(response => {
                    if (response.status === true) {
                        Swal.fire({
                            title: response.message,
                        });
                    }
                })
                    .catch(error => {
                        console.error('GET request error:', error);
                    });
            }
        })

    }

    const userColumns = [
        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">
                    {
                        row.is_locked == 'Yes' && (
                            <ButtonGroup size="sm">
                                <Button
                                    title="Unlock number"
                                    onClick={() => unlockPhone(row.public_id)} variant="outline-danger"
                                >
                                    <Icon name="unlock-fill"></Icon>
                                </Button>
                            </ButtonGroup>
                        )
                    }
                </div>
            ),
        },
        {
            name: "Number",
            key: 'number',
            selector: (row) => row.number,
            cell: (row) => (
                <span>{row.number}</span>
            ),
        },
        {
            name: "NAME",
            key: 'name',
            selector: (row) => row.name,
            cell: (row) => (
                <span>{row.name}</span>
            ),
        },
        // {
        //     name: "In Use",
        //     key: 'phone_used_in',
        //     selector: (row) => row.phone_used_in,
        //     cell: (row) => (
        //         <span>{row.phone_used_in}</span>
        //     ),
        // },

        {
            name: "Type",
            key: 'type',
            selector: (row) => row.type,
            cell: (row) => (
                <span>{row.type}</span>
            ),
        },

        {
            name: "Is Locked",
            key: 'is_locked',
            selector: (row) => row.is_locked,
            cell: (row) => (
                <span>{row.is_locked}</span>
            ),
        },

        {
            name: "Billing",
            key: 'last_billing_date',
            selector: (row) => row.type,
            cell: (row) => (
                <p>
                    <span><strong>Purchase Date:</strong> {row.purchase_date}</span>
                    <br />
                    <span><strong>Next Billing Date:</strong> {row.last_billing_date}</span>
                </p>
            ),
        }
    ];



    return (
        <Layout title="Phone Numbers" content="container-fluid">
            <div className="main-custom-field">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Phone Numbers</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Phone Numbers</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>

                                    <Tab.Container onSelect={(e) => toggle(e)} id="custom-tabs-example" defaultActiveKey={tab}>
                                        <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="my-number">My Numbers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="get-number">Get Phone Number(s)</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="delete-number">Deleted PhoneNumber(s)</Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item>
                                                <Nav.Link eventKey="unlock-flow">Unlock Phone Flow</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="my-number">
                                                <div className="bio-block">
                                                    {
                                                        tab == 'my-number' && (
                                                            <DataTableComponent
                                                                childRef={childRef}
                                                                selectableRows={false}
                                                                columns={userColumns}
                                                                tableClassName="data-table-head-light table-responsive"
                                                                slug={'settings/phone/numbers'}
                                                                extraCondition={{ 'status': 'Active' }}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="get-number">
                                                <div className="bio-block">

                                                    <Form onSubmit={filterNumber}>
                                                        <Row className="g-3 mb-3">
                                                            <Col>
                                                                <Form.Select
                                                                    size="sm"
                                                                    label="Phone Type"
                                                                    name="phone_type"
                                                                    defaultValue={phoneType}
                                                                    onChange={(e) => setPhoneType(e.target.value)}
                                                                >
                                                                    <option value="local">Local Numbers</option>
                                                                    <option value="toll_free">Toll Free Numbers</option>
                                                                </Form.Select>
                                                            </Col>

                                                            <Col>
                                                                <Form.Select
                                                                    size="sm"
                                                                    label="Country"
                                                                    name="country_code"
                                                                    defaultValue={country}
                                                                    onChange={(e) => setCountry(e.target.value)}
                                                                >
                                                                    <option value="US">USA</option>
                                                                    <option value="CA">Canada</option>
                                                                    <option value="AU">Australia</option>
                                                                    <option value="MX">Mexico</option>
                                                                    <option value="GB">United Kingdom</option>

                                                                </Form.Select>
                                                            </Col>

                                                            <Col>
                                                                <Form.Select
                                                                    size="sm"
                                                                    label="Search By"
                                                                    name="seach_by_type">
                                                                    <option value="areaCode">Area Code</option>
                                                                    <option value="contains">Phone</option>
                                                                </Form.Select>
                                                            </Col>

                                                            <Col>
                                                                <Form.Control size="sm" type="text" name="area_code" placeholder="Area code" />
                                                            </Col>



                                                            <Col>
                                                                <Button size="sm" type="submit" className='mr-5'>Search</Button>
                                                                <Button size="sm" variant="outline-light" type='reset' >Reset</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                    <Table responsive className="table-middle mb-3 table-bordered">
                                                        <thead className="table-light table-head-sm">
                                                            <tr>
                                                                <th> - </th>
                                                                <th> Number</th>
                                                                <th> Region</th>
                                                                <th> Postal Code</th>
                                                                <th> Locality</th>
                                                                <th> Address</th>
                                                                <th> Voice Enabled</th>
                                                                <th> SMS Enabled</th>
                                                                <th> MMS Enabled</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.map((row, i) => {
                                                                    return (
                                                                        <tr
                                                                            key={i + '--' + row.id}
                                                                        >
                                                                            <td>

                                                                                <input className="form-check-input" type="checkbox" name="selected_numbers" value={row.phone_number}
                                                                                    checked={selectedValues.includes(row.phone_number)}
                                                                                    onChange={handleCheckboxChange}
                                                                                />
                                                                            </td>
                                                                            <td>{row.phone_number} </td>
                                                                            <td> {row.region} </td>
                                                                            <td> {row.postal_code == '' ? '--' : row.postal_code} </td>
                                                                            <td>{row.locality}  </td>
                                                                            <td> {row.address_requirements} </td>
                                                                            <td> {row.capabilities.voice ? 'True' : 'False'} </td>
                                                                            <td> {row.capabilities.sms ? 'True' : 'False'}  </td>
                                                                            <td> {row.capabilities.mms ? 'True' : 'False'}  </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>

                                                        {
                                                            selectedValues.length > 0 && (
                                                                <>
                                                                    <Form.Group className="form-group mb-2">
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control size="sm" placeholder="Phone Name(optional)" name="name" type="text"
                                                                                className="form-control"
                                                                                value={`${name}` || ''}
                                                                                onChange={(e) => setName(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </Form.Group>
                                                                    <button
                                                                        className="btn btn-primary waves-effect waves-light"
                                                                        type="submit"
                                                                        onClick={() => purchaseNumber()}
                                                                        disabled={selectedValues.length < 1 ? true : false}
                                                                    >
                                                                        Purchase Number
                                                                    </button>
                                                                </>
                                                            )
                                                        }
                                                    </Table>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="unlock-flow">
                                                <div className="bio-block">
                                                    <Form onSubmit={submitUnlockFlow}>
                                                        <Row className="g-3 mb-3">
                                                            <Col lg="12">
                                                                <Form.Select
                                                                    size="sm"
                                                                    name="group_id"
                                                                    defaultValue={flow.group_id}
                                                                    required
                                                                >
                                                                    <option value="">Select Group</option>
                                                                    {
                                                                        flow.groups && flow?.groups.map((row, i) => {
                                                                            return <option value={row.id}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Col>

                                                            <Col lg="12">

                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="title">Change Voice Message</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control defaultValue={flow.voice_text} as="textarea" name="voice_text" placeholder="Voice message" required />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="col-12">
                                                                <Button className="btn-sm" type="submit">Submit</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>

                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block>
            </div>
        </Layout>
    )
}

export default PhoneNumbers;