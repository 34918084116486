import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import { TopNav } from "./TopNav";
import Basic from "./Basic";
import Inbound from "./Inbound";
import IvrUpdate from "./IvrUpdate";
import Outbound from "./Outbound";
import Automation from "./Automation";

function CampaignEdit(props) {
    const { id, type, ivr_id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            fetchRecordById();
        };
        fetchData();
    }, []);



    const fetchMeta = async () => {
        const formData = new FormData();
        formData.append('section', type);
        formData.append('id', id);
        if (ivr_id) {
            formData.append('ivr_id', ivr_id);
        }

        await sendRequest('campaign/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = () => {
        sendRequest(`campaign/${id}/show`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const refreshCampaign = (method = 'campaign') => {
        if (method == 'meta') {
            fetchMeta();
        } else if (method == 'campaign') {
            fetchRecordById();
        }

    }



    return (
        <Layout title="Campaign List" content="container-fluid">
            <div className="group-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Campaign List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/campaign-manage/lists">Campaign List</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    <TopNav campaign_id={id} active={type} />

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                {
                                    type == 'info' && (
                                        <Card.Body>
                                            <Basic data={data} meta={meta} id={id} refreshCampaign={refreshCampaign} />
                                        </Card.Body>
                                    )
                                }

                                {
                                    type == 'inbound' && (
                                        <Card.Body>
                                            {
                                                ivr_id ? (
                                                    <IvrUpdate meta={meta} id={id} ivr_id={ivr_id} refreshCampaign={refreshCampaign} />
                                                ) : (
                                                    <Inbound data={data} meta={meta} id={id} refreshCampaign={refreshCampaign} />
                                                )
                                            }
                                        </Card.Body>
                                    )
                                }

                                {
                                    type == 'outbound' && (
                                        <Card.Body>
                                            <Outbound data={data} meta={meta} id={id} refreshCampaign={refreshCampaign} />
                                        </Card.Body>
                                    )
                                }

                                {
                                    type == 'automations' && (
                                        <Card.Body>
                                            <Automation data={data} meta={meta} id={id} refreshCampaign={refreshCampaign} />
                                        </Card.Body>
                                    )
                                }



                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default CampaignEdit;