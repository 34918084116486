import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Dropdown, Offcanvas, Accordion, Tab, Nav, OverlayTrigger, Popover, ButtonGroup, Modal } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, ChatList, CustomDropdownMenu, CustomDropdownToggle, MediaGroup, MediaText, Message, ChatMedia, DateRangePicker } from '../../../components';
import { Break, convertPhoneFormat, currencySign, uToken } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";
import classNames from "classnames";
import SimpleBar from 'simplebar-react';
import QuickConnect from "../QuickConnect";
import { useAuth } from "../../../AuthContext";
import EditableField from './EditableField';
import Invoice from "../../payments-manage/Lists";
import Milestone from "../../pipeline-manage/Milestone";
import AppointmentsEdit from "../../calendar-manage/appointments/Edit";
import Attachment from "./attachment/Lists";
import MediaManager from "../../MediaManager";
import Swal from "sweetalert2";
import { ApiUrl } from "../../../utilities/config";
import CreateContact from "../../../pages/crm-manage/contacts/CreateContact";
import { HostUrl } from "../../../utilities/config";


function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
// import EventLists from "./EventLists";
function LeadDetails(props) {
	let query = useQuery();
	const { callInit } = useAuth();

	const { id } = useParams();
	const [data, setData] = useState({ pipeline: {} });
	const [loading, setLoading] = useState(false);
	const [meta, setMeta] = useState({});
	const [customField, setCustomField] = useState([]);
	const [customFieldData, setCustomFieldData] = useState({});
	const [formloading, setFormLoading] = useState(false);
	const [jobLoading, setJobLoading] = useState(false);
	const [assignTags, setAssignTags] = useState([]);
	const [activities, setActivities] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [jobsCategory, setJobsCategory] = useState([]);
	const [tab, setTab] = useState(query.has('type') ? query.get('type') : 'all');
	const textareaRef = useRef(null);
	const [token, setToken] = useState('');
	const [offcanvasStart, setOffcanvasStart] = useState(false);
	const [activeTab, setActiveTab] = useState(query.has('type') ? query.get('type') : 'all');
	const [jobActiveTab, setJobActiveTab] = useState(0);
	const [modal, setModal] = useState({ modal: false, record: {} });
	const eventDefault = { opened: false }
	const [eventModal, setEventModal] = useState(eventDefault);
	const iframeRef = useRef(null);

	const [showMedia, setShowMedia] = useState({ releatedId: 0, show: false });
	const [showPopover, setShowPopoverLocal] = useState({ show: false, id: 0, area: 'activity' });
	const [expandedRows, setExpandedRows] = useState({});
	const [responseData, setResponseData] = useState({});
	const [documentId, setDocumentId] = useState('');
	const [activeContactTab, setActiveContactTab] = useState('details');
	const [show, setShow] = useState(false);
	const [previewModal, setPreviewModal] = useState(false);
	const [password, setPassword] = useState('');
	const [portalFiles, setPortalFiles] = useState([]);
	const [callTypes, setCallTypes] = useState([]);
	const [actionType, setActionType] = useState('call');
	const [activityValue, setActivityValue] = useState('');

	function savePassword() {
		const formData = new FormData();

		formData.append('password', password);
		formData.append('cliend_id', id);

		sendRequest('crm/leads/save/portal-password', 'POST', formData).then(response => {
			if (response.status === true) {
				// Swal.fire({
				// 	title: 'Success',
				// 	text: `Password Updated`,
				// 	icon: 'success',
				// 	confirmButtonText: 'Ok'
				// })
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	function closePreviewModal() {
		setPreviewModal(false);
	}

	const handleCrossClick = () => {
		setShowPopoverLocal({ show: false, id: 0, area: 'activity' });
	};

	const handleTabChange = (selectedTab) => {
		setActiveContactTab(selectedTab);
	};

	const [quickConnect, setQuickConnect] = useState({
		type: '',
		modal: false,
		id: 0,
		to: '',
		coach_id: 0,
		campaign_id: 0,
	});

	const quickAction = (status = false, data = {}) => {
		if (status && quickConnect.type != 'activity') {
			fetchActivity(quickConnect.type);
		};
		setQuickConnect({
			type: '',
			modal: false,
			id: 0,
			to: '',
			campaign_id: 0,
			coach_id: 0,
		});

	}
	const insertValueAtCursor = (valueToInsert) => {
		const textarea = textareaRef.current;
		const start = textarea.selectionStart;
		const end = textarea.selectionEnd;
		const newText = data.body ? data.body.substring(0, start) + valueToInsert + data.body.substring(end) : valueToInsert;
		setData((prevState) => ({
			...prevState,
			body: newText,
		}));

		// Set the cursor position after the inserted value
		textarea.selectionStart = start + valueToInsert.length;
		textarea.selectionEnd = start + valueToInsert.length;
		textarea.focus();
	};


	useEffect(() => {
		const handleIframeMessage = (event) => {
			if (event.data === 'iframeLoaded') {
				closeEventModal();
				fetchActivity('event')
			}
		};
		window.addEventListener('message', handleIframeMessage);

		const fetchData = async () => {
			await fetchMeta();
			await fetchRecordById();
			await fetchActivity(tab);
			await fetchJobs(0);
		};
		fetchData();
		return () => {
			window.removeEventListener('message', handleIframeMessage);
		};
	}, []);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handlePrimarySubmit = async (event) => {
		setFormLoading(true);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}
		submitData.append('parent_id', id);
		sendRequest('crm/leads/create', 'POST', submitData)
			.then(response => {
				if (response.status === true) {

					setData((prevState) => ({
						...prevState,
						contacts: [
							...prevState.contacts,
							{
								name: response.data.lead_rec.name,
								email: response.data.lead_rec.email,
								age: response.data.lead_rec.age,
								contact_relation: response.data.lead_rec.contact_relation,
								title: response.data.lead_rec.title
							}
						]
					}));

					handleClose();
				}
				setFormLoading(false);
			})
	}

	const fetchActivity = async (tab) => {

		let url = `crm/leads/activity/${id}/${tab}`;
		if (['sms', 'email'].includes(tab)) {
			url = `crm/reports/${id}/${tab}`;
		}

		if (tab == 'event') {
			url = `calendar/appointments/lists?page_number=1&page_size=50000&lead_id=${id}&date_range=All`;
		}

		const formData = new FormData();

		if (tab == 'note') {
			formData.append('note_type', 'manual')
		}

		await sendRequest(`${url}`, 'POST', formData).then(response => {
			if (response.status === true) {
				if (tab == 'event') {
					setActivities(response.data.records)
				} else {
					setActivities(response.data.lists)
					setCallTypes(response.data.call_statuses)
				}
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}
	const fetchJobs = async (category_id) => {

		let url = `jobs/lists/${id}`;
		const formData = new FormData();

		formData.set('category_id', category_id)
		await sendRequest(`${url}`, 'POST', formData).then(response => {
			if (response.status === true) {
				setJobs(response.data.records);
				setPortalFiles(response.data.portal_files);
				setJobsCategory(response.data.category_data);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}
	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'default')
		await sendRequest('crm/leads/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setCustomField(response.data.customFields.custom_fields);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const deleteAppointment = async (id, area) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to remove this record`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`calendar/appointments/${id}`, 'DELETE')
					.then(response => {
						if (response.status === true) {
							fetchActivity(area);
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});
	}

	const fetchRecordById = async () => {
		await sendRequest(`crm/leads/${id}`, 'POST').then(response => {
			if (response.status === true) {
				setData(response.data.list);
				setCustomFieldData(response.data.list.custom_fields);
				setAssignTags(response.data.list.tags);
				setPassword(atob(response.data.list.encrypted_password))
			}
			setLoading(true);
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}
	const removeJob = async (job_id, lead_id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to unassign this job from this dialer contact?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`crm/leads/job/remove/${job_id}/${lead_id}`, 'DELETE')
					.then(response => {
						if (response.status === true) {
							fetchJobs(0);
							setJobActiveTab(0);
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});
	}
	const handleSMSChange = (e, key) => {
		const { name, value, placeholder } = e.target;
		setData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	const handleChange = (e, key) => {
		const { name, value, placeholder } = e.target;
		// setData((prevState) => ({
		// ...prevState,
		// [name]: value,
		// })); 
	}


	const handleSubmit = async (event) => {
		setFormLoading(true);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		let group = false;
		let tag = false;
		for (let [key, value] of formData.entries()) {

			if (key == 'group_id[]') {
				group = true;
			}

			if (key == 'tag_id[]') {
				tag = true;
			}

			if (!customField.some(item => item.key === key) && key != 'group_id[]' && key != 'tag_id[]') {
				submitData.append(key, value);
			}

		}

		submitData.append('id', id);

		if (group) {
			submitData.append('group_id', formData.getAll('group_id[]'));
		} else {
			submitData.append('group_id', data.group_id);
		}

		if (tag) {
			submitData.append('tag_id', formData.getAll('tag_id[]'));
		} else {
			submitData.append('tag_id', data.tag_id);
		}


		//submitData.append('custom_fields', JSON.stringify(customFieldData));
		sendRequest('crm/leads/create', 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					fetchRecordById();
				}
				setFormLoading(false);

			})
			.catch(error => {
				setFormLoading(false);
				console.error('POST request error:', error);
			});
	};

	const handleButtonClick = (value) => {
		setActionType(value);
	};

	const handleSubmitQuickAction = (event) => {
		setFormLoading(true);
		event.preventDefault();
		let action_type = '';
		let to = '';
		const formData = new FormData(event.currentTarget);

		const actionTypeValue = formData.get('call_type');

		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			if (key == 'action_type') {
				action_type = value;
			}
			if (key == 'to') {
				to = value;
			}
			submitData.append(key, value);
		}

		if (action_type === 'call' && actionTypeValue === 'save') {

			let call_note = submitData.get('activity')

			if (!call_note) {
				Swal.fire({
					icon: 'warning',
					title: 'Please add a call note',
					showConfirmButton: true,
				})

				return;
			}
		}

		if (to != '') {
			submitData.append('tos', to);
		}
		submitData.append('campaign_id', 0);

		submitData.append('lead_id', data.id);
		submitData.append('type', action_type);
		submitData.append('call_type', actionTypeValue);

		if (action_type == 'sms') {
			submitData.append('tos', data.mobile);
		}

		sendRequest(`crm/leads/add/activity/${data.id}/${action_type}`, 'POST', submitData).then(response => {
			if (response.status === true) {
				if (action_type == 'note') {
					//setSendNote(false);
					//getNotes(data.id, note_type);
				} else {
					//props.quickAction(true, {});
				}
				fetchActivity(action_type);
				setActivityValue('')

			}
			setData((prevState) => ({
				...prevState,
				body: '',
			}));
			setFormLoading(false);
		})
			.catch(error => {
				setFormLoading(false);
				console.error('POST request error:', error);
			});
	}
	const callbackMilestone = (response) => {
		if (response) {
			fetchRecordById();
			setOffcanvasStart(false);
		}
	}

	const handleReset = () => {
		if (modal.modalArea == 'activity') {
			fetchActivity('all');
		} else {
			fetchActivity('event');
		}
		setModal({ modal: false, record: {} })
	};
	function openAddModal() {

		const updateEventModal = {
			...eventModal,
			opened: true,
			modalTitle: "Add Appointment",
		}
		setEventModal(updateEventModal);
	}
	function closeEventModal() {
		const updateEventModal = { ...eventModal, opened: false }
		setEventModal(updateEventModal);
	}

	const tabChange = (tab) => {
		setActivities([]);
		if (tab != 'attachment') {
			fetchActivity(tab);
		}
		setActiveTab(tab);
	}

	const jobTabChange = (tab) => {
		setJobLoading(true);
		fetchJobs(tab);
		setJobActiveTab(tab);
		setJobLoading(false);
	}

	const handleMediaManager = (status, data = '') => {
		if (status) {
			setFormLoading(true);
			const submitData = new FormData();
			submitData.append('image', data);

			sendRequest(`crm/leads/update/${id}`, 'POST', submitData)
				.then(response => {
					if (response.status === true) {
						fetchRecordById();
					}
					setFormLoading(false);
				})
				.catch(error => {
					setFormLoading(false);
					console.error('POST request error:', error);
				});
		}
		setShowMedia({ releatedId: 0, show: false })
	};

	const adjustIframeHeight = () => {
		const iframe = iframeRef.current;
		if (iframe) {
			try {
				const { contentWindow } = iframe;
				const { document } = contentWindow;
				const height = Math.max(
					document.body.scrollHeight,
					document.body.offsetHeight,
					document.documentElement.clientHeight,
					document.documentElement.scrollHeight,
					document.documentElement.offsetHeight,
					contentWindow.screen.availHeight
				);
				iframe.style.height = `${height}px`;
			} catch (error) {
				console.log(error, 'error')
				iframe.style.height = `700px`;
			}
		}
	};

	const handleMessageFromIframe = (event) => {
		const { data } = event;
		const iframe = iframeRef.current;
		if (data && data.type === 'changeUrl') {
			const { url, lead_id, lead_name } = data;
			let newUrl = `${window.location.origin}/calendar-manage/appointments/create/${url}?type=dialer&lead_id=${lead_id}&lead_name=${lead_name}&page_name=contact`
			iframe.src = newUrl;
		}
	};

	React.useEffect(() => {
		window.addEventListener('message', handleMessageFromIframe);

		return () => {
			window.removeEventListener('message', handleMessageFromIframe);
		};
	}, []);


	const handleSubmitSnoozeAppointment = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		sendRequest(`calendar/appointments/snooze/${showPopover.id}`, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					if (showPopover.area == 'activity') {
						fetchActivity('all');
					} else {
						fetchActivity('event');
					}

					setShowPopoverLocal({ show: false, id: 0, area: 'activity' })
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});


	};

	const getReportDetails = async (id) => {
		const [report_id, activity_id] = id.split('_');


		// const secondValue = activity_id !== undefined ? activity_id : '';

		await sendRequest(`crm/report/${report_id}/details`, 'GET', []).then(response => {
			if (response.status === true) {
				setResponseData({ ...responseData, [id]: response.data.list });
			}
		})
			.catch(error => {
				console.error('GET request error:', error);
			});
	}

	const toggleExpand = (public_id) => {
		setExpandedRows((prevState) => ({
			...prevState,
			[public_id]: !prevState[public_id],
		}));
	}

	useEffect(() => {
		// Listen for changes in expandedRows and trigger API calls
		Object.keys(expandedRows).forEach((public_id) => {
			if (expandedRows[public_id] && !responseData[public_id]) {
				getReportDetails(public_id);
			}
		});
	}, [expandedRows, responseData]);



	const handleAction = (action) => {
		// Define the URL endpoint with the action parameter
		const url = `${ApiUrl}document/download/${documentId}?action=${action}&lead_id=${data.id}&coach_id=${data.coach_id}`;


		const options = {
			method: 'Get',
			headers: {
				'Authorization': `Bearer ` + uToken()
			},
		};

		fetch(url, options)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.blob();
			})
			.then(blob => {
				const contentType = 'application/pdf'; // Specify the correct content type
				const blobOptions = { type: contentType };
				const blobWithContentType = new Blob([blob], blobOptions);

				if (action === 'doc') {
					const filename = 'document.docx';
					downloadFile(blobWithContentType, filename);
				} else if (action === 'pdf') {
					const filename = 'document.pdf';
					downloadFile(blobWithContentType, filename);
				} else if (action === 'preview') {
					const pdfUrl = window.URL.createObjectURL(blobWithContentType);
					window.open(pdfUrl, '_blank');
				}
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	const downloadFile = (blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};


	return (
		<Layout content="container-fluid" title="Details" from="lead-detail">
			<div className='parentClasses row' id="chatWrap">
				<Col lg="7" className="pe-0">
					<div className="UserDetailDialer">
						<div className="nk-aside-content py-0 position-relative pb-2 px-2">
							<MediaGroup className="text-center pt-2 pb-0">
								<div className="d-flex w-100 align-items-center">
									<div className="ProfilePipelineStatus">
										<div className="lead-image position-relative">
											<button className="img-upload-lead btn btn-sm"
												onClick={() => setShowMedia({ releatedId: data.id, show: true })}
											>
												<Icon name={'upload'} />
											</button>
											<Media size="big" shape="circle" middle className="lead-image">
												{data.image ? (
													<Image src={data.image} alt={data.initials} />
												) : (
													<b>{data.initials}</b>
												)}
											</Media>
										</div>
										<div className="StatusPipe">
											<button
												className="btn btn-outline-light p-1 py-0 text-white"
												onClick={() => setOffcanvasStart(true)}
												style={{
													background: data.pipeline?.status_title_color,
													color: '#fff !important',
													fontWeight: 500
												}}
												title="Milestone Status"
											>
												{data.pipeline.status_title != '' ? data.pipeline.status_title : 'Assign Milestone'}
											</button>
											<br></br>
											<button
												className="btn btn-link pb-0 text-light"
												onClick={() => setOffcanvasStart(true)}
												title="Milestone Category"
											>
												{data.pipeline.master_title}
											</button>
										</div>
									</div>
									<div className="text-start ms-3 w-100 PipelineUserName">
										<h3 className="title mb-1">{data.name}
											{
												data.age != '' && (
													<small class="text ms-1">({data.age} Years)</small>
												)
											}

										</h3>

										<ul className="nk-list-option pt-1">

											{data.address && (
												<li><Icon name="map-pin"></Icon><span className="small"><a target="_blank" href={`http://maps.google.com/maps?q=${data.address}`}>{data.address}</a></span></li>
											)}

											{/* {data.company_name && (
												<li><Icon name="building"></Icon><span className="small">{data.company_name}</span></li>
											)} */}
										</ul>
										<ul className="nk-list-option pt-0 w-100 text-start align-items-start">
											<li>
												<div className="mt-md-0 text-start">
													<ul className="d-flex flex-wrap gap gx-1">
														{assignTags.length > 0 && assignTags.map((row, i) => {
															return (
																<li key={i}>
																	<span className="badge "
																		style={{ backgroundColor: row.bg_color, color: row.text_color, padding: '5px 10px', borderRadius: '5px' }}
																	>
																		{row.title}
																	</span>
																</li>
															)
														})}
													</ul>
												</div>
											</li>
										</ul>
									</div>
									<div>
										<ul className="nk-chat-head-tools gap ActionButtons ms-2 SocialIcons">
											<li>
												<Button onClick={() => {
													setPreviewModal(true);
													// props.navigate(`/leads/portal/${data.portal_key}`);
												}}
													title="Portal"
													size="md" variant="zoom" className="btn-icon">
													<Icon name="link"></Icon>
												</Button>
											</li>
											<li>
												<Button onClick={() => {
													props.navigate(`/crm-manage/leads/edit/${data.public_id}`);
												}}
													title="Edit Lead"
													size="md" variant="zoom" className="btn-icon CallButton">
													<Icon name="edit"></Icon>
												</Button>
											</li>
											<li>
												<Button
													onClick={() => {
														setActiveTab('note');
														fetchActivity('note');
														// setQuickConnect({
														// modal: true,
														// id: data.id,
														// type: 'note',
														// to: '',
														// coach_id: data.coach_id,
														// campaign_id: 0,
														// })
													}} title="Add Note" size="md" variant="zoom" className="btn-icon NoteButton">
													<Icon name="notes-alt"></Icon>
												</Button>
											</li>
											<li>
												<Button onClick={() => {
													setActiveTab('sms');
													fetchActivity('sms');
													// setQuickConnect({
													// modal: true,
													// id: data.id,
													// type: 'sms',
													// to: data.lead_number,
													// coach_id: data.coach_id,
													// campaign_id: 0
													// })
												}} title="Send text message" size="md" variant="zoom" className="btn-icon SMSButton">
													<Icon name="msg"></Icon>
												</Button>
											</li>
											<li>
												<Button onClick={() => {
													setActiveTab('email');
													fetchActivity('email');
													// setQuickConnect({
													// modal: true,
													// id: data.id,
													// type: 'email',
													// to: data.email,
													// coach_id: data.coach_id,
													// campaign_id: 0
													// })
												}} title="Send email message" size="md" variant="zoom" className="btn-icon EmailButton">
													<Icon name="mail"></Icon>
												</Button>
											</li>
											<li>
												<Button onClick={() => {
													callInit({
														modal: false,
														id: data.id,
														type: 'call',
														to: data.lead_number,
														campaign_id: 0,
														coach_id: data.coach_id,
														first_name: data.first_name,
														last_name: data.last_name
													});
													setActiveTab('call');
													fetchActivity('call');
												}}
													title="Initiate outbound call" size="md" variant="zoom" className="btn-icon CallButton">
													<Icon name="call"></Icon>
												</Button>
											</li>

											<li className="d-none">
												<Dropdown>
													<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-zoom btn-icon">
														<Icon name="more-h"></Icon>
													</Dropdown.Toggle>
													<Dropdown.Menu as={CustomDropdownMenu} align="end">
														<div className="dropdown-content py-1">
															<ul className="link-list is-compact CallActionDropDown">
																<li>
																	<Link to={`/crm-manage/leads/edit/${data.public_id}`}>
																		<Icon name="edit"></Icon><span>Edit</span>
																	</Link>

																</li>
																<li>
																	<a href="/#"><Icon name="user-cross"></Icon><span>Block</span></a>
																</li>
																<li>
																	<a href="/#"><Icon name="alert"></Icon><span>Report</span></a>
																</li>
															</ul>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</li>


										</ul>
										<div style={{ width: '100px' }} class=" list-group-borderless list-group-sm list-group text-end">
											<span class="text">${data.spent} Spent</span>
											<span class="text">${data.balance_due} Balance</span>
										</div>

										<Link className="d-none" to={`/crm-manage/leads/edit/${data.public_id}`}> <Icon name="pen"></Icon></Link>

									</div>
								</div>
							</MediaGroup>
						</div>
						<Tab.Container id="accordion-tabs" activeKey={activeContactTab} onSelect={handleTabChange}>
							<Nav variant="tabs" className="nav-tabs-s1 m-3">
								<Nav.Item>
									<Nav.Link eventKey="details"><b>Details</b></Nav.Link>
								</Nav.Item>
								{data.parent_id == 0 && (
									<Nav.Item>
										<Nav.Link eventKey="subContacts"><b>Contacts</b></Nav.Link>
									</Nav.Item>
								)}
								<Nav.Item>
									<Nav.Link eventKey="jobDetails"><b>Jobs</b></Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="documentMerging"><b>Mail Merge</b></Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="portalFiles"><b>Documents ({portalFiles.length})</b></Nav.Link>
								</Nav.Item>
								<div className="ms-auto">
									<button className="btn btn-icon btn-sm btn-primary btn-primary-s1 me-2">
										<Link to={`/crm-manage/leads/edit/${data.public_id}`}> <Icon className={'text-white '} name="edit"></Icon></Link>
									</button>
								</div>
							</Nav>

							{/* here need to add edit button on right side */}

							<Tab.Content>
								<Tab.Pane eventKey="details">
									<Card className="card-gutter-md border-0">
										<Card.Body className="p-2">
											<div className="bio-block">
												{(meta.groups && data.id) && (
													<>
														{!formloading && (
															<Form onSubmit={handleSubmit}>

																{/* here need to show parent of currect contact */}

																{/* {data.primaryContactData && (
																	<Row className="g-0 mb-1 d-flex align-items-center">
																		<h6 className="mb-0 me-2">
																			Primary Contact:
																		</h6>
																		<Link to={`/crm-manage/leads/dialer/${data.primaryContactData.public_id}`}>
																			{data.primaryContactData.email}
																		</Link>
																	</Row>
																)} */}


																<Row className="g-0">
																	<Col md="6">
																		<EditableField label="Name" value={data.name} type="text" name="name" placeholder="Name" required={true} onChange={handleChange} />
																	</Col>
																	{/* <Col md="6">
																		<EditableField label="First Name" value={data.first_name} type="text" name="first_name" placeholder="First Name" required={true} onChange={handleChange} />
																	</Col>
																	<Col md="6">
																		<EditableField label="Last Name" value={data.last_name} name="last_name" type="text" placeholder="Last Name" required={true} onChange={handleChange} />
																	</Col> */}

																	<Col md="6">
																		{/* <Form.Group className="form-group">
																			<Form.Label htmlFor="group_id">Group</Form.Label>
																				<div className="form-control-wrap">

																					<Select removeItemButton name="group_id[]" multiple defaultValue={data.group_id} >
																						<option value="">Select Group</option>
																						{
																							meta.groups.map((row, i) => {
																								return <option key={i} value={row.id}>{row.title}</option>
																							})
																						}
																					</Select>
																				</div>
																			</Form.Group> */}
																		<div className="Select-min-height">

																			<EditableField label="Group" value={data.group_id} name="group_id[]" type="select" placeholder="Group" required={true} onChange={handleChange} data={data} meta={meta} />
																		</div>
																	</Col>

																	<Col md="6">
																		<EditableField label="Client Type" value={data.type == 0 ? 'Individual' : 'Business'} name="type" type="select" placeholder="Client Type" required={true} onChange={handleChange} data={data} meta={{ 0: 'Individual', 1: 'Business' }} />
																	</Col>

																	<Col md="6">
																		<EditableField label="Record Type" value={data.record_type == 0 ? 'Lead' : 'Client'} name="record_type" type="select" placeholder="Record Type" required={true} onChange={handleChange} data={data} meta={{ 0: 'Lead', 1: 'Client' }} />
																	</Col>

																	<Col md="6">
																		<EditableField label="Email" value={data.email} name="email" type="text" placeholder="Email" required={true} onChange={handleChange} />
																	</Col>

																	{/* <Col md="12">
																		<EditableField label="Country Code" value={data.country_code} name="country_code" type="text" placeholder="Country Code" required={true} onChange={handleChange} data={data} meta={meta} />
																	</Col> */}

																	<Col md="6">
																		<EditableField label="Mobile" value={data.mobile} name="mobile" type="text" placeholder="Mobile" required={true} onChange={handleChange} />
																	</Col>

																	<Col md="6">
																		<EditableField label="Phone" value={data.phone} name="phone" type="text" placeholder="Phone" required={true} onChange={handleChange} />
																	</Col>


																	<Col md="6">
																		<EditableField label="City" value={data.city} name="city" type="text" placeholder="City" required={true} onChange={handleChange} />
																	</Col>

																	<Col md="6">
																		<EditableField label="State" value={data.state} name="state" type="text" placeholder="State" required={true} onChange={handleChange} />
																	</Col>

																	<Col md="6">
																		<EditableField label="Zip" value={data.zip} name="zip" type="text" placeholder="Zip" required={true} onChange={handleChange} />
																	</Col>

																	<Col md="6">
																		{/* <a target="_blank" href={`http://maps.google.com/maps?q=${data.address}`}> */}
																		<EditableField label="Address" value={data.address} name="address" type="text" placeholder="Address" required={true} onChange={handleChange} />
																		{/* </a> */}
																	</Col>

																	{/* <Col md="6">
																		<EditableField label="Company Name" value={data.company_name} name="company_name" type="text" placeholder="Company Name" required={true} onChange={handleChange} />
																	</Col> */}

																	{data.type == '0' && (
																		<Col md="6">
																			<EditableField label="Date of Birth" formateValue={data.date_of_birth_formated} value={data.date_of_birth} name="date_of_birth" type="date" placeholder="Last Name" required={true} onChange={handleChange} />
																		</Col>
																	)}

																	<Col md="6" className="CustomFields d-none">
																		<EditableField label="Custom Fields" value='custom_fields' name="custom_fields" type="fields" placeholder=" Code" required={true} onChange={handleChange} data={data} meta={meta} />
																	</Col>

																	<Col md="6">
																		<EditableField label="Record Owner" value={data.record_owner} name="coach_partner_id" type="select" placeholder="Record Owner" required={true} onChange={handleChange} data={data} meta={meta} />
																	</Col>


																</Row>
															</Form>
														)}
													</>
												)}
											</div>
										</Card.Body>
									</Card>
								</Tab.Pane>
								{data.parent_id == 0 && (
									<Tab.Pane eventKey="subContacts">
										<Card className="card-gutter-md border-0">
											<Card.Body className="p-2">
												<div className="bio-block document-generate">
													<Button className="mb-1 float-end" size="sm" variant="primary" action="add"
														onClick={handleShow}
													>
														<span>Add new </span>
													</Button>

													<table class="table table-striped  table-bordered">
														<thead>
															<tr>
																<td>Name</td>
																<td>Email</td>
																<td>Age</td>
																<td>Relationship</td>
															</tr>
														</thead>
														<tbody>
															{data.contacts && data.contacts.length > 0 ? (
																data.contacts.map((contact, i) => (
																	<tr key={i}>
																		<td style={{ lineHeight: '16px' }}>
																			{contact.name}
																			<br className="m-0 p-0" />
																			<span style={{ fontSize: 'smaller', marginTop: '-5px', color: 'gray' }}>{contact.title}</span>
																		</td>

																		<td>{contact.email}</td>
																		<td>{contact.age}</td>
																		<td>{contact.contact_relation}</td>
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan="5" className="text-center">No Contacts</td>
																</tr>
															)}

														</tbody>
													</table>

												</div>
											</Card.Body>
										</Card>
									</Tab.Pane>
								)}
								<Tab.Pane eventKey="jobDetails">
									<Card className="card-gutter-md border-0">
										<Card.Body className="p-2">

											<div className="bio-block">
												<SimpleBar className="nk-chat-panel">
													<div className="m-0">
														<Button className="mb-1 float-end" size="sm" variant="primary" action="add"
															onClick={() => { props.navigate(`/jobs/create?rel_id=${data.public_id}`) }}
														>
															<span>Add new </span>
														</Button>
														<Tab.Container id="details" onSelect={(selectedKey) => jobTabChange(selectedKey)} defaultActiveKey={jobActiveTab} activeKey={jobActiveTab}>
															<Nav variant="tabs" className="nav-tabs-s1 mb-3">
																<Nav.Item>
																	<Nav.Link eventKey="0">All</Nav.Link>
																</Nav.Item>
																{jobsCategory && jobsCategory.map((row, i) => {
																	return (
																		<Nav.Item key={i}>
																			<Nav.Link eventKey={row.id}>{row.title}</Nav.Link>
																		</Nav.Item>
																	);
																})}
															</Nav>
															<Tab.Content>
																<Tab.Pane eventKey="0">
																	{
																		!jobLoading && (
																			jobs.length > 0 && (
																				<table className="table table-striped  table-bordered">
																					<thead>
																						<tr>
																							<td>Job Details</td>
																							<td>Owner & Service</td>
																							<td>Amount & Milestone</td>
																							<td>Action</td>
																						</tr>
																					</thead>
																					<tbody>
																						{jobs.map((row, i) => (
																							<tr key={i}>
																								<td>
																									<button
																										onClick={() => { props.navigate(`/jobs/detail/${row.public_id}`) }}
																										className="p-0 btn badge text-bg-info-soft"
																										title={"view Job details"}
																									>
																										Job #{row.id}
																									</button>
																									<div>Due Date: {row.due_date_formated}</div>
																								</td>
																								<td>
																									<div>Owner: {row.record_owner}</div>
																									<div>Service: {row.service_title}</div>
																								</td>
																								<td>
																									<div>
																										<span className="badge text-bg-success-soft">Amount: {currencySign}{row.amount_formated}</span>
																									</div>
																									<div>Milestone: {row.pipeline?.status_title}</div>
																								</td>
																								<td className="text-center">
																									<button
																										onClick={() => removeJob(row.public_id, id)}
																										className="p-0 btn ms-1 text-danger"
																									>
																										<Icon name={'trash'} />
																									</button>
																								</td>
																							</tr>
																						))}
																					</tbody>
																				</table>
																			)
																		)
																	}

																</Tab.Pane>
																{
																	!jobLoading && (
																		jobsCategory && (
																			<div className="tabs">
																				{jobsCategory.map((category, i) => (
																					<Tab.Pane eventKey={category.id} key={i}>
																						{jobs.length > 0 && (
																							<table className="table table-striped  table-bordered">
																								<thead>
																									<tr>
																										<td>Job Details</td>
																										<td>Owner & Service</td>
																										<td>Amount & Milestone</td>
																										<td>Action</td>
																									</tr>
																								</thead>
																								<tbody>
																									{jobs.map((row, j) => (
																										<tr key={j}>
																											<td>
																												<button
																													onClick={() => { props.navigate(`/jobs/detail/${row.public_id}`) }}
																													className="p-0 btn badge text-bg-info-soft"
																													title={"view Job details"}
																												>
																													Job #{row.id}
																												</button>
																												<div>Due Date: {row.due_date_formated}</div>
																											</td>
																											<td>
																												<div>Owner: {row.record_owner}</div>
																												<div>Service: {row.service_title}</div>
																											</td>
																											<td>
																												<div>
																													<span className="badge text-bg-success-soft">Amount: {currencySign}{row.amount_formated}</span>
																												</div>
																												<div>Milestone: {row.pipeline?.status_title}</div>
																											</td>
																											<td className="text-center">
																												<button
																													onClick={() => removeJob(row.public_id, id)}
																													className="p-0 btn ms-1 text-danger"
																												>
																													<Icon name={'trash'} />
																												</button>
																											</td>
																										</tr>
																									))}
																								</tbody>
																							</table>
																						)}
																					</Tab.Pane>
																				))}
																			</div>
																		)
																	)
																}


															</Tab.Content>
														</Tab.Container>
													</div>
												</SimpleBar>
											</div>

										</Card.Body>
									</Card>
								</Tab.Pane>
								<Tab.Pane eventKey="documentMerging">
									<Card className="card-gutter-md border-0">
										<Card.Body className="p-2">
											<div className="bio-block document-generate">
												<h6>Choose Document</h6>
												{(meta.documents && data.id) && (
													<Form onSubmit={handleSubmit}>
														<Row className="g-0">
															<Col md="6">
																<Form.Group className="form-group">
																	<Form.Label htmlFor="document_id">Document</Form.Label>
																	<div className="form-control-wrap">
																		<Select onChange={(e) => setDocumentId(e)} removeItemButton name="document_id" defaultValue={documentId} >
																			<option value="">Select Document</option>
																			{
																				meta.documents.map((row, i) => {
																					return <option key={i} value={row.id}>{row.title}</option>
																				})
																			}
																		</Select>
																	</div>
																</Form.Group>
															</Col>

															<Col md={"6"}>
																<Form.Group className="ms-3 mt-1">
																	<ButtonGroup size="sm" aria-label="Basic example">
																		{/* <Button disabled={(documentId == '' || documentId == 0) ? true : false} variant="outline-primary" onClick={() => handleAction('doc')}>Save as Doc</Button> */}
																		<Button disabled={(documentId == '' || documentId == 0) ? true : false} variant="outline-primary" onClick={() => handleAction('pdf')}>Save</Button>
																		<Button disabled={(documentId == '' || documentId == 0) ? true : false} variant="outline-primary" onClick={() => handleAction('preview')}>Preview</Button>
																	</ButtonGroup>
																</Form.Group>
															</Col>
														</Row>
													</Form>
												)}
											</div>
										</Card.Body>
									</Card>
								</Tab.Pane>
								<Tab.Pane eventKey="portalFiles">
									<Card className="card-gutter-md border-0">
										<Card.Body className="p-2">
											<div className="bio-block document-generate">
												<div className="">
													{portalFiles && portalFiles.length > 0 ? (
														<table className="table table-striped  table-bordered">
															<thead>
																<tr>
																	<td>View Job</td>
																	<td>File Name</td>
																	<td>Added Date</td>
																	<td>Action</td>
																</tr>
															</thead>
															<tbody>
																{portalFiles.map((file, k) => (
																	<tr key={k}>
																		<td>
																			<button
																				onClick={() => { props.navigate(`/jobs/detail/${file.job_public_id}`) }}
																				className="p-0 btn badge text-bg-info-soft"
																				title={"view Job details"}
																			>
																				Job #{file.job_id}
																			</button>
																		</td>
																		<td>
																			<a href={file.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-primary">
																				{file.key}
																			</a>
																		</td>
																		<td>
																			<div className="file-details">
																				<small className="text-muted">Added at: {new Date(file.added_at).toLocaleString()}</small>
																			</div>
																		</td>
																		<td className="text-center">
																			<a href={file.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-primary  fs-5">
																				<Icon role="button" name={'download'} className="text-primary cursor-pointer" />
																			</a>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													) : (
														<div className="alert alert-warning">
															No files uploaded by this client yet.
														</div>
													)}
												</div>
											</div>

										</Card.Body>
									</Card>
								</Tab.Pane>

								<CreateContact
									show={show}
									handleClose={handleClose}
									handlePrimarySubmit={handlePrimarySubmit}
									contactRelationsTypes={meta?.contactRelationsTypes}
								/>
							</Tab.Content>
						</Tab.Container>
					</div>
				</Col >
				<Col lg="5">
					<div className='chatBodyClasses' id="chatBody">
						<SimpleBar className="nk-chat-panel">
							<div className="m-0">
								<Tab.Container id="details" onSelect={(selectedKey) => tabChange(selectedKey)} defaultActiveKey={activeTab} activeKey={activeTab}>
									<Nav variant="tabs" className="nav-tabs-s1 mb-3">
										<Nav.Item>
											<Nav.Link eventKey="all">All</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="note">Note</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="email">Email</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="sms">SMS</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="call">Call</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="invoice">Invoice</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="event">Event</Nav.Link>
										</Nav.Item>
										{/* <Nav.Item>
											<Nav.Link eventKey="job">Job</Nav.Link>
										</Nav.Item> */}
										<Nav.Item>
											<Nav.Link eventKey="attachment">Attachment</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="other">Activity</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="all">
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														if (row.type == 'appointment') {
															return <div className={"all-activity-area"} key={i}>
																<div className={"list-" + row.type}>
																	<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																		<div className="nk-schedule-item-inner list-invoice">
																			<div className="nk-schedule-content d-flex justify-content-between flex-column">

																				<div className="d-flex justify-content-between pt-1">
																					<div role="button" onClick={() => setModal({ modal: true, record: row.releation_data, modalArea: 'activity' })}>
																						<div className="d-flex">
																							<div class="nk-ibx-item-subject">{row.relational?.calendar?.title}</div>
																							<span className='badge m-auto ms-2' style={{ backgroundColor: row.releation_data?.appointment_bg_color, color: row.releation_data?.status_color }}>{row.releation_data?.appointment_status_title}</span>
																						</div>
																						{/* <button
																							onClick={() => setModal({ modal: true, record: row.releation_data, modalArea: 'activity' })}
																							className="p-0 btn"> */}

																						<div className="p nk-ibx-item-message">{row.releation_data?.froms} </div>
																						{/* </button> */}
																						<div className="text-start">

																							{
																								(row.releation_data?.snooze_start_date != '' && row.releation_data?.snooze_start_date != null) && (
																									<div className="text-start smaller">
																										{
																											row.releation_data?.snooze_start_date == row.releation_data?.snooze_end_date
																												?
																												'Snooze ' + row.releation_data?.snooze_start_date
																												:

																												'Snooze From ' + row.releation_data?.snooze_start_date + ' To ' + row.releation_data?.snooze_end_date

																										}
																									</div>
																								)
																							}
																						</div>
																					</div>
																					<div>
																						<ul class="nk-ibx-item-tags">
																							<li>
																								<span class="badge text-bg-success-soft">{row.releation_data?.dated}</span>
																							</li>
																						</ul>
																						<div className="d-flex justify-content-end">
																							<div className="btns ViewDeleteBtn">
																								<OverlayTrigger
																									rootClose
																									trigger="click"
																									placement="top"
																									overlay={
																										<Popover id={`popover-positioned-top-${row.id}`}>
																											<Popover.Header as="h3">Snooze</Popover.Header>
																											<Popover.Body>
																												<Form onSubmit={handleSubmitSnoozeAppointment}>
																													<div className='d-flex justify-content-between'>
																														<div className='sm w-auto'>
																															<DateRangePicker
																																autoHide={false}
																																title={"Select start and end date for snooze"}
																																format={'yyyy-mm-dd'}
																																startDate={row.releation_data?.snooze_start_date}
																																endDate={row.releation_data?.snooze_end_date}
																															/>
																														</div>

																														<div className='action-btns'>
																															<Button type='submit' className='ms-1 py-0 px-1' size='sm' variant='outline-light'>
																																<Icon name={'check'} />
																															</Button>
																															<Button className=' py-0 px-1 ms-1' size='sm' type='button' variant='outline-light' onClick={handleCrossClick}>
																																<Icon name={'cross'} />
																															</Button>
																														</div>
																													</div>
																												</Form>
																											</Popover.Body>
																										</Popover>
																									}
																									show={showPopover.show && showPopover.id == row.releation_data?.public_id}
																									onToggle={() => setShowPopoverLocal({ show: false, id: 0 })}
																								>
																									<Button size='sm' variant="secondary" className="p-0 px-1" onClick={() => setShowPopoverLocal({ show: true, id: row.releation_data?.public_id, area: 'activity' })}>
																										Snooze
																									</Button>
																								</OverlayTrigger >

																								{/* <button
																							onClick={() => setModal({ modal: true, record: row.releation_data, modalArea: 'activity' })}
																							className="p-0 btn">
																							<Icon name={'edit'} />
																						</button> */}

																								<button
																									onClick={() => deleteAppointment(row.releation_data?.public_id, 'all')}
																									className="p-0 btn ms-1">
																									<Icon name={'trash'} />
																								</button>

																							</div>
																						</div>

																						{/* <div className="p"><b className="text-capitalize"> To :</b> {row.releation_data?.tos} </div> */}


																					</div>
																				</div>
																			</div>
																		</div>
																	</Message>
																</div>
															</div>

														} else if (['sms', 'mms', 'voice', 'rvm', 'email'].includes(row.type)) {
															return <div key={i} className={"list-" + row.type}>
																<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																	<div className="nk-schedule-item-inner">
																		<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																			<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																				<div class="d-flex flex-column" role={(row.type == 'sms' || row.type == 'email') ? 'button' : 'none'} onClick={() =>
																					setQuickConnect({
																						modal: true,
																						id: row.releation_data?.id,
																						type: 'activity',
																						to: "",
																						coach_id: "",
																						campaign_id: 0
																					})
																				}>
																					<div class="nk-ibx-item-title justify-content-between">
																						<div class="nk-ibx-item-subject">{row.releation_data?.created_by_user}</div>
																						<ul class="nk-ibx-item-tags">
																							<li>
																								<span class="badge text-bg-secondary-soft">{row.dated}</span>
																							</li>
																						</ul>
																					</div>
																					<div class="nk-ibx-item-message"><div dangerouslySetInnerHTML={{ __html: row.releation_data?.body }} /> </div>
																					<div className="d-flex justify-content-between align-items-center ">
																						<div className="d-flex justify-content-between align-items-center">
																							<div className="p"><b className="text-capitalize"> From :</b> {row.releation_data?.froms} </div>
																							<div className="p ps-2"><b className="text-capitalize"> To :</b> {row.releation_data?.tos} </div>


																						</div>
																						<div className="button">
																							{/* <button onClick={() => setQuickConnect({
																								modal: true,
																								id: row.releation_data?.id,
																								type: 'activity',
																								to: "",
																								coach_id: "",
																								campaign_id: 0
																							})} className="btn-icon btn btn-zoom btn-md py-0"><Icon name={'eye'}></Icon></button> */}

																							{/* {
																								row.type == 'email' && (

																									<button onClick={() => toggleExpand(row.releation_data?.id + '_' + row.id)} className="btn btn-color-primary btn-icon btn-soft btn-sm p-0">
																										{expandedRows[row.releation_data?.id + '_' + row.id] ?
																											<Icon name="caret-down-fill"></Icon>
																											:
																											<Icon name="caret-right-fill"></Icon>
																										}
																									</button>
																								)
																							} */}


																						</div>

																					</div>
																					{/* {expandedRows[row.releation_data?.id + '_' + row.id] && (
																						<div className="nk-ibx-item-message">

																							<div className="p" dangerouslySetInnerHTML={{ __html: responseData[row.releation_data?.id + '_' + row.id]?.body }}>
																							</div>

																						</div>
																					)} */}
																				</div>
																			</div>
																		</div>
																	</div>
																</Message>
															</div>
														} else {
															return <div key={i} className={"list-" + row.type}>
																<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																	<div className="nk-schedule-item-inner">
																		<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																			<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																				<div class="d-flex flex-column">
																					<div class="nk-ibx-item-title justify-content-between">
																						<div class="nk-ibx-item-subject">{row.releation_data?.created_by_user}</div>
																						<ul class="nk-ibx-item-tags">
																							<li>
																								<span class="badge text-bg-secondary-soft">{row.dated}</span>
																							</li>
																						</ul>
																					</div>
																					<div class="nk-ibx-item-message"><b className="text-capitalize"> {row.type != 'other' ? row.type + ':' : ''} </b> {row.activity}.</div>

																				</div>
																			</div>
																		</div>
																	</div>
																</Message>
															</div>
														}
													})
												}
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="note">
											{!formloading && (
												<Form onSubmit={handleSubmitQuickAction}>
													<Form.Control defaultValue={'note'} type="hidden" name="action_type" />
													<Form.Group className="form-group p-3">
														<div className="form-control-wrap">
															<Form.Control as={'textarea'} name="activity" placeholder="Add Note" required />
														</div>
														<div className="form-control-wrap">
															<Button size='sm' className='m-1' type="submit">Save</Button>
														</div>
													</Form.Group>
												</Form>
											)}
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div key={i} className={"list-" + row.type}>
															<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																<div className="nk-schedule-item-inner">
																	<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																		<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																			<div class="d-flex flex-column">
																				<div class="nk-ibx-item-title justify-content-between">
																					<div class="nk-ibx-item-subject">{row.releation_data?.created_by_user}</div>
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-secondary-soft">{row.dated}</span>
																						</li>
																					</ul>
																				</div>
																				<div class="nk-ibx-item-message">{row.activity}</div>

																			</div>
																		</div>
																	</div>
																</div>
															</Message>
														</div>
													})
												}
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="email">
											<Row className="g-0">
												<Col md="12">
													<Button onClick={() => {
														setQuickConnect({
															modal: true,
															id: data.id,
															type: 'email',
															to: data.email,
															coach_id: data.coach_id,
															campaign_id: 0
														})
													}} size='sm' className="float-right pull-right m-2">
														Compose new Email
													</Button>
												</Col>
											</Row>
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div key={i} className={"list-" + row.type}>
															<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																<div className="nk-schedule-item-inner">
																	<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																		<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																			<div class="d-flex flex-column" role="button" onClick={() => setQuickConnect({
																				modal: true,
																				id: row.id,
																				type: 'activity',
																				to: "",
																				coach_id: "",
																				campaign_id: 0
																			})}>
																				<div class="nk-ibx-item-title justify-content-between">
																					<div class="nk-ibx-item-subject">{row.created_by_user}</div>
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-secondary-soft">{row.dated}</span>
																						</li>
																					</ul>
																				</div>
																				<div class="nk-ibx-item-message"><div dangerouslySetInnerHTML={{ __html: row.body }} /></div>
																				<div className="d-flex justify-content-between align-items-center ">
																					<div className="d-flex justify-content-between align-items-center">
																						<div className="p"><b className="text-capitalize"> From :</b> {row.froms} </div>
																						<div className="p ps-2"><b className="text-capitalize"> To :</b> {row.tos} </div>
																					</div>
																					<div className="button">
																						{/* <button onClick={() => setQuickConnect({
																						modal: true,
																						id: row.id,
																						type: 'activity',
																						to: "",
																						coach_id: "",
																						campaign_id: 0
																					})} className="btn-icon btn btn-zoom btn-md py-0"><Icon name={'eye'}></Icon></button> */}
																						{/* <button onClick={() => toggleExpand(row.id)} className="btn btn-color-primary btn-icon btn-soft btn-sm p-0">
																						{expandedRows[row.id] ?
																							<Icon name="caret-down-fill"></Icon>
																							:
																							<Icon name="caret-right-fill"></Icon>
																						}
																					</button> */}
																					</div>
																				</div>
																				{/* {expandedRows[row.id] && (
																				<div className="nk-ibx-item-message">

																					<div className="p" dangerouslySetInnerHTML={{ __html: responseData[row.id]?.body }}>
																					</div>

																				</div>
																			)} */}
																			</div>
																		</div>
																	</div>
																</div>
															</Message>
														</div>
													})
												}
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="sms">
											{meta?.groups && meta.groups.length > 0 && (
												<div className="px-3">
													<Form onSubmit={handleSubmitQuickAction}>
														<Form.Control defaultValue={'sms'} type="hidden" name="action_type" />
														{!formloading && (
															<Card className="card-gutter-md border-0">
																<Card.Body className="p-1">
																	<Row className="g-1">
																		<Col md="6">
																			<Select name="template_id" onChange={(e) => {
																				const desiredObject = meta?.smsTemplate.find(item => item.id == e[0]);
																				const body = desiredObject?.body || "";
																				setData((prevState) => ({
																					...prevState,
																					body: body,
																				}));
																			}}>
																				<option value="">Select Template</option>
																				{
																					meta?.smsTemplate && meta.smsTemplate.map((row, i) => {
																						return <option key={i} value={row.id}>{row.title}</option>
																					})
																				}
																			</Select>
																		</Col>
																		<Col md="6">
																			<Select name="froms">
																				<option value="">Select Phone</option>
																				{
																					meta?.phone_numbers && meta.phone_numbers.map((row, i) => {
																						return <option key={i} value={row.number}>{convertPhoneFormat(row.number)}</option>
																					})
																				}
																			</Select>
																		</Col>
																		<Col md="6">
																			<Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
																				<option value=''>Select Token</option>
																				<optgroup label="Default Fields">
																					{
																						meta?.tokens?.default_fields && meta.tokens.default_fields.map((row, i) => {
																							return <option key={i} value={`{${row.key}}`}>{row.label}</option>;
																						})
																					}
																				</optgroup>
																				<optgroup label="Custom Fields">
																					{
																						meta?.tokens?.custom_fields && meta.tokens.custom_fields.map((row, i) => {
																							return <option key={i} value={`{${row.key}}`}>{row.label}</option>;
																						})
																					}
																				</optgroup>
																			</Select>
																		</Col>
																		<Col md="6">
																			<Button className="w-100 btn-sm" type="button" onClick={() => insertValueAtCursor(` ${token}`)} variant="outline-secondary">
																				Insert Token
																			</Button>
																		</Col>
																		<Col md="12">
																			<Form.Group className="form-group mt-2">
																				<div className="form-control-wrap">
																					<Form.Control
																						ref={textareaRef}
																						value={data.body}
																						onChange={handleSMSChange}
																						as="textarea"
																						name="body"
																						placeholder="Enter body"
																						required />
																				</div>
																			</Form.Group>
																		</Col>
																		<Col md="12">
																			<Button size='sm' className='m-1' type="submit">Submit</Button>
																		</Col>
																	</Row>
																</Card.Body>
															</Card>
														)}
													</Form>
												</div>
											)}
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div key={i} className={"list-" + row.type}>
															<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																<div className="nk-schedule-item-inner">
																	<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																		<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																			<div class="d-flex flex-column" role="button" onClick={() => setQuickConnect({
																				modal: true,
																				id: row.id,
																				type: 'activity',
																				to: "",
																				coach_id: "",
																				campaign_id: 0
																			})}>
																				<div class="nk-ibx-item-title justify-content-between">
																					<div class="nk-ibx-item-subject">{row.created_by_user}</div>
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-secondary-soft">{row.dated}</span>
																						</li>
																					</ul>
																				</div>
																				<div class="nk-ibx-item-message"><div dangerouslySetInnerHTML={{ __html: row.body }} /></div>
																				<div className="d-flex justify-content-between align-items-center ">
																					<div className="d-flex justify-content-between align-items-center">
																						<div className="p"><b className="text-capitalize"> From :</b> {row.froms} </div>
																						<div className="p ps-2"><b className="text-capitalize"> To :</b> {row.tos} </div>
																					</div>
																					<div className="button">
																						{/* <button onClick={() => setQuickConnect({
																							modal: true,
																							id: row.id,
																							type: 'activity',
																							to: "",
																							coach_id: "",
																							campaign_id: 0
																						})} className="btn-icon btn btn-zoom btn-md py-0"><Icon name={'eye'}></Icon></button> */}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</Message>
														</div>
													})
												}
											</div>

										</Tab.Pane>
										<Tab.Pane eventKey="call">
											{
												true && (
													<>
														<Form onSubmit={handleSubmitQuickAction}>
															<Form.Control defaultValue={'call'} type="hidden" name="action_type" />
															<Form.Control defaultValue={actionType} type="hidden" name="call_type" />
															<Form.Group className="form-group p-3 pb-0">
																<div className="form-control-wrap">
																	<Form.Control as="textarea" name="activity" value={activityValue} placeholder="Call log..." onChange={(e) => setActivityValue(e.target.value)} />
																</div>
																<div className="form-control-wrap">
																	<Button size="sm" className="m-1 ms-0" type="submit" name="action_button" value="save" onClick={() => handleButtonClick('save')}>
																		Save
																	</Button>
																</div>
															</Form.Group>
															<div className="form-control-wrap text-start p-3 pt-0">
																{callTypes &&
																	Object.values(callTypes).map((row, i) => {
																		return (
																			<Button
																				key={i}
																				size="sm"
																				className="me-1 mb-1"
																				type="submit"
																				name="action_button"
																				value={row}
																				variant="light"
																				onClick={() => handleButtonClick(row)}
																			>
																				{row}
																			</Button>
																		);
																	})
																}
															</div>
														</Form>
													</>

												)
											}
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div className={"list-" + row.type} key={i}>
															<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																<div className="nk-schedule-item-inner">
																	<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																		<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																			<div class="d-flex flex-column">
																				<div class="nk-ibx-item-title justify-content-between">
																					<div class="nk-ibx-item-subject">{row.releation_data?.created_by_user}</div>
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-secondary-soft">{row.dated}</span>
																						</li>
																					</ul>
																				</div>
																				<div className="nk-ibx-item-message">
																					{row.call_type && row.call_type !== 'save' ? (
																						<>
																							<strong>{row.call_type}</strong> - {row.activity}
																						</>
																					) : (
																						row.activity
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</Message>
														</div>
													})
												}
											</div>
											{/* <div className="nk-message-wrap">
			{
				activities.length > 0 && activities.map((row, i) => {
					return <div className={"list-" + row.type}>
						<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
							<div className="nk-schedule-item-inner">
								<div className="nk-schedule-content">
									<span className="smaller">{row.dated}</span>
									<div className="p"><b className="text-capitalize"> From :</b> {row.from} </div>
									<div className="p"><b className="text-capitalize"> To :</b> {row.to} </div>
									<div className="button">
										<button onClick={() => setQuickConnect({
											modal: true,
											id: row.id,
											type: 'activity',
											to: "",
											coach_id: "",
											campaign_id: 0
										})} className="btn-icon btn btn-zoom btn-md"><Icon name={'eye'}></Icon></button>
									</div>
								</div>
							</div>
						</Message>
					</div>
				})
			}
		</div> */}
										</Tab.Pane>
										<Tab.Pane eventKey="invoice">
											<div className="invoice mx-1">
												<Invoice type={'leads'} masterType={'invoice'} releatedId={id} />
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="event">
											<div className='invoice mx-1'>
												<Button className="mb-2" size="sm" variant="primary" action="add" onClick={openAddModal}>
													<span>Add new</span>
												</Button>
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div className={"all-activity-area"} key={i}>
															<div className={"list-appointment"}>
																<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																	<div className="nk-schedule-item-inner list-invoice">
																		<div className="nk-schedule-content d-flex justify-content-between flex-column">
																			<div className="d-flex justify-content-between pt-1">
																				<div role="button" onClick={() => setModal({ modal: true, record: row })}>
																					<div className="d-flex gap gx-3">
																						<div class="nk-ibx-item-subject">{row.calendar?.title}</div>

																						<span className='badge m-auto ms-2' style={{ backgroundColor: row.appointment_bg_color, color: row?.appointment_status?.text_color }}>{row.appointment_status_title}</span>
																					</div>
																					<div className="p nk-ibx-item-message">
																						{row.title}
																					</div>
																					<div className="text-start">

																						{
																							(row.snooze_start_date != '' && row.snooze_start_date != null) && (
																								<div className="text-start smaller">
																									{
																										row.snooze_start_date == row.snooze_end_date
																											?
																											'Snooze ' + row.snooze_start_date
																											:

																											'Snooze From ' + row.snooze_start_date + ' To ' + row.snooze_end_date

																									}
																								</div>
																							)
																						}
																					</div>
																				</div>
																				<div className="">
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-success-soft">{row.appointment_date}</span>
																						</li>
																					</ul>
																					<div className="d-flex justify-content-end">
																						<div className="btns ViewDeleteBtn">

																							<OverlayTrigger
																								rootClose
																								trigger="click"
																								placement="top"
																								overlay={
																									<Popover id={`popover-positioned-top-${row.id}`}>
																										<Popover.Header as="h3">Snooze</Popover.Header>
																										<Popover.Body>
																											<Form onSubmit={handleSubmitSnoozeAppointment}>
																												<div className='d-flex justify-content-between'>
																													<div className='sm w-auto'>
																														<DateRangePicker
																															autoHide={false}
																															title={"Select start and end date for snooze"}
																															format={'yyyy-mm-dd'}
																															startDate={row.snooze_start_date}
																															endDate={row.snooze_end_date}
																														/>
																													</div>

																													<div className='action-btns'>
																														<Button type='submit' className='ms-1 py-0 px-1' size='sm' variant='outline-light'>
																															<Icon name={'check'} />
																														</Button>
																														<Button className=' py-0 px-1 ms-1' size='sm' type='button' variant='outline-light' onClick={handleCrossClick}>
																															<Icon name={'cross'} />
																														</Button>
																													</div>
																												</div>
																											</Form>
																										</Popover.Body>
																									</Popover>
																								}
																								show={showPopover.show && showPopover.id == row.public_id}
																								onToggle={() => setShowPopoverLocal({ show: false, id: 0 })}
																							>
																								<Button size='sm' variant="secondary" className="p-0 px-1" onClick={() => setShowPopoverLocal({ show: true, id: row.public_id, area: 'event' })}>
																									Snooze
																								</Button>
																							</OverlayTrigger >

																							{/* <button
																			onClick={() => setModal({ modal: true, record: row })}
																			className="p-0 btn">
																			<Icon name={'edit'} />
																		</button> */}
																							<button
																								onClick={() => deleteAppointment(row.public_id, 'event')}
																								className="p-0 btn ms-1">
																								<Icon name={'trash'} />
																							</button>
																						</div>

																					</div>


																					{/* <div className="title">Duration: {row.duration} minutes</div> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</Message>
															</div>
														</div>
													})
												}
											</div>
										</Tab.Pane>

										<Tab.Pane eventKey="attachment">
											<div className="attachment mx-1">
												<Attachment leadId={id} />
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey="other">
											<div className="">
												{
													activities.length > 0 && activities.map((row, i) => {
														return <div className={"list-" + row.type} key={i}>
															<Message className={row.direction == 'in' ? 'is-you' : 'is-me'}>
																<div className="nk-schedule-item-inner">
																	<div className="nk-schedule-content d-flex justify-content-between align-items-center p-0">
																		<div class="nk-ibx-item-elem nk-ibx-item-content py-0">
																			<div class="d-flex flex-column">
																				<div class="nk-ibx-item-title justify-content-between">
																					<div class="nk-ibx-item-subject">{row.releation_data?.created_by_user}</div>
																					<ul class="nk-ibx-item-tags">
																						<li>
																							<span class="badge text-bg-secondary-soft">{row.dated}</span>
																						</li>
																					</ul>
																				</div>
																				<div class="nk-ibx-item-message">{row.activity}</div>

																			</div>
																		</div>
																	</div>
																</div>
															</Message>
														</div>
													})
												}
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</div>
						</SimpleBar>
					</div>
				</Col>
			</div >
			{
				showMedia.show && (
					<MediaManager selectionType="single" data={showMedia} handleMediaManager={handleMediaManager} />
				)
			}

			{
				modal.modal && (
					<AppointmentsEdit data={modal.record} handleReset={handleReset} appointmentStatus={meta.appointmentStatus} />
				)
			}

			{
				quickConnect.modal && (
					<QuickConnect quickAction={quickAction} connect={quickConnect} />
				)
			}

			<Offcanvas className="w-25 h-100" placement="start" show={offcanvasStart} onHide={() => setOffcanvasStart(false)}>
				<Offcanvas.Header closeButton className="py-1 ps-1">
					<h5 className="offcanvas-title" id="offcanvasLabel">Milestone Status</h5>
				</Offcanvas.Header>
				<Offcanvas.Body className="p-1 m-0">
					<Milestone releatedId={id} callback={callbackMilestone} />
				</Offcanvas.Body>
			</Offcanvas>

			<Offcanvas className="w-75" placement="end" show={eventModal.opened} onHide={() => closeEventModal()}>
				<Offcanvas.Body className="p-0">

					<Row>
						<Col md={12}>
							<div className="CloseBtn" >
								<button className="btn p-0 text-dark" onClick={() => closeEventModal()}>
									<em class="icon ni ni-cross"></em>
								</button>
							</div>

							<iframe
								ref={iframeRef}
								title="embedded-link"
								width="100%"
								id="leadIframe"
								// height={"780px"}
								src={`${meta.default_calendar?.calendar_url}?type=dialer&lead_id=${data.id}&lead_name=${data.first_name + " " + data.last_name}&page_name=contact`}
								allowFullScreen
								onLoad={adjustIframeHeight}
							></iframe>
						</Col>
					</Row>
				</Offcanvas.Body>
			</Offcanvas>

			<Modal show={previewModal} onHide={closePreviewModal}>
				<Modal.Header closeButton>
					<Modal.Title className="event-title" as="h4">Portal Login Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Row className="g-3">
							<Col xs="12">
								<h6 className="overline-title">Connect Gmail Link</h6>
								<p className="small">
									<a href={HostUrl + '/portal/connect-gmail/' + data.portal_key} target="_blank" rel="noopener noreferrer">{HostUrl + '/portal/connect-gmail/' + data.portal_key}</a>
								</p>
							</Col>
							<hr></hr>
							<Col xs="12">
								<h6 className="overline-title">Portal Link</h6>
								<p className="small">
									<a href={HostUrl + '/portal/login'} target="_blank" rel="noopener noreferrer">{HostUrl + '/portal/login'}</a>
								</p>
							</Col>
							<Col xs="12">
								<h6 className="overline-title">Email</h6>
								<p className="small">
									{data.email}
								</p>
							</Col>
							<Col xs="12">
								<h6 className="overline-title">Password</h6>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<input type="text" className="form-control mew-password" defaultValue={password} onChange={(e) => setPassword(e.target.value)} style={{ marginRight: '10px' }} />
									<button className="btn btn-sm btn-primary" onClick={savePassword}>Save</button>
								</div>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{/* <Button variant="danger" onClick={openDeleteModal}>Delete</Button>
					<Button variant="primary" onClick={openEditModal}>Edit Event</Button> */}
				</Modal.Footer>
			</Modal>

		</Layout >
	)
}

export default LeadDetails;
