import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone, TimePicker } from '../../../../components';
import { sendRequest } from "../../../../utilities/api";
function CalendarEdit(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            fetchRecordById();
        };
        fetchData();
    }, []);


    const fetchRecordById = () => {
        sendRequest(`calendar/appointments/status/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('business_timing', JSON.stringify(data.business_timing))

        sendRequest(`calendar/appointments/status/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/calendar-manage/appointments/status/lists');
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });

    };



    return (
        <Layout title="Calendar" content="container">
            <div className="calendar-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Calendar</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/lists">Calendar</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/appointments/lists">Appointments</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/appointments/status/Lists">Status</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                        <div className={`bio-block`}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="title">Title</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={data.title} type="text" name="title" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="">Type</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select  defaultValue={data.type} name="type" className="form-controll">
                                                                    <option value="Open">Open</option>
                                                                    <option value="In-Progress">In-Progress</option>
                                                                    <option value="Closed">Closed</option>
                                                                    <option value="OverDue">OverDue</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                  
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="bg_color">Background Color</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control className="w-100"  defaultValue={data.bg_color}  type="color" name="bg_color" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="text_color">Text Color</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control className="w-100"  defaultValue={data.text_color} type="color" name="text_color" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                        
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="">Status</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select  defaultValue={data.status} name="status" className="form-controll">
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Update</Button>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default CalendarEdit;