import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone, TimePicker } from '../../../../components';
import { sendRequest } from "../../../../utilities/api";

function CalendarCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [], calendars: [] });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        business_timing: [
            {
                "index": 0,
                "day": "Sunday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 1,
                "day": "Monday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 2,
                "day": "Tuesday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 3,
                "day": "Wednesday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 4,
                "day": "Thursday",
                "availability": true,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 5,
                "day": "Friday",
                "availability": false,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            },
            {
                "index": 6,
                "day": "Saturday",
                "availability": false,
                "start_time": "9:00 AM",
                "end_time": "5:00 PM"
            }
        ]
    });
    useEffect(() => {
        setLoading(true);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('calendar/appointments/status/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/calendar-manage/appointments/status/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };



    return (
        <Layout title="Calendar" content="container-fluid">
            <div className="calendar-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Appointments Status</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/lists">Calendar</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/appointments/lists">Appointments</Link></li>
                                <li className="breadcrumb-item"><Link to="/calendar-manage/appointments/status/Lists">Status</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <div className={`bio-block`}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="title">Title</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control type="text" name="title" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                      
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="">Type</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="type" className="form-controll">
                                                                    <option value="Open">Open</option>
                                                                    <option value="In-Progress">In-Progress</option>
                                                                    <option value="Closed">Closed</option>
                                                                    <option value="OverDue">OverDue</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="bg_color">Background Color</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control className="w-100" type="color" name="bg_color" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="text_color">Text Color</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control className="w-100" type="color" name="text_color" defaultValue={'#ffffff'} required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="">Status</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Select name="status" className="form-controll">
                                                                    <option value="Active">Active</option>
                                                                    <option value="Inactive">Inactive</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Create</Button>

                                                    </Col>
                                                </Row>
                                            </div>

                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default CalendarCreate;