import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Offcanvas, Spinner, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from '../../utilities/api';
import { Icon, LinkListItem } from "../../components";
import DataTableComponent from '../../components/DataTable/DataTable';
import { convertPhoneFormat } from '../../utilities';
import { useAuth } from '../../AuthContext';

function ReportsContacts(props) {
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const { hasPermission } = useAuth();

	const [meta, setMeta] = useState({});
	const [loading, setLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState(false);
	const [userColumns] = useState([
		{
			name: "Appointment Date/Time",
			key: 'appointment_date',
			selector: (row) => row.appointment_date,
			cell: (row) => (
				<span>{row.appointment_date} </span>
			),
		},

		{
			name: <div className='w-100 text-center'>Id</div>,
			key: 'lead_details.id',
			selector: (row) => row.lead_details?.id,
			cell: (row) => (
				<span className=''>{row.lead_details?.id}</span>
			),
		},

		{
			name: "Appointment With",
			key: 'lead_details.first_name',
			selector: (row) => row.lead_details?.first_name,
			cell: (row) => (
				<span>{row.lead_details?.first_name}</span>
			),
		},

		{
			name: 'Groups',
			key: 'lead_details.groups',
			selector: (row) => row.lead_details?.groups,
			cell: (row) => (
				<span className=''>{row.lead_details?.groups}</span>
			),
		},

		{
			name: 'Tags',
			key: 'lead_details.tags',
			selector: (row) => row.lead_details?.tags,
			cell: (row) => (
				<span className=''>{row.lead_details?.tags}</span>
			),
		},


		{
			name: 'Email',
			key: 'lead_details.email',
			selector: (row) => row.lead_details?.email,
			cell: (row) => (
				<span className=''>{row.lead_details?.email}</span>
			),
		},

		{
			name: 'Phone',
			key: 'lead_details.phone',
			selector: (row) => row.lead_details?.phone,
			cell: (row) => (
				<span className=''>{row.lead_details?.phone}</span>
			),
		},


		{
			name: 'Mobile',
			key: 'lead_details.mobile',
			selector: (row) => row.lead_details?.mobile,
			cell: (row) => (
				<span className=''>{row.lead_details?.mobile}</span>
			),
		},


		{
			name: 'City',
			key: 'lead_details.city',
			selector: (row) => row.lead_details?.city,
			cell: (row) => (
				<span className=''>{row.lead_details?.city}</span>
			),
		},

		{
			name: 'State',
			key: 'lead_details.state',
			selector: (row) => row.lead_details?.state,
			cell: (row) => (
				<span className=''>{row.lead_details?.state}</span>
			),
		},

		{
			name: 'Zip',
			key: 'lead_details.zip',
			selector: (row) => row.lead_details?.zip,
			cell: (row) => (
				<span className=''>{row.lead_details?.zip}</span>
			),
		},

		{
			name: 'Address',
			key: 'lead_details.address',
			selector: (row) => row.lead_details?.address,
			cell: (row) => (
				<span className=''>{row.lead_details?.address}</span>
			),
		},

		{
			name: 'Website',
			key: 'lead_details.website',
			selector: (row) => row.lead_details?.website,
			cell: (row) => (
				<span className=''>{row.lead_details?.website}</span>
			),
		},

		{
			name: 'Company',
			key: 'lead_details.company',
			selector: (row) => row.lead_details?.company,
			cell: (row) => (
				<span className=''>{row.lead_details?.company}</span>
			),
		},

		{
			name: 'Date of Birth',
			key: 'lead_details.date_of_birth',
			selector: (row) => row.lead_details?.date_of_birth,
			cell: (row) => (
				<span className=''>{row.lead_details?.date_of_birth}</span>
			),
		},

		{
			name: "Created By",
			key: 'created_by_user',
			selector: (row) => row.created_by_user,
			cell: (row) => (
				<span>{row.created_by_user}</span>
			),
		},

		{
			name: "Calendar",
			key: 'calendar.title',
			selector: (row) => row.calendar?.title,
			cell: (row) => (
				<span>{row.calendar?.title}</span>
			),
		},

		{
			name: "Duration/minutes",
			key: 'duration',
			selector: (row) => row.duration,
			cell: (row) => (
				<span>{row.duration}</span>
			),
		},

		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'appointment_status_title',
			selector: (row) => row.appointment_status_title,
			cell: (row) => (
				<span className=''>{row.appointment_status_title}</span>
			),
		},


	]);

	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'listPage');
		formData.set('filter_type', 'appointments');

		await sendRequest('crm/leads/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setLoading(false);
			setCustomDateFilter(false);
			if (searchFilter) {
				setColumnVisibility({
					appointment_date: true,
					'lead_details.first_name': true,
					created_by_user: true,
					'calendar.title': true,
					duration: true,
					appointment_status_title: true,

				});

				setSearchFilter(false);
			}
			setTimeout(() => {
				setLoading(true);

			}, 100);
		}
	};


	// const userColumns = 

	const getInitialColumnVisibility = () => {
		const storedVisibility = localStorage.getItem('appointmentReportsColumnVisibility');
		if (storedVisibility) {
			let visible = JSON.parse(storedVisibility);
			visible.checkboxes = true;
			return visible;
		}
		// Default visibility state
		return {
			appointment_date: true,
			'lead_details.first_name': true,
			created_by_user: true,
			'calendar.title': true,
			duration: true,
			appointment_status_title: true,
		};
	};

	const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);
	useEffect(() => {
		localStorage.setItem('appointmentReportsColumnVisibility', JSON.stringify(columnVisibility));
	}, [columnVisibility]);

	const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);
	const handlePipelines = (data) => {
		if (data.selected_columns != '') {
			setColumnVisibility(data.selected_columns);
		}
	};

	return (
		<Layout title="Report Appointment List" content="container-fluid">
			<div className="Report Appointment">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Reports</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Appointment</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{
									hasPermission('viewLeadsFilter') && (
										<Link className={`btn btn-sm btn-soft btn-secondary`} to={'/crm-manage/leads/filters/lists/appointments'}>List & Filter</Link>

									)
								}

								{
									hasPermission('createLeadsFilter') && (
										<Link className={`btn btn-sm  ms-2 btn-primary`} to={'/crm-manage/leads/filters/create/appointments'}>
											<Icon name="plus"></Icon> <span>Add Filter</span>
										</Link>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card>
								<Card.Body>
									<Form ref={formRef} onSubmit={handleSubmit}>
										<Row>
											<Col>
												<Form.Select
													size="sm"
													name="search_filter_id"
													onChange={() => {
														setSearchFilter(true);
														//document.getElementById('submitButton').click()
													}}>
													<option value="">All Records</option>

													<optgroup label="Select Filter">

														{
															meta.search_filters && meta.search_filters.map((row, i) => {
																return <option key={i} value={row.id}>{row.name}</option>
															})

														}
													</optgroup >
												</Form.Select>
											</Col>
											<Col>
												<Form.Select
													size="sm"
													onChange={(e) =>
														e.target.value == "Custom Date"
															? setCustomDateFilter(true)
															: setCustomDateFilter(false)
													}
													name="date_range">
													<option value="">Filter by Date</option>
													<option value="Current Week">Current Week</option>
													<option value="Last Week">Last Week</option>
													<option value="Current Month">Current Month</option>
													<option value="Last Month">Last Month</option>
													<option value="Last 3 Months">Last 3 Months</option>
													<option value="Last 6 Months">Last 6 Months</option>
													<option value="Current Year">Current Year</option>
													<option value="Last Year">Last Year</option>
													<option value="Custom Date">Custom Date</option>
												</Form.Select>
											</Col>
											{
												customDateFilter && (
													<>
														<Col>
															<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>

														<Col>
															<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>
													</>
												)
											}

											<Col>
												<Button id="submitButton" size="sm" type="submit" className='mr-5'>Filter</Button>
												<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
											</Col>
										</Row>
									</Form>



									<DataTableComponent
										childRef={childRef}
										selectableRows={false}
										columns={visibleColumns}
										tableClassName="data-table-head-light table-responsive LeadListTable"
										slug={'calendar/appointments/lists'}
										actions={true}
										defaultRow={1000}
										sendDataToParent={handlePipelines}

									/>
								</Card.Body>
							</Card>
						)
					}
				</Block>
			</div>
		</Layout>
	)
}

export default ReportsContacts;