import React, { useEffect, useState } from "react"
import { Icon } from "../../components";
import { Button } from "react-bootstrap";
// import CampaignDialer from "./dialer.js";

const Incoming = ({ connection, state, displayMessage, setStatusOnReject, sliderOut }) => {
  const [showPage, setShowPage] = useState(false);
  const [muted, setMuted] = useState(false);
  const [hangupCall, setHangupCall] = useState(false);

  const acceptConnection = () => {
    connection.accept();
    setShowPage(true);

  };
  const rejectConnection = () => {
    connection.reject();
    setStatusOnReject();
  };

  const cancelConnection = () => {
    connection.disconnect();
    setHangupCall(true);
    setStatusOnReject();
  };
  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);

  };

  let params = Object.fromEntries(new URLSearchParams(connection.parameters.Params));
  params.From = connection.parameters.From;

  console.log(state)
  console.log(connection)

  return (
    <div className="incoming-twilio-call">
      <>
        {state != "ON_CALL" && state != "CANCEL" ? (
          <div className="d-flex">
            <h4 className="incomint-notify"> <i className="fas fa-phone"></i> Incoming call From  {connection.parameters.From}</h4>
            <div className="accept-rejact mb-5">

              <Button onClick={acceptConnection} size="sm" className="rounded-pill" variant="success">
                <Icon name={'call'}></Icon> 
              </Button>

              <Button onClick={rejectConnection} size="sm" className="rounded-pill" variant="danger">
                <Icon name={'call'}></Icon> 
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex mb-5">
            <h4 className="incomint-notify"> <Icon name={'call'}></Icon> {displayMessage}</h4>
            {
              (hangupCall || state == "CANCEL" )&& (
                <button onClick={() => { setStatusOnReject(); sliderOut(); }} className="btn btn-link">
                  <Icon name={'cross'} />
                </button>
              )
            }


            {
              !hangupCall && state != "CANCEL" && (
                <div className="accept-rejact mb-5">
                  {/* <button className="accept" onClick={() => acceptConnection()} color="green"><Icon name={'call'}></Icon></button> */}
                  <Button onClick={cancelConnection} size="sm" className="rounded-pill" variant="danger">
                    <Icon name={'call'}></Icon> 
                  </Button>

                  {
                    state != "CANCEL" && (
                      <>
                        {
                          muted && (
                            <Button onClick={handleMute} size="sm" className="rounded-pill unmute" variant="danger">
                              <Icon name={'mic-off'}></Icon>
                            </Button>
                          )
                        }

                        {
                          !muted && (
                            <Button onClick={handleMute} size="sm" className="rounded-pill mute" variant="success">
                              <Icon name={'mic'}></Icon>
                            </Button>

                          )
                        }
                      </>
                    )
                  }


                </div>
              )
            }
          </div>
        )}
      </>

      {/* {
        showPage && params.coach_id && state === "ON_CALL" && (
          <div className="incoming">
            <CampaignDialer dialerInfo={params} CallSid={connection.parameters.CallSid} />
          </div>
        )
      } */}


    </div>
  );
};

export default Incoming;
