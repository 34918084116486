import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, InputGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon, Select } from '../../components';
import { sendRequest } from "../../utilities/api";
import { useAuth } from "../../AuthContext";
import iconsData from "../../store/icons/IconData";

function PipelineView(props) {
    const { id } = useParams();
    const { hasPermission } = useAuth();
    const [modal, setModal] = useState(false);
    const [lists, setLists] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [name, setName] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();

        };
        fetchData();
    }, []);


    const fetchRecordById = async () => {
        await sendRequest(`pipelines/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setLists(response.data);
                setName(response.data?.record?.title);
                setLoading(true);
            }

        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const submitPipeline = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('master_id', id);

        let url = 'pipelines/create';
        if (data.public_id) {
            url = `pipelines/update/${data.public_id}`;
        }

        sendRequest(url, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    closeModal();
                    fetchRecordById();
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };

    const openModal = (row = {}) => {
        setData(row);
        setTimeout(() => {
            setModal(true);
        }, 200);
    }

    const closeModal = () => {
        setData({});
        setModal(false);
    }

    const addStatus = (listId) => {
        const formData = new FormData();
        formData.append('title', 'New Status');

        sendRequest(`pipelines/statuses/${listId}/create`, 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    setLists((prevLists) => {
                        const updatedLists = prevLists.lists.map((list) =>
                            list.public_id === listId
                                ? { ...list, pipeline_status: [...list.pipeline_status, response.data.list] }
                                : list
                        );
                        return { lists: updatedLists };
                    });
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });


    };

    const removeStatus = (listId, statusId) => {
        sendRequest(`pipelines/statuses/${listId}/${statusId}`, 'DELETE')
            .then(response => {
                if (response.status === true) {
                    setLists((prevLists) => {
                        const updatedLists = prevLists.lists.map((list) =>
                            list.public_id === listId
                                ? { ...list, pipeline_status: list.pipeline_status.filter((pipeline_status) => pipeline_status.public_id !== statusId) }
                                : list
                        );
                        return { lists: updatedLists };
                    });
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };

    const updateStatus = (listId, statusId, value) => {
        const formData = new FormData();
        formData.append('title', value);

        sendRequest(`pipelines/statuses/${listId}/update/${statusId}`, 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    setLists((prevLists) => {
                        const updatedLists = prevLists.lists.map((list) =>
                            list.public_id === listId
                                ? {
                                    ...list,
                                    pipeline_status: list.pipeline_status.map((pipeline_status) =>
                                        pipeline_status.public_id === statusId ? { ...pipeline_status, name: value } : pipeline_status
                                    )
                                }
                                : list
                        );
                        return { lists: updatedLists };
                    });
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };

    const handleChange = (e, pipeline_id) => {
        const { name, checked } = e.target;
        const submitData = new FormData();
        submitData.append('setting_key',name)
        submitData.append('setting_value',checked)
        sendRequest(`pipelines/update/${pipeline_id}/settings`, 'POST', submitData)
            .then(response => {
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }
    return (
        <Layout title="Pipeline Category " content="container-fluid">
            <div className="pipelines-view">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">{name} </Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/pipelines-manage/lists"> {name} </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">View</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                {
                                    hasPermission('createPipelines') && (
                                        <li>
                                            <button className="btn btn-sm btn-primary d-none" onClick={() => openModal()}>
                                                <Icon name="plus" />
                                                <span>Add Pipeline</span>
                                            </button>



                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <Row>
                                        {
                                            lists.lists.map((row, i) => {
                                                return (
                                                    <Col md="6" key={i} className="mb-3">
                                                        <div className="d-flex justify-content-between align-items-center mb-3 fw-bold">
                                                            <p className="mb-0" style={{ color: row.color_code }} >
                                                                <span className="me-1"> <Icon className={'fw-bold'} name={row.icon}></Icon></span>
                                                                {row.title}
                                                            </p>

                                                            <div>
                                                                {
                                                                    hasPermission('editPipelines') && (
                                                                        <button onClick={() => openModal(row)} className="btn btn-icon btn-soft p-0 d-none">
                                                                            <Icon name="edit"></Icon>
                                                                        </button>
                                                                    )
                                                                }

                                                                {
                                                                    (hasPermission('createPipelines') && row.mode != 'not-contact') && (
                                                                        <button onClick={() => addStatus(row.public_id)} className="btn  btn-icon btn-soft p-0">
                                                                            <Icon name="plus"></Icon>
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            row.pipeline_status.map((status, k) => {
                                                                return (
                                                                    <div className="pipeline-status">

                                                                        <InputGroup className="mb-3" key={status.id}>
                                                                            <Button style={{ backgroundColor: row.color_code, color: '#fff' }} variant="none">
                                                                                <Icon name={row.icon}></Icon>
                                                                            </Button>
                                                                            <Form.Control readOnly={row.mode === 'not-contact'} onChange={(e) => updateStatus(row.public_id, status.public_id, e.target.value)} defaultValue={status.title} />
                                                                            {
                                                                                (hasPermission('deletePipelines') && row.mode != 'not-contact') && (
                                                                                    <Button onClick={() => removeStatus(row.public_id, status.public_id)} variant="light">
                                                                                        <Icon name={'trash'} />
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                        </InputGroup>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            row.mode == 'not-contact' && (
                                                                <div className="status-not-contract">
                                                                    <div className="radio">
                                                                        <Form.Check name="sms" onChange={(e) => handleChange(e, row.public_id)} defaultChecked={row.settings?.sms === "true" ? true : false} inline label="SMS" type="checkbox" ></Form.Check>


                                                                        <Form.Check name="email" onChange={(e) => handleChange(e, row.public_id)} defaultChecked={row.settings?.email === "true" ? true : false} inline label="Email" type="checkbox" ></Form.Check>
                                                                    </div>
                                                                    <h6>Opt Out Keywords <br /> <small>(stop,stopall,stop all,spam,remove,unsub,unsubscribe,cancel,end,quit)</small></h6>
                                                                </div>
                                                            )
                                                        }
                                                    </Col>
                                                )
                                            })
                                        }

                                    </Row>

                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
            <Modal show={modal} onHide={() => closeModal()} size="lg">
                <Form onSubmit={submitPipeline}>
                    <Modal.Header closeButton>
                        <Modal.Title className="event-title" as="h4">{data.public_id ? 'Edit' : 'Add'} Pipeline</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Row className="g-gs">

                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="title">Title</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control onChange={(e) => setData({ ...data, title: e.target.value })} defaultValue={data.title} type="text" name="title" required />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="color_code">Color Code</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control onChange={(e) => setData({ ...data, color_code: e.target.value })} className="w-100" defaultValue={data.color_code} type="color" name="color_code" required />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="icon">Icon </Form.Label>
                                    <div className="form-control-wrap">
                                        <Select searchEnabled name="icon" allowHTML={true} defaultValue={data.icon} onChange={(e) => setData({ ...data, icon: e })}>
                                            {
                                                iconsData.map((row, i) => {
                                                    return <option key={i}> <Icon className={'p-1'} name={row} /> {row}</option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="throttle_duration">Status</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Select defaultValue={data.status} onChange={(e) => setData({ ...data, status: e.target.value })} name="status" className="form-controll">
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={() => closeModal()}>Close</Button>
                        <Button variant="primary" type="submit" >{data.public_id ? 'Update' : 'Create'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Layout>
    )
}

export default PipelineView;