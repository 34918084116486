import React, { useEffect, useState, useRef } from 'react'
// import { useEffect, useState, useRef } from 'react';

import Nav from 'react-bootstrap/nav'
import { Tooltip, OverlayTrigger, Offcanvas, Image, Popover } from 'react-bootstrap';
import Incall from '../../../pages/Incall'
import { Icon, Media, MediaGroup, MediaText, DateRangePicker } from "../../../components";
import { Modal, Button, Form } from 'react-bootstrap';
import { sendRequest } from '../../../utilities/api';
import { Link } from 'react-router-dom';
import AppointmentsEdit from '../../../pages/calendar-manage/appointments/Edit';
import { useAuth } from "../../../AuthContext";

function Footer(props) {
    const { callInit } = useAuth();

    const [show, setShow] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [appointmentStatus, setAppointmentsStatuses] = useState([]);
    const [remindersOn, setRemindersOn] = useState(false);
    const [modal, setModal] = useState({ modal: false, record: {} });
    const [dueTodayModal, setDueTodayModal] = useState(false);
    const [dueTodayList, setDueTodayList] = useState([]);
    const [showPopover, setShowPopoverLocal] = useState({ show: false, id: 0, area: 'activity' });

    const handleClose = () => setDueTodayModal(false);
    const handleShow = () => setDueTodayModal(true);

    const handleFooterItemClick = () => {
        fetchAppointmentsData('due-today');

        handleShow();
    }


    const handleToggle = () => {
        const tokenString = localStorage.getItem('authToken');
        let token = JSON.parse(tokenString);
        let user = token.user;

        setRemindersOn(user.events_activity_status)

        fetchAppointmentsData();

        setShow(!show);
    }

    const handleReminderToggle = () => {
        sendRequest('calendar/appointments/events/toggle-switch', 'POST')

        setTimeout(() => {
            setRemindersOn(prevState => !prevState);

            const tokenString = localStorage.getItem('authToken');
            let token = JSON.parse(tokenString);

            if (token && token.user) {
                token.user.events_activity_status = !remindersOn; 

                localStorage.setItem('authToken', JSON.stringify(token));
            } else {
                console.error('User or token not found');
            }

        }, 300);
    }

    const fetchAppointmentsData = async (type = 'upcoming') => {
        const response = await sendRequest('calendar/appointments/upcoming/events?type=' + type, 'POST');
        if (response) {
            if (type == 'due-today') {
                setDueTodayList(response.data.appointmentsData);
                setAppointmentsStatuses(response.data.appointmentStatus);
            }
            else {
                setAppointments(response.data.appointmentsData);
                setAppointmentsStatuses(response.data.appointmentStatus);
            }
        }
    }

    const DateFormatter = ({ start, end, isToday = false, onlyDateStart = false }) => {
        const formatDate = (dateStr) => {
            const date = new Date(dateStr);
            const options = { month: 'short', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };

        const formatTime = (dateStr) => {
            const date = new Date(dateStr);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutes} ${ampm}`;
        };

        const monthDay = formatDate(start);
        const startTime = formatTime(start);
        const endTime = formatTime(end);

        return (
            <div>
                {onlyDateStart ? (
                    <span >{startTime}</span>
                ) : (
                    <>
                        <Icon className="text-primary" name="calendar" />
                        <span style={{ fontSize: '14px', marginLeft: '5px' }}>
                            {isToday ? `${startTime} - ${endTime}` : `${monthDay} - ${startTime} - ${endTime}`}
                        </span>
                    </>
                )}

            </div>
        );
    };

    const closeAppointment = async (id = null, action = '', type = '') => {
        const formData = new FormData();

        formData.append('action', action);

        await sendRequest(`calendar/appointments/events/${id}/close`, 'POST', formData).then(response => {
            if (type == 'due-today') {
                fetchAppointmentsData('due-today')
            }
            else {
                fetchAppointmentsData();
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleReset = () => {
        setModal({ modal: false, record: {} })
        fetchAppointmentsData();
    };

    const renderTooltip = (props) => (
        <Tooltip id="tooltip-top" {...props}>
            Past/Due Now <br /> Activities
        </Tooltip>
    );

    const handleSubmitSnoozeAppointment = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`calendar/appointments/snooze/${showPopover.id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchAppointmentsData('due-today');

                    setShowPopoverLocal({ show: false, id: 0, area: 'activity' })
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleCrossClick = () => {
        setShowPopoverLocal({ show: false, id: 0, area: 'activity' });
    };

    const setUserReminder = async (isChecked, id) => {
        const formData = new FormData();

        formData.append('isChecked', isChecked ? 'Yes' : 'No');

        await sendRequest(`calendar/appointments/events/${id}/toggle-activity`, 'POST', formData).then(response => {
            if (response.status === true) {
                fetchAppointmentsData('due-today');
            }
        })
    }

    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    {/* <div className="nk-footer-copyright">
                        &copy; {new Date().getUTCFullYear()}  <a href="https://newapp.10xcrm.com/" target="_blank" rel="noreferrer" className="text-reset">10xCRM</a>
                    </div> */}
                    <div className="nk-footer-copyright footer-items" onClick={handleFooterItemClick} >
                        Due Today
                    </div>
                    <div className="nk-footer-links">
                        <Nav as="ul" className="nav-sm">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <Nav.Item as="li" style={{ cursor: "pointer", position: "relative" }} onClick={handleToggle}>
                                    <Icon name="clock" className="text-danger fs-2" />
                                    {remindersOn && appointments.length > 0 && (
                                        <span className="notification-badge">{appointments.length}</span>
                                    )}
                                </Nav.Item>
                            </OverlayTrigger>
                        </Nav>

                        <div className={`chat-popup ${show ? 'show' : ''}`}>
                            <div className="chat-header">
                                <h5 className='text-white ms-1'>Reminders</h5>
                                <div>
                                    <button onClick={handleReminderToggle} className="btn btn-sm btn-primary me-2">
                                        {remindersOn ? 'OFF' : 'ON'}
                                    </button>
                                    <button onClick={handleToggle} className="close-btn">&times;</button>
                                </div>
                            </div>
                            <div className="chat-body">
                                {remindersOn && (
                                    <>
                                        {appointments.length > 0 ? (
                                            <div className="button-container">
                                                <button
                                                    className="btn btn-sm btn-secondary clear-button"
                                                    type="button"
                                                    onClick={() => closeAppointment(null, 'clear_all')}
                                                >
                                                    Clear All
                                                </button>
                                            </div>
                                        ) : (
                                            <p className="text-center">No data found.</p>
                                        )}
                                        <hr />
                                        {appointments.length > 0 && appointments.map((item, index) => (
                                            <>
                                                <div key={index} className="schedule-item">
                                                    <a href='javascript:void(0);' className="title-link" onClick={() => setModal({ modal: true, record: item })}>{item.title}</a>
                                                    {/* <Link className="title-link" to={`/crm-manage/leads/dialer/${item.related_to_public_id}?type=event`}>
                                                        {item.title}
                                                    </Link> */}
                                                    <button className="btn btn-sm btn-danger float-end dismiss-button" type="button" onClick={() => closeAppointment(item.public_id)}>Dismiss</button>
                                                    <div className="small-text">
                                                        <DateFormatter start={item.date_start} end={item.date_end} />
                                                    </div>
                                                    <p className="small-text">
                                                        <strong>Status:</strong> {item.appointment_status_title}
                                                        <input className='m-1' checked={!item.is_open} type="checkbox" onChange={() => closeAppointment(item.public_id)}></input>
                                                    </p>
                                                    <p className="small-text">
                                                        <strong className='display-inline me-1'>Related To:</strong>
                                                        <Link to={`/crm-manage/leads/dialer/${item.lead_details.public_id}`}>
                                                            {item.lead_details.first_name + ' ' + item.lead_details.last_name}
                                                        </Link>
                                                    </p>
                                                </div>
                                                <hr />
                                            </>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>

                        <Offcanvas show={dueTodayModal} onHide={handleClose} >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Today's Activities</Offcanvas.Title>
                                <div className="d-flex align-items-center ms-auto">
                                    <Button className="btn btn-sm btn-primary me-1" onClick={() => fetchAppointmentsData('due-today')}>
                                        Refresh
                                    </Button>

                                </div>
                            </Offcanvas.Header>

                            <hr className="m-0" />

                            <Offcanvas.Body>
                                {dueTodayList && dueTodayList.length ? (dueTodayList.map((item, index) => (
                                    <>
                                        <div key={index} className="schedule-item bg-light p-2 mb-2 ">
                                            <div className="d-none d-sm-flex">
                                                <MediaGroup>
                                                    <Media size="sm" shape="circle" middle className="lead-image " >
                                                        {item.lead_details.image ? (
                                                            <Image src={item.lead_details.image} alt={item.lead_details.initials} style={{ width: '35px', height: '30px' }} />
                                                        ) : (
                                                            <b style={{ border: '2px solid #000', borderRadius: '50%', width: '40px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '40px' }}>
                                                                {item.lead_details.initials ?? 'U'}
                                                            </b>
                                                        )}
                                                    </Media>
                                                    <MediaText>
                                                        {/* <div className="lead-text">{item.lead_details?.first_name + ' ' + item.lead_details?.last_name}</div> */}
                                                        <Link className="lead-text" to={`/crm-manage/leads/dialer/${item.lead_details.public_id}`}>
                                                            {item.lead_details.first_name + ' ' + item.lead_details.last_name}
                                                        </Link>
                                                        <span className="sub-text">{item.lead_details.company_name ? 'Manager at ' + item.lead_details.company_name : ''}</span>
                                                    </MediaText>
                                                </MediaGroup>
                                                <div className="form-control-wrap">
                                                    <div className="custom-control custom-switch me-1" title="User Reminder">
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-checkbox"
                                                            className="custom-checkbox-radio"
                                                            name="user_reminder"
                                                            role='button'
                                                            defaultChecked={item.user_reminder === 'Yes'}
                                                            // checked={reminder}
                                                            onChange={(e) => setUserReminder(e.target.checked, item.public_id)}
                                                        />
                                                    </div>
                                                </div>
                                                <span className='me-1' role='button' onClick={() => setModal({ modal: true, record: item })}>
                                                    <Icon name="calendar"></Icon>
                                                </span>
                                                <span role='button' onClick={() => {
                                                    callInit({
                                                        modal: false,
                                                        id: item.lead_details.id,
                                                        type: 'call',
                                                        to: item.lead_details.mobile,
                                                        campaign_id: 0,
                                                        coach_id: item.calendar?.coach_id,
                                                        first_name: item.lead_details.first_name,
                                                        last_name: item.lead_details.last_name
                                                    });
                                                    handleClose();
                                                }}
                                                    title="Initiate outbound call" >
                                                    <Icon name="call"></Icon>
                                                </span>
                                            </div>
                                            <div className="small-text">
                                                <DateFormatter start={item.date_start} end={item.date_end} isToday={true} />
                                            </div>
                                            <p className="small-text link-primary" role='button' onClick={() => setModal({ modal: true, record: item })}>
                                                {item.title}
                                            </p>
                                            {item.snooze_start_date && (
                                                <p className="small-text">
                                                    Snooze: {item.snooze_start_date}
                                                </p>
                                            )}
                                            <p className="small-text">
                                                <OverlayTrigger
                                                    rootClose
                                                    trigger="click"
                                                    placement="top"
                                                    overlay={
                                                        <Popover id={`popover-positioned-top-${item.id}`}>
                                                            <Popover.Header as="h3">Snooze</Popover.Header>
                                                            <Popover.Body>
                                                                <Form onSubmit={handleSubmitSnoozeAppointment}>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className='sm w-auto'>
                                                                            <DateRangePicker
                                                                                autoHide={false}
                                                                                title={"Select start and end date for snooze"}
                                                                                format={'yyyy-mm-dd'}
                                                                                startDate={item.snooze_start_date}
                                                                                endDate={item.snooze_end_date}
                                                                            />
                                                                        </div>

                                                                        <div className='action-btns'>
                                                                            <Button type='submit' className='ms-1 py-0 px-1' size='sm' variant='outline-light'>
                                                                                <Icon name={'check'} />
                                                                            </Button>
                                                                            <Button className=' py-0 px-1 ms-1' size='sm' type='button' variant='outline-light' onClick={handleCrossClick}>
                                                                                <Icon name={'cross'} />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                    show={showPopover.show && showPopover.id == item.public_id}
                                                    onToggle={() => setShowPopoverLocal({ show: false, id: 0 })}
                                                >
                                                    <Button size='sm' variant="secondary" className="me-1 btn btn-sm border" onClick={() => setShowPopoverLocal({ show: true, id: item.public_id, area: 'event' })}>
                                                        Snooze
                                                    </Button>
                                                </OverlayTrigger >

                                                <Button size='sm' variant="secondary" className="me-1 btn btn-sm border" >
                                                    <DateFormatter start={item.date_start} end={item.date_end} onlyDateStart={true} />
                                                </Button>
                                                <Button size='sm' variant="secondary" className="me-1 btn btn-sm border" onClick={() => closeAppointment(item.public_id, '', 'due-today')}>
                                                    Done
                                                </Button>
                                            </p>
                                        </div>
                                        <hr />
                                    </>
                                ))) :
                                    <div className="text-center">No today activities.</div>
                                }
                            </Offcanvas.Body>
                        </Offcanvas>

                    </div>
                </div>
            </div>

            {
                modal.modal && (
                    <AppointmentsEdit data={modal.record} handleReset={handleReset} appointmentStatus={appointmentStatus.length > 0 ? appointmentStatus : []} />
                )
            }
        </div>
    )
}

export default Footer

// can we change it to something else other than a modal, like must appear from right bottom with little animation like most popup appears in support chats