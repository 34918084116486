var GOOGLE_MAP_API_KEY = 'AIzaSyCZW5TIqnX6W9SJ7Bh1L0CQzWkfn0W73ao';

// var GOOGLE_AUTH_CLIENT_ID = '705520943486-bgnmd05mbod44u56k9qf97c6b0474v0s.apps.googleusercontent.com';
// var GOOGLE_AUTH_CLIENT_ID = '698598392371-8727rhm92snkfc17sd3gmatsjkesa6t1.apps.googleusercontent.com';
var GOOGLE_AUTH_CLIENT_ID = '719775216095-c9km8652eevibr04qjul25p8k0lvhca4.apps.googleusercontent.com';
var OUTLOOK_AUTH_TENANT_ID = '4f39acaf-14f4-4295-a887-83e5a10ed866';
var OUTLOOK_AUTH_CLIENT_ID = '4bbb4792-710e-427c-9d4e-9c2b4b81d03a';

export {
    GOOGLE_MAP_API_KEY, 
    GOOGLE_AUTH_CLIENT_ID,
    OUTLOOK_AUTH_TENANT_ID,
    OUTLOOK_AUTH_CLIENT_ID
};