import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from '../../../../utilities/api';
import { Icon, LinkList, LinkListItem } from "../../../../components";
import { useAuth } from '../../../../AuthContext';
import DataTableComponent from '../../../../components/DataTable/DataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function LeadFormView(props) {
    const { id, leadId } = useParams();
    const [modal, setModal] = useState(false);
    const [clientForm, setClientForm] = useState(false);

    const formRef = useRef(null);
    const childRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`crm/leads/forms/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };

    const userColumns = [
        {
            name: "Contact Name",
            key: 'full_name',
            selector: (row) => row.full_name,
            cell: (row) => (
                <span>
                    <button className="btn p-0 btn-link" onClick={() => { props.navigate(`/crm-manage/leads/dialer/${row.lead_public_id}`) }}>
                        {row.full_name}
                    </button>
                </span>
            ),
        },
        {
            name: "Contact Email",
            key: 'email',
            selector: (row) => row.email,
            cell: (row) => (
                <span>
                    <button className="btn p-0 btn-link" onClick={() => { props.navigate(`/crm-manage/leads/dialer/${row.lead_public_id}`) }}>
                        {row.email}
                    </button>
                </span>
            ),
        },

        {
            name: "IP Address",
            key: 'ip_address',
            selector: (row) => row.ip_address,
            cell: (row) => (
                <span>{row.ip_address}</span>
            ),
        },
        {
            name: "Created Date",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },
        {
            name: "Disclaimer/Signature",
            key: 'row.data.disclaimer',
            selector: (row) => row.data?.disclaimer,
            cell: (row) => (
                <button className="btn p-0 btn-link" onClick={() => { setModal(true); setClientForm(row) }}>
                    <span>{row.data?.signature_type == 'draw_it' ? 'Signature' : row.data?.signature_type == 'type_it' ? 'Text' : ''}</span>
                </button>
            ),
        },
    ];

    return (
        <Layout title="Contact Form Details" content="container">
            <div className="Form Details">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Contact Form Details</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/crm-manage/leads/forms/lists">Contacts</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Forms</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>

                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive LeadListTable table-striped"
                                slug={`crm/leads/forms/details/${id}/${leadId}`}

                            />
                        </Card.Body>
                    </Card>
                </Block>
                <Modal show={modal} onHide={() => setModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className="event-title" as="h4">Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {
                            clientForm.data?.signature_type == 'draw_it' ?
                                (
                                    <div className='sig'>
                                        <table>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Card>
                                                        <Card.Img variant="top" src={clientForm.data?.digital_signature_image_url} />
                                                    </Card>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>IP ADDRESS:</b> {clientForm.ip_address}</td>
                                                <td><b>DATETIME:</b> {clientForm.dated}</td>
                                            </tr>
                                        </table>


                                    </div>
                                )
                                :
                                (
                                    <div className='type'>
                                        <table className='table'>
                                            <tr>
                                                <td><b>INITIAL:</b> {clientForm.data?.[`text-sig`]}</td>
                                                <td><b>IP ADDRESS:</b> {clientForm.ip_address}</td>
                                                <td><b>DATETIME:</b> {clientForm.dated}</td>

                                            </tr>
                                        </table>

                                    </div>
                                )
                        }

                        {
                            clientForm.disclaimer_body != '' && (
                                <div className='disclaimer'>
                                    <label className={`bold h6`}>Disclaimer:</label>
                                    <div className="fb-text  form-group "><div dangerouslySetInnerHTML={{ __html: clientForm.disclaimer_body }}></div></div>
                                </div>
                            )
                        }



                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' variant="danger" onClick={() => { setClientForm({}); setModal(false); }}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Layout>
    )
}

export default LeadFormView;
