import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, InputGroup, ListGroup } from 'react-bootstrap';

import Layout from '../../layout/fullpage';
import Block from '../../components/Block/Block';
import { Icon, Select } from '../../components';
import { sendRequest } from "../../utilities/api";
import { useAuth } from "../../AuthContext";
import iconsData from "../../store/icons/IconData";

function Milestone(props) {
    const { id } = useParams();
    const [master, setMaster] = useState([]);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeMasterId, setActiveMasterId] = useState('MA==');
    const [activeStatusId, setActiveStatusId] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();

        };
        fetchData();
    }, []);


    const fetchRecordById = async () => {
        const formData = new FormData();
        formData.append('lead_id', id);
        await sendRequest(`pipelines/lists`, 'POST', formData).then(response => {
            if (response.status === true) {
                setMaster(response.data.records);
                setActiveMasterId(response.data.active_master_id);
                getSubPipeline(response.data.active_master_id);
                setLoading(true);
            }

        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const getSubPipeline = async (pipeline_id) => {
        setLoading(false);
        const formData = new FormData();
        formData.append('lead_id', id);
        await sendRequest(`pipelines/${pipeline_id}`, 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.lists);
                setActiveMasterId(pipeline_id);
                setActiveStatusId(response.data.active_status_id);
            }
            setLoading(true);

        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }
    const updateStatus = (master_id, sub_id, status_id) => {
        const formData = new FormData();
        formData.append('master_id', master_id);
        formData.append('sub_id', sub_id);
        formData.append('status_id', status_id);
        formData.append('lead_id', id);
        sendRequest(`pipelines/statuses/${sub_id}/assign/${status_id}`, 'POST', formData)
            .then(response => {
                getSubPipeline(master_id);
                setLoading(true);
                if (props.callback) {
                    props.callback(true);
                }
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Pipeline Category " content="container-fluid">
            {loading && (
                <div className="mb-1">
                    <select defaultValue={activeMasterId} onChange={(e) => { getSubPipeline(e.target.value); }}
                        className="form-select form-select-sm w-100">
                        {master.length > 0 && master.map((row, i) => {
                            return (
                                <option key={i} value={row.public_id}>{row.title}</option>
                            )
                        }
                        )}
                    </select>
                </div>
            )}
            <ListGroup as="ul" className="PipeLineStatus">
                {
                    lists.length > 0 && lists.map((row, i) => {
                        return (
                            <>
                                <ListGroup.Item className="px-1 border-0" as="li" key={i}>
                                    <h6 style={{ color: '#000' }}> {row.title}</h6>
                                </ListGroup.Item>
                                <span className="border-bottom">
                                    {
                                        row.pipeline_status.length > 0 && row.pipeline_status.map((status, k) => {
                                            return (
                                                <ListGroup.Item className={`"border-0 border-0 ps-3 ${activeStatusId == status.id ? 'activePipeleine' : ''}`} action onClick={(e) => updateStatus(row.public_master_id, row.public_id, status.public_id)} key={status.id}
                                                    style={{backgroundColor: activeStatusId == status.id ? row.color_code : '' }}
                                                >
                                                      {status.title}
                                                </ListGroup.Item>

                                            )
                                        })
                                    }
                                </span>
                            </>
                        )
                    })
                }
            </ListGroup>
        </Layout>
    )
}

export default Milestone;