import { useEffect, useState, useRef } from "react";
import { Card, Button, Form, Row, Col, Dropdown, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { sendRequest } from "../../utilities/api";
import {
	Icon,
	LinkList,
	Select,
} from "../../components";
import { useAuth } from "../../AuthContext";
import DataTableComponent from "../../components/DataTable/DataTable";
import Swal from 'sweetalert2';
import ContactNav from "../nav/ContactsNav";
import JobsNav from "../../pages/nav/JobsNav";

function Export() {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);


	const formRef = useRef(null);
	const childRef = useRef(null);

	const location = useLocation();
	const showingJobs = location.pathname.includes('jobs');

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};

	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to perform this action?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`crm/leads/export/${id}`, "DELETE", { id: id })
					.then((response) => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch((error) => {
						console.error("Delete request error:", error);
					});
			}
		});
	};

	const openModal = (row = {}) => {
		setTimeout(() => {
			setModal(true);
		}, 200);
	};

	const userColumns = [
		{
			name: "Exported By",
			key: "export_by",
			selector: (row) => row.export_by,
			cell: (row) =>
				<span>{row.export_by}</span>
		},

		{
			name: <div className='w-100 text-center'>Status</div>,
			key: "status",
			selector: (row) => row.status,
			cell: (row) => (
				<span
					className={`badge m-auto text-bg-${row.status === "In-Progress"
						? "warning-soft"
						: row.status === "Completed"
							? "success-soft"
							: row.status === "Failed"
								? "danger-soft"
								: "primary-soft"
						}`}
				>
					{row.status ? row.status : "In-Progress"}
				</span>
			),
		},
		{
			name: "Type",
			key: "type",
			selector: (row) => row.type,
			cell: (row) => <span>{row.type}</span>,
		},
		{
			name: "Created Date",
			key: "dated",
			selector: (row) => row.dated,
			cell: (row) => <span>{row.dated}</span>,
		},

		{
			name: "Action",
			key: "action",
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
							{row.status === 'Completed' && (
								<a
									className="btn p-0"
									target="_blank"
									href={row.file_path}
								>
									<Icon name="download"></Icon>
								</a>
							)}
							<button
								className="btn p-0"
								onClick={() => deleteRecord(row.public_id)}
							>
								<Icon name="trash" className="text-danger"></Icon>
							</button>
						</LinkList>
					</div>
				</div>
			),
		},
	];

	return (
		<Layout title="Exported Items" content="container-fluid">
			<div className="ExportDataList">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Exported Items</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item">
										{showingJobs ? (
											<Link to="/jobs/lists">
												Jobs List
											</Link>
										) : (
											<Link to="/crm-manage/leads/lists">Contact List</Link>
										)}
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Export List
									</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex ">
								{
									showingJobs ? (
										<JobsNav from={4} />
									) : (
										<ContactNav from={4} />
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range"
										>
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>

									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control
														size="sm"
														type="date"
														name="start_date"
														placeholder="Start date"
														disabled={!customDateFilter}
														required={customDateFilter}
													/>
												</Col>

												<Col>
													<Form.Control
														size="sm"
														type="date"
														name="end_date"
														placeholder="End date"
														disabled={!customDateFilter}
														required={customDateFilter}
													/>
												</Col>
											</>
										)
									}

									<Col>
										<Form.Select size="sm" name="status">
											<option value="">All </option>
											<option value="In-progress">In-progress</option>
											<option value="Completed">Completed</option>
											<option value="Failed">Failed</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className="mr-5">
											Filter
										</Button>
										<Button
											size="sm"
											variant="outline-light"
											type="button"
											onClick={handleReset}
										>
											Reset
										</Button>
									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={showingJobs ? "crm/leads/export/lists?page=jobs" : "crm/leads/export/lists"}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>
		</Layout>
	);
}

export default Export;
