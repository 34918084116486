import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../../../components';
import { sendRequest } from "../../../../utilities/api";

function LeadFunnelsEdit(props) {
    const { id } = useParams();

    const [meta, setMeta] = useState({ coachPartners: [], tags: [], domains: [] });
    const [loading, setLoading] = useState(false);
    const [leadCheck, setLeadCheck] = useState({ type: "create", tags: "" });
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            await fetchRecordById();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        const formData = new FormData();
        await sendRequest('crm/leads/funnels/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = async () => {
        await sendRequest(`crm/leads/funnels/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setLeadCheck(response.data.list.leadCheck);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest(`crm/leads/funnels/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/crm-manage/leads/funnels/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };


    return (
        <Layout title="Funnel" content="container">
            <div className="Funnel-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Edit Form</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/crm-manage/leads/funnels/lists">Funnels</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>

                                            <div className={`bio-block `}>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Name</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={data.name} type="text" name="name" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Assign To</Form.Label>
                                                            {meta?.coachPartners.length > 0 ? (
                                                                <Select defaultValue={data.permissions} name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.coachPartners.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="permissions[]" multiple removeItemButton>
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Sub Domain</Form.Label>
                                                            {meta?.domains.length > 0 ? (
                                                                <Select defaultValue={data.domain_id} name="domain_id">
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.domains.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.name}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            ) :
                                                                <Select name="domain_id" >
                                                                    <option value="">Select</option>
                                                                </Select>

                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Duplicate Check:</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Check defaultChecked={leadCheck.type == 'create' ? true : false} onChange={() => setLeadCheck({ type: "create", tag: [] })} name="check_duplicate" value={"create"} label="Don't check, create Duplicate" type="radio"></Form.Check>
                                                                <Form.Check defaultChecked={leadCheck.type == 'exists' ? true : false} onChange={() => setLeadCheck({ type: "exists", tag: [] })} name="check_duplicate" value={"exists"} label="Check record, if exists then do nothing and add a tag to the original" type="radio"></Form.Check>
                                                                <Form.Check defaultChecked={leadCheck.type == 'update' ? true : false} onChange={() => setLeadCheck({ type: "update", tag: [] })} name="check_duplicate" value={"update"} label=" Check record, update existing record and add a tag to the original" type="radio"></Form.Check>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    {
                                                        leadCheck.type != 'create' && (
                                                            <Col md="6">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="role">Tags</Form.Label>
                                                                    {meta?.tags.length > 0 ? (
                                                                        <Select defaultValue={leadCheck.tags} name="tags[]" multiple removeItemButton>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.tags.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title} </option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    ) :
                                                                        <Select name="tags" removeItemButton>
                                                                            <option value="">Select</option>
                                                                        </Select>

                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    }

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Popup Height</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control defaultValue={data.popup_height} type="number" name="popup_height" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Create New Contact:</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Check defaultChecked={data.lead_create == 'Yes' ? true : false} inline name="lead_create" value="Yes" label="Yes" type="radio"></Form.Check>
                                                                <Form.Check defaultChecked={data.lead_create == 'No' ? true : false} inline name="lead_create" value="No" label="No" type="radio"></Form.Check>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="role">Status</Form.Label>
                                                            <Select name="status" defaultValue={data.status}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Select>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Update</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default LeadFunnelsEdit;