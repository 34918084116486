import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";

function UserCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [] });

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('groups/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('groups/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/jobs/category/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Job Category" content="container-fluid">
            <div className="category-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Job Category</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/jobs/category/lists">Categories & Services</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Type</Form.Label>
                                                <Select defaultValue={'Category'} name="type">
                                                    <option value="Category">Category</option>
                                                    <option value="Service">Service</option>                                                        
                                                </Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="role">Assign To</Form.Label>
                                                {meta?.coachPartners.length > 0 && (
                                                    <Select defaultValue={meta.teamIds} name="permissions[]" multiple removeItemButton>
                                                        <option value="">Select</option>
                                                        {
                                                            meta?.coachPartners.map((row, i) => {
                                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                            })
                                                        }
                                                    </Select>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Button  type="submit" className="btn-sm">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default UserCreate;