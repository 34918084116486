// CallComponent.js
import React, { useState, useEffect } from 'react';
import { addPlivoEventListeners, removePlivoEventListeners, makeCall, hangupCall, muteCall, unmuteCall } from './PlivoService';
import { uInfo } from '../../utilities';
import Dialler from './Dialler';
import { Icon } from '../../components';
import KeypadButton from './KeypadButton';
import Swal from 'sweetalert2';
import { Button, Card, Form, Spinner, Col, Row } from 'react-bootstrap';
import { useRef } from 'react';
import { sendRequest } from '../../utilities/api';

const CallComponent = ({ paramsters, getCallSid }) => {
    const [callStatus, setCallStatus] = useState('idle');
    const [callUUID, setCallUUID] = useState('');
    const [to_number, setNumber] = useState(paramsters.phoneno);
    const [phone] = useState(paramsters.phone);
    const [mobile] = useState(paramsters.mobile);
    const [mute, setMute] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedSeconds, setElapsedSeconds] = useState(0);
    const intervalRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [zone, setZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles');
    const [slots, setSlots] = useState([]);
    const [activity, setActivity] = useState('');
    const [subject, setSubject] = useState('');
    const [date, setDate] = useState('');
    const [start, setStart] = useState('');
    const [actionType, setActionType] = useState('call');
    const [reminder, setReminder] = useState(false);

    useEffect(() => {
        getCallSid(callStatus);
    }, [callStatus])

    useEffect(() => {
        addPlivoEventListeners(
            onCallFailed,
            onCallTerminated,
            onCallAnswered

        );
        handleMakeCall();

        return () => {
            removePlivoEventListeners(
                onCallFailed,
                onCallTerminated,
                onCallAnswered
            );
        };
    }, []);


    const handleStartStop = () => {
        clearInterval(intervalRef.current);
        if (isRunning === false) {
            setElapsedSeconds(0);
        }
        setIsRunning(!isRunning);
    };

    const calculateElapsedTime = () => {
        const formattedMinutes = Math.floor(elapsedSeconds / 60).toString().padStart(2, '0');
        const formattedSeconds = (elapsedSeconds % 60).toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;

    };

    useEffect(() => {
        if (isRunning) {
            intervalRef.current = setInterval(() => {
                setElapsedSeconds((prevElapsed) => prevElapsed + 1);
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [isRunning]);


    const onCallFailed = () => {
        console.log('onCallFailed');
    };

    const onCallTerminated = () => {
        setCallStatus('ended');
        handleStartStop();
    };

    const onCallAnswered = (callInfo) => {
        setCallStatus('answered');
        handleStartStop();
    };

    const handleMakeCall = async () => {
        if (uInfo().outbound_phone == '') {
            Swal.fire({
                title: "Please set outbound number as from number in your profile setting",
                icon: 'warning',
            });
            return;
        }

        if (to_number == '') {
            Swal.fire({
                title: "Sorry , To number is empty",
                icon: 'warning',
            });
            return;
        }

        try {
            const dest = to_number;
            const extraHeaders = {
                'X-PH-COACH-ID': paramsters.coach_id,
                'X-PH-User-ID': uInfo().id,
                'X-PH-Lead-ID': paramsters.client_id,
                'X-PH-CAMPAIGN-ID': paramsters.campaign_id ? paramsters.campaign_id : 0,
                'X-PH-From-Number': uInfo().outbound_phone,
            };
            makeCall(dest, extraHeaders);

        } catch (error) {
            console.error('Error making call:', error);
        }
    };

    const handleHangupCall = () => {
        hangupCall();
    };

    const handleMuteAction = () => {
        if (mute === true) {
            setMute(false)
            unmuteCall();
        } else {
            setMute(true)
            muteCall();
        }
    };


    const handleSubmitCallLog = (event) => {
        // setIsLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();

        const actionTypeValue = formData.get('call_type');

        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (paramsters.campaign_id != '') {
            submitData.append('campaign_id', paramsters.campaign_id);
        }

        if (actionTypeValue === 'save') {

            let call_note = submitData.get('activity')

            if (!call_note) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Please add a call note',
                    showConfirmButton: true,
                })

                return;
            }
        }

        submitData.append('time_zone', zone);
        submitData.append('lead_id', paramsters.client_id);
        submitData.append('type', 'call');
        submitData.append('call_type', actionTypeValue);

        sendRequest(`crm/leads/add/activity/${paramsters.client_id}/call`, 'POST', submitData).then(response => {
            if (response.status === true) {
                setActivity('')
                setSubject('')
                setDate('')
                setStart('')
                // setIsLoading(false);
            }
        })
            .catch(error => {
                setIsLoading(false);
                console.error('POST request error:', error);
            });
    }

    const fetchSlots = async (record, date = record.date_start, from = '0') => {
        // setData({});

        const formData = new FormData();
        formData.append('calendar_id', '');
        formData.append('time_zone', zone);
        formData.append('date', date);
        formData.append('page', 'call_dialer');

        await sendRequest(`calendar/appointments/slots/fetch`, 'POST', formData).then(response => {
            if (response.status === true) {
                setSlots(response.data.slots)
            }

        })
            .catch(error => {
                // setLoading(true)

                console.error('POST request error:', error);
            });
    }

    const callTypes =
    {
        'inquiry': 'Inquiry',
        'invalid_call': 'Invalid Call',
        'won': 'Won',
        'lost': 'Lost',
        'contact_made': 'Contact Made',
        'send_website': 'Send Website',
        'call_back': 'Call Back',
        'not_interested': 'Not Interested',
        'hang_up': 'Hang Up'
    };

    const handleButtonClick = (value) => {
        setActionType(value);
    };

    if (callStatus == 'idle') {
        return (
            <div className="chat-content bg-primary">
                <div className='d-flex dial-call-plivo'>
                    <div className="call">
                        <Icon className='call-icon' name={'call'}></Icon>
                    </div>
                    <Card className='border-0'>
                        <Card.Body className='p-0'>
                            <p>{paramsters.phoneno}</p>
                            <p>
                                Calling
                                &nbsp;
                                <Spinner className='spiner-custom-size-8' as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                &nbsp;
                                <Spinner className='spiner-custom-size-8' as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                &nbsp;
                                <Spinner className='spiner-custom-size-8' as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            </p>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    } else if (callStatus == 'answered' || callStatus == 'ended') {
        return (
            <div className='answer-header'>
                <div className='answren-call  bg-primary'>
                    <Card className='bg-transparent border-0 shadow-none'>
                        <Card.Body className='p-0 text-start'>
                            <p>{paramsters.first_name} {paramsters.last_name} ({paramsters.phoneno})</p>
                            <p>{calculateElapsedTime()}</p>
                        </Card.Body>
                    </Card>
                    {
                        callStatus == 'answered' && (
                            <div className="call">
                                <div className="call-options py-0">
                                    <KeypadButton handleClick={handleMuteAction} color={mute ? "gray" : "green"}>
                                        <p className="mute-unmute">
                                            {!mute ?
                                                <Icon name={'mic'}></Icon>
                                                :
                                                <Icon name={'mic-off'}></Icon>

                                            }
                                        </p>
                                    </KeypadButton>
                                </div>

                                <div className="hang-up">
                                    <KeypadButton handleClick={handleHangupCall} color="red btn btn-sm" >
                                        <Icon name={'call'}></Icon>
                                    </KeypadButton>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Form onSubmit={handleSubmitCallLog}>
                    <Form.Control defaultValue={'call'} type="hidden" name="action_type" />
                    <Form.Control defaultValue={actionType} type="hidden" name="call_type" />
                    <Form.Group className="form-group p-3">
                        {
                            !isLoading && (
                                <div className="form-control-wrap">
                                    <Form.Control as={'textarea'} name="activity" placeholder="Call log..." value={activity} onChange={(e) => setActivity(e.target.value)} />
                                </div>
                            )
                        }
                        <div className="form-control-wrap ">
                            <Row className={'mb-1 mt-3'}>
                                <h5>Create a New Follow Up</h5>
                                <Col md="6" className={'mb-1'}>
                                    <Form.Group >
                                        <div className="form-control-wrap">
                                            <Form.Control type="text" name="subject" placeholder="Follow up subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <div className="form-control-wrap">
                                        <div className="custom-control custom-switch">
                                            <Form.Check
                                                type="switch"
                                                id="custom-checkbox"
                                                label="User Reminders"
                                                className="custom-checkbox-radio"
                                                name="user_reminder"
                                                checked={reminder}
                                                onChange={(e) => setReminder(e.target.checked)}
                                            />                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6" className={'mb-1'}>
                                    <Form.Group>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                name="date"
                                                type="date"
                                                value={date}
                                                onChange={(e) => {
                                                    fetchSlots('data', e.target.value, '0');
                                                    setDate(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </Form.Group>

                                </Col>
                                <Col md="6" className={'mb-1'}>
                                    <Form.Group>
                                        <div className="form-control-wrap">
                                            {/* <div className="form-control-wrap">
                                                <Form.Control name='date' type="time" />
                                            </div> */}
                                            <Form.Select name="start" value={start} onChange={(e) => setStart(e.target.value)}>
                                                {
                                                    slots && slots.map((row, i) => {
                                                        return <option key={i} value={row.key}>{row.value}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-control-wrap text-start">
                            <Button size='sm' className='my-1' type="submit" onClick={() => handleButtonClick('save')}>Save</Button>
                        </div>
                    </Form.Group>
                    <div className="form-control-wrap text-start p-3 pt-0">
                        {callTypes &&
                            Object.values(callTypes).map((row, i) => {
                                return (
                                    <Button
                                        key={i}
                                        size="sm"
                                        className="me-1 mb-1"
                                        type="submit"
                                        name="action_button"
                                        value={row}
                                        variant="light"
                                        onClick={() => handleButtonClick(row)}
                                    >
                                        {row}
                                    </Button>
                                );
                            })}
                    </div>
                </Form>

            </div>
        )

    } else {
        // return (
        //     <div className="d-flex justify-content-center my-5">
        //         <Spinner animation="border" variant="secondary"></Spinner>
        //     </div>
        // )
    }

    // return (
    //     <div>
    //         <Dialler phone={phone} mobile={mobile} number={to_number} setNumber={""} connection={conn}></Dialler>

    //         <p>Call Status: {callStatus}</p>
    //         <button onClick={handleMakeCall}>Make Call</button>
    //         <button onClick={handleHangupCall} disabled={callStatus === 'idle'}>
    //             Hangup Call
    //         </button>
    //     </div>
    // );
};

export default CallComponent;
