import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, Offcanvas } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { currentMonth, toInitials, today, yesterday } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";
import AppCalendar from "../Calendar";
import CalanderNav from "../nav/CalanderNav";

function CalanderEdit(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [calendarId, setCalendatId] = useState("MA==");
    const [events, setEvents] = useState([]);
    const [eventCategory, setEventCategory] = useState([]);
    const formDefault = { opened: false }
    const [formModal, setFormModal] = useState(formDefault);
    const [calendarUrl, setCalendarUrl] = useState("");
    const [offDays, setOffDays] = useState([]);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [iframeVisible, setIframeVisible] = useState(true);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    const iframeRef = useRef(null);
    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
            if (id != 'MA==') {
                getCalenderAppointments(id);
            }
        };
        fetchData();
        const handleIframeMessage = (event) => {
            if (event.data === 'iframeLoaded') {
                closeFormModal();
                fetchRecordById();
            }
        };
        window.addEventListener('message', handleIframeMessage);
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, []);

    useEffect(() => {
        getCalenderAppointments(calendarId)
    }, [selectedUserIds, selectedStatuses, selectedTypes]);

    const fetchRecordById = async () => {
        await sendRequest(`location/meta/fetch`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data);
                if (id == 'MA==') {
                    const foundObject = response.data?.calendars.find(obj => obj.public_id === response.data.defaultCalendarId);
                    let days = foundObject ? foundObject.off_days.join(',') : "";
                    setCalendatId(response.data.defaultCalendarId);
                    setOffDays(days);
                    getCalenderAppointments(response.data.defaultCalendarId);
                } else {
                    const foundObject = response.data?.calendars.find(obj => obj.public_id === id);
                    let days = foundObject ? foundObject.off_days.join(',') : "";
                    setOffDays(days);
                }
                setCalendarUrl(response.data.defaultCalendarUrl);
            }

        })
            .catch(error => {

                console.error('POST request error:', error);
            });
    }

    const getCalenderAppointments = async (value, type = '') => {
        setLoading(false);
        const formData = new FormData();

        formData.append('calendar_id', value);
        formData.append('filter_type', type);
        formData.append('selectedUsers', selectedUserIds);
        formData.append('selectedStatuses', selectedStatuses);
        formData.append('selectedTypes', selectedTypes);

        await sendRequest(`calendar/appointments/lists?page_number=${0}&page_size=50000000`, 'POST', formData).then(response => {
            if (response.status === true) {

                const dynamicEvents = [];
                for (let i = 0; i < response.data.records.length; i++) {
                    const eventId = response.data.records[i].id;
                    const randomColor = response.data.records[i].appointment_bg_color;
                    const textColor = response.data.records[i].appointment_status.text_color ?? '#fff';
                    const start = response.data.records[i].start_time;
                    const end = response.data.records[i].end_time;
                    const lead_name = response.data.records[i]?.lead_details?.first_name +
                        (response.data.records[i]?.lead_details?.last_name
                            ? ' ' + response.data.records[i]?.lead_details?.last_name
                            : '');
                    dynamicEvents.push({
                        id: eventId,
                        title: `${response.data.records[i].appointment_date}`,
                        start,
                        end,
                        className: `${randomColor}`,
                        extendedProps: {
                            description: ` <b>Appointment With: </b> ${lead_name}                       
                              <br> <b>Status:</b> ${response.data.records[i].appointment_status_title}
                            `,
                            lead_name: lead_name,
                            lead_id: response.data.records[i]?.lead_details?.public_id,
                            duration: response.data.records[i].duration,
                            textColor: textColor
                        },
                        //   description: `Description for Event ${i + 1} on `,
                    });
                }

                setEvents(dynamicEvents);
                setCalendatId(value);

                setLoading(true);
            }

        })
            .catch(error => {

                console.error('POST request error:', error);
            });

    }

    function openAddModal() {
        const updateFormModal = {
            ...formModal,
            opened: true,
            modalTitle: "Add Appointment",
        }
        setFormModal(updateFormModal);
    }

    function closeFormModal() {
        const updateFormModal = { ...formModal, opened: false }
        setFormModal(updateFormModal);
    }
    const handleIframeLoad = () => {
        // Set state to indicate that the iframe has loaded
        setIframeLoaded(true);

        // Close the iframe or perform other actions as needed
        setIframeVisible(false);
    };


    const handleFilterChange = (key, value) => {
        if (key === 'user_ids') {
            setSelectedUserIds((prevSelected) => {
                if (prevSelected.includes(value)) {
                    return prevSelected.filter((id) => id !== value);
                } else {
                    return [...prevSelected, value];
                }
            });
        }

        if (key === 'statuses') {
            setSelectedStatuses((prevSelected) => {
                if (prevSelected.includes(value)) {
                    return prevSelected.filter((id) => id !== value);
                } else {
                    return [...prevSelected, value];
                }
            })
        }

        if (key === 'types') {
            setSelectedTypes((prevSelected) => {
                if (prevSelected.includes(value)) {
                    return prevSelected.filter((id) => id !== value);
                } else {
                    return [...prevSelected, value];
                }
            })
        }

        // getCalenderAppointments(calendarId, key);
    }

    const Filters = () => {
        return (
            <div className="col-md-2">
                <div className="mb-3 mt-3">
                    <label className="form-label fs-5">Users</label>
                    {
                        data.coachPartners && data.coachPartners.length > 0 && data.coachPartners.map((item, index) => {
                            return (
                                <div className="form-check" key={index}>
                                    <input className="form-check-input" type="checkbox" value={item.public_id} id={item.public_id} onChange={(e) => handleFilterChange('user_ids', e.target.value)} />
                                    <label className="form-check-label" htmlFor={item.public_id}>{item.first_name} {item.last_name}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="mb-3">
                    <label className="form-label fs-5">Status</label>
                    {
                        data.appointmentStatus && data.appointmentStatus.length > 0 && data.appointmentStatus.map((item, index) => {
                            return (
                                <div className="form-check" key={index}>
                                    <input className="form-check-input" type="checkbox" value={item.public_id} id={item.public_id} onChange={(e) => handleFilterChange('statuses', e.target.value)} />
                                    <label className="form-check-label" htmlFor={item.public_id}>{item.title}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="mb-3">
                    <label className="form-label fs-5">Types</label>
                    {
                        data.appointmentTypes && Object.entries(data.appointmentTypes).length > 0 && Object.entries(data.appointmentTypes).map(([key, value]) => {
                            return (
                                <div className="form-check" key={key}>
                                    <input className="form-check-input" type="checkbox" value={key} id={key} onChange={(e) => handleFilterChange('types', e.target.value)} />
                                    <label className="form-check-label" htmlFor={key}>{value}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };

    return (
        <>
            <Layout title="Calendar" content="container-fluid">
                <div className="calander-view">
                    <Block.Head>
                        <Block.HeadBetween>
                            <Block.HeadContent>
                                <Block.Title tag="h2">Calendar View </Block.Title>
                                <nav>
                                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Calendar</li>
                                    </ol>
                                </nav>
                            </Block.HeadContent>
                            <Block.HeadContent>
                                <ul className="d-flex">
                                    <CalanderNav from={2} />
                                </ul>
                            </Block.HeadContent>
                        </Block.HeadBetween>
                    </Block.Head>
                    <Block>

                        <Card className="card-gutter-md">
                            <Card.Body>
                                <div className="bio-block">
                                    <Row>
                                        <Col md="4">
                                            <Form.Select size="sm" defaultValue={calendarId} onChange={(e) => { setCalendatId(e.target.value); getCalenderAppointments(e.target.value); setCalendarUrl(e.target.options[e.target.selectedIndex].getAttribute('data-id'), 'target'); setOffDays(e.target.options[e.target.selectedIndex].getAttribute('data-offDays'), 'target') }} >
                                                <option value={'MA=='}>Select Calendar</option>
                                                {
                                                    data && data.calendars && data.calendars.map((row, i) => {
                                                        return <option data-offDays={row.off_days} key={i} data-id={row.calendar_url} value={row.public_id}>{row.title}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col md="4">
                                            {
                                                calendarId != 'MA==' && (
                                                    <Button size="sm" variant="primary" action="add" onClick={openAddModal}>
                                                        <Icon name="plus"></Icon><span>Add Appointment</span>
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="mb-0" />

                                {
                                    calendarId != 'MA==' && (
                                        <>
                                            <Row>
                                                {Filters()}
                                                <Col md="10">
                                                    {
                                                        !loading ? (
                                                            <Spinner animation="grow" variant="primary"></Spinner>
                                                        ) : (
                                                            <AppCalendar offDays={offDays} navigate={props.navigate} eventCategory={eventCategory} eventData={events} calendarId={calendarId} calendarUrl={calendarUrl} />
                                                        )
                                                    }

                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }

                                <hr className="mb-0" />
                                <div className="row my-2">

                                    {
                                        data.appointmentStatus && data.appointmentStatus.map((row, i) => {
                                            return (
                                                <div className="col-md" key={i}>
                                                    <div style={{ backgroundColor: row.bg_color, color: row.text_color }} className="text-center">{row.title}</div>


                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Card>

                    </Block >
                </div>
                {!iframeLoaded && iframeVisible && (
                    <Offcanvas className="w-75" placement="end" show={formModal.opened} onHide={() => closeFormModal()}>
                        <Offcanvas.Header className="px-2 py-2" closeButton />
                        <Offcanvas.Body className="p-0">
                            <Row>
                                <Col md={12}>

                                    <iframe
                                        ref={iframeRef}
                                        title="embedded-link"
                                        width="100%"
                                        height={"650"}
                                        src={`${calendarUrl}?type=inner_calendar`}
                                        allowFullScreen
                                    ></iframe>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                )}
            </Layout >


        </>
    )
}

export default CalanderEdit;