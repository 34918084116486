import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Offcanvas, Spinner, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Message, Select } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import { convertPhoneFormat } from '../../../utilities';
import Swal from 'sweetalert2';

function RecycleBin() {
	const { hasPermission, callInit } = useAuth();
	const [offcanvasStart, setOffcanvasStart] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataTableData, setDataTableData] = useState({});
	const [selectedIds, setSelectedIds] = useState([]);
	const childRef = useRef(null);

	useEffect(() => {
		setLoading(true);
	}, []);



	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to remove permanently selected contacts?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`crm/leads/update/bulk/permanentlyRemove`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};
	const restoreRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to restore selected contacts?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`crm/leads/update/bulk/restoreContacts`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};

	const userColumns = [
		{
			name: <Form.Check value="selectAll" onChange={(e) => handleCheckbox(e)} type="checkbox" id="selectAllCheckbox" label="" />,
			key: 'checkboxes',
			selector: (row) => row.id,
			cell: (row) => (
				<Form.Check checked={selectedIds.includes(row.public_id)} id={`flexCheckDefault${row.id}`} defaultValue={row.public_id} onChange={(e) => handleCheckbox(e)} type="checkbox" label="" />
			),
		},
		{
			name: "Lead ID",
			key: 'id',
			selector: (row) => row.id,
			cell: (row) => (
				<span>{row.id}</span>
			),
		},
		{
			name: "Group",
			key: 'groups',
			selector: (row) => row.groups,
			cell: (row) => (
				<span>{row.groups}</span>
			),
		},
		{
			name: "Tag",
			key: 'tags',
			selector: (row) => row.tags,
			cell: (row) => (
				<span>{row.tags}</span>
			),
		},
		{
			name: "Full Name",
			key: 'name',

			selector: (row) => row.name,
			cell: (row) => (
				<span>{row.first_name} {row.last_name}</span>
			),
		},
		{
			name: "Email",
			key: 'email',
			minWidth: '280px',
			selector: (row) => row.email,
			cell: (row) => (
				(row.email != '' && row.email != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<span>{row.email}</span>
						</li>
					</ul>
				)
			),
		},

		{
			name: "Phone",
			key: 'lead_number',
			minWidth: '260px',
			selector: (row) => row.lead_number,
			cell: (row) => (
				(row.lead_number != '' && row.lead_number != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<span>
								{convertPhoneFormat(row.lead_number)}
							</span>
						</li>
					</ul>
				)
			),
		},

		{
			name: "Mobile",
			key: 'mobile',
			minWidth: '260px',
			selector: (row) => row.mobile,
			cell: (row) => (
				(row.mobile != '' && row.mobile != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<span>
								{convertPhoneFormat(row.mobile)}
							</span>
						</li>
					</ul>
				)
			),
		},
		{
			name: "City",
			key: 'city',
			selector: (row) => row.city,
			cell: (row) => (
				<span>{row.city}</span>
			),
		},

		{
			name: "State",
			key: 'state',
			selector: (row) => row.state,
			cell: (row) => (
				<span>{row.state}</span>
			),
		},
		{
			name: "Zip",
			key: 'zip',
			selector: (row) => row.zip,
			cell: (row) => (
				<span>{row.zip}</span>
			),
		},
		{
			name: "Address",
			key: 'address',
			selector: (row) => row.address,
			cell: (row) => (
				<span>{row.address}</span>
			),
		},
		{
			name: "website",
			key: 'website',
			selector: (row) => row.website,
			cell: (row) => (
				<span>{row.website}</span>
			),
		},
		{
			name: "Company",
			key: 'company_name',
			selector: (row) => row.company_name,
			cell: (row) => (
				<span>{row.company_name}</span>
			),
		},
		{
			name: "Deleted At",
			key: 'deleted_at',
			selector: (row) => row.deleted_at,
			cell: (row) => (
				<span>{row.deleted_at}</span>
			),
		},

	];

	const getInitialColumnVisibility = () => {
		return {
			name: true,
			email: true,
			lead_number: true,
			address: true,
			company_name: true,
			checkboxes: true,
			deleted_at: true,
		};
	};

	const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);
	const toggleColumnVisibility = (key) => {
		setColumnVisibility((prevVisibility) => ({
			...prevVisibility,
			[key]: !prevVisibility[key],
		}));
	};
	const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);

	const handlePipelines = (data) => {
		setDataTableData(data);
		setSelectedIds([]);
		if (data.selected_columns != '') {
			setColumnVisibility(data.selected_columns);
		}
	};

	const handleCheckbox = (e) => {
		const { value, checked } = e.target;
		if (value === 'selectAll') {
			if (checked) {
				setSelectedIds(dataTableData.records.map((row) => row.public_id));
			} else {
				setSelectedIds([]);
			}
		} else {
			setSelectedIds((prevSelectedIds) => {
				if (checked) {
					return [...prevSelectedIds, value];
				} else {
					return prevSelectedIds.filter((id) => id !== value);
				}
			});
		}

	};
	const CreateUI = () => {
		return (
			selectedIds.length > 0 && (
				<Card>
					<Card.Body>
						<ul className="d-flex">
							<Dropdown>
								<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-primary btn-sm me-n1 hide-caret">
									Action
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="center" style={{ minWidth: '200px' }}>
									<div className="dropdown-content py-1">
										<LinkList className="link-list-hover-bg-primary link-list-md">
											<button className="btn p-0" onClick={() => restoreRecord('MA==')}>
												Restore Contacts
											</button>

											{
												hasPermission('deleteLeads') && (
													<button className="btn p-0" onClick={() => deleteRecord('MA==')}>
														Permanently Remove
													</button>
												)
											}

										</LinkList>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</Card.Body>
				</Card>
			)
		)
	}
	return (
		<Layout title="Trash Contacts" content="container-fluid">
			<div className="Leads">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Trash Contacts</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Contacts</li>
								</ol>
							</nav>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<>
								<Card>
									<Card.Body>
										<DataTableComponent
											childRef={childRef}
											selectableRows={false}
											columns={visibleColumns}
											tableClassName="data-table-head-light table-responsive LeadListTable"
											slug={'crm/leads/lists?status=0'}
											sendDataToParent={handlePipelines}
										/>
									</Card.Body>
								</Card>
								{CreateUI()}
							</>
						)
					}
				</Block>
			</div>
		</Layout >
	)
}

export default RecycleBin;
