import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone, Tinymce } from '../../components';
import { toInitials, uInfo } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";

function DocumentCreate(props) {
    const [meta, setMeta] = useState({ coachPartners: [], calendars: [] });
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        const formData = new FormData();
        sendRequest('document/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest('document/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/document-manage/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };


    return (
        <Layout title="Document" content="container-fluid">
            <div className="document-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Document</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/document-manage/lists">Document</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">

                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <div className={`bio-block`}>
                                                <Row className="g-3">
                                                    <Col md="12">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="name">Title</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control placeholder="Enter title" type="text" name="title" required />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="12">
                                                        <Form.Group className="form-group d-flex justify-content-center">
                                                            <Tinymce width={700} leadToken={true} height={700} initialValue={""} textareaName={'body'} toolbar={true} />
                                                        </Form.Group>
                                                    </Col>

                                                    {
                                                        (uInfo().type == 'Reseller' || uInfo().type == 'Staff') ?
                                                            <Form.Control type="hidden" name="type" required value={'global'} />
                                                            :
                                                            <Col md="12">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="role">Assign To</Form.Label>
                                                                    {meta?.coachPartners.length > 0 ? (
                                                                        <Select name="permissions[]" multiple removeItemButton>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.coachPartners.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    ) :
                                                                        <Select name="permissions[]" multiple removeItemButton>
                                                                            <option value="">Select</option>
                                                                        </Select>
                                                                    }
                                                                    <Form.Control type="hidden" name="type" required value={'default'} />
                                                                </Form.Group>
                                                            </Col>
                                                    }

                                                    <Col className="col-12 text-end">
                                                        <Button type="submit" className="btn-sm">Create</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout>
    )
}

export default DocumentCreate;