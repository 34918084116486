import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Dropdown, Form, ListGroup, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { sendRequest } from '../../../../utilities/api';
import { FileUpload, Icon } from '../../../../components';
import DocumentLists from './DocumentLists';
import DocumentRequest from './DocumentRequest';

const Attachment = (props) => {
    const [leadId] = useState(props.leadId);
    const [lists, setLists] = useState([]);
    const [showAddFolderForm, setShowAddFolderForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openIndices, setOpenIndices] = useState([]);
    const [sublists, setSublists] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [showEditFolderForm, setShowEditFolderForm] = useState(false);
    const [data, setData] = useState({});

    const [manageDoc, setManageDoc] = useState({
        show: false,
        type: 'request'
    });


    useEffect(() => {
        const fetchData = async () => {
            await fetchCoachFolders();
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Function to fetch sublist data from API
        const fetchSublistData = async (index) => {
            const formData = new FormData();
            formData.set('folder_id', index);
            formData.set('lead_id', leadId);

            await sendRequest(`resource/file/lists`, 'POST', formData).then(response => {
                if (response.status === true) {
                    setSublists((prevSublists) => ({ ...prevSublists, [index]: response.data.records }));

                }
            })
                .catch(error => {
                    console.error('POST request error:', error);
                });

        };

        // Fetch sublist data for each open index
        openIndices.forEach((index) => {
            if (!sublists[index]) {
                fetchSublistData(index);
            }
        });
    }, [openIndices, sublists]);

    const fetchCoachFolders = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        formData.set('lead_id', leadId);

        await sendRequest('resource/folder/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.records);
                setShowAddFolderForm(false);
                setShowEditFolderForm(false);
                setData({});
                setCheckedRows([]);
                setOpenIndices([]);
                setSublists([]);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('resource/folder/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchCoachFolders();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('resource/folder/update/' + data.public_id, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchCoachFolders();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const handleFileSubmit = (files, releatedId) => {
        setLoading(true);
        const submitData = new FormData();

        if (Array.isArray(files)) {
            files.forEach((file, index) => {
                // Append each file with a unique key, e.g., 'file0', 'file1', etc.
                submitData.append(`files[]`, file);
            });
        } else {
            // If files is a single file, append it without iterating
            submitData.append('files[]', files);
        }

        submitData.append('folder_id', releatedId);
        submitData.append('lead_id', leadId);


        sendRequest('resource/file/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire({
                        title: response.message,
                        icon: 'success',
                    });
                    fetchCoachFolders();
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    }

    const deleteFolder = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this folder`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`resource/folder/${id}`, 'Delete')
                    .then(response => {
                        if (response.status === true) {
                            fetchCoachFolders();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });

    };

    const deleteBulkFiles = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove all selected files`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                const submitData = new FormData();
                submitData.append('ids', keysWithHyphenAndTrue);
                sendRequest(`resource/file/bulk/delete`, 'POST', submitData)
                    .then(response => {
                        if (response.status === true) {
                            fetchCoachFolders();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });

    };

    const handleItemClick = (folderId) => {
        // If the clicked item is already open, close it
        if (openIndices.includes(folderId)) {
            setOpenIndices(openIndices.filter((i) => i !== folderId));
            toggleCheckbox(folderId, true);
        } else {
            // Otherwise, open the clicked item
            setOpenIndices([...openIndices, folderId]);
        }
    };

    const toggleCheckbox = (mainId, checkbox = false) => {
        setCheckedRows((prevCheckedRows) => {
            const newCheckedRows = { ...prevCheckedRows };
            newCheckedRows[mainId] = checkbox == true ? false : !newCheckedRows[mainId];
            const sublistItems = sublists[mainId] || [];
            sublistItems.forEach((subitem) => {
                const key = `${mainId}-${subitem.id}`;
                newCheckedRows[key] = newCheckedRows[mainId];
            });

            return newCheckedRows;
        });
    };

    const toggleSublistCheckbox = (mainId, subitemId) => {
        setCheckedRows((prevCheckedRows) => {
            const key = `${mainId}-${subitemId}`;
            const newCheckedRows = { ...prevCheckedRows };
            newCheckedRows[key] = !newCheckedRows[key];
            return newCheckedRows;
        });
    };

    const keysWithHyphenAndTrue = Object.keys(checkedRows)
        .filter((key) => key.includes('-') && checkedRows[key]);

    return (
        <div className='attachment-lists'>
            <div className='documents mx-2'>
                <div className='btns'>
                    <button onClick={() => setManageDoc({ show: !manageDoc.show, type: 'request' })} className="w-auto btn btn-outline-primary me-2 btn-sm">
                        Request Documents
                    </button>
                    <button onClick={() => setManageDoc({ show: !manageDoc.show, type: 'manage' })} className="w-auto btn btn-outline-primary btn-sm">
                        Manage Doc Request Templates
                    </button>
                </div>

                {
                    (manageDoc.show && manageDoc.type == 'manage') && (
                        <div className='manage-doc'>
                            <DocumentLists />
                        </div>
                    )
                }
                {
                    (manageDoc.show && manageDoc.type == 'request') && (
                        <div className='request-doc'>
                            <DocumentRequest leadId={props.leadId} />
                        </div>
                    )
                }
            </div>
            <div className='action-resource my-1 mx-2'>

                {
                    keysWithHyphenAndTrue.length > 0 && (

                        <button onClick={() => deleteBulkFiles()} className="text-nowrap justify-content-start btn btn-outline-primary btn-sm">
                            Delete Selected Files
                        </button>


                    )
                }

                <div className='text-end w-100'>
                    <button title='Add Folder' onClick={() => setShowAddFolderForm(!showAddFolderForm)} className="p-0 btn btn-color-primary  btn-icon">
                        <Icon name={'folder-plus'} />
                    </button>
                </div>

            </div>
            <div className="mx-2">
                {
                    showAddFolderForm && (
                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="form-group">
                                <div className="form-control-wrap">
                                    <Form.Control type="text" name="title" placeholder='Enter folder name' required />
                                </div>
                            </Form.Group>

                            <ButtonGroup size='sm' className='mt-2' aria-label="Basic example">
                                <Button type='submit' variant="outline-primary">Save</Button>
                                <Button onClick={() => setShowAddFolderForm(false)} variant="outline-secondary">Cancel</Button>
                            </ButtonGroup>

                        </Form>
                    )
                }
            </div>
            <div className='folders-list'>
                {
                    lists.map((row, i) => {
                        return (
                            <div key={i} className='m-2'>

                                <div className='folder mb-2'>
                                    <div className='d-flex w-100 justify-content-between'>

                                        <div className='name-checkbox'>
                                            {openIndices.includes(row.id) && sublists[row.id] && (
                                                <Form.Check
                                                    type="checkbox"
                                                    className='form-check-sm m-0'
                                                    inline
                                                    label=""
                                                    checked={checkedRows[row.id] || false}
                                                    onChange={() => toggleCheckbox(row.id)}
                                                />
                                            )}

                                            <Button onClick={() => handleItemClick(row.id)} size='sm' className="p-0 btn-link text-white" variant="outline-link">
                                                {row.title} - {row.file_count} Files
                                            </Button>
                                        </div>

                                        <div className='action-attachment'>

                                            <Dropdown as={ButtonGroup}>

                                                <Dropdown.Toggle className='hide-caret p-1 text-white' variant="outline-link" >
                                                    <Icon name={'setting-alt'} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => { setData(row); setShowEditFolderForm(true) }}>Edit Folder</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => deleteFolder(row.public_id)}>Delete Folder</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>




                                        </div>
                                    </div>
                                </div>

                                {
                                    (showEditFolderForm && row.id == data.id) && (
                                        <Form onSubmit={handleEditSubmit}>

                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.title} type="text" name="title" placeholder='Enter folder name' required />
                                                </div>
                                            </Form.Group>

                                            <ButtonGroup size='sm' className='mt-2 mb-2' aria-label="Basic example">
                                                <Button type='submit' variant="outline-primary">Save</Button>
                                                <Button onClick={() => { setShowEditFolderForm(false); setData({}) }} variant="outline-secondary">Cancel</Button>
                                            </ButtonGroup>

                                        </Form>
                                    )
                                }

                                {
                                    !loading && (
                                        <div className='drop-area'>
                                            <FileUpload maxFiles={4} maxSize={52428800} preview={false} releatedId={row.id} iconName="files" onSubmitCallback={handleFileSubmit} />
                                        </div>
                                    )
                                }


                                {
                                    openIndices.includes(row.id) && sublists[row.id] && (
                                        <ListGroup className='my-2'>
                                            {sublists[row.id].map((subitem, subindex) => (
                                                <ListGroup.Item key={subindex}>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                inline
                                                                label=""
                                                                className='form-check-sm m-0'
                                                                // Use a unique identifier for the checked state of subitems
                                                                checked={checkedRows[`${row.id}-${subitem.id}`] || false}
                                                                onChange={() => toggleSublistCheckbox(row.id, subitem.id)}
                                                            />
                                                        </Col>
                                                        <Col xs={3}><a href={subitem.file_path} target='_blank'>{subitem.file_name}</a></Col>
                                                        <Col xs={3}>{subitem.user_name}</Col>
                                                        <Col xs={4}>{subitem.dated}</Col>
                                                    </Row>
                                                </ListGroup.Item>

                                            ))}
                                        </ListGroup>
                                    )
                                }

                            </div>

                        )
                    })
                }
            </div>

        </div >
    )

}

export default Attachment;
