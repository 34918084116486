import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal, ButtonGroup, ButtonToolbar, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, Select } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import Swal from "sweetalert2";

function ResellerCredit() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const [meta, setMeta] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [userCredit, setUserCredit] = useState({});

    const handleShowModal = (modalType) => {
        setShowModal(true);
        setUserCredit((prevState) => ({
            ...prevState,
            operation: modalType,
        }));
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUserId(-1);

        let UserId = selectedUserId
        setTimeout(() => {
            setSelectedUserId(UserId);
        }, 5);
    }



    const formRef = useRef(null);
    const childRef = useRef(null);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        sendRequest('credits/Reseller/meta/fetch', 'POST', []).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleCreditSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        sendRequest('credits/Reseller/update/' + selectedUserId, 'POST', formData).then(response => {
            if (response.status === true) {
                Swal.fire({
                    title: response.message,
                    icon: 'success',
                });
                setShowModal(false);
                handleReset();
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchCredit = (user_id) => {
        sendRequest('dropdown/user/setting/' + user_id, 'GET', []).then(response => {
            if (response.status === true) {
                setSelectedUserId(-1);
                setUserCredit(response.data.record);
                setTimeout(() => {
                    setSelectedUserId(user_id);
                }, 5);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.public_id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Full Name",
            key: 'full_name',
            selector: (row) => row.full_name,
            cell: (row) => (
                <span>{row.full_name}</span>
            ),
        },
        {
            name: "Email",
            key: 'email',
            selector: (row) => row.email,
            cell: (row) => (
                <span>{row.email}</span>
            ),
        },

        {
            name: "Type",
            key: 'type',
            selector: (row) => row.type,
            cell: (row) => (
                <span className={`badge text-bg-${row.type === "Purchased" ? "success-soft"
                    : row.type === "Free" ? "warning-soft"
                        : row.type === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.type ? row.type : 'General'}
                </span>
            ),
        },
        {
            name: <>(&cent;) Credit</>,
            key: 'credits',
            selector: (row) => row.credits,
            cell: (row) => (
                <span className='price-end'>&cent;{row.credits}</span>
            ),
        },
        {
            name: "Note",
            key: 'other_settings',
            selector: (row) => row.other_settings,
            cell: (row) => (
                <span>{row.other_settings}</span>
            ),
        },


        {
            name: "Dated",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },

    ];

    return (
        <Layout title="Reseller Free Credits" content="container-fluid">
            <div className="Reseller-Free-Credits">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Reseller Free Credits</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/credit-manage/reseller">Free Credits</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reseller</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <ul className="d-flex gap-2">
                                <li style={{ minWidth: '350px' }}>
                                    {meta.users && selectedUserId >= 0 && (
                                        <Select
                                            removeItemButton
                                            searchEnabled
                                            defaultValue={selectedUserId}
                                            value={selectedUserId}
                                            onChange={(selectedId) => {
                                                if (selectedId) {
                                                    const formData = new FormData();
                                                    formData.append('user_id', selectedId)
                                                    if (childRef.current) {
                                                        childRef.current.handleFilter(formData);
                                                    }

                                                    fetchCredit(selectedId);
                                                } else {
                                                    setSelectedUserId(0);
                                                    handleReset();
                                                }

                                            }}
                                            name="user_id">
                                            <option value={''}>Select Reseller</option>
                                            {meta.users.map((row, i) => {
                                                return <option key={i} value={row.id}>{row.full_name} - {row.email} </option>
                                            })}

                                        </Select>
                                    )}

                                </li>
                                <li>
                                    <ButtonGroup size='sm' aria-label="Basic example">
                                        <Button disabled={selectedUserId < 1 ? true : false} onClick={() => handleShowModal(0)} variant="success"><Icon name="plus-sm"></Icon></Button>
                                        <Button disabled={selectedUserId < 1 ? true : false} onClick={() => handleShowModal(1)} variant="danger"><Icon name="minus-sm"></Icon></Button>
                                    </ButtonGroup>
                                </li>
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {
                                        customDateFilter && (
                                            <>
                                                <Col>
                                                    <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>

                                                <Col>
                                                    <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>
                                            </>
                                        )
                                    }

                                    <Col>
                                        <Form.Select size="sm" name="credit_type" >
                                            <option value="">All </option>
                                            <option value="free">Free</option>
                                            <option value="purchased">Purchased</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>


                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive"
                                slug={'credits/Reseller/lists'}
                            />
                        </Card.Body>
                    </Card>
                </Block>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{userCredit.operation == 0 ? 'Add' : 'Deduct'} Free Credit </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleCreditSubmit}>
                        <Row className="g-3">
                            <Col lg="12">
                                <p>Available Credit: {userCredit.available_credits}</p>
                                <InputGroup size="md">
                                    <InputGroup.Text>(&cent;)</InputGroup.Text>
                                    <Form.Control min={1} required name='credit' type="number" placeholder={userCredit.operation == 0 ? 'Add Credit' : 'Deduct Credit'} />
                                </InputGroup>
                                <Form.Control required name='action_type' type="hidden" value={userCredit.operation} />

                                <Form.Control required name='user_id' type="hidden" placeholder={selectedUserId} value={selectedUserId} />

                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button type='submit' variant="primary" >Save</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout >
    )
}

export default ResellerCredit;
