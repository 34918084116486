import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import InvoiceForm from "./Create"
import InvoiceView from './Details';
import { currencySign } from '../../utilities';
const Invoice = (props) => {
    const [leadId] = useState(props.releatedId);
    const [type] = useState(props.type);
    const [masterType] = useState(props.masterType??"invoice");

    const [invoiceModal, setInvoiceModal] = useState(false);
    const [viewinvoice, setViewInvoice] = useState({ modal: false, invoiceId: 0 });
    const [activities, setActivities] = useState([]);
    const [meta, setMeta] = useState({
        paymentMethods: [],
        products: []
    });

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            await fetchActivity();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        await sendRequest('payments/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchActivity = async () => {
        const formData = new FormData();
        formData.append('releated_id', leadId);
        formData.append('type', type);
        formData.append('master_type', masterType);

        await sendRequest('payments/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setActivities(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const triggerAction = (fetchRecord = false) => {
        setInvoiceModal(false);
        if (fetchRecord) {
            fetchActivity()
        }
    }

    const viewInvoice = (invoiceId) => {
        setViewInvoice({
            modal: true,
            invoiceId: invoiceId
        })
    }

    const viewinvoiceAction = (fetchRecord = false, parentModalClose = true) => {
        if (fetchRecord) {
            fetchActivity();
        }

        if (parentModalClose) {
            setViewInvoice({
                modal: false,
                invoiceId: 0
            })
        }
    }

    const deleteRecord = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`payments/${id}`, 'DELETE', { id: id })
                    .then(response => {
                        if (response.status === true) {
                            fetchActivity();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });
    };


    return (
        <div className='invoice'>
            {
                !invoiceModal && (
                    <button onClick={() => setInvoiceModal(true)} className="btn btn-primary btn-sm">
                        Add Invoice
                    </button>
                )
            }

            {
                (meta.products && invoiceModal) && (
                    <InvoiceForm triggerAction={triggerAction} type={'leads'} releatedId={leadId} paymentMethods={meta.paymentMethods} products={meta.products} />
                )
            }


            {
                (activities.length > 0 && !invoiceModal) && activities.map((row, i) => {
                    return <div className={" card list-invoice my-2"} key={i}>
                        <div className="card-body">
                            <div className="d-flex justify-content-between pt-1">
                                <div className="d-flex gap gx-3">
                                    <button onClick={() => viewInvoice(row.public_id)} className='btn btn-link btn-sm py-0'>
                                        <div className="title">{row.payment_no}</div>
                                    </button>

                                    <span className={`badge text-bg-${row.status === "Complete" ? "success-soft"
                                        : row.status === "Pending" ? "warning-soft"
                                            : row.status === "InProgress" ? "secondary-soft"
                                                : "primary-soft"
                                        }`
                                    }>
                                        {row.status ? row.status : 'General'}
                                    </span>
                                </div>
                                <div className="">
                                    <div className="title">{row.dated}</div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex gap gx-3">
                                    <div className="amount-wrap">
                                        <span className="smaller">Total Amount</span>
                                        <div className="amount h6 mb-0">{currencySign}{row.total_amount}</div>
                                    </div>

                                    <div className="amount-wrap">
                                        <span className="smaller">Down Payment</span>
                                        <div className="amount h6 mb-0">{currencySign}{row.down_payment}</div>
                                    </div>

                                    <div className="amount-wrap">
                                        <span className="smaller">Due Balance</span>
                                        <div className="amount h6 mb-0">{currencySign}{row.due_amount}</div>
                                    </div>

                                    {
                                        row.refund_amount > 0 && (
                                            <div className="amount-wrap">
                                                <span className="smaller">Refund Amount</span>
                                                <div className="amount h6 mb-0 text-danger">{currencySign}{row.refund_amount}</div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="btns ViewDeleteBtn">
                                    <button onClick={() => viewInvoice(row.public_id)} className="btn btn-sm p-0">View</button>
                                    {
                                        row.status === 'Pending' && (
                                            <button onClick={() => deleteRecord(row.public_id)} className="btn btn-sm ms-1 p-0">Delete</button>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                row.internal_notes != '' && (
                                    <div className='my-1'>
                                        <span className='smaller'>- {row.internal_notes}</span>

                                    </div>
                                )
                            }

                        </div>
                    </div>
                })
            }

            {
                viewinvoice.modal && (
                    <InvoiceView viewinvoiceAction={viewinvoiceAction} params={viewinvoice} />
                )
            }
        </div>
    )

}

export default Invoice;
