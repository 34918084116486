import React, { useState } from 'react';
import { Row, Col, Card, Form, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Layout from '../../layout/fullpage';

import { Media, MediaGroup, Image, OverlineTitle, Logo } from '../../components';
import { sendRequest } from '../../utilities/api';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

const AuthToggle = (props) => {
    const navigate = useNavigate(); // Access navigation function
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [users] = useState(location.state?.users ?? []);
    const [staff_user_id] = useState(searchParams.get('id'));

    const handleSubmit = async (id) => {
        const submitData = new FormData();
        submitData.append('user_id',id);
        submitData.append('staff_user_id',staff_user_id);

        sendRequest('login/choice', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    localStorage.setItem('authToken', JSON.stringify(response.data));

                    localStorage.setItem('authAccounts',btoa(JSON.stringify(users)));
                    window.location.href = '/';
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });



    };

    return (
        <>
            <Layout title="Login" centered>
                <div className="container p-2 p-sm-4">
                    <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                        <Row className="g-0">
                            <Col lg={{ span: 6, offset: 3 }}>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title mb-1">How would you like to proceed?</h3>
                                        </div>
                                    </div>

                                    <ButtonToolbar className='justify-content-center' aria-label="Toolbar with button groups">
                                        {

                                            users.map((user, i) => {

                                                return (
                                                    <>


                                                        <ButtonGroup aria-label="Second group">
                                                            <Button onClick={() => handleSubmit(user.id)} variant="outline-primary">{user.type}</Button>
                                                        </ButtonGroup>
                                                        {
                                                            users.length > i + 1 && (
                                                                <ButtonGroup disabled className="ms-2 me-2" aria-label="Third group">
                                                                    <Button disabled variant="outline-info">OR</Button>
                                                                </ButtonGroup>
                                                            )
                                                        }

                                                        {/* //  <Button onClick={() => handleSubmit(user.user_id)} variant="outline-primary" className='m-2'>{user.type}</Button> */}

                                                    </>

                                                )

                                            })
                                        }
                                    </ButtonToolbar>

                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AuthToggle;
