import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Layout from '../../layout/fullpage';

import { Image, OverlineTitle, Logo } from '../../components';
import { sendRequest } from '../../utilities/api';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { GOOGLE_AUTH_CLIENT_ID } from "../../store/site";

const AuthLoginPage = (props) => {
    const navigate = useNavigate(); // Access navigation function

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);

        }

        sendRequest('portal-login', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    localStorage.setItem('portalAuthToken', JSON.stringify(response.data));
                    window.location.href = '/portal/dashboard';
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });



    };

    return (
        <>
            <Layout title="Portal Login" centered>
                <div className="container p-2 p-sm-4">
                    <div className="LoginLogo text-center mb-4">
                        {/* <Logo /> */}
                        <h2>
                            Client Portal
                        </h2>
                    </div>
                    <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh w-40 m-auto MaxWidthForm">
                        <Row className="g-0">
                            <Col lg={{ span: 12 }}>
                                <Card.Body className="d-flex flex-column justify-content-center">
                                    {/* <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title mb-1">Login to Account</h3>
                                            <p className="small">Please sign-in to your account and start the adventure.</p>
                                        </div>
                                    </div> */}
                                    <Form onSubmit={handleSubmit}>
                                        <Row className="gy-3">
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Email</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name='email' type="email" id="email" placeholder="Enter email" required />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="password">Password</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name='password' type="password" id="password" placeholder="Enter password" required />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col className="col-12">
                                                <div className="d-flex flex-wrap justify-content-end">
                                                    {/* <Form.Check
                                                        className="form-check-sm"
                                                        type="checkbox"
                                                        id="rememberMe"
                                                        label="Remember Me"
                                                    /> */}
                                                    {/* <Link to="/auths/auth-reset" className="small">Forgot Password?</Link> */}
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="d-grid mb-3">
                                                    <Button type="submit">Continue</Button>
                                                </div>
                                                {/* <div class="text-gray-500 text-center fw-semibold fs-6">Not a Member yet? <a class="link-primary" href="/auth/registration">Sign up</a></div> */}
                                            </Col>

                                            <Col className="col-12">
                                                <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
                                                    <GoogleLogin
                                                        onSuccess={credentialResponse => {

                                                            const userData = jwtDecode(credentialResponse.credential);

                                                            const submitData = new FormData();

                                                            submitData.append('email', userData.email);
                                                            submitData.append('aud', userData.aud);
                                                            submitData.append('social_id', btoa(userData.sub.toString()));
                                                            submitData.append('social_type', 'google');
                                                            submitData.append('target_table', 'client');

                                                            sendRequest('login', 'POST', submitData)
                                                                .then(response => {
                                                                    if (response.status === true) {
                                                                        // if (response.data.has_multiple == 1) {
                                                                        //     navigate('/auths/auth-toggle', { state: { users: response.data.users } });
                                                                        // } else {
                                                                        localStorage.setItem('portalAuthToken', JSON.stringify(response.data));
                                                                        window.location.href = '/portal/dashboard';
                                                                        // }
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.error('POST request error:', error);
                                                                });
                                                        }}
                                                        onError={() => {
                                                            console.log('Login Failed');
                                                        }}
                                                    />
                                                </GoogleOAuthProvider>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="my-3 text-center d-none">
                                        <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                    </div>
                                    <Row className="g-2 d-none">
                                        <Col xxl="6">
                                            <Button href="#auth" variant="outline-light" className="w-100">
                                                <Image src="/images/icon/d.png" alt="" className="icon" />
                                                <span className="fw-medium">Continue with Google</span>
                                            </Button>
                                        </Col>
                                        <Col xxl="6">
                                            <Button href="#auth" variant="outline-light" className="w-100">
                                                <Image src="/images/icon/b.png" alt="" className="icon" />
                                                <span className="fw-medium">Continue with Facebook</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-4 d-none">
                                        <p className="small">Don't have an account? <Link to="/auths/auth-register">Register</Link></p>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AuthLoginPage;
