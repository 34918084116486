import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Dropdown, Accordion, Tab, Nav } from 'react-bootstrap';
import { Image, Icon, Media, Select, ChatList, CustomDropdownMenu, CustomDropdownToggle, MediaGroup, MediaText, Message, ChatMedia } from '../../../components';
import { countries } from "../../../store/customData";

function EditableField({ formateValue = '', label, value, name, type, placeholder, required, onChange, data = {}, meta = {} }) {
	const [editMode, setEditMode] = useState(false);
	const [btnType, setBtnType] = useState('button');
	const [customFieldData, setCustomFieldData] = useState(data?.custom_fields);
	const [customField, setCustomField] = useState(meta.customFields?.custom_fields);
	const [oldValue, setOldValue] = useState({});

	const createUi = () => {

		return customField.map((elementSchema, elementIndex) => {
			const elementName = elementSchema.key;
			if (customField.hasOwnProperty(elementName) && !oldValue[elementName]) {
				setCustomFieldData((prevData) => ({
					...prevData,
					[elementName]: oldValue[elementName],
				}));
			}

			if (elementSchema.type === 'select') {
				return (
					<div className="col-md-6 mb-3" key={elementSchema.label}>
						<label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
						<Form.Select defaultValue={customFieldData[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key}
							id={elementSchema.key}>
							{elementSchema.options.map((row, i) => {
								return (
									<option
										key={i}
										value={`${row.option}`}
									>
										{row.option}
									</option>
								);
							})}

						</Form.Select>
					</div>
				);
			} else if (elementSchema.type === 'radio') {
				return (
					<div className="col-md-6 mb-3" key={elementSchema.label}>
						<label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
						<br />
						{elementSchema.options.map((row, i) => {
							return (
								<Form.Check
									key={i}
									inline
									type="radio"
									name={elementSchema.key}
									id={row.option}
									value={row.option}
									defaultChecked={customFieldData[elementSchema.key] === row.option}
									label={row.option}
									onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
								/>

							);
						})}

					</div>
				);
			} else if (elementSchema.type === 'checkbox') {
				return (
					<div className="col-md-6 mb-3" key={elementSchema.label}>
						<div className="col-md-6 mb-3" key={elementSchema.label}>
							<label className="form-label" htmlFor={elementSchema.key}>{elementSchema.label}</label>
							<br />
							{elementSchema.options.map((row, i) => {
								return (
									<Form.Check
										key={i}
										inline
										type="checkbox"
										name={elementSchema.key}
										value={row.option}
										id={row.option}
										defaultChecked={customFieldData[elementSchema.key] && customFieldData[elementSchema.key].includes(row.option)}
										label={row.option}
										onChange={(e) => handleChangeCustomField(elementSchema.type, e)}
									/>

								);
							})}
						</div>
					</div>
				);
			} else if (elementSchema.type === 'date') {
				return (
					<div className="col-md-6 mb-3" key={elementSchema.label}>
						<Form.Group className="form-group">
							<Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
							<div className="form-control-wrap">
								<Form.Control
									type="date"
									data-date=""
									data-date-format="DD MMMM YYYY"
									defaultValue={customFieldData[elementSchema.key] ? customFieldData[elementSchema.key].toString().split("/").reverse().join("-") : ''}
									//defaultValue={customFieldData[elementSchema.key]}
									onChange={(e) => handleChangeCustomField(elementSchema.type, e)} name={elementSchema.key} />
							</div>
						</Form.Group>
					</div>
				);
			} else {
				return (
					<div className="col-md-12 mb-2" key={elementSchema.label}>
						<Form.Group className="form-group d-flex flex-column">
							<Form.Label htmlFor={elementSchema.key}>{elementSchema.label}</Form.Label>
							<div className="form-control-wrap">
								<Form.Control defaultValue={customFieldData[elementSchema.key]} onChange={(e) => handleChangeCustomField(elementSchema.type, e)} type="text" name={elementSchema.key} />
							</div>
						</Form.Group>
					</div>
				);
			}

		})
	}
	const handleChangeCustomField = (type, e) => {
		const { name, value } = e.target;
		console.log(name, value, type);
		if (type === "checkbox") {
			if (customFieldData[name] && customFieldData[name].includes(value)) {
				setCustomFieldData((prevState) => ({
					...prevState,
					[name]: prevState[name].filter((color) => color !== value),
				}));
			} else {
				setCustomFieldData((prevState) => ({
					...prevState,
					[name]: [...(prevState[name] || []), value],
				}));
			}
		} else if (type === "date") {
			setCustomFieldData((prevState) => ({
				...prevState,
				[name]: value.toString().split("-").reverse().join("/"),
			}));
		} else {
			setCustomFieldData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}

		setBtnType('submit')
	};
	return (
		<Form.Group className="form-group">
			<Form.Label htmlFor={label}>{label}</Form.Label>
			{editMode ? (
				<div className="form-control-wrap" >
					<Row className="g-3" style={{ minWidth: '200px' }}>
						<Col md="12">
							{(name == 'group_id[]' && meta.groups) ?
								<Select removeItemButton name="group_id[]" multiple defaultValue={data.group_id} onChange={() => setBtnType('submit')} >
									<option value="">Select Group</option>
									{
										meta.groups.map((row, i) => {
											return <option key={i} value={row.id}>{row.title}</option>
										})
									}
								</Select>
								: name === 'coach_partner_id' ?
									<Select removeItemButton name="coach_partner_id" defaultValue={data.coach_partner_id} onChange={() => setBtnType('submit')} >
										<option value="">Select Owner</option>
										{
											meta?.coachPartners.map((row, i) => {
												return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
											})
										}
									</Select>
									: name === 'type' || name === 'record_type' ?
										<Select removeItemButton name={name} defaultValue={data[name]} onChange={() => setBtnType('submit')} >
											<option value="">Select {name}</option>
											{
												// console.log(meta)
												Object.entries(meta).map(([key, value]) => (
													<option key={key} value={key}>
														{value}
													</option>
												))
											}
										</Select>
										: name === 'country_code' ?
											<Form.Select defaultValue={data.country_code} onChange={(e) => { onChange(e); setBtnType('submit'); }} placeholder="Type" name="country_code">
												<option value="">Select Country</option>
												{
													countries.map((row, i) => {
														return <option key={i} value={row.code}>{row.label}</option>
													})
												}
											</Form.Select>
											:
											name === 'custom_fields' ?
												(meta.customFields && customField.length > 0) && (
													<Row className="g-0">
														{createUi()}

														<Form.Control as={'textarea'} className="form-control d-none" name="custom_fields" value={JSON.stringify(customFieldData)} />
													</Row>
												)
												:
												<>
													<Form.Control
														defaultValue={value}
														onChange={(e) => { onChange(e, label); setBtnType('submit'); }}
														type={type}
														name={name}
														placeholder={placeholder}
														required={required}
													/>
												</>
							}
							{/* </Col>
						<Col md="4" className="d-flex align-items-center"> */}
							<div className="ActionBtnEdit">
								<Button
									variant="none"
									type={btnType}
									className="btn-icon btn-submit mx-1 SaveButton">
									<Icon name="save-fill"></Icon>
								</Button>
								<Button
									type="button"
									variant="none"

									onClick={() => {
										setEditMode(false);
										setBtnType('button');
									}}
									className="btn-icon SaveButton">
									<Icon name="cross-circle"></Icon>
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			) : (
				<div className="form-control-wrap first-name-view">
					{
						name === 'group_id[]' ? data.group_title :
							name === 'custom_fields' ? '' :
								name === 'address' ?
									<a target="_blank" href={`http://maps.google.com/maps?q=${value}`}>
										{value}
									</a>
									:
									name === 'date_of_birth' ?
										formateValue
										:
										value
					}

					<Button
						type="button"
						onClick={() => {
							setEditMode(true);
							setBtnType('button');
						}} size="md" variant="zoom" className="btn-icon EditButton">
						<Icon name="pen"></Icon>
					</Button>
				</div>
			)}
		</Form.Group>
	);
}

export default EditableField;
