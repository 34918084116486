import React, { useState, useRef, useLayoutEffect } from 'react'
import Layout from '../layout/default';

import { Icon, Block, BlockTitle, BlockHead, BlockHeadBetween, BlockHeadContent, DatePicker, TimePicker } from '../components';

import { today, yesterday, currentMonth, monthList } from '../utilities';
import toTwelve from '../utilities/toTwelve'

import { Modal, Row, Col, Button, Card, Forml, Offcanvas, Form } from 'react-bootstrap'
import Choices from 'choices.js';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'



function renderEventContent(eventInfo) {
    const timeText = eventInfo.timeText,
        title = eventInfo.event._def.title,
        description = eventInfo.event._def.extendedProps.description,
        view = eventInfo.view.type,
        color = eventInfo.event._def.ui.classNames[0],
        textColor = eventInfo.event._def.extendedProps.textColor,
        duration = eventInfo.event._def.extendedProps.duration,
        name = eventInfo.event._def.extendedProps.lead_name;
    return (
        <>
            <div className={`${duration < 45 ? 'short_event' : ''}`} style={{ cursor: 'pointer', backgroundColor: color, borderColor: color, display: 'flex', width: '100%', color: textColor, margin: 0, padding: "0 5px", alignItems: 'center', height: '100%' }}>
                {timeText && (view === 'dayGridMonth') && <div className="fc-daygrid-event-dot asd"></div>}
                {timeText && <div className="fc-event-time" >{name} at {timeText}</div>}
                <div className="fc-event-title as"></div>

            </div>

        </>
    )
}

function EventCategory({ categories, value, ...props }) {
    const eventCategory = useRef(null);
    useLayoutEffect(() => {
        let choicesCat = new Choices(eventCategory.current, {
            silent: true,
            allowHTML: false,
            searchEnabled: true,
            placeholder: true,
            placeholderValue: null,
            searchPlaceholderValue: 'Search',
            shouldSort: false,
            choices: categories,
            callbackOnCreateTemplates: function (template) {
                return {
                    item: ({ classNames }, data) => {
                        return template(`
                        <div class="${classNames.item} ${data.highlighted
                                ? classNames.highlightedState
                                : classNames.itemSelectable
                            } ${data.placeholder ? classNames.placeholder : ''
                            }" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''
                            } ${data.disabled ? 'aria-disabled="true"' : ''}>
                            <span style="background-color: ${data.value};border-color: ${data.value}" class="fc-select-dot fc-${data.value}"></span> ${data.label}
                        </div>
                        `);
                    },
                    choice: ({ classNames }, data) => {
                        return template(`
                        <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                            }" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
                                ? 'data-choice-disabled aria-disabled="true"'
                                : 'data-choice-selectable'
                            } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                            }>
                            <span style="background-color: ${data.value};border-color: ${data.value}" class="fc-select-dot fc-${data.value}"></span> ${data.label}
                        </div>
                        `);
                    },
                }
            }
        });
        choicesCat.setChoiceByValue(value);
    })
    return (
        <Form.Select ref={eventCategory} {...props}></Form.Select>
    )
}

function AppCalendar(props) {
    // eslint-disable-next-line 
    const iframeRef = useRef(null);
    const modalForm = useRef(null);
    const selectEvent = []
    const formDefault = {
        opened: false
    }
    const [events, setEvents] = useState(props.eventData);
    const [selectedEvent, setSelectedEvent] = useState(selectEvent);
    const [previewModal, setPreviewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [formModal, setFormModal] = useState(formDefault);
    const [selectedDate, setSelectedDate] = useState({});
    const [calendarUrl, seCalendarUrl] = useState(props.calendarUrl ? props.calendarUrl : '');
    const [disableDay, setDiableDay] = useState(props.offDays != '' ? props.offDays.split(',').map(Number) : []);

    function closePreviewModal() {
        setPreviewModal(false);
    }

    function openDeleteModal() {
        setPreviewModal(false);
        setDeleteModal(true);
    }

    function closeDeleteModal() {
        setDeleteModal(false);
        setSelectedEvent(selectEvent);
    }

    function openAddModal() {
        const updateFormModal = {
            ...formModal,
            opened: true,
            modalTitle: "Add Event",
            action: 'add',
            eventTitle: '',
            eventStartTime: '',
            eventStartDate: '',
            eventEndTime: '',
            eventEndDate: '',
            eventDescription: '',
            eventCategory: '',
        }
        setFormModal(updateFormModal);
    }

    function openEditModal() {
        setPreviewModal(false);
        const updateFormModal = {
            ...formModal,
            opened: true,
            modalTitle: "Edit Event",
            action: 'edit',
            eventTitle: selectedEvent.title,
            eventStartTime: selectedEvent.startTime,
            eventStartDate: selectedEvent.startDate,
            eventEndTime: selectedEvent.endTime,
            eventEndDate: selectedEvent.endDate,
            eventDescription: selectedEvent.description,
            eventCategory: selectedEvent.className,
        }
        setFormModal(updateFormModal);
    }

    function closeFormModal() {
        const updateFormModal = { ...formModal, opened: false }
        setFormModal(updateFormModal);
    }

    const globalFcStyle = `
        .fc-list-event-title {
          position:relative;
        }
    `

    function handleEventClick(eventInfo) {
        const id = eventInfo.event._def.extendedProps.lead_id;
        props.navigate('/crm-manage/leads/dialer/' + id + '?type=event');
        return;
        let title = eventInfo.event._def.title;
        let description = eventInfo.event._def.extendedProps.description;
        let start = eventInfo.event._instance.range.start;
        let startDate = start.getFullYear() + '-' + String(start.getMonth() + 1).padStart(2, '0') + '-' + String(start.getDate()).padStart(2, '0');
        let startDateEng = String(start.getDate()).padStart(2, '0') + ' ' + monthList[start.getMonth()] + ' ' + start.getFullYear();
        let startTime = start.toUTCString().split(' '); startTime = startTime[startTime.length - 2]; startTime = (startTime === '00:00:00') ? '' : startTime;
        let end = eventInfo.event._instance.range.end;
        let endDate = end.getFullYear() + '-' + String(end.getMonth() + 1).padStart(2, '0') + '-' + String(end.getDate()).padStart(2, '0');
        let endDateEng = String(end.getDate()).padStart(2, '0') + ' ' + monthList[end.getMonth()] + ' ' + end.getFullYear();
        let endTime = end.toUTCString().split(' '); endTime = endTime[endTime.length - 2]; endTime = (endTime === '00:00:00') ? '' : endTime;
        let className = eventInfo.event._def.ui.classNames[0];
        let eventId = eventInfo.event._def.publicId;

        const currentEvent = {
            ...selectedEvent,
            id: eventId,
            title: title,
            description: description,
            className: className,
            startTime: startTime,
            startDate: startDate,
            startDateEng: startDateEng,
            endTime: endTime,
            endDate: endDate,
            endDateEng: endDateEng,
        }
        console.log(currentEvent, 'handleEventClick')
        setSelectedEvent(currentEvent);
        setPreviewModal(true)
    }

    function handleDeleteEvent() {
        const updatedEvents = events.filter((item) => item.id !== selectedEvent.id)
        setEvents(updatedEvents);
        setDeleteModal(false);
        setSelectedEvent(selectEvent)
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        let newTitle = modalForm.current.querySelector('#eventTitle').value;
        let neWStartDate = modalForm.current.querySelector('#eventStartDate').value;
        let newStartTime = modalForm.current.querySelector('#eventStartTime').value;
        let neWEndDate = modalForm.current.querySelector('#eventEndDate').value;
        let newEndTime = modalForm.current.querySelector('#eventEndTime').value;
        let newDescription = modalForm.current.querySelector('#eventDescription').value;
        let neWclassName = modalForm.current.querySelector('#eventCategory').value;
        let newEvent = {
            id: selectedEvent.id || 'added-event-id-' + Math.floor(Math.random() * 9999999),
            title: newTitle,
            start: neWStartDate + (newStartTime ? 'T' + newStartTime + 'Z' : ''),
            end: neWEndDate + (newEndTime ? 'T' + newEndTime + 'Z' : ''),
            className: `${neWclassName}`,
            color: `${neWclassName}`,
            description: newDescription
        }
        setEvents([...events, newEvent]);
    }

    function handleEditEvent(e) {
        const updatedEvents = events.filter((item) => item.id !== selectedEvent.id)
        setEvents(updatedEvents);
        closeFormModal();
    }

    function handleAddEvent(e) {
        closeFormModal();
    }
    const handleDateClick = (arg) => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight

        const argDate = new Date(arg.date);
        argDate.setHours(0, 0, 0, 0);
        const year = argDate.getFullYear();
        const month = String(argDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
        const day = String(argDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;


        if (disableDay.includes(arg.date.getDay()) || argDate < currentDate) {
            console.log("reasda sasd  ", arg.date, currentDate)
            return;
        }
        openAddModal();
        setSelectedDate(formattedDate);
    };

    const dayCellClassNames = (arg) => {
        const classNames = [];
        if (disableDay.includes(arg.date.getDay())) {
            classNames.push('disabled-cell');
        }

        if (!arg.date || !(arg.date instanceof Date)) {
            throw new Error('Invalid date object');
        }

        let date = arg.date.getFullYear() +
            '-' +
            ('0' + (arg.date.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + arg.date.getDate()).slice(-2);

        if (selectedDate && date === selectedDate) {
            classNames.push('selected-cell');
        }

        return classNames.join(' ');
    };

    return (
        <>
            {/* <div className='text-end my-2'>
                <Button size='sm' variant="primary" action="add" onClick={openAddModal}>
                    <Icon name="plus"></Icon><span>Add Event</span>
                </Button>
            </div> */}
            <style>{globalFcStyle}</style>
            <FullCalendar
                events={events}
                plugins={[dayGridPlugin, timeGridPlugin, bootstrapPlugin, listPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                timeZone='UTC'
                themeSystem='bootstrap'
                headerToolbar={{
                    left: 'title prev,next',
                    center: null,
                    right: 'today dayGridMonth,timeGridWeek,timeGridDay'
                }}
                height={800}
                contentHeight={780}
                aspectRatio={3}
                editable={false}
                views={{
                    dayGridMonth: {
                        dayMaxEventRows: 5,
                    }
                }}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                dayCellClassNames={dayCellClassNames}
            />


            <Modal show={previewModal} onHide={closePreviewModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="event-title" as="h4">{selectedEvent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className="g-3">
                            <Col xs="6">
                                <h6 className="overline-title">Start Time</h6>
                                <p className="event-start small">{selectedEvent.startDateEng} {(selectedEvent.startTime && ` - ${toTwelve(selectedEvent.startTime)}`)}</p>
                            </Col>
                            <Col xs="6">
                                <h6 className="overline-title">End Time</h6>
                                <p className="event-end small">{selectedEvent.endDateEng} {(selectedEvent.endTime && ` - ${toTwelve(selectedEvent.endTime)}`)}</p>
                            </Col>
                            <Col xs="12">
                                <h6 className="overline-title">Event Details</h6>
                                <p className="event-description small">{selectedEvent.description}</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={openDeleteModal}>Delete</Button>
                    <Button variant="primary" onClick={openEditModal}>Edit Event</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteModal} onHide={closeDeleteModal}>
                <div className="modal-content">
                    <div className="modal-body p-5 text-center">
                        <div className="media media-rounded media-xxl media-middle mx-auto text-bg-danger mb-4"><em className="icon ni ni-cross"></em></div>
                        <h3>Are You Sure ?</h3>
                        <p className="small">This event data will be removed permanently.</p>
                        <ul className="d-flex gap g-3 justify-content-center pt-1">
                            <li><Button variant="success" onClick={handleDeleteEvent}>Yes Delete it!</Button></li>
                            <li><Button variant="danger" className="btn-soft" onClick={closeDeleteModal}>Cancel</Button></li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close position-absolute top-0 end-0 p-3" onClick={closeDeleteModal} aria-label="Close"></button>
                </div>
            </Modal>

            <Offcanvas className="w-75" placement="end" show={formModal.opened} onHide={() => closeFormModal()}>
                <Offcanvas.Header className="px-2 py-2" closeButton />
                <Offcanvas.Body className="p-0">
                    <Row>
                        <Col md={12}>
                            <iframe
                                ref={iframeRef}
                                title="embedded-link"
                                width="100%"
                                height={"650"}
                                src={`${calendarUrl}?type=selected_date&selectedDate=${selectedDate}`}
                                allowFullScreen
                            ></iframe>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
            {/* <Modal show={formModal.opened} onHide={closeFormModal} size="lg">
                <Form ref={modalForm} onSubmit={handleFormSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className="event-title" as="h4">{formModal.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-gs">
                            <Col xs="12">
                                <Form.Group>
                                    <Form.Label htmlFor="eventTitle">Event Title</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="eventTitle" type="text" defaultValue={formModal.eventTitle} placeholder="Event Title" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col sm="6">
                                <Form.Group>
                                    <Form.Label htmlFor="eventStartDate" >Event Starts</Form.Label>
                                    <Row className="g-2">
                                        <Col xs="7">
                                            <div className="form-control-wrap">
                                                <DatePicker id="eventStartDate" value={formModal.eventStartDate} placeholder="yyyy-mm-dd" />
                                            </div>
                                        </Col>
                                        <Col xs="5">
                                            <div className="form-control-wrap">
                                                <TimePicker format={12} id="eventStartTime" value={formModal.eventStartTime} placeholder="hh:mm" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col sm="6">
                                <Form.Group>
                                    <Form.Label htmlFor="eventEndDate">Event Ends</Form.Label>
                                    <Row className="g-2">
                                        <Col xs="7">
                                            <div className="form-control-wrap">
                                                <DatePicker id="eventEndDate" value={formModal.eventEndDate} placeholder="yyyy-mm-dd" />
                                            </div>
                                        </Col>
                                        <Col xs="5">
                                            <div className="form-control-wrap">
                                                <TimePicker format={12} id="eventEndTime" value={formModal.eventEndTime} placeholder="hh:mm" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xs="12">
                                <Form.Group>
                                    <Form.Label htmlFor="eventDescription">Event Description</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="eventDescription" as="textarea" placeholder="Event Description" defaultValue={formModal.eventDescription} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs="12">
                                <Form.Group className="fc-category-select">
                                    <Form.Label htmlFor="eventCategory">Event Category</Form.Label>
                                    <div className="form-control-wrap">
                                        <EventCategory categories={props.eventCategory} id="eventCategory" value={formModal.eventCategory} placeholder="Select Category" />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={closeFormModal}>Discard</Button>
                        {formModal.action === 'add' && <Button variant="primary" type="submit" onClick={handleAddEvent}>Add Event</Button>}
                        {formModal.action === 'edit' && <Button variant="primary" type="submit" onClick={handleEditEvent}>Update Event</Button>}
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </>
    )
}

export default AppCalendar