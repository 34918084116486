import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function InvoiceNav(props) {
    const { hasPermission } = useAuth();
    return (
        <div>
            <div className='btns'>
                {
                    hasPermission('viewPaymentLog') && (
                        <Link className={`btn btn-sm btn-soft me-2 mb-3 ${props.from == '1' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/payment-manage'}>Payment Log</Link>
                    )
                }
                
                {
                    hasPermission('viewCheckoutLinks') && (
                        <Link className={`btn btn-sm btn-soft me-2 mb-3 ${props.from == '2' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/checkout-links-manage/lists'}>Checkout Link</Link>
                    )
                }

                {
                    hasPermission('viewProducts') && (
                        <Link className={`btn btn-sm btn-soft me-2 mb-3 ${props.from == '3' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/products-manage/lists'}>Products</Link>
                    )
                }

                {
                    hasPermission('viewPayment') && (
                        <Link className={`btn btn-sm btn-soft me-2 mb-3 ${props.from == '4' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/setting-manage/payment/lists'}>Payment Gateway</Link>
                    )
                }

                {
                    hasPermission('viewPayment') && (
                        <Link className={`btn btn-sm btn-soft me-2 mb-3 ${props.from == '5' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/payment-manage/settings'}>Setting</Link>
                    )
                }
            </div>
        </div>
    );
}

export default InvoiceNav;
