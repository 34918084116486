import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function JobsNav(props) {
    const { hasPermission } = useAuth();
    return (
        <div>
            <div className='btns'>
                {
                    hasPermission('viewJobs') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '1' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/jobs/lists'}>Jobs</Link>
                    )
                }
                {
                    hasPermission('viewCategory') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '2' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/tags-manage/lists'}>Categories & Services</Link>
                    )
                }
                {
                    hasPermission('viewJobCustomFields') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '3' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/jobs/custom-fields'}>Custom Fields</Link>
                    )
                }
                {
                    hasPermission('viewJobs') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '4' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/jobs/export'}>Exported Items</Link>
                    )
                }



            </div>
        </div>
    );
}

export default JobsNav;
