import { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import '../../../assets/scss/libs/editors/tinymce.scss';
import { sendRequest } from '../../../utilities/api';
import { uInfo } from '../../../utilities';

function Tinymce({ leadToken = false, userToken = true, extraToken = [], height, width, toolbar, menubar, inline, initialValue, textareaName, ...props }) {
    const editorRef = useRef(null);


    // Define a variable to store the fetched options
    let cachedOptions = [];
    let leadsOptions = { 'Default': [], 'Custom': [] };

    // Fetch the options only if they haven't been fetched yet
    function fetchOptionsIfNeeded() {
        if (cachedOptions.length === 0 || leadsOptions['Default'].length === 0) {
            sendRequest(`dropdown/tokens/${uInfo().id}`, 'GET', [])
                .then(response => {
                    if (response.status === true) {
                        cachedOptions = response.data.records.users_token;
                        leadsOptions = {
                            'Default': response.data.records.default_fields,
                            'Custom': response.data.records.custom_fields,
                        };

                    }
                })
                .catch(error => {
                    console.error('API request error:', error);
                });
        }
    }

    return (
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={initialValue}
            init={{
                selector: 'textarea',
                plugins: "wordcount image link custom_dropdown code",
                height: height || 300,
                width: width || '',
                skin: false,
                branding: false,
                image_title: true,
                toolbar: toolbar ? "custom_dropdown_button leads_dropdown_button extra_dropdown_button | link code image | undo redo | styles | bold italic underline | align bullist numlist" : false,
                menubar: menubar,
                inline: inline,
                force_hex_color: 'always',
                fix_nesting : true,
                fix_table_elements : true,
                setup: (editor) => {
                    editor.addCommand("handleCustomDropdown", function (ui, value) {
                        // Append the selected value to the editor
                        editor.insertContent(value);
                    });

                    if (userToken) {
                        editor.ui.registry.addMenuButton('custom_dropdown_button', {
                            text: 'User Tokens',
                            fetch: async (callback) => {
                                fetchOptionsIfNeeded();
                                const items = cachedOptions.map(({ key, label }) => {
                                    return {
                                        type: "menuitem",
                                        text: label, // Display the "key" in the menu
                                        onAction: function () {
                                            editor.execCommand("handleCustomDropdown", false, ` {{${key}}} `); // Use the "value" as the content to add to the editor
                                        },
                                    };
                                });
                                callback(items);
                            },
                        });
                    }

                    if (leadToken) {
                        editor.ui.registry.addMenuButton('leads_dropdown_button', {
                            text: 'Leads Tokens',
                            fetch: async (callback) => {
                                // Fetch the options if needed
                                fetchOptionsIfNeeded();

                                // Organize options into groups
                                const groupedOptions = leadsOptions;

                                // Generate menu items with option groups
                                const items = Object.keys(groupedOptions).map(groupName => {
                                    const group = groupedOptions[groupName];
                                    const submenuItems = group.map(({ key, label }) => {
                                        return {
                                            type: "menuitem",
                                            text: label,
                                            onAction: function () {
                                                editor.execCommand("handleCustomDropdown", false, `{${key}} `);
                                            },
                                        };
                                    });
                                    return {
                                        type: "nestedmenuitem",
                                        text: groupName,
                                        getSubmenuItems: function () {
                                            return submenuItems;
                                        }
                                    };
                                });
                                callback(items);
                            },

                        });
                    }

                    if (extraToken.length > 0) {
                        editor.ui.registry.addMenuButton('custom_dropdown_button', {
                            text: 'Extra Tokens',
                            fetch: async (callback) => {
                                fetchOptionsIfNeeded();
                                const items = extraToken.map(({ key, label }) => {
                                    return {
                                        type: "menuitem",
                                        text: label, // Display the "key" in the menu
                                        onAction: function () {
                                            editor.execCommand("handleCustomDropdown", false, ` {${key}} `); // Use the "value" as the content to add to the editor
                                        },
                                    };
                                });
                                callback(items);
                            },
                        });
                    }

                },
            }}
            apiKey='636kdb4thn0426hbe5l95davsc3xnkoe4euqlvcomh0ejysa'
            textareaName={textareaName}

        />
    )
}

export default Tinymce;
