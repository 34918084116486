import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, ProgressBar, Alert, Table } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { sendRequest } from "../../utilities/api";
import { countries } from "../../store/customData";
import Swal from "sweetalert2";

function JobImport(props) {
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [tab, setTab] = useState("import");
    const [records, setRecords] = useState([]);
    const [columns, setColumns] = useState([]);
    const [file_id, setFileId] = useState("");
    const [custom_fields, setCustomFields] = useState([]);
    const [default_fields, setDefaultFields] = useState([]);
    const [fields, setFields] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [imports, setImports] = useState([]);
    const [activeMasterId, setActiveMasterId] = useState('MA==');
    const [activeStatusId, setActiveStatusId] = useState(0);
    const [listsMileston, setListsMileston] = useState([]);

    useEffect(() => {
        fetchMeta();
    }, []);

    useEffect(() => {
        switch (tab) {
            case 'process':
                getImport(1);
                break;
            case 'failed':
                getImport(0);
                break;
            default:
                break;
        }
    }, [tab]);


    const fetchMeta = () => {
        const formData = new FormData();
        formData.set('type', 'default')
        sendRequest('jobs/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
                setLoading(true);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
                setLoading(true);
            });
    }

    const getImport = (type) => {
        sendRequest(`jobs/imports/${type}`, 'GET', {}).then(response => {
            if (response.status === true) {
                setImports(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleColumnSubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();

        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('csv_data_file_id', file_id);

        sendRequest('jobs/map/column', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                setFormLoading(false);
            })
            .catch(error => {
                setFormLoading(false);
                console.error('POST request error:', error);
            });

    }


    const handleSubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }


        sendRequest('jobs/import/process', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setRecords(response.data.records);
                    setColumns(response.data.columns);
                    setFileId(response.data.csv_data_file);
                    setCustomFields(parsecustomfields(response.data.fields.custom_fields));
                    setDefaultFields(parsecustomfields(response.data.fields.default_fields));

                    let concat = parsecustomfields(response.data.fields.default_fields).concat(parsecustomfields(response.data.fields.custom_fields));
                    let result = concat.map(({ value }) => value)
                    setFields(result);

                }
                setFormLoading(false);

            })
            .catch(error => {
                setFormLoading(false);
                console.error('POST request error:', error);
            });
    };

    const steps = ['PREPARE & UPLOAD', 'MAP YOUR DATA', 'IMPORT'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const parsecustomfields = (data) => {
        return data.map((customfield) => {
            return { label: customfield.label, value: customfield.key };
        });
    };


    const importData = () => {
        setFormLoading(true);
        const formData = new FormData();
        formData.append('csv_data_file_id', file_id);
        sendRequest('jobs/data/import', 'POST', formData)
            .then(response => {
                if (response.status === true) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    Swal.fire({
                        title: response.message,
                        icon: 'success',
                    });
                }
                setFormLoading(false);
            })
            .catch(error => {
                setFormLoading(false);
                console.error('POST request error:', error);
            });
    }

    const deleteImport = (public_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`jobs/import/${public_id}`, 'Delete', {})
                    .then(response => {
                        if (response.status === true) {
                            const updatedItems = imports.filter(item => item.public_id !== public_id);
                            setImports(updatedItems);
                        }
                    })
                    .catch(error => {
                        console.error('POST request error:', error);
                    });

            }
        })
    }

    const getSubPipeline = async (pipeline_id) => {
        if(pipeline_id == ''){
          setListsMileston([]);
          setActiveMasterId('MA==');
          setActiveStatusId(0);
        }
          const formData = new FormData();
          await sendRequest(`pipelines/${pipeline_id}`, 'POST', formData).then(response => {
              if (response.status === true) {
                  setListsMileston(response.data.lists);
                  setActiveMasterId(pipeline_id);
                  setActiveStatusId(response.data.active_status_id);
              }else{
                  setListsMileston([]);
                  setActiveMasterId('MA==');
                  setActiveStatusId(0);
              }
            
  
          })
              .catch(error => {
                  console.error('POST request error:', error);
              });
      }
    return (
        <Layout title="Job" content="container">
            <div className="Leads-import">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Jobs</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/jobs/lists">Jobs</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Import</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <Tab.Container onSelect={(e) => setTab(e)} id="custom-tabs-example" defaultActiveKey="import">
                                        <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="import">Import</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link eventKey="process">Process Queue</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link eventKey="failed">Failed Queue</Nav.Link>
                                            </Nav.Item>

                                        </Nav>



                                        {
                                            !loading && (
                                                <div className="d-flex justify-content-center my-5">
                                                    <Spinner animation="border" variant="secondary"></Spinner>
                                                </div>
                                            )
                                        }



                                        <Tab.Content>
                                            <Tab.Pane eventKey="import">
                                                <div className="progress-container">
                                                    {steps.map((step, index) => (
                                                        <div
                                                            key={step}
                                                            className={`step ${activeStep === index ? 'active' : ''}`}
                                                        >
                                                            <span className="step-count">{index + 1}</span>
                                                            <p className="step-name">{step}</p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {activeStep === steps.length && (
                                                    <div>
                                                        <p className="text-center">Thank you for submitting!</p>

                                                        <Button
                                                            variant="secondary"
                                                            onClick={() => setActiveStep(0)}
                                                            disabled={activeStep === 0}
                                                        >
                                                            Back
                                                        </Button>

                                                    </div>
                                                )}

                                                {
                                                    activeStep == 0 && (
                                                        <div className="bio-block">
                                                            <Form onSubmit={handleSubmit}>
                                                                <Row className="g-3">
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="category_id">Category</Form.Label>
                                                                            <div className="form-control-wrap">


                                                                                <Select removeItemButton name="category_id" >
                                                                                    <option value="">Select Category</option>
                                                                                    {
                                                                                        meta.categories && meta.categories.map((row, i) => {
                                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                                        })
                                                                                    }
                                                                                </Select>


                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="service_id">Service</Form.Label>
                                                                            <div className="form-control-wrap">


                                                                                <Select removeItemButton name="service_id" >
                                                                                    <option value="">Select Service</option>
                                                                                    {
                                                                                        meta.services && meta.services.map((row, i) => {
                                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                                        })
                                                                                    }
                                                                                </Select>


                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="tag_id">Tags</Form.Label>
                                                                            <div className="form-control-wrap">


                                                                                <Select removeItemButton name="tag_id" >
                                                                                    <option value="">Select Tag</option>
                                                                                    {
                                                                                        meta.tags && meta.tags.map((row, i) => {
                                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                                        })
                                                                                    }
                                                                                </Select>


                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="country_code">Pipeline</Form.Label>
                                                                            <div className="form-control-wrap">
                                                                                <Form.Select defaultValue={activeMasterId} onChange={(e) => { getSubPipeline(e.target.value); }} placeholder="Type" name="pipeline_id">
                                                                                    <option value="">Select Pipeline</option>
                                                                                    {meta.masterPipelines.map((row, i) => {
                                                                                        return (
                                                                                            <option key={i} value={row.id}>{row.title}</option>
                                                                                        )
                                                                                    }
                                                                                    )}
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="country_code">Milestone</Form.Label>
                                                                            <div className="form-control-wrap">
                                                                                <Form.Select defaultValue={activeStatusId}  placeholder="Type" name="status_id">
                                                                                    <option value="0">Select Milestone</option>
                                                                                {
                                                                                    listsMileston.length > 0 && listsMileston.map((row, i) => {
                                                                                            return (
                                                                                                <>
                                                                                                <optgroup label={row.title}>
                                                                                                {
                                                                                                    row.pipeline_status.length > 0 && row.pipeline_status.map((status, k) => {
                                                                                                        return (
                                                                                                            <option key={k} value={status.id}>{status.title}</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                </optgroup>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <Form.Group className="form-group">
                                                                            <Form.Label htmlFor="first_name">Select File (csv) First row of .csv file must include header</Form.Label>
                                                                            <Form.Control accept=".csv" name="file" type="file" />
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md="12">
                                                                        <Alert className="d-flex mb-0" variant="info">
                                                                            <p><b>Important:</b> Make sure the date format for any column is MM/DD/YYYY</p>
                                                                        </Alert>
                                                                    </Col>


                                                                    <Col md="6">
                                                                        <Button variant="primary" className="btn-sm" type="submit" disabled={formloading}>
                                                                            {
                                                                                formloading && (
                                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                                )
                                                                            }
                                                                            <span className="ms-1">Submit File</span>
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form>


                                                        </div>
                                                    )
                                                }

                                                {
                                                    activeStep == 1 && (
                                                        <div className="bio-block">
                                                            <Form onSubmit={handleColumnSubmit}>
                                                                <Table responsive className="small">
                                                                    <thead>
                                                                        {fields && records[0].map((row, index) => {
                                                                            let value = typeof row === 'string' ? row.replace(/ /g, "_").toLowerCase() : row;
                                                                            const found = fields.find(element => {
                                                                                return element.toLowerCase() === value;
                                                                            });
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{row}</td>
                                                                                    <td>{records[1][index]}</td>
                                                                                    <td key={index}>
                                                                                        <Form.Select value={found} name={`f${index}`}  >
                                                                                            <option value={''}>Map Column</option>
                                                                                            <optgroup label="Default Fields">
                                                                                                {default_fields.map(
                                                                                                    (column, c) => {
                                                                                                        return (
                                                                                                            <option
                                                                                                                key={column.value}
                                                                                                                value={column.value}
                                                                                                            >
                                                                                                                {column.label}
                                                                                                            </option>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </optgroup>
                                                                                            <optgroup label="Custom Fields">
                                                                                                {custom_fields.map(
                                                                                                    (column, c) => {
                                                                                                        return (
                                                                                                            <option
                                                                                                                key={column.value}
                                                                                                                value={column.value}
                                                                                                            >
                                                                                                                {column.label}
                                                                                                            </option>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </optgroup>
                                                                                        </Form.Select>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                        }
                                                                    </thead>
                                                                </Table>
                                                                <Button variant="primary" type="submit" disabled={formloading}>
                                                                    {
                                                                        formloading && (
                                                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                        )
                                                                    }
                                                                    <span className="ms-1">Map Column</span>
                                                                </Button>
                                                            </Form>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    activeStep == 2 && (
                                                        <Button variant="primary" onClick={importData}>
                                                            Import
                                                        </Button>
                                                    )
                                                }



                                                <div className="d-flex justify-content-between d-none">
                                                    <Button
                                                        variant="secondary"
                                                        onClick={handleBack}
                                                        disabled={activeStep === 0}
                                                    >
                                                        Back
                                                    </Button>
                                                    {activeStep === steps.length - 1 ? (
                                                        <Button variant="primary" className="btn-sm" onClick={handleSubmit}>
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button variant="primary" className="btn-sm" onClick={handleNext}>
                                                            Next
                                                        </Button>
                                                    )}
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="process">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Category</th>
                                                            <th>Date Submission</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            imports.map((row, i) => {
                                                                return <tr key={i}>
                                                                    <td>{row.id}</td>
                                                                    <td>{row.group}</td>
                                                                    <td>{row.dated}</td>
                                                                    <td>
                                                                        <Button
                                                                            variant="danger"
                                                                            size="sm"
                                                                            onClick={() => deleteImport(row.public_id)}
                                                                        >
                                                                            <Icon name={'trash'} />
                                                                        </Button>
                                                                    </td>


                                                                </tr>

                                                            })
                                                        }
                                                    </tbody>

                                                </Table>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="failed">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Category</th>
                                                            <th>Date Submission</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            imports.map((row, i) => {
                                                                return <tr key={i}>
                                                                    <td>{row.id}</td>
                                                                    <td>{row.group}</td>
                                                                    <td>{row.dated}</td>
                                                                    <td>
                                                                        <Button
                                                                            variant="danger"
                                                                            size="sm"
                                                                            onClick={() => deleteImport(row.public_id)}
                                                                        >
                                                                            <Icon name={'trash'} />
                                                                        </Button>
                                                                    </td>


                                                                </tr>

                                                            })
                                                        }
                                                    </tbody>

                                                </Table>
                                            </Tab.Pane>

                                        </Tab.Content>

                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        )
                    }
                </Block>
            </div>
        </Layout >
    )
}

export default JobImport;