import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Modal, Nav, Row, Tab, Table } from 'react-bootstrap';
import { Icon, Select } from '../../components';
import Swal from 'sweetalert2';
import { sendRequest } from '../../utilities/api';
import InvoiceForm from "./Create"
import logo from "../../assets/images/default.png"
import { NumberFormat, currencySign } from '../../utilities';

const InvoiceDetails = (props) => {
	const [params] = useState(props.params);
	const [data, setData] = useState({});
	const [modal, setModal] = useState(false);
	const [action, setAction] = useState(props.params.history ? { type: 'Receipt', data: props.params.history } : {});
	const [refundType, setRefundType] = useState('full');
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await fetchRecordById();
		};
		fetchData();
	}, []);

	const fetchRecordById = async () => {
		await sendRequest(`payments/${params.invoiceId}`, 'POST').then(response => {
			if (response.status === true) {
				setData(response.data);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const triggerAction = () => {
		console.log(action)
		if (action.type === 'Receipt' && !props.params.history) {
			closeModal();
		} else {
			props.viewinvoiceAction();
		}
	}

	const openModal = (type, data = {}) => {
		setAction({
			type: type,
			data: data,
		});
		setModal(true);

	}

	const closeModal = (fetchRecord = false) => {
		if (fetchRecord) {
			fetchRecordById();
			props.viewinvoiceAction(true, false);
		}
		setAction({});
		setModal(false);

	}

	const changePaymentStatus = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		await sendRequest(`payments/${params.invoiceId}/history/update/${action.data.public_id}`, 'POST', submitData).then(response => {
			if (response.status === true) {
				closeModal(true);
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const refundPayment = async (event) => {
		setLoader(true);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		await sendRequest(`payments/${params.invoiceId}/history/refund/${action.data.public_id}`, 'POST', submitData).then(response => {
			if (response.status === true) {
				closeModal(true);
			}
			setLoader(false);
		})
			.catch(error => {
				setLoader(false);
				console.error('POST request error:', error);
			});
	}


	const handlePrint = () => {

		const elementToPrint = document.getElementById('pdfContent');

		if (elementToPrint) {
			const printWindow = window.open('', '');
			// var printWindow = window.open('', 'PRINT', 'height=1200,width=600');
			printWindow.document.write('<html><head><title>Print Invoice</title>');
			printWindow.document.write('<link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">');
			printWindow.document.write('<style>');
			printWindow.document.write(`
				body {
				}

				table {
				}
			`);
			printWindow.document.write('</style></head><body>');
			printWindow.document.write(elementToPrint.outerHTML);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			setTimeout(() => {
				printWindow.print();
			}, 100);
		} else {
			console.error("Element with ID 'pdfContent' not found.");
		}
	}
	return (
		<div className='View'>
			<Modal

				className='PaymenyView'
				size={'lg'}
				show={params.modal}
				onHide={() => triggerAction()}>
				<div className='mx-2  my-1 text-end'>
					{
						(action.type === 'Receipt' && action.data.status != 'Refund') && (
							<button className='btn btn-sm btn-secondary' onClick={handlePrint}>Print</button>
						)
					}
					<button className='btn btn-sm btn-info ms-2' onClick={() => triggerAction()}>Close</button>
				</div>
				<div id="pdfContent">
					<Modal.Header className='p-0'>

						<header
							className="p-2 w-100"
							style={{
								backgroundColor: data.list?.domain?.other_settings?.header_bg_color,
								color: data.list?.domain?.other_settings?.header_text_color
							}}
						>
							<Row className='align-items-center'>
								<Col lg={8}>
									<Image
										src={data.list?.domain?.other_settings?.header_logo}
										alt={data.list?.domain?.name}
										className="img-responsive"
									/>
								</Col>
								<Col lg={4}>
									<p className='mb-0 h4 text-end' style={{ color: data.list?.domain?.other_settings?.header_text_color }}>
										<b>#</b>{data.list?.payment_no}
									</p>
									<p className='mb-0 h6 text-end' style={{ color: data.list?.domain?.other_settings?.header_text_color }}>
										{data.list?.dated}
									</p>
								</Col>
							</Row>
						</header>
					</Modal.Header>
					<Modal.Body className='p-0'>
						<Col lg={12} >
							<div className="receipt-header">

							</div>
							<div className='px-3'>
								<div className="my-2 ms-2">
									<Row>
										<Col xs={8} sm={8} md={8}>
											<div className="">
												<span className="h5"><b>Bill To</b></span>
												<p className='m-0'>{data.record?.first_name} {data.record?.last_name}</p>
												<p>{data.record?.email}</p>
											</div>
										</Col>
										<Col lg={4} className='d-flex align-items-center justify-content-end'>
											<div className="amount-wrap d-flex">
												<span className="h4">Total:&nbsp;</span>
												<div className="h4">{currencySign}{NumberFormat(data.list?.total_amount)}</div>
											</div>
										</Col>

									</Row>
								</div>
								<div>
									<Table size='sm' className='table-striped table-bordered'>
										<thead>
											<tr>
												<th>Items</th>
												<th>Quantity</th>
												<th>Rate</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{
												data.list?.meta_data.map((row, i) => {
													return (
														<tr key={i}>
															<td>{row.name}</td>
															<td>{row.quantity}</td>
															<td className='text-end'>{currencySign}{NumberFormat(row.price)}</td>
															<td className='text-end'>{currencySign}{NumberFormat(row.price * row.quantity)}</td>

														</tr>
													)
												})
											}
											<tr className="border-top-0 border-bottom-0">
												<td className="border-0" colSpan={2}></td>
												<td className="border-width-0 text-end" >Gross </td>
												<td className="text-end" >{currencySign}{NumberFormat(data.list?.total_amount)} </td>
											</tr>
											<tr className="border-top-0 border-bottom-0">
												<td className="border-0 bs-table-bg-type" colSpan={2}></td>
												<td className="border-width-0 text-end" >Payment made </td>
												<td className='text-end'>{currencySign}{NumberFormat(data.list?.payment_made)} </td>
											</tr>
											<tr className="border-top-0 border-bottom-0">
												<td className="border-0" colSpan={2}></td>
												<td className="border-width-0 text-end" >Refund</td>
												<td className='text-end'>{currencySign}{NumberFormat(data.list?.refund_amount)} </td>
											</tr>
											<tr className="border-top-0 border-bottom-0">
												<td className="border-0 bs-table-bg-type" colSpan={2}></td>
												<td className="border-width-0 text-end" >Balance</td>
												<td className="text-end" ><b>{currencySign}{NumberFormat(data.list?.due_amount)} </b></td>
											</tr>
										</tbody>
									</Table>

									{
										data.list?.notes != '' && (
											<div className='notes-leads'>
												<span className='smaller'>- {data.list?.notes}</span>

											</div>
										)
									}

								</div>
								<Tab.Container id="details" defaultActiveKey="history">
									<Nav variant="tabs" className="nav-tabs-s1 mb-3">
										<Nav.Item>
											<Nav.Link eventKey="history">Payments</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="history">
											<Table size='sm' className='table-striped table-bordered' >
												<thead>
													<tr>
														<th>Charge Id#</th>
														<th>Amount</th>
														<th>Due Date</th>
														<th className='text-center'>Status</th>
														{
															action?.type != 'Receipt' && (
																<th className='text-center'>Action</th>
															)
														}
													</tr>
												</thead>
												<tbody>
													{
														data?.paymentHistory && data.paymentHistory.map((row, i) => {
															if (action?.type != 'Receipt') {
																return (
																	<tr key={i}>
																		<td>{row.history_no}</td>
																		<td className={`text-end pe-2 ${row.status == 'Refund' && ('text-danger')}`}>
																			{currencySign}{NumberFormat(row.amount)}
																		</td>
																		<td>{row.formated_payment_date}</td>
																		<td className='text-center'>
																			<span className={`badge m-auto text-bg-${row.status === "Paid" ? "success-soft"
																				: row.status === "Unpaid" ? "warning-soft"
																					: row.status === "Due" ? "secondary-soft"
																						: row.status === "Failed" ? "danger-soft"
																							: "primary-soft"
																				}`
																			}>
																				{row.status ? row.status : 'General'}
																			</span>
																		</td>
																		<td className='text-center'>
																			{
																				row.status === 'Paid' ? (

																					<div className='btns'>
																						<Button title='Receipt' variant="primary" onClick={() => openModal('Receipt', row)}
																							size='sm'
																						>
																							<Icon name={'eye'}></Icon>

																						</Button>

																						{
																							(row.has_child == 0 && row.amount > 0) && (
																								<Button className='ms-1' title='Refund' variant="danger" onClick={() => openModal('Refund', row)}
																									size='sm'
																								>
																									<Icon name={'undo'}></Icon>

																								</Button>
																							)
																						}


																					</div>
																				)
																					: (row.status === 'Unpaid' || row.status === 'Due') ? (
																						<div className='btns'>
																							{
																								data.list?.method == 'authorize' ? (
																									<Button title='Payment Link' variant="primary" onClick={() => openModal('Copy', row)}
																										size='sm'
																									>
																										<Icon name={'link'}></Icon>
																									</Button>
																								) : (
																									<Button className='ms-1' title='Change Payment Status' variant="secondary" onClick={() => openModal('Manual', row)}
																										size='sm'
																									>
																										<Icon name={'sign-dollar'}></Icon>
																									</Button>
																								)
																							}

																						</div>
																					)
																						: row.status === 'Refund' ? (
																							<div className='btns'>
																								<Button onClick={() => openModal('RefundNote', row)} title='Note' variant="secondary" size='sm'>
																									<Icon name={'info'}></Icon>
																								</Button>
																							</div>
																						)
																							: row.status === 'unkonow' ? (
																								<div className='btns'>

																								</div>
																							) :
																								(
																									<>
																									</>
																								)
																			}
																		</td>

																	</tr>
																)
															} else {
																if (action?.type == 'Receipt' && action.data?.public_id == row.public_id) {
																	return (
																		<tr key={i}>
																			<td>{row.history_no}</td>
																			<td className={`text-end pe-2 ${row.status == 'Refund' && ('text-danger')}`}>
																				{currencySign}{NumberFormat(row.amount)}
																			</td>
																			<td>{row.formated_payment_date}</td>
																			<td className='text-center'>
																				<span className={`badge m-auto text-bg-${row.status === "Paid" ? "success-soft"
																					: row.status === "Unpaid" ? "warning-soft"
																						: row.status === "Due" ? "secondary-soft"
																							: row.status === "Failed" ? "danger-soft"
																								: "primary-soft"
																					}`
																				}>
																					{row.status ? row.status : 'General'}
																				</span>
																			</td>
																		</tr>
																	)
																}
															}
														})
													}
												</tbody>
											</Table>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>

								{
									(action.type == 'Copy' || action.type == 'Manual' || action.type == 'Refund' || action.type == 'RefundNote') && (
										<Modal size='md' show={modal} onHide={() => closeModal()}>
											{
												action.type == 'Copy' && (
													<>
														<Modal.Header closeButton >
															<h5>Payment Link</h5>
														</Modal.Header>
														<Modal.Body>
															<div className="">
																<div className="text-end mb-3">
																	<button onClick={() => {
																		const textToCopy = action.data?.payment_url;
																		navigator.clipboard.writeText(textToCopy).then(() => {
																		}).catch((error) => {
																			Swal.fire('Error', 'Unable to copy text.', 'error');
																		});

																	}} id="copyButton" className="btn btn-primary btn-md">Copy</button>
																</div>
																<div className="bg-light bg-gradient p-2">
																	<span id="payment_link">
																		<a href={action.data.payment_url} target="_blank">
																			{action.data?.payment_url}
																		</a>
																	</span>
																</div>
															</div>
														</Modal.Body>
													</>
												)
											}

											{
												action.type == 'RefundNote' && (
													<>
														<Modal.Header closeButton >
															<h5>Refund Note</h5>
														</Modal.Header>
														<Modal.Body>
															<div className="">
																{
																	action.data?.notes
																}
															</div>
														</Modal.Body>
													</>
												)
											}

											{
												action.type == 'Manual' && (
													<>
														<Modal.Header closeButton >
															<h5>Add Payment</h5>
														</Modal.Header>
														<Modal.Body>
															<Form onSubmit={changePaymentStatus}>
																<Row>
																	<Col lg="12">
																		<Form.Group className="form-group mb-2">
																			<Form.Label htmlFor="header_logo">Amount</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Control type="text" readOnly name="amount" value={action.data?.amount} />
																			</div>
																		</Form.Group>
																	</Col>

																	<Col lg="12">
																		<Form.Group className="form-group mb-2">
																			<Form.Label htmlFor="header_logo">Payment Date</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Control type="text" readOnly name="payment_date" value={action.data?.payment_date} />
																			</div>
																		</Form.Group>
																	</Col>

																	<Col lg="12">
																		<Form.Group className="form-group">
																			<Form.Label htmlFor="master_domain">Status</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Select defaultValue={action.data?.status} name="status" className="form-controll">
																					<option value="Unpaid">Unpaid</option>
																					<option value="Paid">Paid</option>
																					<option value="Due">Due</option>
																					{/* <option value="Failed">Failed</option> */}
																				</Form.Select>
																			</div>
																		</Form.Group>
																	</Col>

																	<Col lg="12">
																		<Form.Group className="form-group my-2">
																			<Form.Label htmlFor="header_logo">Notes</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Control as={'textarea'} name="notes" value={action.data?.notes} required />
																			</div>
																		</Form.Group>
																	</Col>

																	<Modal.Footer className='pb-0'>
																		<Button size='sm' variant="primary" type="submit" >Change Status</Button>
																	</Modal.Footer>
																</Row>

															</Form>
														</Modal.Body>
													</>
												)
											}

											{
												action.type == 'Refund' && (
													<>
														<Modal.Header closeButton >
															<h5>Refund Payment</h5>
														</Modal.Header>
														<Modal.Body>
															<Form onSubmit={refundPayment}>
																<Row>
																	<Col lg="12">
																		<Form.Group className="form-group mb-2">
																			<Form.Label htmlFor="header_logo">Paid Amount</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Control type="text" readOnly name="amount" value={action.data?.amount} />
																			</div>
																		</Form.Group>
																	</Col>

																	<Col lg="12">
																		<Form.Check inline
																			label="Full Amount"
																			type="radio"
																			id="inlineradio1"
																			value={'full'}
																			defaultChecked={refundType == 'full' ? true : false}
																			onChange={(e) =>
																				setRefundType('full')
																			}
																			name="refund_type"
																		/>

																		<Form.Check inline
																			label="Partial Amount"
																			type="radio"
																			value={'partial'}
																			id="inlineradio1"
																			defaultChecked={refundType == 'partial' ? true : false}
																			onChange={(e) =>
																				setRefundType('partial')
																			}
																			name="refund_type"
																		/>
																	</Col>

																	{
																		refundType == 'partial' && (
																			<Col lg="12">
																				<Form.Group className="form-group">
																					<Form.Label htmlFor="header_logo">Enter Amount To Refund</Form.Label>
																					<div className="form-control-wrap">
																						<Form.Control placeholder='0.00' step={'any'} type="number" name="refund_amount" max={action.data?.amount} />
																						<p className='text-danger'>Please enter valid amount</p>
																					</div>
																				</Form.Group>
																			</Col>
																		)
																	}

																	<Col lg="12">
																		<Form.Group className="form-group">
																			<Form.Label htmlFor="header_logo">Notes</Form.Label>
																			<div className="form-control-wrap">
																				<Form.Control placeholder='Enter any relevant details or notes' as={'textarea'} name="notes" required />
																			</div>
																		</Form.Group>
																	</Col>

																	<Modal.Footer className='mt-2 pb-0'>
																		<Button disabled={loader} size='sm' variant="primary" type="submit" >Refund</Button>
																	</Modal.Footer>
																</Row>

															</Form>
														</Modal.Body>
													</>
												)
											}

										</Modal>
									)
								}


								<div className="receipt-header receipt-header-mid receipt-footer">
									<Row>
										<Col lg={12} >
											<div className="text-center mb-2">
												<h5>Thank you for your business!</h5>
											</div>
										</Col>

									</Row>
								</div>
							</div>
						</Col>
					</Modal.Body>
				</div>
			</Modal >
		</div >
	)

}

export default InvoiceDetails;
