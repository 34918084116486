import { useEffect, useState, useRef, Fragment } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';
import { isObjectNotEmpty } from '../../utilities';
function SequenceList() {
    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`sequence/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };


    const userColumns = [
        {
            name: "Sequence",
            key: 'title',
            selector: (row) => row.title,
            cell: (row) => (

                <Link to={`/sequence-manage/flow/${row.public_id}/sequence`}>
                    {row.title}
                </Link>
            ),
        },

        {
            name: "Record Counts",
            key: 'counts',
            selector: (row) => row.counts,
            cell: (row) => (
                <span>{row.counts}</span>
            ),
        },

        {
            name: "Throttle",
            key: 'throttle_value',
            selector: (row) => row.throttle_value,
            cell: (row) => (
                <span>{row.throttle_value} records in {row.throttle_duration} mins</span>
            ),
        },

        {
            name: <div className='w-100 text-center'>Status</div>,
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Draft" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },

        {
            name: "Created By",
            key: 'created_by_user',
            selector: (row) => row.created_by_user,
            cell: (row) => (
                <span>{row.created_by_user}</span>
            ),
        },

        {
            name: "Dated",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },

        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">
                    <LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">
                        {
                            hasPermission('editSequence') && (
                                <LinkListItem to={`/sequence-manage/edit/${row.public_id}`}>
                                    <Icon name="edit"></Icon>
                                </LinkListItem>
                            )
                        }

                        &nbsp;
                        {
                            hasPermission('deleteSequence') && row.can_delete == 1 && (
                                <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                    <Icon name="trash" className='text-danger'></Icon>
                                </button>
                            )
                        }
                    </LinkList>
                </div>
            ),
        },
    ];


    return (
        <Layout title="Sequence List" content="container-fluid">
            <div className="sequence">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Sequence List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Sequence</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                {
                                    hasPermission('createSequence') && (
                                        <li>
                                            <Link className='btn btn-primary d-md-inline-flex btn-sm' to="/sequence-manage/create">
                                                <Icon name="plus" />
                                                <span>Add</span>
                                            </Link>

                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {customDateFilter && (
                                        <>
                                            <Col>
                                                <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                            </Col>
                                            <Col>
                                                <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                            </Col>
                                        </>
                                    )}

                                    <Col>
                                        <Form.Select size="sm" name="status" >
                                            <option value="">All </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Select>
                                    </Col>

                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>

                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive"
                                slug={'sequence/lists'}
                            />


                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default SequenceList;
