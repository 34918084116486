import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip, Overlay, Popover, Modal, ListGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";

import { Icon, Select, Tinymce } from "../../components";
import { truncateString } from "../../utilities";
import Swal from "sweetalert2";

function SequenceFlow(props) {
	const { id, section } = useParams();
	const [meta, setMeta] = useState({ emailTemplates: [], smsTemplates: [], voiceTemplates: [], filters: [] });
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [formFieldData, setFormFieldData] = useState({});
	const [menu, setMenu] = useState("sms");
	const [searchTerm, setSearchTerm] = useState('');
	const [modal, setModal] = useState({ show: false, subject: '', body: '', type: '' });


	useEffect(() => {
		const fetchData = async () => {
			await fetchRecordById();
			fetchMeta();
		};
		fetchData();
	}, []);




	const fetchMeta = async () => {
		const formData = new FormData();
		formData.append('campaign_id', id);
		formData.append('section', section);
		formData.append('group_id', 0);

		await sendRequest(`sequence/${id}/flow/meta/fetch`, 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setLoading(true);
			}
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const fetchRecordById = async (loader = true) => {
		// if (loader) {
		// 	setLoading(false);
		// }
		const formData = new FormData();
		formData.append('campaign_id', id);
		formData.append('section', section);

		sendRequest(`sequence/${id}/series`, 'POST', formData).then(response => {
			if (response.status === true) {
				setData(response.data.records);
			}
		//	setLoading(true);
		})
			.catch(error => {
				//setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const dynamicSetting = (data, type) => {
		setFormLoading(true);
		console.log(data)
		data.body = data.html;
		data.subject = data.series.subject;
		data.interval_of_day = data.interval_of_day;
		data.execution_time = data.series.execution_time;
		data.template_id = data.series.template_id;
		data.p_series_id = data.id;

		setFormFieldData(data);
		setMenu(type);

		setTimeout(() => {
			setFormLoading(false);
		}, 200);


	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}
		submitData.append('campaignId', id);
		submitData.append('type', menu);
		submitData.append('master_type', 'Sequence');
		setFormLoading(true);


		sendRequest(`sequence/${id}/series/create`, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					setFormFieldData({});
					fetchRecordById();
				}
				setFormLoading(false);

			})
			.catch(error => {
				setFormLoading(false);

				console.error('POST request error:', error);
			});
	};


	const handleChange = (e, type = '') => {
		const { name, value } = e.target;

		setFormFieldData({ ...formFieldData, [name]: value });

		if (name == 'template_id' || name == 'automation_id') {
			let templates = [];
			if (type == 'sms') {
				templates = meta?.smsTemplates;

			} else if (type == 'email') {
				templates = meta?.emailTemplates;

			} else if (type == 'voicemail') {
				templates = meta?.voiceTemplates;
			} else if (type == 'automation') {
				templates = meta?.getAutomations;
			}

			const desiredObject = templates.find(item => item.id == e.target.value);;
			const body = desiredObject?.body || "";
			const subject = desiredObject?.title || "";
			console.log(body, subject, desiredObject)
			setFormFieldData((prevState) => ({
				...prevState,
				body: body,
				subject: subject
			}));
		}

	}

	const selectedTemplate = (id, type) => {
		let templates = [];
		if (type == 'sms') {
			templates = meta?.smsTemplates;
		} else if (type == 'email') {
			templates = meta?.emailTemplates;
		} else if (type == 'voicemail') {
			templates = meta?.voiceTemplates;
		}

		const desiredObject = templates.find(item => item.id == id);
		const body = desiredObject?.body || "";
		const subject = desiredObject?.title || "";
		console.log(body, subject, desiredObject)
		setFormFieldData((prevState) => ({
			...prevState,
			body: body,
			subject: subject,
			template_id: id
		}));
	}

	const menuLeft = {
		"Trigger": ["sms", "email", "voicemail", "webhook", "notify", "start", "end"],
		"Drip": ["sms", "email", "voicemail", "no_response"],
		"EventReminder": ["sms", "email", "voicemail"],
		"sequence": ["sms", "email", "voicemail"],

	};

	const getNavItemLabel = (eventKey) => {
		switch (eventKey) {
			case 'sms':
				return 'Add SMS';
			case 'email':
				return 'Add Email';
			case 'voicemail':
				return 'Add Voicemail';
			case 'webhook':
				return 'Webhook';
			case 'notify':
				return 'Notify Someone';
			case 'start':
				return 'Start Automation';
			case 'end':
				return 'End Automation';
			case 'no_response':
				return 'No Response Action';
			default:
				return '';
		}
	};

	const searchInTemplates = (searchTerm) => {
		setSearchTerm(searchTerm.toLowerCase()); // Convert to lowercase for case-insensitive search
	};

	// Filter templates based on the search term
	const filteredEmailTemplates = meta.emailTemplates.filter(
		(template) => template.title.toLowerCase().includes(searchTerm)
	);

	// Filter templates based on the search term
	const filteredSmsTemplates = meta.smsTemplates.filter(
		(template) => template.title.toLowerCase().includes(searchTerm)
	);

	// Filter templates based on the search term
	const filteredVoicemailTemplates = meta.voiceTemplates.filter(
		(template) => template.title.toLowerCase().includes(searchTerm)
	);

	const deleteRecord = async (series_id) => {
		sendRequest(`sequence/${id}/series/remove/${series_id}`, 'DELETE')
			.then(response => {
				if (response.status === true) {
					fetchRecordById();
				}
			})
			.catch(error => {
				console.error('Delete request error:', error);
			});

	};

	const updateFilter = (group_id, type) => {
		const submitData = new FormData();

		if (type == 'filter') {
			submitData.append('group_id', group_id);
			submitData.append('step', 3);
			sendRequest(`sequence/${id}/update`, 'POST', submitData)
				.then(response => {
					if (response.status === true) {
						fetchMeta();
						Swal.fire('Success', response.message, 'success')
					}
				})
				.catch(error => {
					console.error('POST request error:', error);
				});

		} else if (type == 'status') {
			let status = '';
			if (group_id == 'Draft') {
				status = 'Active'
			} else if (group_id == 'Active') {
				status = 'Draft'
			}

			Swal.fire({
				title: 'Are you sure?',
				text: `You wish to ${status} this Sequence?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, do it!'
			}).then((result) => {
				if (result.value) {

					submitData.append('status', status);
					submitData.append('step', '4');

					sendRequest(`sequence/${id}/update`, 'POST', submitData)
						.then(response => {
							if (response.status === true) {
								fetchMeta();
								Swal.fire('Success', response.message, 'success')
							}
						})
						.catch(error => {
							console.error('POST request error:', error);
						});
				}
			});


		}



	}

	return (
		<Layout title="Flow" content="container">
			<div className="mb-1">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">{meta.campaign?.title}</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/sequence-manage/lists">Sequence</Link></li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">

								<li className="me-2">
									<button onClick={() => updateFilter(meta.campaign?.status, 'status')}

										className={`btn btn-sm btn-${meta.campaign?.status === "Active" ? "success"
											: meta.campaign?.status === "Draft" ? "warning"
												: meta.campaign?.status === "Inactive" ? "secondary"
													: "primary"} text-white`
										}
									>

										{meta.campaign?.status ? meta.campaign?.status : 'General'}

									</button>
								</li>

								<li className="width-250">
									{
										loading && (
											<Select onChange={(e) => updateFilter(e, 'filter')} name="group_id" defaultValue={meta.campaign?.group_id}>
												<option value={''}>Exclude Filter</option>
												{
													meta.filters && meta.filters.map((row, i) => {
														return <option value={row.id} key={i}>{row.name}</option>
													})
												}

											</Select>
										)
									}
								</li>

								<li className="ms-2">
									<button onClick={() => props.navigate(`/crm-manage/leads/filters/create/sequence`)} className="btn btn-sm btn-info">
										<Icon name={'plus'} />
									</button>
								</li>

								{
									meta.campaign?.public_group_id != '' && (
										<li className="ms-2">
											<button onClick={() => props.navigate(`/crm-manage/leads/filters/edit/${meta.campaign?.public_group_id}/sequence`)} className="btn btn-sm btn-primary">
												<Icon name={'edit'} />
											</button>
										</li>
									)
								}



							</ul>

						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
			</div>

			<div className="flow-create-edit">
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card className="card-gutter-md">
								<Card.Body>
									<Row>
										<Col lg={6}>

											<Row>
												<Col sm={12}>
													<h5 className="text-primary mb-3">Add a new step</h5>
													<ListGroup horizontal >
														{
															menuLeft[section].map((item, subIndex) => (
																<ListGroup.Item className={`${menu == item ? 'active' : ''} text-center btn-sm p-1 fs-6`} action onClick={(e) => { setFormFieldData({}); setMenu(item); setSearchTerm('') }} key={`${subIndex}`}>
																	{getNavItemLabel(item)}
																</ListGroup.Item>
															))
														}


													</ListGroup>
												</Col>
												<Col sm={12}>
													{
														!formLoading && (
															<form className="email-form" onSubmit={handleSubmit}>
																<div className='row mt-2'>

																	<div className='col-md-6'>

																		<div className="form-group mb-3">
																			<label className='text-capitalize'>{menu} Interval:</label>
																			<div className="input-group">
																				<input required defaultValue={formFieldData.interval_of_day} className="form-control"
																					onChange={(e) => handleChange(e)}
																					readOnly={meta.campaign?.status == 'Active' ? true : false}
																					name="interval_of_day"
																					placeholder="Enter number of day(s)(e.g. 0 or 1 etc )" maxLength={'3'} type="text" />
																			</div>
																			<span className="text text-muted smaller">In days after which {menu} will trigger at, whenever a new contact added</span>
																		</div>
																	</div>


																	{
																		formFieldData.interval_of_day >= 1 ? (
																			<div className='col-md-6'>
																				<div className="form-group mb-3">
																					<label>Trigger on(Time):</label>
																					<Select disabled={meta.campaign?.status == 'Active' ? true : false} defaultValue={formFieldData.execution_time} name={"execution_time"}>
																						<option value="">Select</option>
																						{
																							meta.time_duration && meta.time_duration.map((row, i) => {
																								return <option key={i} value={row}>{row}</option>
																							})
																						}
																					</Select>
																				</div>
																			</div>
																		) : formFieldData.interval_of_day == 0 ? (
																			<>
																				<div className='col-md-6'>
																					<label>{'Time '}</label>
																					<input required readOnly value={'Immediate'}
																						//onChange={(e) => handleChange(e)}
																						type="text" name="" className="form-control" />

																				</div>
																			</>
																		) : (
																			<>
																			</>
																		)
																	}
																</div>

																{menu == "sms" && (

																	<div className="sms-content">

																		<div className="form-group mb-3">
																			<label>Name:*</label>
																			<div className="input-group">
																				<input required className="form-control" type={'text'} name="subject" value={formFieldData.subject} onChange={(e) => handleChange(e)} />
																				<input type={'hidden'} name="template_id" value={formFieldData.template_id} />
																			</div>
																		</div>



																		<div className="form-group mb-3">
																			<label> SMS Token</label>
																			<div className="input-group">
																				<select name="token" className='form-control' onChange={(e) => handleChange(e)} >
																					<option value={''}>Select Token</option>
																					<optgroup label="Lead Default Field Token" >
																						{
																							meta?.tokens?.default_fields.map((df, d) => {
																								return <option key={d} value={`{${df.key}}`}>{df.label}</option>
																							})
																						}
																					</optgroup>
																					<optgroup label="Lead Custom Field Token" >
																						{
																							meta?.tokens?.custom_fields.map((cf, c) => {
																								return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
																							})
																						}
																					</optgroup>
																					<optgroup label="User Tokens" >
																						{
																							meta?.tokens?.users_token.map((cf, c) => {
																								return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
																							})
																						}
																					</optgroup>
																				</select>

																				<button
																					disabled={!formFieldData.token}
																					type='button'
																					onClick={() => setFormFieldData({
																						...formFieldData,
																						body: formFieldData.body ? `${formFieldData.body} ${formFieldData.token}` : formFieldData.token
																					})}
																					className='btn btn-primary btn-md'
																				>
																					Insert Into Body
																				</button>
																			</div>
																		</div>

																		<div className="form-group mb-3">
																			<label>SMS Body</label>
																			<div className="input-group">
																				<textarea onChange={(e) => handleChange(e)}
																					value={formFieldData.body ?? ''} placeholder='Add message body' rows="4" cols="50" name="body" className='form-control' />
																			</div>
																		</div>

																	</div>

																)}
																{menu == "email" && (
																	<div className="email-content">


																		<input type={'hidden'} name="template_id" value={formFieldData.template_id} />

																		<div className="form-group mb-3">
																			<label> Email Token</label>
																			<div className="input-group">
																				<div className="input-group">
																					<select name="token" className='form-control' onChange={(e) => handleChange(e)} >
																						<option value={''}>Select Token</option>
																						<optgroup label="Lead Default Field Token" >
																							{
																								meta?.tokens?.default_fields.map((df, d) => {
																									return <option key={d} value={`{${df.key}}`}>{df.label}</option>
																								})
																							}
																						</optgroup>
																						<optgroup label="Lead Custom Field Token" >
																							{
																								meta?.tokens?.custom_fields.map((cf, c) => {
																									return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
																								})
																							}
																						</optgroup>
																						<optgroup label="User Tokens" >
																							{
																								meta?.tokens?.users_token.map((cf, c) => {
																									return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
																								})
																							}
																						</optgroup>
																					</select>

																					<button
																						disabled={!formFieldData.token}
																						type='button'
																						onClick={() => setFormFieldData({
																							...formFieldData,
																							subject: formFieldData.subject ? `${formFieldData.subject} ${formFieldData.token}` : formFieldData.token
																						})}
																						className='btn btn-primary btn-md'
																					>
																						Insert Into Subject
																					</button>
																				</div>


																			</div>
																		</div>

																		<div className="form-group mb-3">
																			<label>Email Subject</label>
																			<div className="input-group">
																				<input value={formFieldData.subject} onChange={(e) => handleChange(e)} className='form-control' name="subject" />
																			</div>
																		</div>


																		<div className="form-group mb-3">
																			<label>Email Body</label>
																			<div className="input-group TextAreaBox">
																				<Tinymce
																					leadToken={true} userToken={true} height={500} initialValue={formFieldData.body} textareaName={'body'} toolbar={true}
																				/>
																			</div>
																		</div>



																	</div>

																)}

																{menu == "voicemail" && (

																	<div className="voicemail-content">

																		<div className="form-group mb-3">
																			<label>Name:*</label>
																			<div className="input-group">
																				<input required className="form-control" type={'text'} name="subject" value={formFieldData.subject} onChange={(e) => handleChange(e)} />
																				<input type={'hidden'} name="template_id" value={formFieldData.template_id} />
																			</div>
																		</div>


																		<input type="hidden" onChange={(e) => handleChange(e)}
																			value={formFieldData.body ?? ''} placeholder='Add message body' rows="4" cols="50" name="body" className='form-control' />


																	</div>

																)}
																<input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
																<div className="form-group">
																	<button type="submit" className="btn btn-primary btn-sm pull-right save-btn mt-2" >{formFieldData.p_series_id ? 'Update' : 'Add'} Step</button>
																</div>
															</form>
														)
													}
												</Col>
											</Row>
										</Col>


										<Col lg={6}>
											<div className="sequence-area">
												<h5 className="text-primary mb-3">Steps in this Sequence</h5>
												<div className="sequence-table">
													<table className="table table-bordered table-striped">
														{/* <thead>
														<tr>
															<th></th>
															<th></th>
															<th></th>
															<th></th>
															<th></th>
														</tr>
													</thead> */}
														<tbody>
															{
																data.map((row, i) => {
																	return (
																		<tr key={i}>
																			<td>
																				<button onClick={() => dynamicSetting(row, row.type)} className="btn btn-sm btn-link p-0">
																					Day {row.interval_of_day}
																				</button>
																			</td>
																			<td>
																				{row.type == 'sms' ?
																					<Icon name={'chat'} />
																					:
																					row.type == 'email' ?
																						<Icon name={'mail'} />
																						:
																						<Icon name={'call'} />
																				}
																			</td>
																			<td>
																				<button onClick={() => dynamicSetting(row, row.type)} className="btn btn-sm btn-link text-start p-0">
																					{row.series?.subject}
																				</button>
																			</td>
																			<td>
																				{
																					row.interval_of_day == 0 ?
																						'Immediately' :
																						row.series?.execution_time
																				}
																			</td>
																			<td>
																				<div className="d-flex">
																					<button onClick={() => setModal({ show: true, subject: row.series?.subject, body: row.html, type: row.type })} className="p-0 btn btn-sm btn-link">
																						<Icon name={'eye'} />
																					</button>
																					&nbsp;|&nbsp;
																					<button onClick={() => deleteRecord(row.id)} className="btn btn-sm btn-link p-0">
																						<Icon name={'trash'} />
																					</button>
																				</div>
																			</td>
																		</tr>
																	)
																})
															}
														</tbody>
													</table>
												</div>
											</div>

											<div className="templates-area">
												<input placeholder="Enter Template Name" value={searchTerm} name="searching" type="search" className="form-control" onChange={(e) => searchInTemplates(e.target.value)} />
												{
													(meta.emailTemplates && menu == 'email') ?
														<div className="">
															<h5 className="mb-0 mt-2">Template List</h5>
															<div className="TemplatesTable pt-2">
																<table className="table table-bordered table-striped">
																	<tbody>
																		<tr>
																			<td><b>Template Name</b></td>
																		</tr>
																		{
																			filteredEmailTemplates.map((row, i) => {
																				return (
																					<tr key={i}>
																						<td>
																							<button onClick={() => selectedTemplate(row.id, 'email')} className="btn btn-sm btn-link p-0">
																								{row.title}
																							</button>
																						</td>
																					</tr>
																				)
																			})
																		}
																	</tbody>
																</table>
															</div>

														</div>
														:
														(meta.emailTemplates && menu == 'sms') ?
															<div className="">
																<h5 className="mb-0 mt-2">Tamplate List</h5>
																<div className="TemplatesTable SMS pt-2">
																	<table className="table table-bordered table-striped">
																		<tbody>
																			<tr>
																				<td><b>Template Name</b></td>
																				<td><b>Type</b></td>
																			</tr>

																			{
																				filteredSmsTemplates.map((row, i) => {
																					return (
																						<tr key={i}>
																							<td>
																								<button onClick={() => selectedTemplate(row.id, 'sms')} className="btn btn-sm btn-link p-0">
																									{row.title}
																								</button>
																							</td>
																							<td>
																								{row.type}
																							</td>
																						</tr>
																					)
																				})
																			}
																		</tbody>
																	</table>
																</div>

															</div>
															:
															(meta.emailTemplates && menu == 'voicemail') ?
																<div className="">
																	<h5 className="mb-0 mt-2">Tamplate List</h5>
																	<div className="TemplatesTable SMS pt-2">
																		<table className="table table-bordered table-striped">
																			<tbody>
																				<tr>
																					<td><b>Template Name</b></td>
																					<td><b>Type</b></td>
																				</tr>

																				{
																					filteredVoicemailTemplates.map((row, i) => {
																						return (
																							<tr key={i}>
																								<td>
																									<button onClick={() => selectedTemplate(row.id, 'voicemail')} className="btn btn-sm btn-link p-0">
																										{row.title}
																									</button>
																								</td>
																								<td>
																									{row.type}
																								</td>
																							</tr>
																						)
																					})
																				}
																			</tbody>
																		</table>

																	</div>
																</div>
																: <div> </div>

												}
											</div>


										</Col>
									</Row>

								</Card.Body>
							</Card>
						)
					}


					<Modal show={modal.show} size="lg">
						<Modal.Header closeButton onHide={() => { setModal({ show: false, subject: '', body: '', type: '' }) }}>
							<Modal.Title>{modal.type.toUpperCase()} Preview</Modal.Title>
						</Modal.Header>
						<Modal.Body className="automation-action">
							<table className="table table-bordered">
								<tbody>
									<tr>
										<th>Subject</th>
										<td>{modal.subject}</td>
									</tr>
									<tr >
										<td colSpan={2}>
											<div dangerouslySetInnerHTML={{ __html: modal.body }} />
										</td>
									</tr>
								</tbody>
							</table>

						</Modal.Body>
					</Modal>
				</Block >
			</div>
		</Layout >
	)
}

export default SequenceFlow;
