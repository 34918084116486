import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import ContactNav from '../../nav/JobsNav';
import Swal from 'sweetalert2';

function UserList() {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [selectedValue, setSelectedValue] = useState(null);
	const [selectedRecord, setSelectedRecordValue] = useState(null);

	const formRef = useRef(null);
	const childRef = useRef(null);


	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};


	const deleteRecord = async (id) => {
		sendRequest(`groups/${id}`, 'DELETE', { id: id })
			.then(response => {
				if (response.status === true) {
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
			})
			.catch(error => {
				console.error('Delete request error:', error);
			});

	};

	const updateDefaultCategory = async (id, value, type) => {

		Swal.fire({
			title: 'Are you sure?',
			text: `You want to change default ${type}?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();

				formData.append('selected_record', id);
				formData.append('key', 'is_default');
				formData.append('value', value);
				formData.append('type', type);

				sendRequest(`groups/field/update`, 'POST', formData).then(response => {
					if (response.status === true) {
						if (childRef.current) {
							childRef.current.handleFilter(new FormData());
						}
					}
				})
					.catch(error => {
						console.error('POST request error:', error);
					});
			}
		});

	}


	const userColumns = [
		{
			name: "Title",
			key: 'title',

			selector: (row) => row.name,
			cell: (row) => (
				<span>
					{
						hasPermission('editCategory') ? (
							<Link to={`/jobs/category/edit/${row.public_id}`}>
								{row.title}
							</Link>
						) : (
							row.title
						)
					}

				</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Type</div>,
			key: 'type',
			selector: (row) => row.type,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.type === "Category" ? "info-soft"
					: "secondary-soft"}`
				}>
					{row.type}
				</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Is Default?</div>,
			key: 'is_default',
			selector: (row) => row.is_default,
			cell: (row) => (
				<span onClick={() => updateDefaultCategory(row.public_id, row.is_default, row.type)} className={`badge m-auto text-bg-${row.is_default === 1 ? "success-soft"
					: row.status === 0 ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.is_default ? 'Yes' : 'No'}
				</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">
						{
							hasPermission('deleteCategory') && row.can_delete == 1 && (
								<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
									<Icon name="trash" className='text-danger'></Icon>
								</button>
							)
						}
					</LinkList>
				</div>
			),
		},

	];

	const options = [
		{ value: 0, label: 'No' },
		{ value: 1, label: 'Yes' }
	];

	const handleChange = async (event) => {
		let selectedValues = '';

		selectedValues = event['value'];

		setSelectedValue(selectedValues);

		const formData = new FormData();

		formData.append('selected_record', selectedRecord);
		formData.append('key', 'is_default');
		formData.append('value', selectedValues);

		await sendRequest(`groups/field/update`, 'POST', formData).then(response => {
			if (response.status === true) {
				if (childRef.current) {
					childRef.current.handleFilter(new FormData());
				}
			}
		})
			.catch(error => {
				console.error('POST request error:', error);
			});

	};

	return (
		<Layout title="Job Categories & Services List" content="container-fluid">
			<div className="Categories & Services">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Job Categories & Services List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/jobs/lists">Jobs</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Category & Serice</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">

								<ContactNav from={2} />
								{
									hasPermission('createCategory') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/jobs/category/create">
												<Icon name="plus" />
												<span>Add</span>
											</Link>

										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{customDateFilter && (
										<>
											<Col>
												<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
											</Col>
											<Col>
												<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
											</Col>
										</>
									)}
									<Col>
										<Form.Select size="sm" name="type" >
											<option value="">All Types</option>
											<option value="Category">Categories</option>
											<option value="Service">Services</option>
										</Form.Select>
									</Col>

									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All Status</option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5 btn-sm'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' className="btn-sm" onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'groups/lists?type=category_and_service'}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>
		</Layout>
	)
}

export default UserList;
