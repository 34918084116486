import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Offcanvas, Spinner, DropdownButton, Tabs, Tab, Container, Nav, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Message, Select } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import QuickConnect from '../QuickConnect';
import { convertPhoneFormat } from '../../../utilities';
import ContactNav from '../../nav/ContactsNav';
import Swal from 'sweetalert2';
import CreateContact from '../../../pages/crm-manage/contacts/CreateContact';

function LeadList(props) {
	const { hasPermission, callInit } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [quickConnect, setQuickConnect] = useState({
		type: '',
		modal: false,
		id: 0,
		to: '',
		coach_id: 0,
		campaign_id: 0,
	});
	const [offcanvasStart, setOffcanvasStart] = useState(false);
	const [meta, setMeta] = useState({});
	const [loading, setLoading] = useState(false);
	const [dataTableData, setDataTableData] = useState({});
	const [pipelineFilter, setPipelineFilter] = useState({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
	const [selectedIds, setSelectedIds] = useState([]);
	const [searchFilter, setSearchFilter] = useState(false);
	const [selectedType, setSelectedType] = useState(0);
	const [activeContactTab, setActiveContactTab] = useState('clients');
	const [show, setShow] = useState(false);
	const [showFilters, setShowFilters] = useState(false);

	const formRef = useRef(null);
	const childRef = useRef(null);
	const selectTypeRef = useRef(null);
	// console.log("formRef" , formRef);
	// console.log("childRef" , childRef);
	const [editingContact, setEditingContact] = useState({});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handlePrimarySubmit = async (event) => {
		// setFormLoading(true);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}
		// submitData.append('parent_id', id);
		sendRequest('crm/leads/create', 'POST', submitData)
			.then(response => {
				if (response.status === true) {

					handleClose();

					const formData = new FormData();

					formData.append('active_tab', activeContactTab);

					if (childRef.current) {
						childRef.current.handleFilter(formData);
					}
				}
				// setFormLoading(false);
			})
	}

	const handleTabChange = (selectedTab) => {
		setActiveContactTab(selectedTab);

		const form = document.querySelector('.filters-form');

		let formData = '';

		if (!form) {
			formData = new FormData();
		}
		else {
			formData = new FormData(form);
		}

		formData.append('active_tab', selectedTab);

		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	var timeoutId;

	const handleSearch = (e) => {
		e.preventDefault();

		const valueSearch = e.target.value;

		const form = document.querySelector('.filters-form');

		let formData = '';

		if (!form) {
			formData = new FormData();
		} else {
			formData = new FormData(form);
		}

		formData.append('filter_value', valueSearch);
		formData.append('active_tab', activeContactTab);

		// Clear any existing timeout
		clearTimeout(timeoutId);

		// Set a new timeout
		timeoutId = setTimeout(() => {
			if (childRef.current) {
				childRef.current.handleFilter(formData);
			}
		}, 1000); // Wait for 1 second (1000 milliseconds)
	}


	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		const formData = new FormData();
		formData.set('type', 'listPage');
		formData.set('filter_type', 'leads');

		await sendRequest('crm/leads/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const selectedValue = selectTypeRef.current.value;
		setSelectedType(selectedValue);
		const formData = new FormData(event.currentTarget);

		formData.append('active_tab', activeContactTab);

		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			setActiveContactTab('clients');
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setLoading(false);
			setCustomDateFilter(false);
			setPipelineFilter({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
			if (searchFilter) {
				setColumnVisibility({
					name: true,
					email: true,
					// lead_number: true,
					//address: true,
					//company_name: true,
					checkboxes: true,
					date_of_birth: true,
					last_appointment_title: true,
					last_appointment_date: true,
					last_appointment_status: true,
					milestone: true,
					record_owner: true,
				});

				setSearchFilter(false);
			}
			setTimeout(() => {
				setLoading(true);

			}, 100);
		}
	};


	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to move selected contacts to the recycle bin?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`crm/leads/update/bulk/delete`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};
	const exportRecords = async () => {
		if (selectedIds.length == 0) {
			Swal.fire({
				text: 'Kindly choose a contact record from the provided list to proceed with the desired action.',
				icon: 'warning',
			});
			return false;
		}
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to submit export request for selected contacts?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, do it!'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				formData.append('ids', selectedIds)
				sendRequest(`crm/leads/update/bulk/export`, 'POST', formData)
					.then(response => {
						if (response.status === true) {
							props.navigate(`/crm-manage/leads/export`)
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};
	const quickAction = (status = false, data = {}) => {

		setQuickConnect({
			type: '',
			modal: false,
			id: 0,
			to: '',
			campaign_id: 0,
			coach_id: 0,
			is_bulk: false,
		});
	}

	const fetchEditingContactDetails = async (encrypted_id) => {
		await sendRequest(`crm/leads/${encrypted_id}`, 'POST').then(response => {
			if (response.status === true) {
				setEditingContact(response.data.list);
				handleShow();
			}
			setLoading(true);
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const userColumns = [
		{
			name: <Form.Check value="selectAll" onChange={(e) => handleCheckbox(e)} type="checkbox" id="selectAllCheckbox" label="" />,
			key: 'checkboxes',
			width: '60px',
			selector: (row) => row.id,
			cell: (row) => (
				<Form.Check checked={selectedIds.includes(row.public_id)} id={`flexCheckDefault${row.id}`} defaultValue={row.public_id} onChange={(e) => handleCheckbox(e)} type="checkbox" label="" />
			),
		},
		// {
		// 	name: "Lead ID",
		// 	key: 'id',
		// 	selector: (row) => row.id,
		// 	cell: (row) => (
		// 		<span>{row.id}</span>
		// 	),
		// },
		{
			name: "Client Name",
			key: 'name',
			selector: (row) => row.name,
			cell: (row) => (
				<Link to={`/crm-manage/leads/dialer/${row.public_id}`}>
					{row.first_name} {row.last_name}
				</Link>
			),
		},
		{
			name: "Owner",
			key: 'record_owner',
			selector: (row) => row.record_owner,
			cell: (row) => (
				<span>{row.record_owner}</span>
			),
		},
		{
			name: "Email",
			key: 'email',
			minWidth: '280px',
			selector: (row) => row.email,
			cell: (row) => (
				(row.email != '' && row.email != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<Link to={`/crm-manage/leads/dialer/${row.public_id}`}>
								{row.email}
							</Link>
							<div className="d-none d-sm-flex nk-message-actions">
								<button
									onClick={() => {
										setQuickConnect({
											modal: true,
											id: row.id,
											type: 'note',
											to: '',
											coach_id: row.coach_id,
											campaign_id: 0,
											is_bulk: false,
										})
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="notes-alt"></Icon>
								</button>

								<button
									onClick={() => {
										setQuickConnect({
											modal: true,
											id: row.id,
											type: 'email',
											to: row.email,
											coach_id: row.coach_id,
											campaign_id: 0,
											is_bulk: false,
										})
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="mail"></Icon>
								</button>
								<button
									onClick={() => {
										navigator.clipboard.writeText(`${row.email}`);
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="copy"></Icon>

								</button>

							</div>
						</li>
					</ul>
				)
			),
		},
		{
			name: "Type",
			key: 'type',
			selector: (row) => row.type,
			cell: (row) => (
				<span>{row.type == 0 ? 'Individual' : 'Business'}</span>
			),
		},
		{
			name: "Record Type",
			key: 'record_type',
			selector: (row) => row.record_type,
			cell: (row) => (
				<span>{row.record_type == 0 ? 'Lead' : 'Client'}</span>
			),
		},
		{
			name: "Sub Contacts",
			key: 'sub_contacts',
			selector: (row) => row.sub_contacts_count,
			cell: (row) => (
				<span>{row.sub_contacts_count}</span>
			),
		},
		{
			name: "Phone",
			key: 'phone',
			minWidth: '260px',
			selector: (row) => row.phone,
			cell: (row) => (
				(row.phone != '' && row.phone != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<Link to={`/crm-manage/leads/dialer/${row.public_id}`}>
								{convertPhoneFormat(row.phone)}
							</Link>
							<div className="d-none d-sm-flex nk-message-actions">
								<button
									onClick={() => {
										setQuickConnect({
											modal: true,
											id: row.id,
											type: 'note',
											to: '',
											coach_id: row.coach_id,
											campaign_id: 0,
											is_bulk: false,
										})
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="notes-alt"></Icon>

								</button>

								<button
									onClick={() => {
										callInit({
											modal: false,
											id: row.id,
											type: 'call',
											to: row.country_code + row.phone,
											campaign_id: 0,
											coach_id: row.coach_id,
											first_name: row.first_name,
											last_name: row.last_name
										})
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="call"></Icon>

								</button>
								<button
									onClick={() => {
										navigator.clipboard.writeText(`${row.phone}`);
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="copy"></Icon>

								</button>
							</div>
						</li>
					</ul>
				)
			),
		},

		{
			name: "Mobile",
			key: 'mobile',
			minWidth: '260px',
			selector: (row) => row.mobile,
			cell: (row) => (
				(row.mobile != '' && row.mobile != null) && (
					<ul className="nk-message-bubbles">
						<li className=''>
							<Link to={`/crm-manage/leads/dialer/${row.public_id}`}>
								{convertPhoneFormat(row.mobile)}
							</Link>
							<div className="d-none d-sm-flex nk-message-actions">
								<button
									onClick={() => {
										setQuickConnect({
											modal: true,
											id: row.id,
											type: 'note',
											to: '',
											coach_id: row.coach_id,
											campaign_id: 0,
											is_bulk: false,
										})
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="notes-alt"></Icon>

								</button>
								<button
									onClick={() => {
										setQuickConnect({
											modal: true,
											id: row.id,
											type: 'sms',
											to: row.mobile,
											coach_id: row.coach_id,
											campaign_id: 0,
											is_bulk: false,
										})
									}}
									className="btn btn-md  px-1 py-0"
								>
									<Icon name="msg"></Icon>

								</button>
								<button
									onClick={() => {
										callInit({
											modal: false,
											id: row.id,
											type: 'call',
											to: row.country_code + row.mobile,
											campaign_id: 0,
											coach_id: row.coach_id,
											first_name: row.first_name,
											last_name: row.last_name
										})
									}}
									className="btn btn-md p-1"
								>
									<Icon name="call"></Icon>

								</button>
								<button
									onClick={() => {
										navigator.clipboard.writeText(`${row.mobile}`);
									}}
									className="btn btn-md px-1 py-0"
								>
									<Icon name="copy"></Icon>

								</button>
							</div>
						</li>
					</ul>
				)
			),
		},

		{
			name: "Date  of Birth",
			key: 'date_of_birth',
			selector: (row) => row.date_of_birth,
			cell: (row) => (
				<span>{row.date_of_birth}</span>
			),
		},

		{
			name: "Last Appt",
			key: 'last_appointment_date',
			selector: (row) => row.last_appointment_date,
			cell: (row) => (
				<span>{row.last_appointment_date}</span>
			),
		},

		{
			name: "Apt Status",
			key: 'last_appointment_status',
			selector: (row) => row.last_appointment_status,
			cell: (row) => (
				<span>{row.last_appointment_status}</span>
			),
		},
		{
			name: "Milestone",
			key: 'milestone',
			selector: (row) => row.pipeline?.status_title,
			cell: (row) => (
				<span>{row.pipeline?.status_title}</span>
			),
		},


		{
			name: "City",
			key: 'city',
			selector: (row) => row.city,
			cell: (row) => (
				<span>{row.city}</span>
			),
		},

		{
			name: "State",
			key: 'state',
			selector: (row) => row.state,
			cell: (row) => (
				<span>{row.state}</span>
			),
		},
		{
			name: "Zip",
			key: 'zip',
			selector: (row) => row.zip,
			cell: (row) => (
				<span>{row.zip}</span>
			),
		},
		{
			name: selectedType == '1' ? "Company Address" : "Address",
			key: 'address',
			selector: (row) => row.address,
			cell: (row) => (
				<span>{row.address}</span>
			),
		},
		{
			name: "website",
			key: 'website',
			selector: (row) => row.website,
			cell: (row) => (
				<span>{row.website}</span>
			),
		},
		{
			name: "Company",
			key: 'company_name',
			selector: (row) => row.company_name,
			cell: (row) => (
				<span>{row.company_name}</span>
			),
		},
		{
			name: meta.tags_labels?.Group ?? 'Group',
			key: 'groups',
			selector: (row) => row.groups,
			cell: (row) => (
				<span>{row.groups}</span>
			),
		},
		{
			name: meta.tags_labels?.Tag ?? 'Tag',
			key: 'tags',
			selector: (row) => row.tags,
			cell: (row) => (
				<span>{row.tags}</span>
			),
		},
		// {
		// 	name: "Action",
		// 	key: 'action',
		// 	cell: (row) => (
		// 		<div className="text-center w-100">
		// 			<div className="dropdown-content">
		// 				<LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">
		// 					{
		// 						hasPermission('editLeads') && (
		// 							<LinkListItem to={`/crm-manage/leads/edit/${row.public_id}`}>
		// 								<Icon name="edit"></Icon>
		// 							</LinkListItem>
		// 						)
		// 					}
		// 					&nbsp;
		// 					{
		// 						hasPermission('deleteLeads') && (
		// 							<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
		// 								<Icon name="trash" className='text-danger'></Icon>
		// 							</button>
		// 						)
		// 					}

		// 				</LinkList>
		// 			</div>
		// 		</div>
		// 	),
		// },

	];

	const getInitialColumnVisibility = () => {
		const storedVisibility = localStorage.getItem('leadsColumnVisibility');
		if (storedVisibility) {
			let visible = JSON.parse(storedVisibility);
			visible.checkboxes = true;
			return visible;
		}
		// Default visibility state
		return {
			// id: true,
			name: true,
			email: true,
			// lead_number: true,
			//address: true,
			//	company_name: true,
			checkboxes: true,
			date_of_birth: true,
			last_appointment_title: true,
			last_appointment_date: true,
			last_appointment_status: true,
			milestone: true,
			record_owner: true,


		};
	};

	const [columnVisibility, setColumnVisibility] = useState(getInitialColumnVisibility);
	useEffect(() => {
		localStorage.setItem('leadsColumnVisibility', JSON.stringify(columnVisibility));
	}, [columnVisibility]);

	const toggleColumnVisibility = async (key) => {
		setColumnVisibility((prevVisibility) => ({
			...prevVisibility,
			[key]: !prevVisibility[key],
		}));

		const formData = new FormData();
		formData.set('columnVisibility', JSON.stringify(columnVisibility));

		await sendRequest('visible-columns/leads/set', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}
			setLoading(true);

		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	};
	const visibleColumns = userColumns.filter((column) => columnVisibility[column.key]);

	const contactsColumns = [
		{
			name: <Form.Check value="selectAll" onChange={(e) => handleCheckbox(e)} type="checkbox" id="selectAllCheckbox" label="" />,
			key: 'checkboxes',
			width: '60px',
			selector: (row) => row.id,
			cell: (row) => (
				<Form.Check checked={selectedIds.includes(row.public_id)} id={`flexCheckDefault${row.id}`} defaultValue={row.public_id} onChange={(e) => handleCheckbox(e)} type="checkbox" label="" />
			),
		},
		{
			name: "Contact Name",
			key: 'name',
			selector: (row) => row.name,
			cell: (row) => (
				<Link to='javascript:void(0);' onClick={() => fetchEditingContactDetails(row.public_id)}>
					{row.name}
				</Link>
			),
		},
		{
			name: "Title",
			key: 'title',
			selector: (row) => row.title,
			cell: (row) => (
				<span>{row.title}</span>
			),
		},
		{
			name: "Email",
			key: 'email',
			selector: (row) => row.email,
			cell: (row) => (
				<span>{row.email}</span>
			),
		},
		{
			name: "Date  of Birth",
			key: 'date_of_birth',
			selector: (row) => row.date_of_birth,
			cell: (row) => (
				<span>{row.date_of_birth}</span>
			),
		},
		{
			name: "Relationship",
			key: 'contact_relation',
			selector: (row) => row.contact_relation,
			cell: (row) => (
				<span>{row.contact_relation}</span>
			),
		},
		{
			name: "Address",
			key: 'address',
			selector: (row) => row.address,
			cell: (row) => (
				<span>{row.address}</span>
			),
		},
	];

	const handlePipelines = (data) => {
		setDataTableData(data);
		setSelectedIds([]);
		if (data.selected_columns != '') {
			setColumnVisibility(data.selected_columns);
		}
	};

	const handleDropdownItemClick = (row, from) => {
		if (from == 'parent') {
			setPipelineFilter({ master: 0, pipelineId: row.pipeline_id, statusId: 0, statusTitle: '', statusCount: 0 });
		} else {
			setPipelineFilter({
				master: row.master_pipeline_id,
				pipelineId: row.pipeline_id,
				statusId: row.status_id,
				statusTitle: row.status_title,
				statusCount: row.status_count
			})
		}

		setTimeout(() => {
			document.getElementById('submitButton').click();
		}, 200);
	};

	const handleCheckbox = (e) => {
		const { value, checked } = e.target;
		if (value === 'selectAll') {
			if (checked) {
				setSelectedIds(dataTableData.records.map((row) => row.public_id));
			} else {
				setSelectedIds([]);
			}
		} else {
			setSelectedIds((prevSelectedIds) => {
				if (checked) {
					return [...prevSelectedIds, value];
				} else {
					return prevSelectedIds.filter((id) => id !== value);
				}
			});
		}

	};

	return (
		<Layout title="Clients List" content="container-fluid">
			<div className="Contacts">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Client List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									{/* <li className="breadcrumb-item"><Link to="/crm-manage/leads/lists">Lead Manage</Link></li> */}
									<li className="breadcrumb-item active" aria-current="page">Clients</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								<ContactNav from={1} />
								{
									hasPermission('createLeads') && (
										<>
											{activeContactTab === 'contacts' ? (
												<Link className="btn btn-sm mr-5 btn-primary" to='javascript:void(0);' onClick={() => { handleShow(); setEditingContact({}) }}>
													<Icon name="plus" /> <span>Add Contact</span>
												</Link>
											) : (
												<Link className="btn btn-sm mr-5 btn-primary" to={'/crm-manage/leads/create'}>
													<Icon name="plus" /> <span>Add Client</span>
												</Link>
											)}
										</>
									)
								}

								<Button size='sm' className='mr-5' variant="primary" onClick={() => setOffcanvasStart(true)}>
									<Icon name={'setting-alt'} />
								</Button>

								<CreateContact
									show={show}
									handleClose={handleClose}
									editingContact={editingContact}
									handlePrimarySubmit={handlePrimarySubmit}
									contactRelationsTypes={meta?.contactRelationsTypes}
								/>

								<Offcanvas className="w-50 h-50" placement="end" show={offcanvasStart} onHide={() => setOffcanvasStart(false)}>
									<Offcanvas.Header closeButton />
									<Offcanvas.Body>
										<div className="filter-list-container">
											{userColumns.map((column, i) => (
												<div key={column.key} className={`filter-slot ${i == 0 && ('d-none')}`}>
													<div className='mb-2 radio-label-custom'>

														<Form.Check
															type="checkbox"
															inline
															label={column.name}
															id={`${column.key}`}
															key={column.key}
															checked={columnVisibility[column.key]}
															onChange={() => toggleColumnVisibility(column.key)}
														/>
													</div>
												</div>

											))}
										</div>
									</Offcanvas.Body>
								</Offcanvas>

								<Dropdown>
									<Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-primary btn-sm me-n1 hide-caret">
										<Icon name={'more-h'} />
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="center">
										<div className="dropdown-content py-1">
											<LinkList className="link-list-hover-bg-primary link-list-md">

												{
													hasPermission('viewLeadsFilter') && (
														<LinkListItem to={`/crm-manage/leads/filters/lists`}>
															<span>List & Filters</span>
														</LinkListItem>
													)
												}
												{
													hasPermission('deleteLeads') && (
														<button
															title='You must select at least one record'
															disabled={selectedIds < 1}
															className="btn p-0 border-0" onClick={() => deleteRecord('MA==')}>
															Mass Delete
														</button>
													)
												}

												<button className="btn p-0 border-0"
													disabled={selectedIds < 1}
													title='You must select at least one record'

													onClick={() => {
														setQuickConnect({
															modal: true,
															id: selectedIds,
															type: 'email',
															to: [],
															coach_id: meta.coach_partner_params?.coach_id,
															campaign_id: 0,
															is_bulk: true,
														})
													}}
												>
													Mass Email
												</button>
												<button className="btn p-0 border-0"
													disabled={selectedIds < 1}
													title='You must select at least one record'
													onClick={() => {
														setQuickConnect({
															modal: true,
															id: selectedIds,
															type: 'sms',
															to: [],
															coach_id: meta.coach_partner_params?.coach_id,
															campaign_id: 0,
															is_bulk: true,
														})
													}}
												>
													Mass SMS
												</button>

												{
													hasPermission('viewLeads') && (
														<LinkListItem to={`/crm-manage/leads/recyclebin`}>
															<span>Recycle Bin</span>
														</LinkListItem>
													)
												}
												{
													hasPermission('createLeads') && (
														<LinkListItem to={`/crm-manage/leads/import`}>
															<span>Import Clients</span>
														</LinkListItem>
													)
												}
												{
													hasPermission('viewLeads') && (
														<button className="btn p-0" onClick={() => exportRecords()}>
															Export Clients
														</button>
													)
												}
												{
													hasPermission('viewLeadsForms') && (
														<LinkListItem to={`/crm-manage/leads/forms/lists`}>
															<span>Contact Forms</span>
														</LinkListItem>
													)
												}


											</LinkList>
										</div>
									</Dropdown.Menu>
								</Dropdown>

							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					{
						!loading ? (
							<Spinner animation="grow" variant="primary"></Spinner>
						) : (
							<Card>
								<Card.Body>

									<div className="d-flex justify-content-between align-items-start mb-3 p-0 pb-2 gray-bg">
										<div className="flex-grow-1">

											<Tab.Container id="accordion-tabs" activeKey={activeContactTab} onSelect={handleTabChange}>
												<div className='bg-white  px-3'>
													<div>
														<Nav variant="tabs" className="nav-tabs-s1  mt-0 mb-2 d-flex justify-content-between nav nav-tabs  mx-0">
															<div className='d-flex'>
																<Nav.Item>
																	<Nav.Link eventKey="clients"><b>Clients</b> </Nav.Link>
																</Nav.Item>
																<Nav.Item>
																	<Nav.Link eventKey="contacts"><b>Contacts</b> </Nav.Link>
																</Nav.Item>
																<Nav.Item>
																	<Nav.Link eventKey="leads"><b>Leads</b></Nav.Link>
																</Nav.Item>
																<Nav.Item>
																	<Nav.Link eventKey="new-leads"><b>{meta.newLeadsCount} New Leads</b></Nav.Link>
																</Nav.Item>
																<Nav.Item>
																	<Nav.Link eventKey="my-records"><b>My Record Only</b></Nav.Link>
																</Nav.Item>
															</div>
															<div className="d-flex align-items-center position-relative SearchBox">
																<InputGroup className="me-2">
																	<Form.Control
																		type="text"
																		placeholder="Search"
																		aria-label="Search"
																		className="rounded-pill top-search"
																		onKeyUp={(e) => handleSearch(e)}
																	/>
																	<InputGroup.Text className="rounded-pill text-gray border-0 SearchBtn px-0">
																		<Icon name="search" ></Icon>
																	</InputGroup.Text>
																</InputGroup>
																<Button onClick={() => setShowFilters(!showFilters)} variant="outline-primary" className="btn btn-outline-primary btn-sm rounded-pill">
																	<Icon name="filter-fill"></Icon> Filter
																</Button>
															</div>
														</Nav>
													</div>
													<div>
														{showFilters && (
															<Form ref={formRef} onSubmit={handleSubmit} className='filters-form'>
																<Row>
																	<Col>
																		<Form.Select
																			size="sm"
																			name="contact_type"
																			ref={selectTypeRef}>
																			<option value="">Filter by Type</option>
																			<option key={0} value='0'>Individual</option>
																			<option key={1} value='1'>Business</option>
																		</Form.Select>
																	</Col>
																	<Col>
																		<Form.Select
																			size="sm"
																			name="search_filter_id"
																			onChange={() => setSearchFilter(true)}>
																			<option value="">All Records</option>

																			<optgroup label="Select Filter">
																				{
																					meta.search_filters && meta.search_filters.map((row, i) => {
																						return <option key={i} value={row.id}>{row.name}</option>
																					})
																				}
																			</optgroup >
																		</Form.Select>
																	</Col>

																	<Col>
																		<Form.Select
																			size="sm"
																			onChange={(e) =>
																				e.target.value == "Custom Date"
																					? setCustomDateFilter(true)
																					: setCustomDateFilter(false)
																			}
																			name="date_range">
																			<option value="">Filter by Date</option>
																			<option value="Current Week">Current Week</option>
																			<option value="Last Week">Last Week</option>
																			<option value="Current Month">Current Month</option>
																			<option value="Last Month">Last Month</option>
																			<option value="Last 3 Months">Last 3 Months</option>
																			<option value="Last 6 Months">Last 6 Months</option>
																			<option value="Current Year">Current Year</option>
																			<option value="Last Year">Last Year</option>
																			<option value="Custom Date">Custom Date</option>
																		</Form.Select>
																	</Col>
																	{
																		customDateFilter && (
																			<>
																				<Col>
																					<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
																				</Col>

																				<Col>
																					<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
																				</Col>
																			</>
																		)
																	}

																	<Col>
																		<div className='sm'>
																			<Select name="group_id">
																				<option value="">{meta.tags_labels?.Group ?? 'Group'}</option>
																				{
																					meta.groups && meta.groups.map((row, i) => {
																						return <option key={i} value={row.id}>{row.title}</option>
																					})
																				}
																			</Select>
																		</div>
																	</Col>

																	<Col>
																		<div className='sm'>
																			<Select name="tag_id">
																				<option value="">{meta.tags_labels?.Tag ?? 'Tag'} </option>
																				{
																					meta.tags && meta.tags.map((row, i) => {
																						return <option key={i} value={row.id}>{row.title}</option>
																					})

																				}
																			</Select>
																		</div>
																	</Col>

																	<Col>
																		<div className='sm'>
																			<Select name="coach_partner_id">
																				<option value="">Everyone </option>
																				{
																					meta.coachPartners && meta.coachPartners.map((row, i) => {
																						return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
																					})

																				}
																			</Select>
																		</div>
																	</Col>

																	<Col>
																		<div className='sm'>
																			<Select name="master_pipeline_id"
																				onChange={() => {
																					setPipelineFilter({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
																					setTimeout(() => {
																						document.getElementById('submitButton').click();
																					}, 100);
																				}}>
																				<option value="">Milestone </option>
																				{
																					meta.masterPipelines && meta.masterPipelines.map((row, i) => {
																						return <option key={i} value={row.master_pipeline_id}>{row.master_pipeline_title}</option>
																					})

																				}
																			</Select>

																			<Form.Control type="hidden" name="pipeline_id" value={pipelineFilter.pipelineId} />
																			<Form.Control type="hidden" name="status_id" value={pipelineFilter.statusId} />


																		</div>
																	</Col>

																	{(dataTableData.pipelines && dataTableData.pipelines.length > 0) &&
																		<Row className='mb-3'>
																			{
																				dataTableData.pipelines.map((row, i) => (
																					<Col key={i}>
																						<DropdownButton className='custom-witdth' variant='outline-light' size='sm' id="dropdown-basic-button" title={`${(pipelineFilter.statusId > 0 && pipelineFilter.pipelineId == row.pipeline_id) ? `${pipelineFilter.statusTitle} (${pipelineFilter.statusCount})` : `${row.pipeline_title} (${row.sub_count})`}`}>
																							{
																								(pipelineFilter.pipelineId == row.pipeline_id) && (
																									<Dropdown.Item key={i} eventKey={row.id} onClick={(e) => handleDropdownItemClick(row, 'parent')}>
																										{`${row.pipeline_title} (${row.sub_count})`}
																									</Dropdown.Item>
																								)
																							}

																							{row.status.map((sub, j) => {

																								if (pipelineFilter.statusId != sub.status_id) {
																									return (
																										<Dropdown.Item key={j} eventKey={sub.id} onClick={(e) => handleDropdownItemClick(sub, 'sub')}>
																											{`${sub.status_title} (${sub.status_count})`}
																										</Dropdown.Item>
																									)
																								}

																							})}
																						</DropdownButton>
																					</Col>
																				))
																			}

																		</Row>
																	}

																	<Col>
																		<Button id="submitButton" size="sm" type="submit" className='mr-5'>Filter</Button>
																		<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
																	</Col>
																</Row>
															</Form>
														)}
													</div>
												</div>

												{/* Tab content */}
												<Tab.Content className='shadow-sm px-2 mx-2 bg-white mt-2'>
													<Tab.Pane eventKey="clients">
														<Button className='btn btn-sm btn-primary rounded-pill my-2'>
															<Link to={'/crm-manage/leads/create'} className='text-white text-decoration-none'>
																<span>CREATE CLIENT</span>
															</Link>
														</Button>
														{hasPermission('createLeads') && (
															<Button variant="outline-primary" className="btn btn-outline-primary btn-sm rounded-pill ms-1" >
																<Link to={`/crm-manage/leads/import`} className="text-decoration-none " style={{ color: 'inherit' }} onMouseOver={(e) => e.target.style.color = 'white'} onMouseOut={(e) => e.target.style.color = 'inherit'}>
																	<span >IMPORT CLIENT LIST</span>
																</Link>
															</Button>
														)}

													</Tab.Pane>

													{activeContactTab != 'clients' && (
														<span >
															&nbsp;
														</span>
													)}

													<DataTableComponent
														childRef={childRef}
														selectableRows={false}
														columns={activeContactTab == 'contacts' ? contactsColumns : visibleColumns}
														tableClassName="data-table-head-light table-responsive LeadListTable customized-width mt-2"
														slug={activeContactTab == 'contacts' ? 'crm/leads/lists?status=1&lead_type=contact' : 'crm/leads/lists?status=1'}
														sendDataToParent={handlePipelines}
														serchBox={false}
													/>
													{/* <Tab.Pane eventKey="leads">
														leads tab
													</Tab.Pane>
													<Tab.Pane eventKey="new-leads">
														new-leads tab
													</Tab.Pane>
													<Tab.Pane eventKey="my-records">
														my-records
													</Tab.Pane> */}
												</Tab.Content>
											</Tab.Container>

										</div>

									</div>

									{/* <Form ref={formRef} onSubmit={handleSubmit}>
										<Row>
											<Col>
												<Form.Select
													size="sm"
													name="contact_type"
													ref={selectTypeRef}>
													<option value="">Filter by Type</option>
													<option key={0} value='0'>Individual</option>
													<option key={1} value='1'>Business</option>
												</Form.Select>
											</Col>
											<Col>
												<Form.Select
													size="sm"
													name="search_filter_id"
													onChange={() => setSearchFilter(true)}>
													<option value="">All Records</option>

													<optgroup label="Select Filter">
														{
															meta.search_filters && meta.search_filters.map((row, i) => {
																return <option key={i} value={row.id}>{row.name}</option>
															})
														}
													</optgroup >
												</Form.Select>
											</Col>

											<Col>
												<Form.Select
													size="sm"
													onChange={(e) =>
														e.target.value == "Custom Date"
															? setCustomDateFilter(true)
															: setCustomDateFilter(false)
													}
													name="date_range">
													<option value="">Filter by Date</option>
													<option value="Current Week">Current Week</option>
													<option value="Last Week">Last Week</option>
													<option value="Current Month">Current Month</option>
													<option value="Last Month">Last Month</option>
													<option value="Last 3 Months">Last 3 Months</option>
													<option value="Last 6 Months">Last 6 Months</option>
													<option value="Current Year">Current Year</option>
													<option value="Last Year">Last Year</option>
													<option value="Custom Date">Custom Date</option>
												</Form.Select>
											</Col>
											{
												customDateFilter && (
													<>
														<Col>
															<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>

														<Col>
															<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
														</Col>
													</>
												)
											}

											<Col>
												<div className='sm'>
													<Select name="group_id">
														<option value="">{meta.tags_labels?.Group ?? 'Group'}</option>
														{
															meta.groups && meta.groups.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})
														}
													</Select>
												</div>
											</Col>

											<Col>
												<div className='sm'>
													<Select name="tag_id">
														<option value="">{meta.tags_labels?.Tag ?? 'Tag'} </option>
														{
															meta.tags && meta.tags.map((row, i) => {
																return <option key={i} value={row.id}>{row.title}</option>
															})

														}
													</Select>
												</div>
											</Col>

											<Col>
												<div className='sm'>
													<Select name="coach_partner_id">
														<option value="">Everyone </option>
														{
															meta.coachPartners && meta.coachPartners.map((row, i) => {
																return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
															})

														}
													</Select>
												</div>
											</Col>

											<Col>
												<div className='sm'>
													<Select name="master_pipeline_id"
														onChange={() => {
															setPipelineFilter({ master: 0, pipelineId: 0, statusId: 0, statusTitle: '', statusCount: 0 });
															setTimeout(() => {
																document.getElementById('submitButton').click();
															}, 100);
														}}>
														<option value="">Milestone </option>
														{
															meta.masterPipelines && meta.masterPipelines.map((row, i) => {
																return <option key={i} value={row.master_pipeline_id}>{row.master_pipeline_title}</option>
															})

														}
													</Select>

													<Form.Control type="hidden" name="pipeline_id" value={pipelineFilter.pipelineId} />
													<Form.Control type="hidden" name="status_id" value={pipelineFilter.statusId} />


												</div>
											</Col>

											{(dataTableData.pipelines && dataTableData.pipelines.length > 0) &&
												<Row className='mb-3'>
													{
														dataTableData.pipelines.map((row, i) => (
															<Col key={i}>
																<DropdownButton className='custom-witdth' variant='outline-light' size='sm' id="dropdown-basic-button" title={`${(pipelineFilter.statusId > 0 && pipelineFilter.pipelineId == row.pipeline_id) ? `${pipelineFilter.statusTitle} (${pipelineFilter.statusCount})` : `${row.pipeline_title} (${row.sub_count})`}`}>
																	{
																		(pipelineFilter.pipelineId == row.pipeline_id) && (
																			<Dropdown.Item key={i} eventKey={row.id} onClick={(e) => handleDropdownItemClick(row, 'parent')}>
																				{`${row.pipeline_title} (${row.sub_count})`}
																			</Dropdown.Item>
																		)
																	}

																	{row.status.map((sub, j) => {

																		if (pipelineFilter.statusId != sub.status_id) {
																			return (
																				<Dropdown.Item key={j} eventKey={sub.id} onClick={(e) => handleDropdownItemClick(sub, 'sub')}>
																					{`${sub.status_title} (${sub.status_count})`}
																				</Dropdown.Item>
																			)
																		}

																	})}
																</DropdownButton>
															</Col>
														))
													}

												</Row>
											}

											<Col>
												<Button id="submitButton" size="sm" type="submit" className='mr-5'>Filter</Button>
												<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
											</Col>
										</Row>
									</Form> */}

									{
										quickConnect.modal && (
											<QuickConnect isBulk={quickConnect.is_bulk ?? false} quickAction={quickAction} connect={quickConnect} />
										)
									}



								</Card.Body>
							</Card>
						)
					}
				</Block>
			</div>
		</Layout>
	)
}

export default LeadList;
