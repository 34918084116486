import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { toInitials, uInfo } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import Dropdown from 'react-bootstrap/Dropdown';

function SMSTemplateCreate(props) {
    const { id } = useParams();

    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ title: '', type: 'SMS', body: '' });
    const [token, setToken] = useState('');

    const textareaRef = useRef(null);

    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = () => {
        sendRequest(`dropdown/tokens/${uInfo().id}`, 'GET').then(response => {
            if (response.status === true) {
                setMeta(response.data.records);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`templates/sms/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(`/template-manage/sms/lists`);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const insertValueAtCursor = (valueToInsert) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = data.body.substring(0, start) + valueToInsert + data.body.substring(end);
        setData((prevState) => ({
            ...prevState,
            body: newText,
        }));

        // Set the cursor position after the inserted value
        textarea.selectionStart = start + valueToInsert.length;
        textarea.selectionEnd = start + valueToInsert.length;
        textarea.focus();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <Layout title="SMS" content="container-fluid">
            <div className="SMS-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">SMS</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={`/template-manage/sms/lists`}>SMS</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="12">
                                            <Form.Check onChange={handleChange} defaultChecked={data.type === 'SMS' ? true : false} name="type" value={"SMS"} inline label="SMS" type="radio" id="inlineCheckbox1"></Form.Check>
                                            <Form.Check onChange={handleChange} defaultChecked={data.type === 'MMS' ? true : false} name="type" value={"MMS"} inline label="MMS" type="radio" id="inlineCheckbox2"></Form.Check>

                                        </Col>

                                        {
                                            data.type == 'MMS' && (
                                                <Col md="12">
                                                    <InputGroup>
                                                        <InputGroup.Text htmlFor="inputGroupFile01">Upload</InputGroup.Text>
                                                        <Form.Control  required name="media_file" type="file" id="inputGroupFile01" />
                                                    </InputGroup>
                                                </Col>
                                            )
                                        }

                                        {
                                            loading && (
                                                <Col md="12">
                                                    <div className="token-button">
                                                        <Select className="as" removeItemButton searchEnabled onChange={(e) => setToken(`${e[0] ?? ''}`)}>
                                                            <option value=''>Select Token</option>
                                                            <optgroup label="Default Fields">
                                                                {
                                                                    meta.default_fields && meta.default_fields.map((row, i) => {
                                                                        return <option value={`{${row.key}}`}>{row.label}</option>;
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="Custom Fields">
                                                                {
                                                                    meta.custom_fields && meta.custom_fields.map((row, i) => {
                                                                        return <option value={`{${row.key}}`}>{row.label}</option>;
                                                                    })
                                                                }
                                                            </optgroup>
                                                        </Select>
                                                        <Button className="btn-sm" type="button" onClick={() => insertValueAtCursor(token)} variant="outline-secondary">
                                                            Insert Token
                                                        </Button>
                                                    </div>
                                                </Col>

                                            )
                                        }

                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="body">Body</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control
                                                        ref={textareaRef}
                                                        value={data.body}
                                                        onChange={handleChange}
                                                        as="textarea"
                                                        name="body"
                                                        placeholder=""
                                                        required />
                                                </div>
                                            </Form.Group>

                                        </Col>


                                        <Col className="col-12">
                                            <Button className="btn-sm" type="submit">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout >
    )
}

export default SMSTemplateCreate;