import { useEffect, useState, useRef } from "react";
import { Card, Button, Form, Row, Col, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { sendRequest } from "../../utilities/api";
import {
	Icon,
	LinkList,
	Select,
} from "../../components";
import { useAuth } from "../../AuthContext";
import DataTableComponent from "../../components/DataTable/DataTable";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InvoiceNav from "../nav/InvoiceNav";
import { currencySign } from "../../utilities";

function CheckoutLinksList() {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);
	const [embedForm, setEmbedForm] = useState(false);
	const [linkModal, setLinkModal] = useState(false);
	const [data, setData] = useState({});
	const [meta, setMeta] = useState({});
	const [checkout_url, setUrl] = useState();
	const [subDomain, setSubDomain] = useState();
	const [loading, setLoading] = useState(false);

	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		const formData = new FormData();
		await sendRequest("checkoutlinks/meta/fetch", "POST", formData)
			.then((response) => {
				if (response.status === true) {
					setMeta(response.data);
				}
			})
			.catch((error) => {
				console.error("POST request error:", error);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};

	const deleteRecord = async (id) => {
		sendRequest(`checkoutlinks/${id}`, "DELETE", { id: id })
			.then((response) => {
				if (response.status === true) {
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
			})
			.catch((error) => {
				console.error("Delete request error:", error);
			});
	};

	const submitHandler = async (event) => {
		setLoading(false);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}
		//submitData.append("slug", checkout_url);
		let url = "checkoutlinks/create";
		if (data.public_id) {
			url = `checkoutlinks/update/${data.public_id}`;
		}

		sendRequest(url, "POST", submitData)
			.then((response) => {
				if (response.status === true) {
					closeModal();
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
				setLoading(true);
			})
			.catch((error) => {
				setLoading(true);
				console.error("POST request error:", error);
			});
	};

	const openModal = (row = {}) => {
		setData(row);
		setUrl(row.slug);
		setTimeout(() => {
			setModal(true);
		}, 200);
	};

	const closeModal = () => {
		setData({});
		setModal(false);
	};

	const userColumns = [
		// {
		// 	name: "Id",
		// 	key: "id",
		// 	selector: (row) => row.id,
		// 	cell: (row) => <span>{row.id}</span>,
		// },

		{
			name: "Title",
			key: "title",
			selector: (row) => row.title,
			cell: (row) =>
				<button className="btn p-0 btn-link" onClick={() => { setLinkModal(true); setEmbedForm(row) }}>
					<span>{row.title}</span>
				</button>
		},
		{
			name: "Amount",
			key: 'amount_formated',
			selector: (row) => row.amount_formated,
			cell: (row) => (
				<span className='price-end'>{currencySign}{row.amount_formated}</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: "status",
			selector: (row) => row.status,
			cell: (row) => (
				<span
					className={`badge m-auto text-bg-${row.status === "Active"
						? "success-soft"
						: row.status === "Pending"
							? "warning-soft"
							: row.status === "Inactive"
								? "secondary-soft"
								: "primary-soft"
						}`}
				>
					{row.status ? row.status : "General"}
				</span>
			),
		},

		{
			name: "Created Date",
			key: "dated",
			selector: (row) => row.dated,
			cell: (row) => <span>{row.dated}</span>,
		},

		{
			name: "Action",
			key: "action",
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
							{hasPermission("editProducts") && (
								<button
									className="btn p-0"
									onClick={() => {
										openModal(row);
									}}
								>
									<Icon name="edit"></Icon>
								</button>
							)}



							{hasPermission("deleteProducts") && row.can_delete == 1 && (
								<button
									className="btn p-0 d-none"
									onClick={() => deleteRecord(row.public_id)}
								>
									<Icon name="trash" className="text-danger"></Icon>
								</button>
							)}
						</LinkList>
					</div>
				</div>
			),
		},
	];

	return (
		<Layout title="Checkout Links" content="container-fluid">
			<div className="CheckoutLinks">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Checkout Links</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item">
										<Link to="/checkout-links-manage/lists">
											Checkout Links
										</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										List
									</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{hasPermission("createCheckoutLinks") && (
									<li>
										<button
											className="btn btn-sm btn-primary"
											onClick={() => openModal()}
										>
											<Icon name="plus" />
											<span>Add</span>
										</button>
									</li>
								)}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<InvoiceNav from="2" />
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range"
										>
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>

									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control
														size="sm"
														type="date"
														name="start_date"
														placeholder="Start date"
														disabled={!customDateFilter}
														required={customDateFilter}
													/>
												</Col>

												<Col>
													<Form.Control
														size="sm"
														type="date"
														name="end_date"
														placeholder="End date"
														disabled={!customDateFilter}
														required={customDateFilter}
													/>
												</Col>
											</>
										)
									}

									<Col>
										<Form.Select size="sm" name="status">
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className="mr-5">
											Filter
										</Button>
										<Button
											size="sm"
											variant="outline-light"
											type="button"
											onClick={handleReset}
										>
											Reset
										</Button>
									</Col>
								</Row>
							</Form>

							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={"checkoutlinks/lists"}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>

			<Modal show={modal} onHide={() => closeModal()} size="lg">
				<Form onSubmit={submitHandler}>
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">
							{data.public_id ? "Edit" : "Add"} Checkout Link
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Row>
							<Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="title">
										Title{" "}
										<a
											target="_blank"
											href={data.domain_id ? checkout_url : ""}
										>
											{/* {data.domain_id ? checkout_url : ""} */}
										</a>
									</Form.Label>
									<div className="form-control-wrap">
										<Form.Control
											onChange={(e) => {
												setData({ ...data, title: e.target.value });
												setUrl(
													`https://${subDomain}/${e.target.value.replace(
														/\s/g,
														""
													)}`
												);
											}}
											defaultValue={data.title}
											type="text"
											name="title"
											required
										/>
									</div>
								</Form.Group>
							</Col>
							<Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="color_code">Sub-Domain </Form.Label>
									<div key={Math.random(4)} className="form-control-wrap">
										{meta.subDomains && meta?.subDomains.length > 0 ? (
											<Form.Select
												defaultValue={data.domain_id}
												name="domain_id"
												onChange={(e) => {
													const selectedIndex = e.target.selectedIndex;
													const selectedOptionText = e.target.options[selectedIndex].text;
													setData({ ...data, domain_id: e.target.value });
													data.title && setUrl(
														`https://${selectedOptionText}/${data.title.replace(
															/\s/g,
															""
														)}`
													);
												}}
											>
												<option value="">Select</option>
												{meta.subDomains.map((row, i) => {
													return (
														<option value={row.id} key={i}>
															{row.name}
														</option>
													);
												})}
											</Form.Select>
										) : (
											<Select name="slug">
												<option value="">Select</option>
											</Select>
										)}
									</div>
								</Form.Group>
							</Col>
							<Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="description">Description</Form.Label>
									<div className="form-control-wrap">
										<Form.Control
											as={"textarea"}
											onChange={(e) =>
												setData({ ...data, description: e.target.value })
											}
											defaultValue={data.description}
											name="description"
											required
										/>
									</div>
								</Form.Group>
							</Col>

							<Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="amount">Amount</Form.Label>
									<div className="form-control-wrap">
										<div className="form-control-icon start">
											<Icon name="sign-dollar"></Icon>
										</div>
										<Form.Control
											step={"any"}
											onChange={(e) =>
												setData({ ...data, amount: e.target.value })
											}
											defaultValue={data.amount}
											type="number"
											name="amount"
											required
										/>
									</div>
								</Form.Group>
							</Col>

							{/* <Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="logo">
										Recommended Size:120 x 100
									</Form.Label>
									<div className="form-control-wrap">
										<Form.Control type="file" name="logo" />
									</div>
									<div className="media media-middle media-lg mt-2">
										{" "}
										<img src={data.media_file_url} alt={data.name} />{" "}
									</div>
								</Form.Group>
							</Col> */}


							{/* <Col md="12">
								<Form.Check
									className="form-group m-2"
									name="can_change_amount"
									value={1}
									defaultChecked={data.can_change_amount}
									type="switch"
									label={"Allow Customer To Enter Custom Amount"}
								/>
							</Col> */}
							<Col md="12">
								<Form.Check
									className="form-group m-2"
									name="can_client_memo"
									value={1}
									defaultChecked={data.can_client_memo}
									type="switch"
									label={"Allow Client Memo"}
								/>
							</Col>
							{/* <Col md="12">
								<Form.Check
									className="form-group m-2"
									name="can_store_card"
									value={1}
									defaultChecked={data.can_store_card}
									type="switch"
									label={"Store Card On File"}
								/>
							</Col>
							<Col md="12">
								<Form.Check
									className="form-group m-2"
									name="can_charge_fee"
									value={1}
									defaultChecked={data.can_charge_fee}
									type="switch"
									label={"Charge Card Fee"}
								/>
							</Col>
							<Col md="12">
								<Form.Check
									className="form-group m-2"
									name="can_enable_ach"
									value={1}
									defaultChecked={data.can_enable_ach}
									type="switch"
									label={"Enable ACH"}
								/>
							</Col> */}
							<Col md="12">
								<Form.Group className="form-group mb-2">
									<Form.Label htmlFor="color_code">Assign To </Form.Label>
									<div key={Math.random(4)} className="form-control-wrap">
										{meta.coachPartners && meta?.coachPartners.length > 0 ? (
											<Select
												defaultValue={data.permissions}
												name="permissions[]"
												multiple
												removeItemButton
											>
												<option value="">Select</option>
												{meta.coachPartners.map((row, i) => {
													return (
														<option key={i} value={row.id}>
															{row.first_name} {row.last_name}
														</option>
													);
												})}
											</Select>
										) : (
											<Select name="permissions[]" multiple removeItemButton>
												<option value="">Select</option>
											</Select>
										)}
									</div>
								</Form.Group>
							</Col>
							<Col md="12">
								<Form.Group className="form-group">
									<Form.Label htmlFor="throttle_duration">Status</Form.Label>
									<div className="form-control-wrap">
										<Form.Select
											defaultValue={data.status}
											onChange={(e) =>
												setData({ ...data, status: e.target.value })
											}
											name="status"
											className="form-controll"
										>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button className="btn-sm" variant="danger" onClick={() => closeModal()}>
							Close
						</Button>
						<Button className="btn-sm" variant="primary" type="submit">
							{data.public_id ? "Update" : "Create"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={linkModal} onHide={() => setLinkModal(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title className="event-title" as="h4">Share {embedForm.title} link</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="url">
						<a
							href={`${embedForm.domain_url}`}
							target="_blank"
							className="pr-5 mr-5"
						>
							{embedForm.domain_url}
						</a>

						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={
								<Tooltip id="overlay-example">
									Copy Form url
								</Tooltip>
							}
						>
							<button
								className="btn btn-link btn-md"
								onClick={() => {
									navigator.clipboard.writeText(`${embedForm.domain_url}`);
								}}
							>
								<Icon name="copy"></Icon>
							</button>
						</OverlayTrigger>
					</div>

					<div className="url d-flex">
						<label className="pt-3">Iframe Embed Code </label>
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={
								<Tooltip id="overlay-example">
									Copy iframe Embed Code
								</Tooltip>
							}
						>
							<button
								className="btn btn-link btn-md"
								onClick={() => {
									navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${embedForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
								}}
							>
								<Icon name="copy"></Icon>
							</button>
						</OverlayTrigger>

					</div>
					<textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${embedForm.domain_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => { setEmbedForm({}); setLinkModal(false); }}>Close</Button>
				</Modal.Footer>
			</Modal>
		</Layout>
	);
}

export default CheckoutLinksList;
