import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, InputGroup, Tab, ListGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import { useAuth } from "../../AuthContext";

function DomainView(props) {
    const { id } = useParams();
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
        };
        fetchData();
    }, []);


    const fetchRecordById = async () => {
        await sendRequest(`domains/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
             
            }

        })
            .catch(error => {
             
                console.error('POST request error:', error);
            });
    }


    return (
        <Layout title="Proposal " content="container-fluid">
            <div className="Proposals-view">
                <Block.Head>
                    <Block.HeadContent>
                            <nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/domains-manage/lists">Sub Domains</Link></li>
									<li className="breadcrumb-item active" aria-current="page">View</li>
								</ol>
							</nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <div class="card">
                        <div class="card-body">
                            <div class="bio-block">
                            <h4 class="bio-block-title">Domains Details:</h4>
                            <ul class="list-group list-group-borderless small">
                                <li class="list-group-item">
                                    <span class="title fw-medium w-40 d-inline-block">Name:</span>
                                    <span class="text">{data.name} <a target="_blank" href={`https://${data.name}`}><em class="icon ni ni-link-alt"></em></a></span>
                                </li>
                                <li class="list-group-item">
                                    <span class="title fw-medium w-40 d-inline-block">Header BG & Text Color:</span>
                                    <span class="text" style={{backgroundColor: data.other_settings?.header_bg_color, color:data.other_settings?.header_text_color}}>Color</span>
                                </li>
                                <li class="list-group-item">
                                    <span class="title fw-medium w-40 d-inline-block">Header Text:</span>
                                    <span class="text">{data.other_settings?.header_text}</span>
                                </li>
                                <li class="list-group-item">
                                    <span class="title fw-medium w-40 d-inline-block">Footer Text:</span>
                                    <span class="text">{data.other_settings?.footer_text}</span>
                                </li>
                                <li class="list-group-item">
                                    <span class="title fw-medium w-40 d-inline-block">Created At</span>
                                    <span class="text">{data.dated}</span>
                                </li>
                            </ul>
                            </div>
                            <div class="bio-block">
                            <h4 class="bio-block-title mb-3">Header Logo</h4>
                            <ul class="d-flex flex-wrap gap g-2">
                                <li><img src={data.other_settings?.header_logo} height={100} alt={data.name} /></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </Block >
            </div>

        </Layout>
    )
}

export default DomainView;