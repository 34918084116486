import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, InputGroup, ButtonGroup } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import { useReactMediaRecorder } from "react-media-recorder";

function EditVoiceMailTemplate(props) {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ title: '', type: '', body: '', });
    const [recordingTime, setRecordingTime] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
        useReactMediaRecorder({ audio: true , video: false });




    useEffect(() => {
        let timerInterval;

        if (status === 'recording') {
            // Start the timer when recording begins
            timerInterval = setInterval(() => {
                setRecordingTime((prevTime) => prevTime + 1);
            }, 1000); // Update every 1 second
        } else {
            // Clear the timer when recording stops or is not active
            clearInterval(timerInterval);
        }

        return () => {
            clearInterval(timerInterval); // Clean up the timer on component unmount
        };
    }, [status]);


    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
        };
        fetchData();
    }, []);



    const fetchRecordById = async () => {
        await sendRequest(`templates/voicemail/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (mediaBlobUrl) {
            await fetch(mediaBlobUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    submitData.append('body', blob, 'recorded-audio.mp3');
                });
        }

        sendRequest(`templates/voicemail/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(`/template-manage/voicemail/lists`);
                }

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);

                console.error('POST request error:', error);
            });
    };



    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name == 'type') {
            setData((prevState) => ({
                ...prevState,
                ['media_url']: '',
            }));
        }
    };
    return (
        <Layout title="Voicemail Template" content="container-fluid">
            <div className="Voicemail-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Voicemail</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={`/template-manage/voicemail/lists`}>Voicemail</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>


                    {
                        loading && (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="title">Title</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="12">
                                                    <Form.Check onChange={handleChange} defaultChecked={data.type === 'Record' ? true : false} name="type" value={"Record"} inline label="Record" type="radio" id="inlineCheckbox1"></Form.Check>
                                                    <Form.Check onChange={handleChange} defaultChecked={data.type === 'Upload' ? true : false} name="type" value={"Upload"} inline label="Upload" type="radio" id="inlineCheckbox2"></Form.Check>
                                                </Col>

                                                {
                                                    data.type == 'Upload' && (
                                                        <Col md="12">
                                                            <audio src={data.media_url} controls />
                                                            <InputGroup>
                                                                <InputGroup.Text htmlFor="inputGroupFile01">Upload</InputGroup.Text>
                                                                <Form.Control name="body" type="file" id="inputGroupFile01" />
                                                            </InputGroup>
                                                        </Col>
                                                    )
                                                }


                                                {
                                                    data.type == 'Record' && (
                                                        <Col md="12">
                                                            <div>
                                                                <Card className="border-0">
                                                                    {
                                                                        mediaBlobUrl ?

                                                                            <audio src={mediaBlobUrl} controls />
                                                                            :
                                                                            <audio src={data.media_url} controls />

                                                                    }
                                                                    <Card.Body>
                                                                        {/* <Card.Title>Recording Status:  {status}</Card.Title> */}
                                                                        <Card.Title>Recording Time: {recordingTime} seconds</Card.Title>
                                                                        <ButtonGroup aria-label="Recording Button">
                                                                            <Button onClick={startRecording}
                                                                                disabled={status === 'recording'}
                                                                                variant="outline-primary"
                                                                            > Start</Button>

                                                                            <Button onClick={() => {
                                                                                stopRecording();
                                                                            }}
                                                                                disabled={status !== 'recording'}
                                                                                variant="outline-primary"
                                                                            >Stop</Button>
                                                                        </ButtonGroup>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        </Col>
                                                    )
                                                }


                                                <Col className="col-12">
                                                    <Button  disabled={isLoading} className="btn-sm" type="submit">Update</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default EditVoiceMailTemplate;