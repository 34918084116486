import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../../layout/default';
import FullPageLayout from '../../../layout/fullpage';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";

function EmailTemplateCreate(props) {
    const fullPageView = props.fullPageView;

    const { id } = useParams();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRecordById();
    }, []);

    const fetchRecordById = () => {
        sendRequest(`dropdown/default/template/${id}`, 'GET').then(response => {
            if (response.status === true) {
                setData(response.data.lists);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`templates/email/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (fullPageView) {
                        props.getUserDetail();
                        return;
                    }
                    
                    props.navigate(`/template-manage/email/lists`);
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const emailTemplateUI = () => {
        return <div className="email-create">
            <Block.Head>
                <Block.HeadContent>
                    <Block.Title tag="h2">Email Template</Block.Title>
                    <nav>
                        <ol className="breadcrumb breadcrumb-arrow mb-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to={`/template-manage/email/lists`}>Email</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"> Create</li>
                        </ol>
                    </nav>
                </Block.HeadContent>
            </Block.Head>
            <Block>
                <Card className="card-gutter-md">
                    <Card.Body>
                        <div className="bio-block">
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md="12">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="title">Title</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="text" name="title" placeholder="Title" required />
                                            </div>
                                        </Form.Group>
                                    </Col>


                                    <Col md="12">
                                        <Form.Group className="form-group">
                                            <Tinymce leadToken={true} height={700} initialValue={data?.body} textareaName={'body'} toolbar={true} />
                                        </Form.Group>
                                    </Col>


                                    <Col className="col-12">
                                        <Button className="btn-sm float-end" type="submit">Create</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </Block>
        </div>
    }

    return (
        <>
            {fullPageView === true ? (
                <FullPageLayout title="Email Template" content="container-fluid" nkWrap={false}>
                    {emailTemplateUI()}
                </FullPageLayout>
            ) : (
                <Layout title="Email Template" content="container-fluid">
                    {emailTemplateUI()}
                </Layout>
            )}
        </>
    )
}

export default EmailTemplateCreate;