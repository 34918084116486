import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { useAuth } from '../../AuthContext';
import DataTableComponent from '../../components/DataTable/DataTable';

function PipelineList() {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	const formRef = useRef(null);
	const childRef = useRef(null);
	const [userColumns, setUserColumns] = useState(
		[
			// {
			// 	name: "Id",
			// 	key: 'id',
			// 	selector: (row) => row.id,
			// 	cell: (row) => (
			// 		<span>{row.id}</span>
			// 	),
			// },

			{
				name: "Title",
				key: 'title',
				selector: (row) => row.title,
				cell: (row) => (

					<Link to={`/pipelines-manage/view/${row.public_id}`}>
						<span>{row.title}</span>
					</Link>


				),
			},
			{
				name: <div className='w-100 text-center'>Module</div>,
				key: 'status',
				selector: (row) => row.module_type,
				cell: (row) => (
					<span className={`badge m-auto text-bg-${row.module_type === "job" ? "info-soft":"secondary-soft"}`
					}>
					{row.module_type ==='job'?'Job':'Contact'}
					</span>
				),
			},
			{
				name: <div className='w-100 text-center'>Status</div>,
				key: 'status',
				selector: (row) => row.status,
				cell: (row) => (
					<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
						: row.status === "Pending" ? "warning-soft"
							: row.status === "Inactive" ? "secondary-soft"
								: "primary-soft"}`
					}>
						{row.status ? row.status : 'General'}
					</span>
				),
			},

			{
				name: "Created Date",
				key: 'dated',
				selector: (row) => row.dated,
				cell: (row) => (
					<span>{row.dated}</span>
				),
			},

			{
				name: "Action",
				key: 'action',
				cell: (row) => (
					<div className="text-center w-100">
						<div className="dropdown-content">
							<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">

								{
									hasPermission('editPipelines') && (
										<button className="btn p-0" onClick={() => { openModal(row) }}>
											<Icon name="edit" ></Icon>
										</button>
									)
								}


								&nbsp;
								{
									hasPermission('deletePipelines') && row.can_delete == 1 && (
										<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
											<Icon name="trash" className='text-danger'></Icon>
										</button>
									)
								}


							</LinkList>
						</div>
					</div>
				),
			},
		]
	);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};


	const deleteRecord = async (id) => {
		sendRequest(`pipelines/${id}`, 'DELETE', { id: id })
			.then(response => {
				if (response.status === true) {
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
			})
			.catch(error => {
				console.error('Delete request error:', error);
			});

	};

	const submitPipeline = async (event) => {
		setLoading(false);
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		let url = 'pipelines/create';
		if (data.public_id) {
			url = `pipelines/update/${data.public_id}`;
		}

		sendRequest(url, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					closeModal();
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
				setLoading(true);
			})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	};

	const openModal = (row = {}) => {
		setData(row);
		setTimeout(() => {
			setModal(true);
		}, 200);
	}

	const closeModal = () => {
		setData({});
		setModal(false);
	}

	return (
		<Layout title="Pipelines List" content="container-fluid">
			<div className="pipelines">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Pipelines List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Pipelines</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								{
									hasPermission('createPipelines') && (
										<li>
											<button className="btn btn-sm btn-primary" onClick={() => openModal()}>
												<Icon name="plus" />
												<span>Add Pipeline</span>
											</button>
										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>
										)
									}
									<Col>
										<Form.Select size="sm" name="module_type" >
											<option value="">All </option>
											<option value="lead">Contact</option>
											<option value="job">Job</option>
										</Form.Select>
									</Col>
									<Col>
										<Form.Select size="sm" name="status" >
											<option value="">All </option>
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>



							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'pipelines/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>

			<Modal show={modal} onHide={() => closeModal()} size="lg">
				<Form onSubmit={submitPipeline}>
					<Modal.Header closeButton>
						<Modal.Title className="event-title" as="h4">{data.public_id ? 'Edit' : 'Add'} Pipeline</Modal.Title>
					</Modal.Header>

					<Modal.Body >
						<Row className="g-gs">

							<Col md="12">
								<Form.Group className="form-group">
									<Form.Label htmlFor="title">Title</Form.Label>
									<div className="form-control-wrap">
										<Form.Control onChange={(e) => setData({ ...data, title: e.target.value })} defaultValue={data.title} type="text" name="title" required />
									</div>
								</Form.Group>
							</Col>

							{/* <Col md="6">
								<Form.Group className="form-group">
									<Form.Label htmlFor="color_code">Title Color </Form.Label>
									<div key={Math.random(4)} className="form-control-wrap">
										<Form.Control onChange={(e) => setData({ ...data, color_code: e.target.value })} required className='w-100' value={data.color_code} type="color" name="color_code" />
									</div>
								</Form.Group>
							</Col> */}
							<Col md="12">
								<Form.Group className="form-group">
									<Form.Label htmlFor="throttle_duration">Module</Form.Label>
									<div className="form-control-wrap">
										<Form.Select defaultValue={data.module_type} onChange={(e) => setData({ ...data, module_type: e.target.value })} name="module_type" className="form-controll">
											<option value="lead">Contact</option>
											<option value="job">Job</option>
										</Form.Select>
									</div>
								</Form.Group>
							</Col>
							<Col md="12">
								<Form.Group className="form-group">
									<Form.Label htmlFor="throttle_duration">Status</Form.Label>
									<div className="form-control-wrap">
										<Form.Select defaultValue={data.status} onChange={(e) => setData({ ...data, status: e.target.value })} name="status" className="form-controll">
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</Form.Select>
									</div>
								</Form.Group>
							</Col>

						</Row>
					</Modal.Body>

					<Modal.Footer>
						<Button className='btn-sm' variant="danger" onClick={() => closeModal()}>Close</Button>
						<Button className='btn-sm' variant="primary" type="submit" >{data.public_id ? 'Update' : 'Create'}</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Layout>
	)
}

export default PipelineList;
