import { useEffect, useState, useRef } from 'react';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Link } from 'react-router-dom';
import { sendRequest } from '../../../utilities/api';
import { Card, Col, Row } from 'react-bootstrap';
function EmailTemplateList(props) {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        fetchTemplates();
    }, [])


    const fetchTemplates = () => {
        sendRequest(`dropdown/default/template`, 'GET').then(response => {
            if (response.status === true) {
                setTemplates(response.data.lists);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    return (
        <Layout title="Template List" content="container-fluid">
            <div className="templates">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Template List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/template-manage/email/lists">Email Manage</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Template</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
            </div>

            <Row>
                {
                    templates.map((row, i) => {
                        return <Col lg="4" key={i} className='mb-3'>
                            <Card>
                                <Link to={`/template-manage/email/create/${row.id}`}>
                                    <Card.Img src={row.image} alt={row.title} />
                                    <Card.ImgOverlay className='center'>
                                        <Card.Title as={'h3'} className='text-white'>{row.title}</Card.Title>
                                    </Card.ImgOverlay>
                                </Link>
                            </Card>
                        </Col>
                    })
                }
            </Row>



        </Layout>
    );
};
export default EmailTemplateList;
