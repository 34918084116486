import { useRef } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from '../../../../utilities/api';
import { Icon, LinkList, LinkListItem } from "../../../../components";
import { useAuth } from '../../../../AuthContext';
import DataTableComponent from '../../../../components/DataTable/DataTable';
import CalanderNav from '../../../nav/CalanderNav';
function StatusList() {
	const { hasPermission } = useAuth();
	const formRef = useRef(null);
	const childRef = useRef(null);

	const deleteRecord = async (id) => {
		sendRequest(`calendar/appointments/status/${id}`, 'DELETE', { id: id })
			.then(response => {
				if (response.status === true) {
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
			})
			.catch(error => {
				console.error('Delete request error:', error);
			});

	};


	const userColumns = [
		{
			name: "Title",
			key: 'title',
			selector: (row) => row.bg_color,
			cell: (row) => (
				<span className='badge' style={{ backgroundColor: row.bg_color, color: row.text_color }}>
					{
						hasPermission('editCalendarAppointmentStatus') ? (
							<Link style={{ color: row.text_color }} to={`/calendar-manage/appointments/status/edit/${row.public_id}`}>
								{row.title}
							</Link>
						) : (
							row.title
						)
					}
				</span>
			),
		},
		{
			name: "Type",
			key: 'type',
			selector: (row) => row.type,
			cell: (row) => (
				<span>{row.type}</span>
			),
		},
		{
			name: "Is Default",
			key: 'can_delete',
			selector: (row) => row.can_delete,
			cell: (row) => (
				<span>{row.can_delete == 1 ? 'Custom' : 'Default'}</span>
			),
		},
		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">

							{
								hasPermission('deleteCalendarAppointmentStatus') && row.can_delete == 1 && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}


						</LinkList>
					</div>
				</div>
			),
		},

	];

	return (
		<Layout title="Appointments Status List" content="container-fluid">
			<div className="locations">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Appointments Status</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/calendar-manage/lists">Calendar</Link></li>
									{/* <li className="breadcrumb-item"><Link to="/calendar-manage/appointments/lists">Appointments</Link></li>
									<li className="breadcrumb-item"><Link to="/calendar-manage/appointments/status/Lists">Status</Link></li> */}
									<li className="breadcrumb-item active" aria-current="page">Status</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								<CalanderNav from={4} />

								{
									hasPermission('createCalendarAppointmentStatus') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to="/calendar-manage/appointments/status/create">
												<Icon name="plus" />
												<span>Add Status</span>
											</Link>

										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'calendar/appointments/status/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>
		</Layout>
	)
}

export default StatusList;
