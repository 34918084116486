import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";

function UserCreate(props) {
    const [type, setType] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const typeValue = params.get('type');

        setType(typeValue);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('groups/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/tags-manage/lists');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Tags" content="container-fluid">
            <div className="group-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Tags {type ? `- ${type}` : ''}</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/tags-manage/lists">Tags</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="6">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col md="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="bg_color">Background Color</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="color" name="bg_color" defaultValue='#fcfcfc' required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="3">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="text_color">Text Color</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="color" name="text_color" defaultValue='#000000' required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        {type === 'Tag' && (
                                            <>
                                                <Col md="6">
                                                    <Form.Label htmlFor="section">Related To Section</Form.Label>

                                                    <div>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox"
                                                            label="Contact"
                                                            name="section"
                                                            value="Contact"
                                                            disabled
                                                            checked
                                                        />
                                                        <Form.Check
                                                            inline
                                                            type="checkbox"
                                                            label="Job"
                                                            name="section"
                                                            value="Job"
                                                        />

                                                        {/* <Form.Check
                                                            inline
                                                            type="checkbox"
                                                            label="Both"
                                                            name="section"
                                                            value="Both"
                                                        /> */}
                                                    </div>
                                                </Col>
                                            </>
                                        )}

                                        <input type="hidden" name="type" value={type} />

                                        <Col className="col-12">
                                            <Button type="submit" className="btn-sm">Create</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>

            </div>
        </Layout>
    )
}

export default UserCreate;