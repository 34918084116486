import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import { getToday } from "../../utilities";
import Swal from "sweetalert2";
import { Select } from "../../components";
// import { TopNav } from "./TopNav";
// import Basic from "./Basic";
// import Inbound from "./Inbound";
// import IvrUpdate from "./IvrUpdate";
// import Outbound from "./Outbound";
// import Automation from "./Automation";

function SequencEdit(props) {
    const { id, type, ivr_id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState('basic');
    const [formLoading, setFormLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            fetchRecordById();
        };
        fetchData();
    }, []);



    const fetchMeta = async () => {
        const formData = new FormData();
        formData.append('section', type);
        formData.append('id', id);
        if (ivr_id) {
            formData.append('ivr_id', ivr_id);
        }

        await sendRequest('sequence/meta/fetch', 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = () => {
        sendRequest(`sequence/${id}/show`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        setFormLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest(`sequence/${id}/update`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire('Success', response.message, 'success')
                }
                setFormLoading(false);

            })
            .catch(error => {
                setFormLoading(false);
                console.error('POST request error:', error);
            });
    };


    return (
        <Layout title="Sequence List" content="container-fluid">
            <div className="group-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Sequence List</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/sequence-manage/lists">Sequence List</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <Tab.Container onSelect={(e) => setCurrentTab(e)} id="custom-tabs-example" defaultActiveKey={currentTab}>
                                        <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="basic">Basic Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="constraint">Constraint Settings</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="basic">
                                                <Form onSubmit={handleSubmit}>
                                                    <Row className="g-3">
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>


                                                        <Form.Control defaultValue={1} type="hidden" name="step" required />

                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="description">Description</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control defaultValue={data.description} as={'textarea'} name="description" placeholder="Description" required />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>




                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="phone_number">Phone Number (Optional)</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={data.phone_number} name="phone_number" className="form-controll">
                                                                        <option value="">Select</option>
                                                                        {
                                                                            meta?.phoneNumbers.map((row, i) => {
                                                                                return <option value={row.number}>{row.number}</option>
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="throttle_duration">Throttle duration</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={data.throttle_duration} name="throttle_duration" className="form-controll">
                                                                        <option value="15">15 minutes </option>
                                                                        <option value="30">30 minutes</option>
                                                                        <option value="60">60 minutes</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="throttle_value">Throttle value</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={data.throttle_value} name="throttle_value" className="form-controll">
                                                                        <option value="5">5</option>
                                                                        <option value="10">10</option>
                                                                        <option value="15">15</option>
                                                                        <option value="25">25</option>
                                                                        <option value="50">50</option>
                                                                        <option value="100">100</option>
                                                                        <option value="150">150</option>
                                                                        <option value="200">200</option>
                                                                        <option value="250">250</option>
                                                                        <option value="300">300</option>
                                                                        <option value="350">350</option>
                                                                        <option value="400">400</option>
                                                                        <option value="450">450</option>
                                                                        <option value="500">500</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>


                                                        <Col md="12">
                                                            <label>Stop on Response</label>
                                                            <br />

                                                            <Form.Check defaultChecked={data.sms_received} name="sms_received" type="checkbox" inline label="SMS Received" />
                                                            <Form.Check defaultChecked={data.incoming_call} name="incoming_call" type="checkbox" inline label="Incoming Call" />
                                                            <Form.Check defaultChecked={data.email_opened} name="email_opened" type="checkbox" inline label="Email Opened" />

                                                        </Col>

                                                        {/* <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="throttle_duration">Status</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Select defaultValue={data.status} name="status" className="form-controll">
                                                                        <option value="Draft">Draft</option>
                                                                        <option value="Active">Active</option>
                                                                        <option value="Inactive">Inactive</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <Form.Label htmlFor="role">Assign To</Form.Label>
                                                                {meta?.coachPartners.length > 0 && (
                                                                    <Select defaultValue={data.permissions} name="permissions[]" multiple removeItemButton>
                                                                        <option value="">Select</option>
                                                                        {
                                                                            meta?.coachPartners.map((row, i) => {
                                                                                return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col className="col-12">
                                                            <Button className="btn-sm" type="submit" variant="primary" disabled={formLoading}>
                                                                {
                                                                    formLoading && (
                                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    )
                                                                }
                                                                <span className="ms-1 btn-sm">Update</span>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="constraint">
                                                <Form onSubmit={handleSubmit}>
                                                    <Row className="g-3">
                                                        <fieldset>
                                                            <p>Current Date: {getToday()}:</p>
                                                            <div className="row g-3 time-constraint">
                                                                <Col md="12">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="throttle_duration">Block Day</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.block_days} placeholder="For Example: 2022-04-16,2022-04-17" name="block_days" className="form-controll" />
                                                                            <div className="note">
                                                                                Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Form.Control defaultValue={2} type="hidden" name="step" required />

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="from_time">From Time</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.from_time} type="time" name="from_time" className="form-controll" />

                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="from_time">To Time</Form.Label>
                                                                        <div className="form-control-wrap">
                                                                            <Form.Control defaultValue={data.to_time} type="time" name="to_time" className="form-controll" />

                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>


                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="skip_weak_days">Skip Week Days</Form.Label>
                                                                        <Form.Select
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="skip_weak_days[]"
                                                                            defaultValue={data.skip_weak_days}
                                                                            multiple
                                                                        >
                                                                            <option value="Monday">Monday</option>
                                                                            <option value="Tuesday">Tuesday</option>
                                                                            <option value="Wednesday">Wednesday</option>
                                                                            <option value="Thursday">Thursday</option>
                                                                            <option value="Friday">Friday</option>
                                                                            <option value="Saturday">Saturday</option>
                                                                            <option value="Sunday">Sunday</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md="6">
                                                                    <Form.Group className="form-group">
                                                                        <Form.Label htmlFor="holidays">USA Holidays</Form.Label>
                                                                        <Form.Select
                                                                            className="select form-control"
                                                                            type="select"
                                                                            name="holidays[]"
                                                                            defaultValue={data.holidays}
                                                                            multiple
                                                                        >
                                                                            {
                                                                                meta.holidays && Object.entries(meta?.holidays).map(([key, value]) => {
                                                                                    return <option value={key}>{value}</option>
                                                                                })

                                                                            }
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                            </div>
                                                        </fieldset>
                                                        <Col className="col-12">
                                                            <Button className="btn-sm" type="submit" variant="primary" disabled={formLoading}>
                                                                {
                                                                    formLoading && (
                                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    )
                                                                }
                                                                <span className="ms-1">Update</span>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default SequencEdit;