import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json'
import { Button, Pagination, Form } from 'react-bootstrap';
import Icon from '../../components/Icon/Icon'

import DataTablePagination from '../Pagination/DataTablePagination';
import { sendRequest } from '../../utilities/api';
import { isObjectNotEmpty } from '../../utilities';

const Export = ({ data, columns }) => {
	const fileName = "data";

	console.log(columns, 'data')
	const getNestedValue = (object, path) => {
		// console.log(object , path , '---------------------')

		return path.split('.').reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), object);
	};

	const exportedData = data
		.filter((item) => columns.every((column) => getNestedValue(item, column.key) !== undefined))
		.map((item) => {
			const filteredItem = {};
			columns.forEach((column) => {
				const columnValue = getNestedValue(item, column.key);
				console.log(column.key, columnValue)

				if (React.isValidElement(column.name)) {
					filteredItem[column.name.props.children] = columnValue;
				} else {
					filteredItem[column.name] = columnValue == null ? '' : columnValue;
				}
			});
			return filteredItem;
		});

	const exportCSV = () => {
		const exportType = exportFromJSON.types.csv;
		exportFromJSON({ data: exportedData, fileName, exportType });
	};

	// const exportExcel = () => {
	// 	const exportType = exportFromJSON.types.xls;
	// 	exportFromJSON({ data: exportedData, fileName, exportType });
	// };

	return (
		<div className="export-options d-flex align-items-center me-2">
			<div className="export-title small me-2">Export</div>
			<div className="btn-group">
				<Button size='sm' variant="outline-light" onClick={() => exportCSV()}>
					CSV
				</Button>

			</div>
		</div>
	);
};

function DataTableComponent({ selectableRows, columns, tableClassName, slug, childRef, extraCondition = {}, actions = false, serchBox = true, sendDataToParent = null, defaultRow = 10, currentEntity = '', ...props }) {
	const [data, setData] = useState([]);

	const [mobileView, setMobileView] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(defaultRow);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [filterData, setFilterData] = useState(new FormData());
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		fetchUsers(currentPage);
	}, [currentPage, perPage, searchText, filterData]);


	const fetchUsers = async (page, newPerPage = perPage, text = searchText, sortColumn = '', sortDirection = '', formData = filterData) => {
		setLoading(true);

		if (isObjectNotEmpty(extraCondition)) {
			Object.entries(extraCondition).forEach(([key, value]) => {
				formData.append(key, value);
			});
		}

		// if (formData && formData.keys().next().done) {
		// formData.append('type', 'Coach');
		// }
		const keywordExists = slug.includes('?');
		if (keywordExists) {
			var new_slug = `${slug}&`;
		} else {
			var new_slug = `${slug}?`;
		}
		let urlSlug = `${new_slug}page_number=${page}&page_size=${newPerPage}&filter_value=${text}&sort_column=${sortColumn}&sort_order=${sortDirection}`;

		sendRequest(urlSlug, 'POST', formData)
			.then(response => {
				setData(response.data.records);
				setTotalRows(response.data.total);
				if (sendDataToParent) {
					sendDataToParent(response.data);
				}
				setLoading(false);
			})
			.catch(error => {
				console.error('POST request error:', error);
				setLoading(false);
			});


	};


	const handlePageChange = page => {
		setCurrentPage(page);
	};

	const handlePerRowsChange = newPerPage => {
		setPerPage(newPerPage);
		setCurrentPage(1);
	};


	const handleSearch = text => {
		setSearchText(text);
		setCurrentPage(1);
	};

	const handleFilter = (formData) => {
		setFilterData(formData);
		setCurrentPage(1);
	};


	const pageNumbers = [];


	for (let i = 1; i <= Math.ceil(totalRows / perPage); i++) {
		pageNumbers.push(i);
	}

	const paginationNumber = () => {
		if (pageNumbers.length <= 5) {
			return pageNumbers;
		} else if (pageNumbers.length >= 5 && currentPage <= 4) {
			return [1, 2, 3, 4, 5, '...', pageNumbers[pageNumbers.length - 1]];
		} else if (pageNumbers.length >= 5 && currentPage >= pageNumbers[pageNumbers.length - 4]) {
			return [1, '...', pageNumbers[pageNumbers.length - 5], pageNumbers[pageNumbers.length - 4], pageNumbers[pageNumbers.length - 3], pageNumbers[pageNumbers.length - 2], pageNumbers[pageNumbers.length - 1]];
		} else if (pageNumbers.length > 5 && currentPage > 4 && currentPage < pageNumbers[pageNumbers.length - 4]) {
			return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pageNumbers[pageNumbers.length - 1]];
		}
	};

	let paginationItems = paginationNumber();



	childRef.current = {
		handleFilter
	};

	return (
		<div className="data-table-wrapper">

			{currentEntity !== 'Tags' && serchBox && (
				<div className="data-table-top">
					<div className="data-table-search">
						<Form.Control
							size="sm"
							className="form-control"
							placeholder="Search"
							type="search"
							onChange={(e) => handleSearch(e.target.value)}
						/>
					</div>
				</div>
			)}


			<DataTable
				columns={columns}
				data={data}
				className={tableClassName}
				noDataComponent={<div className="p-2">There are no records found.</div>}
				sortIcon={
					<div className="data-table-sorter"></div>
				}
				pagination
				expandableRows={mobileView}
				paginationRowsPerPageOptions={[5, 10, 15, 20, 25, defaultRow != 10 && defaultRow]}
				selectableRows={selectableRows}
				paginationServer
				progressPending={loading}
				paginationComponent={() => (
					<>

						{currentEntity !== 'Tags' && (
							<div className="data-table-bottom">
								<div className="pagination-wrap">
									<div className="pagination-info small">
										Showing {perPage * (currentPage - 1) + 1} to {(totalRows > perPage * currentPage) ? perPage * currentPage : totalRows} of {totalRows} entries
									</div>

									<Pagination size="sm" className="pagination-s1 flex-wrap">
										<div className="data-table-action-wrap ">
											{actions && <Export data={data} columns={columns} />}
											<div className="data-table-select">
												<Form.Select
													className="form-select"
													onChange={(e) => handlePerRowsChange(e.target.value)}
													value={perPage}
													size="sm"
												>
													<option value="5">5</option>
													<option value="10">10</option>
													<option value="15">15</option>
													<option value="20">20</option>
													<option value="25">25</option>
													{
														defaultRow != 10 && (
															<option value={defaultRow}>{defaultRow}</option>
														)
													}
												</Form.Select>
												<span className="text">Per page</span>
											</div>
										</div>
										<Pagination.Item
											disabled={currentPage - 1 === 0 ? true : false}
											onClick={(ev) => {
												ev.preventDefault();
												handlePageChange(currentPage - 1);
											}}
										>
											<Icon name="chevron-left" />
										</Pagination.Item>
										{paginationItems.map((item) => {
											return (
												<Pagination.Item
													disabled={isNaN(item)}
													className={` ${currentPage === item ? "active" : ""}`}
													key={item}
													onClick={(ev) => {
														ev.preventDefault();
														handlePageChange(item);
													}}
												>
													{item}
												</Pagination.Item>
											);
										})}
										<Pagination.Item
											disabled={pageNumbers[pageNumbers.length - 1] === currentPage}
											onClick={(ev) => {
												ev.preventDefault();
												handlePageChange(currentPage + 1);
											}}
										>
											<Icon name="chevron-right" />
										</Pagination.Item>
									</Pagination>
								</div>
							</div>
						)}
					</>
				)}
			/>
		</div>
	)
}

export default DataTableComponent;
