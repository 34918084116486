
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, Spinner, Offcanvas } from 'react-bootstrap';
import { sendRequest } from "../../utilities/api";
import Swal from "sweetalert2";
import DataTableComponent from "../../components/DataTable/DataTable";
import { Icon, Select } from "../../components";

function Automation(props) {

    const [id] = useState(props.id);
    const [meta] = useState(props.meta);
    const [data, setData] = useState({ type: 'Trigger' });
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => { setData({ type: 'Trigger' }); setShow(false) };
    const handleShow = () => setShow(true);
    const childRef = useRef(null);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        let url = `campaign/${id}/automation/create`;
        if (data.public_id) {
            url = `campaign/${id}/automation/${data.public_id}/update`;
        }
        sendRequest(`${url}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    handleClose();
                    childRef.current.handleFilter(new FormData());
                    Swal.fire('Success', response.message, 'success')
                }

                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    const deleteRecord = async (automation_id) => {
        sendRequest(`campaign/${id}/automation/${automation_id}`, 'DELETE', {})
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };

    const userColumns = [
        {
            name: "Id",
            key: 'id',
            selector: (row) => row.public_id,
            cell: (row) => (
                <span>{row.public_id}</span>
            ),
        },
        {
            name: "Title",
            key: 'title',
            selector: (row) => row.title,
            cell: (row) => (
                <span>{row.title}</span>
            ),
        },

        {
            name: "Group",
            key: 'group_title',
            selector: (row) => row.group_title,
            cell: (row) => (
                <span>{row.group_title}</span>
            ),
        },

        {
            name: "Type",
            key: 'type',

            selector: (row) => row.type,
            cell: (row) => (
                <span>{row.type}</span>
            ),
        },
        {
            name: "Status",
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Pending" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },

        {
            name: "Dated",
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },

        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">

                    <button className="btn btn-link p-0" onClick={() => { setData(row); handleShow() }}>
                        <Icon name="edit"></Icon>
                    </button>

                    &nbsp; | &nbsp;

                    <Link className="btn btn-link p-0" to={`/campaign-manage/flow/${id}/${row.type}/${row.group_public_id}/${row.public_id}`}>
                        <Icon name="plus"></Icon>
                    </Link>

                    &nbsp; | &nbsp;

                    <button className="btn btn-link p-0" onClick={() => deleteRecord(row.public_id)}>
                        <Icon name="trash" className='text-danger'></Icon>
                    </button>
                </div>
            ),
        },

    ];

    const eventType = [
        {
            value: '1',
            name: 'Inbound Call',
            icon: 'call-fill'
        },
        {
            value: '2',
            name: 'Inbound SMS',
            icon: 'msg-circle'
        },
        {
            value: '6',
            name: 'RVM Fail',
            icon: 'mobile'
        },
    ];

    return (
        <div>
            <div className="bio-block">
                <Button className="btn-sm" variant="primary" onClick={handleShow}>
                    Launch
                </Button>
                <DataTableComponent
                    childRef={childRef}
                    selectableRows={false}
                    columns={userColumns}
                    tableClassName="data-table-head-light table-responsive"
                    slug={`campaign/${id}/automation/lists`}
                    extraCondition={{ 'campaign_id': id }}

                />
                <div className="automation-create">
                    <Offcanvas placement={'end'} show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{data.public_id ? 'Update' : 'Add'} Automation</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md="12">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="title">Title</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control defaultValue={data.title} name="title" placeholder="Title" required />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    {
                                        !data.public_id ? (
                                            <>
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="role">Groups</Form.Label>
                                                        {meta?.groups.length > 0 ? (
                                                            <Select defaultValue={data.group_id} name="group_id" removeItemButton>
                                                                <option value="">Select</option>
                                                                {
                                                                    meta?.groups.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.title}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        ) : (
                                                            <Select name="group_id">
                                                                <option value="">Select</option>
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="type">Type</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select
                                                                onChange={(e) => setData((prevState) => ({
                                                                    ...prevState,
                                                                    'type': e.target.value
                                                                }))}
                                                                defaultValue={data.type} name="type" className="form-controll">
                                                                <option value="Trigger">Trigger</option>
                                                                <option value="Drip">Drip</option>
                                                                <option value="EventReminder">EventReminder</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {
                                                    data.type == 'Trigger' && (
                                                        <>
                                                            <Col md="12">
                                                                <Form.Group className="form-group">
                                                                    <Form.Label htmlFor="run_type">Runs</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Select
                                                                            defaultValue={data.run_type} name="run_type" className="form-controll">
                                                                            <option value="once">Once</option>
                                                                            <option value="multiple">Multiple Times</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col md="12">
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                    {eventType.map((row, i) => {
                                                                        return <>
                                                                            <input
                                                                                name="event_type"
                                                                                defaultValue={row.value}
                                                                                type="radio" class="btn-check"
                                                                                id={'btncheck' + i}
                                                                                autocomplete="off"
                                                                            />

                                                                            <label class="btn btn-outline-primary" for={'btncheck' + i}>
                                                                                <span className="d-grid">
                                                                                    <Icon name={row.icon} />
                                                                                    {row.name}
                                                                                </span>
                                                                            </label>
                                                                        </>

                                                                    })}

                                                                </div>
                                                            </Col>

                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <Col md="12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="status">Status</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Select
                                                            defaultValue={data.status} name="status" className="form-controll">
                                                            <option value="Active">Active</option>
                                                            <option value="Inactive">Inactive</option>
                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )
                                    }


                                    <Col className="col-12">
                                        <Button type="submit" variant="primary" disabled={loading}>
                                            {
                                                loading && (
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                )
                                            }
                                            <span className="ms-1">{data.public_id ? 'Update' : 'Create'}</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </div>
        </div>
    );
};


export default Automation;