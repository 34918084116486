import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, Offcanvas } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, TimeZone, TimePicker } from '../../../components';
import { sendRequest } from "../../../utilities/api";
import Swal from "sweetalert2";
function AppointmentsEdit(props) {

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState({});
    const [slots, setSlots] = useState([]);
    const [meta, setMeta] = useState({});

    useEffect(() => {
        fetchSlots(props.data, props.data.date,'1');
        setModal(true);
    }, []);

    const fetchSlots = async (record, date = record.date_start, from = '0') => {
        setData({});
        
        const formData = new FormData();
        formData.append('calendar_id', record.calendar.public_id);
        formData.append('time_zone', record.time_zone);
        formData.append('date', date);
        formData.append('appointment_id', record.public_id);

        // formData.append('loc_id', loc_id);
        // formData.append('time_zone', zone);
        // formData.append('date', selectedDate);


        await sendRequest(`calendar/appointments/slots/fetch`, 'POST', formData).then(response => {
            if (response.status === true) {
                setData(record);
                setSlots(response.data.slots)
                if(from == '1'){
                    setMeta(response.data);
                }
                setLoading(true)
            }

        })
            .catch(error => {
                setLoading(true)

                console.error('POST request error:', error);
            });
    }

    const updateAppointment = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`calendar/appointments/update/${data.public_id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    //  Swal.fire(response.message);

                    closeModal();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });

    };
    const closeModal = () => {
        setModal(false);
        props.handleReset();
    }

    return (
        <div className="calendar-edit">

            <Offcanvas className="w-75" placement="end" show={modal} onHide={() => closeModal()}>
                <Form onSubmit={updateAppointment}>
                    <Offcanvas.Header className="pt-0 pb-0 bg-light" closeButton >
                        <Offcanvas.Title className="event-title py-1"  as="h4">Edit {data.type == 'event' ? 'Appointment' : data.type}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pt-0 EditAppointment pt-2">
                        {
                            !loading ? (
                                <Spinner animation="grow" variant="primary"></Spinner>
                            ) : (

                                <Row className="">
                                    <Col lg={6}>
                                        <Row>
                                            <Col md="12" className={'mb-1'}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="eventTitle" className="mb-0">Title</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name="title" type="text" defaultValue={data.title} placeholder="Title" />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1 d-none'}>
                                                <Form.Group className="">
                                                    <Form.Label htmlFor="eventTitle">Time Zone</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control type="hidden" defaultValue={data.time_zone} placeholder="TimeZone" />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="eventTitle">Date</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name='date' onChange={(e) => fetchSlots(data, e.target.value,'0')} type="date" defaultValue={data.date} />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group>
                                                    <Form.Label htmlFor="eventTitle">Time</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Select name="start" defaultValue={data.start}>
                                                            {
                                                                slots && slots.map((row, i) => {
                                                                    return <option key={i} value={row.key}>{row.value}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">Sales Person:</Form.Label>
                                                    {meta.sales_person && meta.sales_person.length > 0 && (
                                                        <Select name="sales_person_id" defaultValue={data.sales_person_id} >
                                                            <option value="">Select</option>
                                                            {
                                                                meta.sales_person.map((row, i) => {
                                                                    return <option key={i} value={row.id}> {row.first_name} {row.last_name}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">Products:</Form.Label>
                                                    {meta.products && meta.products.length > 0 && (
                                                        <Select name="product_id" defaultValue={data.product_id} >
                                                            <option value="">Select</option>
                                                            {
                                                                meta.products.map((row, i) => {
                                                                    return <option key={i} value={row.id}> {row.title}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">Client Reminders:</Form.Label>
                                                    {meta.campaigns && meta.campaigns.length > 0 && (
                                                        <Select name="campaign_id" defaultValue={data.campaign_id}  >
                                                            <option value="">Select</option>
                                                            {
                                                                meta.campaigns.map((row, i) => {
                                                                    return <option key={i} value={row.id}> {row.title}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">User Reminders:</Form.Label>

                                                    <Form.Select defaultValue={data.user_reminder} name="user_reminder" className="form-control"  >
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>

                                                    </Form.Select>

                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="role">Change Status</Form.Label>
                                                    {props.appointmentStatus && props.appointmentStatus.length > 0 && (
                                                        <Select name="status_id" defaultValue={data.status_id} >
                                                            <option value="">Select</option>
                                                            {
                                                                props.appointmentStatus.map((row, i) => {
                                                                    return <option key={i} value={row.id}> {row.title}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col md="6" className={'mb-1'}>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="type">Type</Form.Label>
                                                    {meta.appointmentTypes && Object.entries(meta.appointmentTypes).length > 0 && (
                                                        <Select name="type" defaultValue={data.type} >
                                                            <option value="">Select</option>
                                                            {
                                                                Object.entries(meta.appointmentTypes).map(([key, value]) => {
                                                                    return <option key={key} value={key}> {value}</option>
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col md="12">
                                                <Form.Group>
                                                    <Form.Label htmlFor="eventTitle">Notes</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control name='notes' as={'textarea'} defaultValue={data.notes} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        {/* <h5>History</h5> */}

                                        {
                                            (data.logs != '' && data.logs) && (

                                                <div className="mt-4 border-top">
                                                    {
                                                        JSON.parse(data.logs).map((row, i) => {
                                                            return <div key={i}>
                                                                <p
                                                                    class="small border-bottom px-1 py-1 mb-0">
                                                                    <strong>{row.status} </strong>
                                                                    by  <strong>{row.user_name} </strong>
                                                                    at {row.date_time}</p>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </Col>





                                </Row>

                            )
                        }

                        <div className="btn-action mt-2">
                            <Button className="btn-sm" variant="danger" onClick={() => closeModal()}>Close</Button>
                            <Button className="btn-sm ms-1" variant="primary" type="submit" >Update</Button>
                        </div>
                    </Offcanvas.Body>
                </Form>
            </Offcanvas>



        </div>
    )
}

export default AppointmentsEdit;