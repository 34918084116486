import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import CalanderNav from '../../nav/CalanderNav';
import Swal from 'sweetalert2';
import { isObjectNotEmpty } from '../../../utilities';
function CalendarAutoResponderList() {
	const { hasPermission } = useAuth();
	const [meta, setMeta] = useState({ coachPartners: [] });
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});

	const formRef = useRef(null);
	const childRef = useRef(null);


	useEffect(() => {
		fetchMeta();
	}, []);

	const fetchMeta = () => {
		const formData = new FormData();
		sendRequest('campaign/meta/fetch', 'POST', formData).then(response => {
			if (response.status === true) {
				setMeta(response.data);
				setLoading(true);
			}
		})
			.catch(error => {
				setLoading(true);
				console.error('POST request error:', error);
			});
	}

	const openModal = (data = {}) => {
		if (isObjectNotEmpty(data)) {
			setData(data);
			setTimeout(() => {
				setModal(true);

			}, 100);
		} else {
			setModal(true);
		}
	}

	const closeModal = (fetchRecord = false) => {
		if (fetchRecord) {
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
		}
		setData({});
		setModal(false);

	}

	const userColumns = [
		{
			name: "Title",
			key: 'title',
			selector: (row) => row.title,
			cell: (row) => (
				<Link to={`/calendar-manage/responder/series/${row.public_id}`}>
					<span>{row.title}</span>
				</Link>
			),
		},

		{
			name: "Created By",
			key: 'created_by',
			selector: (row) => row.created_by,
			cell: (row) => (
				<span>{row.created_by?.full_name}</span>
			),
		},

		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},
		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},

		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
							{
								hasPermission('editCalendarsAutoResponder') && (
									<button className="btn p-0" onClick={() => openModal(row)}>
										<Icon name="edit" ></Icon>
									</button>
								)
							}


							&nbsp;
							{
								hasPermission('deleteCalendarsAutoResponder') && row.can_delete == 1 && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}
						</LinkList>
					</div>
				</div>
			),
		},

	];

	const handleSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const submitData = new FormData();
		for (let [key, value] of formData.entries()) {
			submitData.append(key, value);
		}

		let url = `calendar/responder/create`;
		if (data.public_id) {
			url = `calendar/responder/update/${data.public_id}`
		}

		sendRequest(url, 'POST', submitData)
			.then(response => {
				if (response.status === true) {
					closeModal(true);
				}
			})
			.catch(error => {
				console.error('POST request error:', error);
			});
	}

	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to remove this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`calendar/responder/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};

	return (
		<Layout title="Autoresponder List" content="container-fluid">
			<div className="Autoresponder">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Autoresponder</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Calendars</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>

							<ul className="d-flex">
								<CalanderNav from={5} />
								<ul className="d-flex">
									{
										hasPermission('createCalendarsAutoResponder') && (
											<li>
												<button className="btn btn-primary d-md-inline-flex btn-sm" onClick={() => openModal()}>
													<Icon name="plus" />
													<span>Add Responder</span>
												</button>

											</li>
										)
									}
								</ul>

							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive"
								slug={'calendar/responder/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>

				<Modal size='md' show={modal} onHide={() => closeModal()}>
					<Form onSubmit={handleSubmit}>
						<Modal.Header closeButton>
							<Modal.Title className="calander-location" as="h4">{data.public_id ? 'Update' : 'Add'} Autoresponder</Modal.Title>
						</Modal.Header>


						<Modal.Body >
							<Form.Group className="form-group mb-2">
								<Form.Label htmlFor="title">Title</Form.Label>
								<div className="form-control-wrap">
									<Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
								</div>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="color_code">Assign Permision </Form.Label>
								<div className="form-control-wrap">
									{meta?.coachPartners.length > 0 && (
										<Select multiple name="permissions[]" defaultValue={data.permissions} removeItemButton>
											<option value="">Select</option>
											{
												meta?.coachPartners.map((row, i) => {
													return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
												})
											}
										</Select>
									)}
								</div>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button size='sm' variant="danger" onClick={() => closeModal()}>Close</Button>
							<Button size='sm' variant="primary" type="submit" >{data.public_id ? 'Update' : 'Add'}</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</div>
		</Layout>
	)
}

export default CalendarAutoResponderList;
