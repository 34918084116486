import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";

function StaffEdit(props) {
    const { id } = useParams();
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRoles();
        fetchRecordById();
    }, []);

    const fetchRoles = () => {
        const formData = new FormData();
        formData.set('type', 'default');
        sendRequest('roles/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setRoles(response.data.records);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = () => {
        sendRequest(`users/Staff/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`users/Staff/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/user-manage/staff/lists');
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Staff" content="container-fluid">
            <div className="staff-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Staff</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/user-manage/staff/lists">Staff Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="first_name">First Name</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.first_name} type="text" name="first_name" placeholder="First Name" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.last_name} type="text" name="last_name" placeholder="Last Name" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.email} type="email" name="email" placeholder="Email" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Password</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="password" name="password" placeholder="Password" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Select defaultValue={data.country_code} name="country_code">
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countries.map((row, i) => {
                                                                        return <option key={i} value={row.code}>{row.label}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Phone</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.phone} type="number" name="phone" placeholder="Phone" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Status </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select defaultValue={data.status} name="status">
                                                                <option value="">Select Status</option>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>

                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col className="col-12">
                                                    <Button type="submit" className='btn-sm'>Update</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default StaffEdit;