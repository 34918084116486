
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sendRequest } from "../../utilities/api";
import { Image, Icon, Media, Select, TimeZone } from '../../components';
import { getToday } from "../../utilities";
import Swal from "sweetalert2";

function Basic(props) {

    const [id] = useState(props.id);
    const [meta] = useState(props.meta);
    const [data, setData] = useState(props.data);
    const [currentTab, setCurrentTab] = useState('basic');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest(`campaign/${id}/update`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire('Success', response.message, 'success')
                }
                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    return (
        <div>
            <div className="bio-block">
                <Tab.Container onSelect={(e) => setCurrentTab(e)} id="custom-tabs-example" defaultActiveKey={currentTab}>
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                        <Nav.Item>
                            <Nav.Link eventKey="basic">Basic Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="constraint">Constraint Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="others">Other Settings</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="basic">
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="title">Title</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Form.Control defaultValue={1} type="hidden" name="step" required />

                                    <Col md="6">
                                        <TimeZone main_key="main_zone" sub_key={"time_zone"} mainZone={data.main_zone} zone={data.time_zone} />
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Groups</Form.Label>
                                            {meta?.groups.length > 0 ? (
                                                <Select defaultValue={data.group_id} name="group_id[]" multiple removeItemButton>
                                                    <option value="">Select</option>
                                                    {
                                                        meta?.groups.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                        })
                                                    }
                                                </Select>
                                            ) : (
                                                <Select name="group_id[]">
                                                    <option value="">Select</option>
                                                </Select>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Tags</Form.Label>
                                            {meta?.tags.length > 0 ? (
                                                <Select defaultValue={data.tag_id} name="tag_id[]" multiple removeItemButton>
                                                    <option value="">Select</option>
                                                    {
                                                        meta?.tags.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                        })
                                                    }
                                                </Select>
                                            ) : (
                                                <Select name="tag_id[]" multiple removeItemButton>
                                                    <option value="">Select</option>
                                                </Select>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="phone_number">Phone Number (Optional)</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Select defaultValue={data.phone_number} name="phone_number" className="form-controll">
                                                    <option value="">Select</option>
                                                    {
                                                        meta?.phoneNumbers.map((row, i) => {
                                                            return <option value={row.number}>{row.number}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="throttle_duration">Throttle duration</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Select defaultValue={data.throttle_duration} name="throttle_duration" className="form-controll">
                                                    <option value="15">15 minutes </option>
                                                    <option value="30">30 minutes</option>
                                                    <option value="60">60 minutes</option>
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="throttle_value">Throttle value</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Select defaultValue={data.throttle_value} name="throttle_value" className="form-controll">
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="150">150</option>
                                                    <option value="200">200</option>
                                                    <option value="250">250</option>
                                                    <option value="300">300</option>
                                                    <option value="350">350</option>
                                                    <option value="400">400</option>
                                                    <option value="450">450</option>
                                                    <option value="500">500</option>
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="ignore_followup_tag">Ignore Followup Tag
                                                <OverlayTrigger placement="top" overlay={
                                                    <Tooltip id="tooltip-top">
                                                        Select the Yes option if you want this message to send regardless of whether or not the lead has responded to this campaign. Leads that respond via any method are automatically removed from all future campaign messaging
                                                    </Tooltip>
                                                }
                                                >

                                                    <button className="btn btn-color-dark btn-hover-primary btn-icon btn-soft p-0">
                                                        <Icon name={'info'} />
                                                    </button>

                                                </OverlayTrigger>
                                            </Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Select defaultValue={data.ignore_followup_tag} name="ignore_followup_tag" className="form-controll">
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="throttle_duration">Status</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Select defaultValue={data.status} name="status" className="form-controll">
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="role">Assign To</Form.Label>
                                            {meta?.coachPartners.length > 0 && (
                                                <Select defaultValue={data.permissions} name="permissions[]" multiple removeItemButton>
                                                    <option value="">Select</option>
                                                    {
                                                        meta?.coachPartners.map((row, i) => {
                                                            return <option key={i} value={row.id}>{row.first_name} {row.last_name}</option>
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col className="col-12">
                                        <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                            {
                                                loading && (
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                )
                                            }
                                            <span className="ms-1 btn-sm">Update</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="constraint">
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <fieldset>
                                        <p>Current Date: {getToday()}:</p>
                                        <div className="row g-3 time-constraint">
                                            <Col md="12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="throttle_duration">Block Day</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control defaultValue={data.block_days} placeholder="For Example: 2022-04-16,2022-04-17" name="block_days" className="form-controll" />
                                                        <div className="note">
                                                            Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Form.Control defaultValue={2} type="hidden" name="step" required />

                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="from_time">From Time</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control defaultValue={data.from_time} type="time" name="from_time" className="form-controll" />

                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="from_time">To Time</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control defaultValue={data.to_time} type="time" name="to_time" className="form-controll" />

                                                    </div>
                                                </Form.Group>
                                            </Col>


                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="skip_weak_days">Skip Week Days</Form.Label>
                                                    <Form.Select
                                                        className="select form-control"
                                                        type="select"
                                                        name="skip_weak_days[]"
                                                        defaultValue={data.skip_weak_days}
                                                        multiple
                                                    >
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="holidays">USA Holidays</Form.Label>
                                                    <Form.Select
                                                        className="select form-control"
                                                        type="select"
                                                        name="holidays[]"
                                                        defaultValue={data.holidays}
                                                        multiple
                                                    >
                                                        {
                                                            meta.holidays && Object.entries(meta?.holidays).map(([key, value]) => {
                                                                return <option value={key}>{value}</option>
                                                            })

                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </div>
                                    </fieldset>
                                    <Col className="col-12">
                                        <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                            {
                                                loading && (
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                )
                                            }
                                            <span className="ms-1">Update</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="others">

                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md="12">
                                        <Form.Control defaultValue={3} type="hidden" name="step" required />

                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="group_status">Inbound Callers Group Add Option</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Check onChange={(e) => setData((prevState) => ({
                                                    ...prevState,
                                                    'group_status': 'All-Groups',
                                                }))} checked={data.group_status === 'All-Groups'} inline name="status" value={'All-Groups'} className="form-check-sm" type="radio" label="All-Groups" />
                                                <Form.Check onChange={(e) => setData((prevState) => ({
                                                    ...prevState,
                                                    'group_status': 'No'
                                                }))} checked={data.group_status === 'No'} inline name="status" value={'No'} className="form-check-sm" type="radio" label="No" />
                                                <Form.Check onChange={(e) => setData((prevState) => ({
                                                    ...prevState,
                                                    'group_status': 'Yes',
                                                }))} checked={data.group_status === 'Yes'} inline name="status" value={'Yes'} className="form-check-sm" type="radio" label="Yes" />
                                            </div>
                                        </Form.Group>

                                        {
                                            data.group_status == 'Yes' ?
                                                <p>Inbound respondants will be added to this specific group</p>
                                                : data.group_status == 'No' ?
                                                    <p>Inbound respondants will not be added to any campaign groups</p>
                                                    : <p>Inbound respondants to the campaign will be automatically added to all groups.
                                                        <span className="text-danger"> Warning*</span> This will trigger any Drips you have set up <br />on groups
                                                        where the lead does not exist, this option is not reccomended.
                                                    </p>
                                        }

                                        {
                                            data.group_status == 'Yes' && (
                                                <Col md="12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="group_status_id">Groups</Form.Label>
                                                        <Form.Select
                                                            className="select form-control"
                                                            type="select"
                                                            name="group_status_id"
                                                            defaultValue={data.group_status_id}
                                                        >
                                                            <option value=""> Select Group </option>
                                                            {
                                                                meta?.groups.length > 0 && meta.groups.map((row, i) => {
                                                                    return <option key={i} value={row.id}> {row.title} </option>
                                                                })
                                                            }

                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }

                                        <div className="form-group">
                                            <h5 className="control-label-more-settings col-md-3 mt-3" >
                                                Incoming SMS Notification</h5>
                                            <div className="col-md-7">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Check onChange={(e) => {
                                                            setData((prevState) => ({
                                                                ...prevState,
                                                                'notify_incoming_sms': e.target.checked ? 'Yes' : 'No',
                                                            }))
                                                        }}
                                                            defaultChecked={data.notify_incoming_sms === 'Yes' ? true : false}
                                                            inline
                                                            defaultValue={data.notify_incoming_sms}
                                                            name="notify_incoming_sms"
                                                            className="form-check-sm"
                                                            type="checkbox"
                                                            label="SMS"
                                                        />
                                                    </div>

                                                    {
                                                        data.notify_incoming_sms === 'Yes' && (
                                                            <div className="col-md-10">
                                                                <Form.Control required type="text" className="form-control" defaultValue={data.notify_incoming_number}
                                                                    name="notify_incoming_number"
                                                                    placeholder="add one/more numbers with country code separated by comma" autocomplete="off" data-lpignore="true" />
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <h5 className="control-label-more-settings col-md-3 mt-3" >
                                                Voicemail Notification</h5>
                                            <div className="col-md-7 mb-3">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Check onChange={(e) => {
                                                            setData((prevState) => ({
                                                                ...prevState,
                                                                'notify_email': e.target.checked ? 'Yes' : 'No',
                                                            }))
                                                        }}
                                                            defaultValue={data.notify_email}
                                                            defaultChecked={data.notify_email === 'Yes' ? true : false}
                                                            inline
                                                            name="notify_email"
                                                            className="form-check-sm"
                                                            type="checkbox"
                                                            label="Email"
                                                        />
                                                    </div>

                                                    {
                                                        data.notify_email === 'Yes' && (
                                                            <div className="col-md-10">
                                                                <Form.Control required type="text" className="form-control" defaultValue={data.email_address}
                                                                    name="email_address"
                                                                    placeholder="add one/more notification emails separated by comma" autocomplete="off" data-lpignore="true" />
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <Form.Check onChange={(e) => {
                                                            setData((prevState) => ({
                                                                ...prevState,
                                                                'notify_sms': e.target.checked ? 'Yes' : 'No',
                                                            }))
                                                        }}
                                                            defaultChecked={data.notify_sms === 'Yes' ? true : false}
                                                            inline
                                                            defaultValue={data.notify_sms}
                                                            name="notify_sms"
                                                            className="form-check-sm"
                                                            type="checkbox"
                                                            label="SMS"
                                                        />
                                                    </div>

                                                    {
                                                        data.notify_sms === 'Yes' && (
                                                            <div className="col-md-10">
                                                                <Form.Control required type="text" className="form-control" defaultValue={data.sms_number}
                                                                    name="sms_number"
                                                                    placeholder="add one/more numbers with country code separated by comma" autocomplete="off" data-lpignore="true" />
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <Col className="col-12">
                                            <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                                {
                                                    loading && (
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    )
                                                }
                                                <span className="ms-1">Update</span>
                                            </Button>
                                        </Col>

                                    </Col>
                                </Row>
                            </Form>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>



            </div>
        </div>
    );
};


export default Basic;