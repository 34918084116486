import { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Card, Col, Form, Nav, Row, Tab, Spinner, Button, OverlayTrigger, Popover, Table, Modal } from 'react-bootstrap';
import { Block, Icon } from '../../components';
import { sendRequest } from '../../utilities/api';
import Swal from 'sweetalert2';
import { HostUrl } from '../../utilities/config';
import InvoiceNav from '../nav/InvoiceNav';
import { Link } from 'react-router-dom';
function Settings() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [notify_data, setNotifyData] = useState({});
    useEffect(() => {
        fetchMeta();
    }, []);

    const fetchMeta = (type) => {
        setLoading(false);
        sendRequest(`payment/setting/all/meta/fetch`, 'POST', {}).then(response => {
            if (response.status === true) {
                setLoading(true);
                setData(response.data.userMeta);
                console.log(response.data.notification_setting, 'dddddddddd')
                setNotifyData(response.data.notification_setting);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to update this settings`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`payment/setting/card/processing/fee`, 'POST', submitData)
                    .then(response => {
                        if (response.status === true) {
                            Swal.fire(response.message);
                            fetchMeta();
                        }
                        setLoading(true);
                    })
                    .catch(error => {
                        setLoading(true);
                        console.error('POST request error:', error);
                    });

            }
        })
    };
    const handleNotifySubmit = async (e) => {
        const { name, checked } = e.target;
        const submitData = new FormData();
        submitData.append(name, checked);
        sendRequest(`payment/setting/card/notification/setting`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                   
                }
                setLoading(true);
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    return (
        <Layout title="Payment Setting" content="container-fluid">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Payment Setting</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">Payment</li>
                                <li className="breadcrumb-item active" aria-current="page">Setting</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <InvoiceNav from="5" />
            <div className="paymentSetting">
                <Block>
                    <Card>
                        <Card.Body>
                            {
                                !loading && (
                                    <div className="d-flex justify-content-center my-5">
                                        <Spinner animation="border" variant="secondary"></Spinner>
                                    </div>
                                )
                            }
                            <Form>
                                <Row className='mb-3'>
                                    <h3 className="nk-block-title">Notification Settings</h3>
                                    <div className="form-control-wrap">
                                        <Form.Check
                                            className="form-group m-2"
                                            name="notification_status"
                                            defaultChecked={notify_data.notification_status}
                                            onChange={(e) => {
                                                handleNotifySubmit(e);
                                            }}
                                            type="switch"
                                            label={"Do you want to send invoice notifications?"}
                                        />
                                    </div>
                                </Row>
                            </Form>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <h3 className="nk-block-title">Credit Card Transaction Processing Fee</h3>
                                    <Table className="table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Credit Card Type</th>
                                                <th className='text-start'>Credit Card Process Fee ($)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>American Express</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.americanexpress}
                                                            type="number"
                                                            name="americanexpress"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discover</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.discover}
                                                            type="number"
                                                            name="discover"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>JCB</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.jcb}
                                                            type="number"
                                                            name="jcb"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Diners Club/ Carte Blanche</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.diners_club}
                                                            type="number"
                                                            name="diners_club"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Visa</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.visa}
                                                            type="number"
                                                            name="visa"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Mastercard</td>
                                                <td>
                                                    <Col md="6">
                                                        <Form.Control
                                                            defaultValue={data.mastercard}
                                                            type="number"
                                                            name="mastercard"
                                                        />
                                                    </Col>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td className='text-left'>
                                                    <Button className='btn-sm' variant="primary" type="submit">
                                                        Save
                                                    </Button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default Settings;
