export const countries = [
    { id: 'usa', label: 'USA / Canada', code: '+1' },
    { id: 'australia', label: 'Australia', code: '+61' },
    { id: 'mexico', label: 'Mexico', code: '+52' },
    { id: 'uk', label: 'United Kingdom', code: '+44' },
    // { id: 'newzealand', label: 'New Zealand' },
    // { id: 'netherlands', label: 'Netherlands' },
    // { id: 'southafrica', label: 'South Africa' },
    // { id: 'ireland', label: 'Ireland' },
    // { id: 'vanuatu', label: 'Vanuatu' },
    // { id: 'pakistan', label: 'Pakistan' },
    // { id: 'uae', label: 'UAE' },
];

export const Interval = [
    { id: 'OneTime', label: 'OneTime' },
    { id: 'Monthly', label: 'Monthly' },
    { id: 'Yearly', label: 'Yearly' },

];

export  const mainTimeZones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
];
