import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Modal, InputGroup, Tab, ListGroup } from 'react-bootstrap';

import Layout from '../../layout/fullpage';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import { useAuth } from "../../AuthContext";

function ProposalView(props) {
    const { id } = useParams();
    const { hasPermission } = useAuth();
    const [modal, setModal] = useState(false);
    const [lists, setLists] = useState({});
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            await fetchRecordById();
        };
        fetchData();
    }, []);


    const fetchRecordById = async () => {
        await sendRequest(`proposals/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setLists(response.data);
                if (response.data.pages.length > 0) {
                    getPage(response.data.pages[0].public_id ?? '')
                }
                setLoading(true);
            }

        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const getPage = async (page_id) => {
        await sendRequest(`cms/proposal_page/${page_id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setLoader(true);
            }

        })
            .catch(error => {
                setLoader(true);
                console.error('POST request error:', error);
            });
    }

    return (
        <Layout title="Proposal " content="container-fluid">
            <div className="container-fluid my-2">
                <div className="Proposals-view">
                    <Block.Head>
                        <Block.HeadContent>
                            <Block.Title tag="h2">{lists.list?.title} </Block.Title>
                            {/* <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/Proposals-manage/lists">Proposal {lists.list?.title} </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">View</li>
                                </ol>
                            </nav> */}
                        </Block.HeadContent>
                    </Block.Head>
                    <Block>

                        {
                            !loading ? (
                                <Spinner animation="grow" variant="primary"></Spinner>
                            ) : (
                                <>
                                    {
                                        lists.pages.length > 0 ? (
                                            <Row>
                                                <Col sm={4}>
                                                    <ListGroup>
                                                        {
                                                            lists.pages.map((row, i) => {
                                                                return (

                                                                    <ListGroup.Item key={i} className={`${row.public_id === data.public_id ? 'active' : ''}`} action onClick={() => getPage(row.public_id)}>
                                                                        {row.title}
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }

                                                    </ListGroup>
                                                </Col>
                                                <Col sm={8}>
                                                    {
                                                        data.public_id && (
                                                            <Card>
                                                                <Card.Body>
                                                                    <div className="proposal-render">
                                                                        <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        ) :

                                            (
                                                <h5 className="text-center text-danger">There are no records found.
                                                </h5>
                                            )
                                    }

                                </>
                            )
                        }

                    </Block >
                </div>
            </div>
        </Layout>
    )
}

export default ProposalView;