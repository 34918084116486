import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { sendRequest } from '../../../../utilities/api';
import { Icon, LinkList, LinkListItem } from "../../../../components";
import { useAuth } from '../../../../AuthContext';
import DataTableComponent from '../../../../components/DataTable/DataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';

function LeadList() {
	const { page } = useParams();
	const currentPage = page !== undefined ? page : 'leads';

	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const formRef = useRef(null);
	const childRef = useRef(null);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setCustomDateFilter(false);
		}
	};


	const deleteRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to delete this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				sendRequest(`crm/leads/filters/${id}`, 'DELETE', { id: id })
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Delete request error:', error);
					});
			}
		});

	};
	const cloneRecord = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to clone this`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, clone it!'
		}).then((result) => {
			if (result.value) {
				const submitData = new FormData();
				submitData.append('id', id);
				sendRequest(`crm/leads/filters/clone/${id}`, 'POST', submitData)
					.then(response => {
						if (response.status === true) {
							if (childRef.current) {
								childRef.current.handleFilter(new FormData());
							}
						}
					})
					.catch(error => {
						console.error('Clone request error:', error);
					});
			}
		});

	};
	const userColumns = [
		{
			name: "Name",
			key: 'name',

			selector: (row) => row.name,
			cell: (row) => (
				<span>
					{
						hasPermission('editLeadsFilter') ? (
							<Link to={`/crm-manage/leads/filters/edit/${row.public_id}${currentPage != 'leads' ? ('/' + currentPage) : ''}`}>
								{row.name}
							</Link>
						) : (
							row.name
						)
					}

				</span>
			),
		},
		{
			name: "Created By",
			key: 'created_by_user',

			selector: (row) => row.created_by_user,
			cell: (row) => (
				<span>{row.created_by_user}</span>
			),
		},
		{
			name: "Shared",
			key: 'shared_to',

			selector: (row) => row.shared_to,
			cell: (row) => (
				<span>{row.shared_to}</span>
			),
		},
		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.status,
			cell: (row) => (
				<span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
					: row.status === "Pending" ? "warning-soft"
						: row.status === "Inactive" ? "secondary-soft"
							: "primary-soft"}`
				}>
					{row.status ? row.status : 'General'}
				</span>
			),
		},

		{
			name: "Created Date",
			key: 'dated',
			selector: (row) => row.dated,
			cell: (row) => (
				<span>{row.dated}</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center justify-content-center">





							{
								hasPermission('viewLeadsFilter') && (
									<button className="btn p-0" onClick={() => cloneRecord(row.public_id)}>
										<Icon name="copy" className='text-success'></Icon>
									</button>
								)
							}
							{
								hasPermission('deleteLeadsFilter') && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}

						</LinkList>
					</div>
				</div>
			),
		},

	];

	return (
		<Layout title="Contact List" content="container">
			<div className="Leads">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Filters List</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to={currentPage == 'leads' ? '/crm-manage/leads/lists' : '/jobs/lists'}>{currentPage == 'jobs' ? 'Jobs' : 'Leads'}</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Filters</li>
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								{
									hasPermission('createLeadsFilter') && (
										<li>
											<Link className='btn btn-primary d-md-inline-flex btn-sm' to={`/crm-manage/leads/filters/create${currentPage != 'leads' ? ('/' + currentPage) : ''}`}>
												<Icon name="plus" />
												<span>New Filter</span>
											</Link>

										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>

							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="">Filter by Date</option>
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Current Month">Current Month</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{
										customDateFilter && (
											<>
												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>
										)
									}
									<Col>
										<Form.Select size="sm" name="module_type" >
											<option value="">All Module</option>
											<option value="lead">Contact</option>
											<option value="job">Job</option>
										</Form.Select>
									</Col>
									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

									</Col>
								</Row>
							</Form>



							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								tableClassName="data-table-head-light table-responsive LeadListTable table-striped"
								slug={'crm/leads/filters/lists'}
								extraCondition={{ type: currentPage }}
							/>
						</Card.Body>
					</Card>
				</Block>
			</div>
		</Layout >
	)
}

export default LeadList;
