import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Dropdown, Form, ListGroup, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { sendRequest } from '../../../../utilities/api';
import { FileUpload, Icon } from '../../../../components';

const DocumentRequest = (props) => {
    const [leadId] = useState(props.leadId);
    const [lists, setLists] = useState([]);
    const [showAddFolderForm, setShowAddFolderForm] = useState(false);
    const [showEditFolderForm, setShowEditFolderForm] = useState(false);

    const [data, setData] = useState({});
    const nextDate = new Date();
    nextDate.setTime(nextDate.getTime() + 1 * 24 * 60 * 60 * 1000);
    const nextDateISOString = nextDate.toISOString().split('T')[0];
    const [items, setItems] = useState([
        { description: '', due_days: 1, due_date: '' },
    ]);
    const [templates, setTemplates] = useState([]);

    const addNewItemRow = () => {
        setItems([...items, { description: '', due_days: 1, due_date: nextDateISOString }]);
    };

    const updateItemField = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        if (field === 'due_days') {
            const date = new Date();
            date.setTime(date.getTime() + value * 24 * 60 * 60 * 1000);
            const newDateISOString = date.toISOString().split('T')[0];
            newItems[index]['due_date'] = newDateISOString;
        }
        setItems(newItems);
    };

    const deleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };


    useEffect(() => {
        const fetchData = async () => {
            await fetchDocumentList();
        };
        fetchData();
    }, []);


    const fetchDocumentList = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        formData.set('lead_id', leadId);
        await sendRequest('document/request/lists', 'POST', formData).then(response => {
            if (response.status === true) {
                setLists(response.data.records);
                setTemplates(response.data.templates);
                setShowAddFolderForm(false);
                setShowEditFolderForm(false);

                setData({});
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('documents', JSON.stringify(items));
        submitData.append('lead_id', leadId);

        sendRequest(`document/request/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchDocumentList();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };



    const handleEditSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('document/request/update/' + data.public_id, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchDocumentList();
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };

    const deleteRecord = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this folder`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!'
        }).then((result) => {
            if (result.value) {
                sendRequest(`document/request/${id}`, 'Delete')
                    .then(response => {
                        if (response.status === true) {
                            fetchDocumentList();
                        }
                    })
                    .catch(error => {
                        console.error('Delete request error:', error);
                    });
            }
        });

    };

    const reset = (edit = false, row) => {
        if (edit) {
            setData(row);
            setShowEditFolderForm(!showEditFolderForm)
        } else {
            setItems([{ description: '', due_days: 1, due_date: '' }]);
            setShowAddFolderForm(!showAddFolderForm);
        }
    }

    const findTemplateById = (id) => {
        let doc = templates.filter((item) => item.id == id);
        if (doc.length > 0) {
            let template = doc[0];
            let documents = JSON.parse(template.documents);
            // Calculate the due_date for each item based on the current date and the 'day' property
            const updatedDocuments = documents.map(item => {
                const dueDate = new Date();
                dueDate.setTime(dueDate.getTime() + item.due_days * 24 * 60 * 60 * 1000);
                return { ...item, due_date: dueDate.toISOString().split('T')[0] };
            });
            setItems(updatedDocuments);
        } else {
            setItems([{ description: '', due_days: 1, due_date: '' }]);
        }
    }

    return (
        <div className='doc-request m-1 mx-0'>



            <Button size='sm' onClick={() => reset()} variant="outline-primary">Request More Document</Button>

            <div className='form mt-2'>
                {
                    showAddFolderForm && (
                        <Form onSubmit={handleSubmit}>

                            <Form.Select className='form-control' onChange={(e) => findTemplateById(e.target.value)}>
                                <option value={''}>Doc Template</option>
                                {
                                    templates.map((row, i) => {
                                        return <option key={i} value={row.id}>{row.title}</option>
                                    })
                                }
                            </Form.Select>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Due In X Days</th>
                                        <th>Due Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>

                                            <td>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control required size="sm" placeholder="Description" value={item.description} name='description[]' onChange={(e) => updateItemField(index, 'description', e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </td>

                                            <td>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control required size="sm" type='number' placeholder="Number of days" value={item.due_days} name='due_days[]' onChange={(e) => updateItemField(index, 'due_days', e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </td>

                                            <td>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control readOnly required size="sm" type='date' placeholder="Due date" value={item.due_date == '' ? nextDateISOString : item.due_date} name='due_date[]' onChange={(e) => updateItemField(index, 'due_days', e.target.value)} />
                                                    </div>
                                                </Form.Group>
                                            </td>

                                            <td>
                                                {
                                                    index != 0 && (
                                                        <button type='button' className="btn btn-sm btn-link ms-1 p-0" onClick={() => deleteItem(index)}>
                                                            <Icon name={'trash'} />
                                                        </button>
                                                    )
                                                }
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <ButtonGroup size='sm' className='mt-0' aria-label="Basic example">
                                <Button type='submit' variant="outline-primary">Request Document </Button>
                                <Button onClick={() => addNewItemRow()} variant="outline-info">Add More</Button>
                                <Button onClick={() => reset()} variant="outline-secondary">Cancel</Button>
                            </ButtonGroup>

                        </Form>
                    )
                }

                {
                    showEditFolderForm && (
                        <Form onSubmit={handleEditSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <div className="form-control-wrap">
                                            <Form.Control required size="sm" placeholder="Description" defaultValue={data.title} name='title' />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="form-group">
                                        <div className="form-control-wrap">
                                            <Form.Control required size="sm" type='date' placeholder="Due date" defaultValue={data.due_date} name='due_date' />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <ButtonGroup size='sm' className='mt-2' aria-label="Basic example">
                                        <Button type='submit' variant="outline-primary">Update </Button>
                                        <Button onClick={() => reset(true, {})} variant="outline-secondary">Cancel</Button>
                                    </ButtonGroup>
                                </Col>

                            </Row>


                        </Form>
                    )
                }
            </div>

            <div className='doc-list table-responsive'>
                {
                    (!showAddFolderForm && !showEditFolderForm) && (
                        <table className='table mb-1'>
                            <thead>
                                <tr>
                                    <td>Sr.No</td>
                                    <td>Description</td>
                                    <td>Due Date</td>
                                    <td>Requester</td>
                                    <td>Response</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lists.map((row, i) => {
                                        return (
                                            <tr key={i} className='my-2'>
                                                <td>{i + 1}</td>
                                                <td>{row.title}
                                                    {
                                                        row.files.length > 0 && (
                                                            <div className='document-file'>
                                                                <ul>
                                                                    {
                                                                        row.files.map((file, k) => {
                                                                            return <li key={k}>
                                                                                <a href={file.url} target='_blank'>
                                                                                    {file.key}
                                                                                </a>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td>{row.due_date}</td>
                                                <td>{row.created_by_user}</td>
                                                <td>{row.status}</td>
                                                <td>
                                                    <button className="btn p-0" onClick={() => reset(true, row)}>
                                                        <Icon name="edit"></Icon>
                                                    </button>

                                                    &nbsp;

                                                    <button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
                                                        <Icon name="trash" className='text-danger'></Icon>
                                                    </button>

                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    )
                }


            </div>

        </div>
    )

}

export default DocumentRequest;
