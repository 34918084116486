import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function ContactNav(props) {
    const { hasPermission } = useAuth();
    return (
        <div>
            <div className='btns'>
                {/* {
                    hasPermission('viewLeads') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '1' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/crm-manage/leads/lists'}>Clients</Link>
                    )
                } */}
                {/* {
                    hasPermission('viewLeads') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '5' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/crm-manage/contacts/lists'}>Contacts</Link>
                    )
                } */}
                {
                    hasPermission('viewGroup') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '2' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/tags-manage/lists'}>Groups & Tags</Link>
                    )
                }
                {
                    hasPermission('viewCustomFields') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '3' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/crm-manage/custom-fields'}>Custom Fields</Link>
                    )
                }
                {
                    hasPermission('viewLeads') && (
                        <Link className={`btn btn-sm btn-soft me-2 ${props.from == '4' ? 'btn-outline-primary' : 'btn-secondary'}`} to={'/crm-manage/leads/export'}>Exported Items</Link>
                    )
                }



            </div>
        </div>
    );
}

export default ContactNav;
