import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

const TreeNode = ({ node, checkedNodes, onCheckboxChange, main = false, }) => {
    // console.log(checkedNodes)
    const isChecked = checkedNodes.includes(node.value);

    const handleCheckboxChange = () => {
        const updatedCheckedNodes = isChecked
            ? checkedNodes.filter((value) => value !== node.value)
            : [...checkedNodes, node.value];

        onCheckboxChange(node.value, !isChecked);
        updateAncestorNodes(node, !isChecked);
        updateChildNodes(node.children, !isChecked);
    };

    const updateChildNodes = (children, isChecked) => {
        if (children) {
            children.forEach((child) => {
                onCheckboxChange(child.value, isChecked);
                updateChildNodes(child.children, isChecked);
            });
        }
    };

    const updateAncestorNodes = (currentNode, isChecked) => {
        if (currentNode.parent) {
            const parent = currentNode.parent;
            const siblings = parent.children;
            const isAnySiblingChecked = siblings.some(
                sibling => checkedNodes.includes(sibling.value)
            );

            if (!isAnySiblingChecked) {
                onCheckboxChange(parent.value, isChecked);
            }

            updateAncestorNodes(parent, isChecked);
        }
    };

    return (
        // className={sub && !main ? 'col-lg-6' : ''
        <ListGroup.Item className={`${main ? 'col-lg-12 main-parent' : 'border-0 col-lg-6 '} ${node.has_child ? 'sub-parent' : ''}`}>
            <Form.Check type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                name={`permision[][${node.value}]`} label={node.label} aria-label={node.label} />

            {node.children && node.children.length > 0 && (
                <ListGroup horizontal className={`row mx-0`}>
                    {node.children.map((child, i) => (
                        <TreeNode
                            key={child.value}
                            node={child}
                            sub={i}
                            checkedNodes={checkedNodes}
                            onCheckboxChange={onCheckboxChange}
                        />
                    ))}
                </ListGroup>
            )}
        </ListGroup.Item>
    );
};

const MultiLevelTreeView = ({ data, initialCheckedNodes }) => {
    // const [checkedNodes, setCheckedNodes] = useState([]);
    const [checkedNodes, setCheckedNodes] = useState(initialCheckedNodes);

    const handleCheckboxChange = (value, isChecked) => {
        if (isChecked) {
            setCheckedNodes((prevCheckedNodes) => [...prevCheckedNodes, value]);
        } else {
            setCheckedNodes((prevCheckedNodes) =>
                prevCheckedNodes.filter((node) => node !== value)
            );
        }
    };

    // Add parent information to the nodes
    const addParentInfo = (nodes, parent = null) => {
        return nodes.map((node) => {
            node.parent = parent;
            if (node.children) {
                node.children = addParentInfo(node.children, node);
            }
            return node;
        });
    };

    const nodesWithParentInfo = addParentInfo(data);

    return (
        <ListGroup className='mb-4'>
            {nodesWithParentInfo.map((node, i) => (
                <TreeNode
                    key={node.value}
                    node={node}
                    checkedNodes={checkedNodes}
                    onCheckboxChange={handleCheckboxChange}
                    main={true}
                />
            ))}
        </ListGroup>
    );
};

export default MultiLevelTreeView;

{/* <ListGroup>
    <ListGroup.Item>
        <Form.Check type="checkbox" label="First checkbox" aria-label="..." />
    </ListGroup.Item>
    <ListGroup.Item>
        <Form.Check type="checkbox" label="Second checkbox" aria-label="..." />
    </ListGroup.Item>
    <ListGroup.Item>
        <Form.Check type="checkbox" label="Third checkbox" aria-label="..." />
    </ListGroup.Item>
    <ListGroup.Item>
        <Form.Check type="checkbox" label="Fourth checkbox" aria-label="..." />
    </ListGroup.Item>
    <ListGroup.Item>
        <Form.Check type="checkbox" label="Fifth checkbox" aria-label="..." />
    </ListGroup.Item>
</ListGroup>
 */}
