import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem, Select, CreatedBy } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import Swal from 'sweetalert2';
import CalanderNav from '../../nav/CalanderNav';
import AppointmentsEdit from './Edit';
function CalendarList(props) {
	const { hasPermission } = useAuth();
	const [customDateFilter, setCustomDateFilter] = useState(false);
	const [meta, setMeta] = useState({});
	const [modal, setModal] = useState({ modal: false, record: {} });

	const formRef = useRef(null);
	const childRef = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMeta();
		};
		fetchData();
	}, []);

	const fetchMeta = async () => {
		await sendRequest(`location/meta/fetch`, 'POST').then(response => {
			if (response.status === true) {
				setMeta(response.data);
			}

		})
			.catch(error => {

				console.error('POST request error:', error);
			});
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		if (childRef.current) {
			childRef.current.handleFilter(formData);
		}
	};

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
			if (childRef.current) {
				childRef.current.handleFilter(new FormData());
			}
			setModal({ modal: false, record: {} })
			setCustomDateFilter(false);
		}
	};


	const deleteRecord = async (id) => {
		sendRequest(`calendar/appointments/${id}`, 'DELETE', { id: id })
			.then(response => {
				if (response.status === true) {
					if (childRef.current) {
						childRef.current.handleFilter(new FormData());
					}
				}
			})
			.catch(error => {
				console.error('Delete request error:', error);
			});

	};

	const userColumns = [
		{
			name: "Appointment Date/Time",
			key: 'date',
			selector: (row) => row.appointment_date,
			cell: (row) => (

				<button className="btn p-0 btn-sm btn-link" onClick={() => setModal({ modal: true, record: row })}>
					<p>{row.appointment_date} </p>
					{/* <small>({row.calendar?.time_zone})</small> */}
				</button>


			),
		},

		{
			name: "Appointment With",
			key: 'lead_id',
			selector: (row) => row.lead_id,
			cell: (row) => (
				<button className="btn p-0 btn-sm btn-link" onClick={() =>  props.navigate(`/crm-manage/leads/dialer/${row.lead_details?.public_id}`)}>
					<span>{row.lead_details?.first_name}</span>
				</button>

				
			),
		},

		{
			name: "Created By",
			key: 'coach_partner_id',
			selector: (row) => row.coach_partner_id,
			cell: (row) => (
				<CreatedBy title={'Select Created By'} primaryKey={row.id} defaultValue={row.coach_partner_id} defaultTitle={row.created_by_user} handleReset={handleReset} tableName="appointments" updatedKey="coach_partner_id" row={row} meta={meta} />

			),
		},

		{
			name: "Calendar",
			key: 'calendar_id',
			selector: (row) => row.calendar_id,
			cell: (row) => (
				<button className="btn p-0 btn-sm btn-link" onClick={() =>  props.navigate(`/calendar-manage/view/${row.calendar?.public_id}`)}>
					<span>{row.calendar?.title}</span>
				</button>
				
			),
		},

		{
			name: "Duration",
			key: 'duration',
			selector: (row) => row.duration,
			cell: (row) => (
				<span>{row.duration} minutes</span>
			),
		},

		{
			name: <div className='w-100 text-center'>Status</div>,
			key: 'status',
			selector: (row) => row.appointment_status_title,
			cell: (row) => (
				<span className='badge m-auto' style={{ backgroundColor: row.appointment_bg_color, color: row?.appointment_status.text_color }}>{row.appointment_status_title}</span>
			),
		},
		{
			name: "Action",
			key: 'action',
			cell: (row) => (
				<div className="text-center w-100">
					<div className="dropdown-content">
						<LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center align-items-center">
							{/* {
								hasPermission('editCalendarAppointment') && (
									<button className="btn p-0" onClick={() => setModal({ modal: true, record: row })}>
										<Icon name="edit"></Icon>
									</button>
								)
							} */}
							{/* &nbsp; */}
							{
								hasPermission('deleteCalendarAppointment') && (
									<button className="btn p-0" onClick={() => deleteRecord(row.public_id)}>
										<Icon name="trash" className='text-danger'></Icon>
									</button>
								)
							}


						</LinkList>
					</div>
				</div>
			),
		},

	];


	return (
		<Layout title="Calendar List" content="container-fluid">
			<div className="locations">
				<Block.Head>
					<Block.HeadBetween>
						<Block.HeadContent>
							<Block.Title tag="h2">Calendar</Block.Title>
							<nav>
								<ol className="breadcrumb breadcrumb-arrow mb-0">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/calendar-manage/lists">Appointments</Link></li>
									{/* <li className="breadcrumb-item active" aria-current="page">List</li> */}
								</ol>
							</nav>
						</Block.HeadContent>
						<Block.HeadContent>
							<ul className="d-flex">
								<CalanderNav from={3} />
								{
									hasPermission('createCalendarAppointment') && (
										<li>
											{/* <Link className='btn btn-primary d-md-inline-flex btn-sm' to="/calendar-manage/appointments/create">
												<Icon name="plus" />
												<span>Add Appointment</span>
											</Link> */}
										</li>
									)
								}
							</ul>
						</Block.HeadContent>
					</Block.HeadBetween>
				</Block.Head>
				<Block>
					<Card>
						<Card.Body>
							<Form ref={formRef} onSubmit={handleSubmit}>
								<Row className="g-3">
									<Col>
										<Form.Select
											size="sm"
											onChange={(e) =>
												e.target.value == "Custom Date"
													? setCustomDateFilter(true)
													: setCustomDateFilter(false)
											}
											name="date_range">
											<option value="Current Month">Current Month</option>
											{/* <option value="">Filter by Date</option> */}
											<option value="Current Week">Current Week</option>
											<option value="Last Week">Last Week</option>
											<option value="Last Month">Last Month</option>
											<option value="Last 3 Months">Last 3 Months</option>
											<option value="Last 6 Months">Last 6 Months</option>
											<option value="Current Year">Current Year</option>
											<option value="Last Year">Last Year</option>
											<option value="Custom Date">Custom Date</option>
										</Form.Select>
									</Col>
									{
										customDateFilter && (
											<>

												<Col>
													<Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>

												<Col>
													<Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
												</Col>
											</>

										)
									}

									<Col>
										<Form.Select size="sm" name="status_id" >
											<option value="">All </option>
											{meta.appointmentStatus && meta.appointmentStatus.map((row, i) => {
												return <option key={i} value={row.id}> {row.title}</option>
											})}
										</Form.Select>
									</Col>

									<Col>
										<Form.Select size="sm" name="calendar_id" >
											<option value="">All </option>
											{
												meta.calendars && meta.calendars.map((row, i) => {
													return <option key={i} value={row.public_id}>{row.title} {row.sub_title}</option>
												})
											}


										</Form.Select>
									</Col>

									<Col>
										<Button size="sm" type="submit" className='mr-5'>Filter</Button>
										<Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>
									</Col>
								</Row>
							</Form>
							<DataTableComponent
								childRef={childRef}
								selectableRows={false}
								columns={userColumns}
								serchBox={false}
								tableClassName="data-table-head-light table-responsive"
								slug={'calendar/appointments/lists'}
							/>
						</Card.Body>
					</Card>
				</Block>

				{
					modal.modal && (
						<AppointmentsEdit data={modal.record} handleReset={handleReset} appointmentStatus={meta.appointmentStatus} />
					)
				}

			</div>
		</Layout>
	)
}
export default CalendarList;
