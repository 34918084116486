import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { toInitials, uInfo } from "../../utilities";
import { sendRequest } from "../../utilities/api";
import MultiLevelTreeView from "./MultilLevelTreeView";

function RoleCreate(props) {
    const [pages, setPages] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [indeterminateStates, setIndeterminateStates] = useState({});
    const [parentCheckedStates, setParentCheckedStates] = useState({});
    const [defaultSelected, setDefaultSelected] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPages();
    }, [])

    const fetchPages = (type = 'Client') => {
        sendRequest(`dropdown/pages/${uInfo().id}/${type}`, 'GET').then(response => {
            if (response.status === true) {
                setDefaultSelected(response.data.allValue);
                setPages(response.data.lists);
                setTimeout(() => {
                    setLoading(true);
                }, 200);
            }
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }




    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest('roles/create', 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/role-manage/list');
                }
            })
            .catch(error => {
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Roles Edit" content="container-fluid">
            <div className="role-create">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Roles</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/role-manage/list">Role Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>
                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Col md="12">
                                        {
                                            (uInfo().type == 'Reseller' || uInfo().type == 'Staff') && uInfo().id != 1 && (
                                                <Form.Group className="form-group mb-3">
                                                    <Form.Label htmlFor="phone">Role For</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Select onChange={(e) => fetchPages(e.target.value)} name="master_type">
                                                            <option value="Client">Client</option>
                                                            <option value="Staff">Staff</option>

                                                        </Form.Select>
                                                    </div>
                                                </Form.Group>
                                            )
                                        }
                                    </Col>

                                    <Form.Group className="form-group mb-4">
                                        <Form.Label htmlFor="exampleFormControlInputText1">Name</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control type="text" name="name" placeholder="Role Name" required />
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="form-group mb-4">
                                        <Form.Label htmlFor="exampleFormControlInputText1">Description</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control as="textarea" placeholder="Role Description" name="description" rows="3"></Form.Control>
                                        </div>
                                    </Form.Group>

                                    {
                                        loading && (

                                            <MultiLevelTreeView data={pages} initialCheckedNodes={defaultSelected} />
                                        )
                                    }


                                    <Col className="col-12">
                                        <Button type="submit" className="btn-sm">Create Role</Button>
                                    </Col>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout >
    )
}

export default RoleCreate;