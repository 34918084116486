import { Button, Form, Row, Col, Offcanvas } from 'react-bootstrap';
import { Select } from "../../../components";

function CreateContact({  show, handleClose, editingContact = {}, handlePrimarySubmit, contactRelationsTypes = [] }) {

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="">{editingContact?.id ? 'Edit' : 'Add'} Contact</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={handlePrimarySubmit}>
                    <Row className="align-items-center justify-content-between">
                        <Row className="g-3 mx-0 mt-0">
                            <Col md='12'>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="name">Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control type="text" name="name" placeholder="Name" defaultValue={editingContact?.name} required />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md='12'>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="title">Title</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control type="text" name="title" placeholder="Title" defaultValue={editingContact?.title} required />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md='12'>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control type="email" name="email" placeholder="Email" defaultValue={editingContact?.email} required />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md='12'>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="date_of_birth">Date of Birth</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control type="date" name="date_of_birth" placeholder="Date of Birth" defaultValue={editingContact?.date_of_birth} />
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md="12">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="contact_relation">Relationship</Form.Label>
                                    <Select name="contact_relation" removeItemButton title="Select" defaultValue={editingContact?.contact_relation}>
                                        <option value="">Select</option>
                                        {contactRelationsTypes && Object.keys(contactRelationsTypes).map((row, i) => {
                                            return <option key={i} value={row}>{row}</option>
                                        })}
                                    </Select>
                                </Form.Group>
                            </Col>

                            <Col md="12">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="Address">Address</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control type="Address" name="address" placeholder="Address" defaultValue={editingContact?.address} />
                                    </div>
                                </Form.Group>
                            </Col>

                            <input type="hidden" name="contact_type" value="primary"></input>
                            <input type="hidden" name="lead_type" value="contact"></input>
                            <input type="hidden" name="id" value={editingContact?.public_id}></input>

                        </Row>
                        <Col className="col-12 text-end pe-0 mt-2">
                            <Button variant="primary" type="submit" className="btn-sm">
                                <span>{editingContact?.id ? 'Update' : 'Create'}</span>
                            </Button>
                            <Button variant="danger" type="button" className="btn-sm ms-2 my-0 me-3 " onClick={handleClose}>
                                <span>Cancel</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CreateContact;