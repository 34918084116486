import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../../layout/fullpage';
import { sendRequest } from '../../../../utilities/api';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import { Tab, Nav, OverlayTrigger, Card, Row, Col, ProgressBar } from 'react-bootstrap';
import FunnelLogo from './../../../../assets/images/default.png';
import Icon from "../../../../components/Icon/Icon.js";

import * as SignaturePad from 'signature_pad';
import FormWizard from './FormWizard.js';

function FunnelDetails(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState([]);
    const [thankYouNote, setThankYouNote] = useState(false);



    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            fetchRecordById();
        };
        fetchData();
    }, []);


    const fetchRecordById = () => {
        sendRequest(`funnelForm/fetch/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setSteps(response.data.list?.metadata)
                setFormData(getInitialFormData(response.data.list?.metadata))
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    }


    function getInitialFormData(data) {
        const initialFormData = {};
        data.forEach((step) => {
            step.forEach((field) => {
                if (field.type === "select" || field.type === "radio-group") {
                    // For select and radio-group types, find the first selected option
                    const selectedOption = field.values.find(option => option.selected);
                    if (selectedOption) {
                        initialFormData[field.name] = selectedOption.value;
                    } else {
                        // If no option is selected, default to the first option
                        initialFormData[field.name] = field.values[0].value;
                    }
                } else if (field.type === "checkbox-group") {
                    // For checkbox type, initialize as an empty array
                    initialFormData[`${field.name}`] = [];
                } else {
                    // For other types, initialize as an empty string
                    if (field.name) {
                        initialFormData[field.name] = '';
                    }
                }
            });
        });
        return initialFormData;
    }

    const handleNext = () => {
        // Validate current step's fields
        if (!validateStep(currentStep)) {
            return;
        }

        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (signature = '') => {
        // Validate all fields before submitting
        if (!validateAllSteps()) {
            return;
        }


        if (formData.signature?.digital_signature_image_url && signature != '') {
            formData.signature.digital_signature_image_url = signature;
        }

        const submitData = new FormData();
        submitData.append('data', JSON.stringify(formData));
        sendRequest(`saveFunnelFormData/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (response.data.redirect_link != '') {
                        Swal.fire({
                            title: 'Please wait while we redirect you to the checkout page.',
                            icon: 'info',
                        });
                        window.location.href = response.data.redirect_link;
                    } else {
                        setThankYouNote(true);
                        setCurrentStep(currentStep + 1);
                        // Swal.fire({
                        //     title: 'Action performed successfully',
                        //     icon: 'success',
                        // });
                        // window.location.reload();
                    }
                }
                // setLoading(true);
            })
            .catch(error => {
                // setLoading(true);
                console.error('POST request error:', error);
            });
    }

    const validateStep = (stepIndex) => {
        const stepFields = steps[stepIndex];
        for (const field of stepFields) {
            if (field.required) {
                if (field.type === "checkbox-group") {
                    const fieldName = field.name;
                    if (!formData[fieldName]?.length) {
                        alert(`Please select at least one option for the ${field.label} field.`);
                        return false;
                    }
                } else if (!formData[field.name]) {
                    alert(`Please fill in the ${field.label} field.`);
                    return false;
                }
            }
        }
        return true;
    };

    // const validateStep = (stepIndex) => {
    //     const stepFields = steps[stepIndex];
    //     for (const field of stepFields) {
    //         if (field.required && !formData[field.name]) {
    //             alert(`Please fill in the ${field.label} field.`);
    //             return false;
    //         }
    //     }
    //     return true;
    // };


    const validateAllSteps = () => {
        for (let i = 0; i < steps.length; i++) {
            if (!validateStep(i)) {
                return false;
            }
        }
        return true;
    };

    // const handleChange = (fieldName, value, type = "") => {
    //     console.log(fieldName , value , type )
    //     setFormData(prevFormData => {
    //         // Check if the fieldName already exists in formData
    //         if (prevFormData.hasOwnProperty(fieldName)) {
    //             // If fieldName exists, update the value
    //             return { ...prevFormData, [fieldName]: value };
    //         } else {
    //             // If fieldName does not exist, add it
    //             return { ...prevFormData, [fieldName]: value };
    //         }
    //     });
    // };

    const handleChange = (fieldName, value, type = "") => {
        setFormData(prevFormData => {
            if (prevFormData.hasOwnProperty(fieldName)) {
                if (type === "checkbox") {
                    let updatedValue;
                    if (Array.isArray(prevFormData[fieldName])) {
                        if (prevFormData[fieldName].includes(value)) {
                            updatedValue = prevFormData[fieldName].filter(item => item !== value);
                        } else {
                            updatedValue = [...prevFormData[fieldName], value];
                        }
                    } else {
                        updatedValue = [value];
                    }
                    return { ...prevFormData, [fieldName]: updatedValue };
                } else {
                    return { ...prevFormData, [fieldName]: value };
                }
            } else {
                return { ...prevFormData, [fieldName]: value };
            }
        });
    };

    return (
        <Layout title="Lead Form" content="container">
            {
                loading && (
                    <div className="MainBg" style={{ background: `${data.theme?.background_type == 'image' ? `url(${data.theme?.background_image_color})` : data.theme?.background_image_color}`, opacity: (data.theme?.overlay / 100) }}>
                        <div className="container">
                            <div className='row'>
                                <div className="col-12 col-lg-12 m-auto">
                                    {
                                        data.header_section?.status && (
                                            <div className='LogoHeader d-flex justify-content-between align-items-center py-3 px-4' style={{ background: '#fff' }}>
                                                <div className='LogoShow'>
                                                    {
                                                        data.header_section?.image_url != '' ?
                                                            <img src={data.header_section?.image_url} alt='Logo' style={{ width: '40px' }}></img>
                                                            :
                                                            <h2 style={{ color: data.header_section?.text_color }}> {data.header_section?.logo_text}</h2>
                                                    }
                                                </div>
                                                <div className='phone-bar text-end'>
                                                    {
                                                        data.header_section?.phone_status && (
                                                            <div className='ShowPhone d-flex align-items-center justify-content-end'>
                                                                <Icon name="mobile" className="fs-2"></Icon> <h3 style={{ color: data.theme?.color_scheme }}>{data.header_section?.phone_number}</h3>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={`my-2 ${(currentStep == 0) && ('d-none')}`}>
                                                        <ProgressBar style={{ background: '#ddd', width: '300px' }} variant='info' max={100} min={0} color='red' animated now={((currentStep + 1) / data.metadata.length * 90)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        !thankYouNote && (
                                            <div className='FunnelBody my-1 p-3'>
                                                {
                                                    !data.header_section?.status && (
                                                        <div className={`d-flex mb-4 false justify-content-end ${(currentStep == 0) && ('d-none')}`}>
                                                            <ProgressBar style={{ background: '#ddd' }} className='w-25' variant='info' max={100} min={0} color='red' animated now={((currentStep + 1) / data.metadata.length * 90)} />
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (data.welcome_text != "" && currentStep == 0) && (
                                                        <div className='' dangerouslySetInnerHTML={{ __html: data.welcome_text }} />
                                                    )
                                                }

                                                {
                                                    data.theme?.design_type == 'boxed' && (
                                                        <Row>
                                                            <Col lg="8">
                                                                <FormWizard
                                                                    steps={steps}
                                                                    currentStep={currentStep}
                                                                    onNext={handleNext}
                                                                    onPrev={handlePrev}
                                                                    onSubmit={handleSubmit}
                                                                    onChange={handleChange}
                                                                    formData={formData}
                                                                    cta={data.cta != '' ? data.cta : "Lets Go"}
                                                                    ctaButtonColor={data.theme?.button}
                                                                    ctaColor={data.theme?.cta_color}
                                                                    question={data.theme?.question_heading}
                                                                    answer={data.theme?.answer}
                                                                    theme={data.theme?.color_scheme}
                                                                    layout="boxed"


                                                                />
                                                            </Col>
                                                            <Col lg="4">
                                                                {
                                                                    data.theme?.feature_type == 'video' && (
                                                                        <div className='video'>
                                                                            <iframe className="video_object" width="320" height="570" src={data.theme?.feature_url} /> </div>
                                                                    )
                                                                }

                                                                {
                                                                    data.theme?.feature_type == 'image' && (
                                                                        <div className='image'>
                                                                            <img src={data.theme?.feature_url} />
                                                                        </div>
                                                                    )
                                                                }

                                                            </Col>

                                                        </Row>
                                                    )
                                                }

                                                {
                                                    data.theme?.design_type == 'full' && (
                                                        <Row>
                                                            <Col lg="3">

                                                                {
                                                                    data.theme?.feature_type == 'image' && (
                                                                        <div className='image'>
                                                                            <img src={data.theme?.left_image_url} />
                                                                        </div>
                                                                    )
                                                                }
                                                            </Col>

                                                            <Col lg="6">
                                                                <FormWizard
                                                                    steps={steps}
                                                                    currentStep={currentStep}
                                                                    onNext={handleNext}
                                                                    onPrev={handlePrev}
                                                                    onSubmit={handleSubmit}
                                                                    onChange={handleChange}
                                                                    formData={formData}
                                                                    cta={data.cta != '' ? data.cta : "Lets Go"}
                                                                    ctaButtonColor={data.theme?.button}
                                                                    ctaColor={data.theme?.cta_color}
                                                                    question={data.theme?.question_heading}
                                                                    answer={data.theme?.answer}
                                                                    theme={data.theme?.color_scheme}
                                                                    layout="full"


                                                                />
                                                            </Col>
                                                            <Col lg="3">

                                                                {
                                                                    data.theme?.feature_type == 'image' && (
                                                                        <div className='image'>
                                                                            <img src={data.theme?.right_image_url} />
                                                                        </div>
                                                                    )
                                                                }

                                                            </Col>

                                                        </Row>
                                                    )
                                                }




                                                {/* <form className='UserFormFunnel text-center'>
	<label className='text-center mb-4 mt-3' style={{ color: '#1b468d' }}>Name</label>
	<div className='row '>
		<div className='col-lg-6 mb-3 pe-1'>
			<select className='form-control text-center'>
				<option value="Mr.">Mr.</option>
				<option value="Mrs.">Mrs.</option>
				<option value="Ms.">Ms.</option>
				<option value="Dr.">Dr.</option>
				<option value="Prof.">Prof.</option>
			</select>
		</div>
		<div className='col-lg-6 ps-1'>
			<input type='text' className='form-control' placeholder='First Name' style={{ color: 'red' }}></input>
		</div>
		<div className='col-lg-6 pe-1'>
			<input type='text' className='form-control' placeholder='Middle Name' style={{ color: 'red' }}></input>
		</div>
		<div className='col-lg-6 ps-1'>
			<input type='text' className='form-control' placeholder='Last Name' style={{ color: 'red' }}></input>
		</div>
		<div className='col-lg-12'>
			<span className="input-group-btn" id="button_1">
				<button className="button button--tamaya button--border-thick" data-text="Lets Go!" type="button" onclick="move_to_next1.call(this,'mdf_14');">Lets Go!<Icon name="arrow-right"></Icon></button>
			</span>
		</div>
	</div>
	</form> */}
                                            </div>
                                        )
                                    }

                                    {
                                        thankYouNote && (
                                            <div className='FunnelBody my-1 p-3'>
                                                {
                                                    !data.header_section?.status && (
                                                        <div className={`d-flex mb-4 false justify-content-end ${(currentStep == 0) && ('d-none')}`}>
                                                            <ProgressBar style={{ background: '#ddd' }} className='w-25' variant='info' max={100} min={0} color='red' animated now={((currentStep + 1) / data.metadata.length * 90)} />
                                                        </div>
                                                    )
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: data.thankyou_text }} />
                                            </div>
                                        )
                                    }

                                    {
                                        data.footer_section?.status && (
                                            <div className='FunnelFooter text-center py-2' style={{ background: '#fff' }}>
                                                <div dangerouslySetInnerHTML={{ __html: data.footer_section?.text }} />
                                            </div>
                                        )
                                    }

                                    <div className='SecureIcon d-flex justify-content-end my-1 align-items-center'>
                                        <Icon name="lock-fill"></Icon>
                                        <h4 className="poweredby">&nbsp;&nbsp;Secured by&nbsp;&nbsp;
                                            <a href="#">
                                                <img src='https://app.10xcrm.com/resource/logo.png' alt='logo'></img>
                                            </a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Layout>
    )
}

export default FunnelDetails;
