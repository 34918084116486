import { useEffect, useState, useRef } from 'react';
import { Card, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
// import DataTable from 'react-data-table-component';
import { sendRequest } from '../../../utilities/api';
import { Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import { useAuth } from '../../../AuthContext';
import DataTableComponent from '../../../components/DataTable/DataTable';
import { capitalizeFirstLetter, uInfo } from '../../../utilities';
function PagesCms() {
    const { type, id } = useParams();

    const { hasPermission } = useAuth();
    const [customDateFilter, setCustomDateFilter] = useState(false);
    const formRef = useRef(null);
    const childRef = useRef(null);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        if (childRef.current) {
            childRef.current.handleFilter(formData);
        }
    };

    const handleReset = () => {
        if (formRef.current) {
            formRef.current.reset();
            if (childRef.current) {
                childRef.current.handleFilter(new FormData());
            }
            setCustomDateFilter(false);
        }
    };


    const deleteRecord = async (id) => {
        sendRequest(`groups/${id}`, 'DELETE', { id: id })
            .then(response => {
                if (response.status === true) {
                    if (childRef.current) {
                        childRef.current.handleFilter(new FormData());
                    }
                }
            })
            .catch(error => {
                console.error('Delete request error:', error);
            });

    };


    const userColumns = [
        // {
        //     name: "Id",
        //     key: 'id',
        //     selector: (row) => row.id,
        //     cell: (row) => (
        //         <span>{row.id}</span>
        //     ),
        // },
        {
            name: "Title",
            key: 'title',

            selector: (row) => row.name,
            cell: (row) => (
                <Link to={id ? `/cms-manage/${type}/view/${row.public_id}/${id}` : `/cms-manage/${type}/view/${row.public_id}`}>
                    <span>{row.title}</span>
                </Link>


            ),
        },

        {
            name: "Type",
            key: 'sub_type',

            selector: (row) => row.sub_type,
            cell: (row) => (
                <span>{row.sub_type}</span>

            ),
        },

        {

            name: <div className='w-100 text-center'>Status</div>,
            key: 'status',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={`badge m-auto text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Pending" ? "warning-soft"
                        : row.status === "Inactive" ? "secondary-soft"
                            : "primary-soft"}`
                }>
                    {row.status ? row.status : 'General'}
                </span>
            ),
        },

        {
            name: <div className='w-100 text-start'>Dated</div>,
            key: 'dated',
            selector: (row) => row.dated,
            cell: (row) => (
                <span>{row.dated}</span>
            ),
        },

        {
            name: "Action",
            key: 'action',
            cell: (row) => (
                <div className="text-center w-100">
                    <div className="dropdown-content">
                        <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center">
                            {
                                !['disclaimer', 'termsofservice', 'privacypolicy'].includes(type) && (
                                    <>
                                        {
                                            hasPermission(['editDisclaimer', 'editTerms', 'editPrivacy', 'editNotification', 'editProposals']) && (
                                                <LinkListItem to={id ? `/cms-manage/${type}/edit/${row.public_id}/${id}` : `/cms-manage/${type}/edit/${row.public_id}`}>
                                                    <Icon name="edit"></Icon>
                                                </LinkListItem>
                                            )
                                        }
                                    </>
                                )
                            }

                        </LinkList>
                    </div>
                    {/* <Dropdown>
                        <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                            <Icon name="more-v"></Icon>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="center">
                            
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            ),
        },

    ];

    if ((uInfo().type == 'Manager' || uInfo().type == 'SubManager')) {
        // Find the index of the status object in the array
        const indexToRemove = userColumns.findIndex(column => column.key === 'status');
        // Remove the status object from the array
        userColumns.splice(indexToRemove, 1);

    }else{
        const indexToRemove = userColumns.findIndex(column => column.key === 'sub_type');
        // Remove the status object from the array
        userColumns.splice(indexToRemove, 1);
    }


    if (['disclaimer', 'termsofservice', 'privacypolicy'].includes(type)) {
        userColumns.pop();
    }

    return (
        <Layout title="CMS List" content="container-fluid">
            <div className={`${type}_pages`}>
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">{capitalizeFirstLetter(type)} List</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active " aria-current="page"><Link to={id ? `/proposals-manage/lists` : `/cms-manage/${type}/lists`}> {capitalizeFirstLetter(type)}</Link></li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <ul className="d-flex">
                                {
                                    hasPermission(['createDisclaimer', 'createTerms', 'createPrivacy', 'createNotification', 'createProposals']) && (
                                        <li>
                                            <Link className='btn btn-primary d-md-inline-flex btn-sm' to={id ? `/cms-manage/${type}/create/${id}` : `/cms-manage/${type}/create`}>
                                                <Icon name="plus" />
                                                <span>Add</span>
                                            </Link>

                                        </li>
                                    )
                                }
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    <Card>
                        <Card.Body>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) =>
                                                e.target.value == "Custom Date"
                                                    ? setCustomDateFilter(true)
                                                    : setCustomDateFilter(false)
                                            }
                                            name="date_range">
                                            <option value="">Filter by Date</option>
                                            <option value="Current Week">Current Week</option>
                                            <option value="Last Week">Last Week</option>
                                            <option value="Current Month">Current Month</option>
                                            <option value="Last Month">Last Month</option>
                                            <option value="Last 3 Months">Last 3 Months</option>
                                            <option value="Last 6 Months">Last 6 Months</option>
                                            <option value="Current Year">Current Year</option>
                                            <option value="Last Year">Last Year</option>
                                            <option value="Custom Date">Custom Date</option>
                                        </Form.Select>
                                    </Col>
                                    {
                                        customDateFilter && (
                                            <>
                                                <Col>
                                                    <Form.Control size="sm" type="date" name="start_date" placeholder="Start date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>

                                                <Col>
                                                    <Form.Control size="sm" type="date" name="end_date" placeholder="End date" disabled={!customDateFilter} required={customDateFilter} />
                                                </Col>
                                            </>
                                        )
                                    }

                                    {
                                        !(uInfo().type === 'Manager' || uInfo().type === 'SubManager') && (
                                            <Col>
                                                <Form.Select size="sm" name="status" >
                                                    <option value="">All </option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive {uInfo().type}</option>
                                                </Form.Select>
                                            </Col>

                                        )
                                    }


                                    <Col>
                                        <Button size="sm" type="submit" className='mr-5'>Filter</Button>
                                        <Button size="sm" variant="outline-light" type='button' onClick={handleReset}>Reset</Button>

                                    </Col>
                                </Row>
                            </Form>

                            <DataTableComponent
                                childRef={childRef}
                                selectableRows={false}
                                columns={userColumns}
                                tableClassName="data-table-head-light table-responsive"
                                slug={`cms/${type}/lists`}
                                extraCondition={{ 'proposal_id': id ? id : 'MA==' }}
                            />
                        </Card.Body>
                    </Card>
                </Block>
            </div>
        </Layout>
    )
}

export default PagesCms;
