import { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../../../layout/fullpage';
import { sendRequest } from '../../../../utilities/api';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import { Tab, Nav, OverlayTrigger, Card } from 'react-bootstrap';

import * as SignaturePad from 'signature_pad';

function FormDetails(props) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const [tab, setTab] = useState("");
    const [textSig, setTextSig] = useState("");
    const [drawSig, setDrawSig] = useState([]);
    const [requiredSig, setRequiredSig] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            fetchRecordById();
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Update requiredSig state based on metadata
        const pattern = /^signature-\d+$/;
        if (data.metadata && data.metadata.some(row => row.type === "textarea" && row.name.match(pattern))) {
            const row = data.metadata.find(row => row.type === "textarea" && row.name.match(pattern));
            setRequiredSig(row.required);
            setTab("type_it");
        } else {
            setRequiredSig(false); // Reset requiredSig if no matching rows found
        }
    }, [data.metadata]); // Run this effect whenever metadata changes


    const fetchRecordById = () => {
        sendRequest(`leadform/fetch/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    }


    const toggle = (index) => {
        if (tab !== index) {
            setTab(index);
        }
    };

    let signaturePad = null;
    if (document.querySelector('canvas') && tab == 'draw_it') {
        var canvas = document.querySelector('canvas');
        signaturePad = new SignaturePad.default(canvas, { backgroundColor: 'rgb(255, 255, 255)', width: '100%' });
        signaturePad.addEventListener("beginStroke", () => {
            setDrawSig(signaturePad.toData());
        }, { once: true });


        document.getElementById('clear').addEventListener('click', function () {
            signaturePad.clear();
            setDrawSig([]);
        });

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();

        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (requiredSig) {
            if ((textSig == '' && tab == 'type_it') || (tab == 'draw_it' && drawSig.length < 1)) {
                alert('Please Provide your Signature');
                return false;
            }
        }

        if (tab == 'type_it') {
            submitData.append('digital_signature', textSig);
            submitData.append('canvas_jason', '');
            submitData.append('signature_type', 'type_it')
        } else if (tab == 'draw_it') {
            submitData.append('digital_signature', '');
            submitData.append('canvas_jason', JSON.stringify(drawSig));
            submitData.append('digital_signature_image_url', signaturePad.toDataURL('image/jpeg'));
            submitData.append('signature_type', 'draw_it');

        }

        // setLoading(false);

        sendRequest(`saveLeadFormData/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    if (response.data.redirect_link != '') {
                        Swal.fire({
                            title: 'Please wait while we redirect you to the checkout page.',
                            icon: 'info',
                        });
                        window.location.href = response.data.redirect_link;
                    } else {
                        Swal.fire({
                            title: 'Action performed successfully',
                            icon: 'success',
                        });
                        window.location.reload();
                    }
                }
                // setLoading(true);
            })
            .catch(error => {
                // setLoading(true);
                console.error('POST request error:', error);
            });
    }
    return (
        <Layout title="Lead Form" content="container-fluid">
            <div className="container-fluid my-2">
                {
                    loading && (
                        <Card>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                            {/* {CreateCustomFieldUI()} */}

                                            {data.metadata && data.metadata.map((row, i) => {
                                                if (row.type == "text" || row.type == "date" || row.type == "number") {
                                                    return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                                                        <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                                                            {row.required && (<span className='text-danger'>*</span>)}
                                                            {row.description && (
                                                                <OverlayTrigger
                                                                    key={row.type + '--' + i}
                                                                    placement={'auto'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {row.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle pl-3"></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </label>
                                                        <input
                                                            type={row.type}
                                                            placeholder={row.placeholder}
                                                            name={row.name}
                                                            defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
                                                            readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                                                            maxLength={row.maxlength ? row.maxlength : ''}
                                                            className={row.className}
                                                            id={row.name}
                                                            title={row.description}
                                                            required={row.required}

                                                        />
                                                    </div>
                                                } else if (row.type == "select") {
                                                    return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.name}>`}>
                                                        <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                                                            {row.required && (<span className='text-danger'>*</span>)}
                                                            {row.description && (
                                                                <OverlayTrigger
                                                                    key={row.type + '--' + i}
                                                                    placement={'auto'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {row.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle pl-3"></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </label>
                                                        <select className={row.className} name={row.name}
                                                            defaultValue={data.lead_rec_array[data.mapped_data[row.name]]}
                                                            disabled={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}

                                                        >
                                                            {row.values.map((row, i) => {
                                                                return <option key={row.value + '--' + i} value={row.value}


                                                                    selected={data.lead_rec_array[data.mapped_data[row.name]] == row.value ? 'selected' : ''}
                                                                    disabled={
                                                                        row.value == data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                                                                >{row.label}</option>

                                                            })}
                                                        </select>
                                                    </div>

                                                } else if (row.type == "radio-group") {
                                                    return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                                                        <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                                                            {row.required && (<span className='text-danger'>*</span>)}
                                                            {row.description && (
                                                                <OverlayTrigger
                                                                    key={row.type + '--' + i}
                                                                    placement={'auto'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {row.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle pl-3"></i>
                                                                </OverlayTrigger>
                                                                // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
                                                            )}
                                                        </label>
                                                        {row.values.map((option, i) => {
                                                            return <div key={option.value + '--' + i} className={`form-check ${row.className}`}>
                                                                <input
                                                                    name={row.name} type="radio"
                                                                    className="form-check-input" value={option.value} />
                                                                <label className="form-check-label">{`${option.label}`}</label>
                                                            </div>
                                                        })}
                                                    </div>
                                                } else if (row.type == "checkbox-group") {
                                                    return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                                                        <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                                                            {row.required && (<span className='text-danger'>*</span>)}
                                                            {row.description && (
                                                                <OverlayTrigger
                                                                    key={row.type + '--' + i}
                                                                    placement={'auto'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {row.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle pl-3"></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </label>
                                                        {row.values.map((option, i) => {
                                                            return <div key={option.value + '--' + i} className="form-check form-check-inline">
                                                                <input name={`${row.name}[]`} type="checkbox"
                                                                    className=" form-check-input" value={`${option.value}`} />
                                                                <label className="form-check-label">{`${option.label}`}</label>
                                                            </div>
                                                        })}
                                                    </div>
                                                } else if (row.type == "textarea") {
                                                    var pattern = /^signature-\d+$/;

                                                    if (row.name.match(pattern)) {
                                                        return <div>
                                                            <Tab.Container id="details" onSelect={(selectedKey) => toggle(selectedKey)} defaultActiveKey={tab} activeKey={tab}>
                                                                <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="type_it">Type it</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="draw_it">Draw it</Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="type_it">
                                                                        <div className="pt-20">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label className={`mt-3`}>
                                                                                        Type Your Signature (Full Name)
                                                                                    </label>
                                                                                    {row.required && (<span className='text-danger'>*</span>)}
                                                                                    <div className={`mb-3 form-group`}>
                                                                                        <input
                                                                                            id="text-sig"
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="text-sig"
                                                                                            required={tab == '1' ? true : false}
                                                                                            onChange={(e) => setTextSig(e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label className={`mt-3`}>

                                                                                    </label>
                                                                                    <div className="outer_type_it">
                                                                                        <div className="type_it">{textSig}</div>

                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </Tab.Pane>
                                                                    <Tab.Pane eventKey="draw_it">
                                                                        <div className="mt-3">

                                                                            <div className='d-flex'>
                                                                                <div className="canvas-wrapper" style={{ border: '2px solid #000', width: "1000px" }}>
                                                                                    <canvas id="signature-pad" className="signature-pad" width="1000px"></canvas>
                                                                                </div>

                                                                                <button className="btn btn-link" type="button" id="clear">Clear</button>
                                                                            </div>


                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                            </Tab.Container>

                                                        </div>

                                                    } else {
                                                        return <div className={`fb-select mb-3 form-group field-${row.name}>`}>
                                                            <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                                                                {row.required && (<span className='text-danger'>*</span>)}
                                                                {row.description && (
                                                                    <OverlayTrigger
                                                                        key={row.type + '--' + i}
                                                                        placement={'auto'}
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {row.description}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fas fa-question-circle pl-3"></i>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </label>
                                                            <textarea
                                                                type={row.type}
                                                                placeholder={row.placeholder}
                                                                name={row.name}
                                                                defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
                                                                readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                                                                maxLength={row.maxlength ? row.maxlength : ''}
                                                                className={row.className}
                                                                id={row.name}
                                                                title={row.description}
                                                                required={row.required}

                                                            >

                                                            </textarea>

                                                        </div>
                                                    }
                                                } else if (row.type == 'file') {
                                                    return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                                                        <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                                                            {row.required && (<span className='text-danger'>*</span>)}
                                                            {row.description && (
                                                                <OverlayTrigger
                                                                    key={row.type + '--' + i}
                                                                    placement={'auto'}
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {row.description}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle pl-3"></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </label>
                                                        <input
                                                            type={row.type}
                                                            placeholder={row.placeholder}
                                                            name={row.name}
                                                            className={row.className}
                                                            id={row.name}
                                                            title={row.description}
                                                            required={row.required}
                                                        />
                                                    </div>
                                                } else if (row.type == 'paragraph') {
                                                    return <div key={row.type + '--' + i} className="fb-text  form-group "><div dangerouslySetInnerHTML={{ __html: row.label }}></div></div>
                                                } else if (row.type == 'header') {
                                                    return <div key={row.type + '--' + i} className="fb-text  form-group "><p className={row.subtype}>{row.label}</p></div>
                                                }
                                            })}


                                            {data.disclaimer && (
                                                <>
                                                    <label className={`formbuilder-text-label`}>Disclaimer:</label>
                                                    <div className="fb-text  form-group "><div dangerouslySetInnerHTML={{ __html: data.disclaimer_body }}></div></div>
                                                </>
                                            )}

                                            <div className="form-group mt-3">
                                                <button className="btn btn-sm btn-block btn-primary" type="submit" id="submit_selection_myform" value="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    )
                }
            </div>
        </Layout>
    )
}

export default FormDetails;
