import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, InputGroup } from 'react-bootstrap';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../components';
import { sendRequest } from "../../utilities/api";
import Swal from "sweetalert2";
import JobsNav from "../nav/JobsNav";

function CustomFields(props) {
    const [data, setData] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [draggedIndex, setDraggedIndex] = useState(null);

    useEffect(() => {
        fetchRecordById();
    }, []);

    const fetchRecordById = () => {
        sendRequest(`crm/custom/fields/lists`, 'GET').then(response => {
            if (response.status === true) {
                setData(response.data.list);
                setCustomFields(JSON.parse(response.data.list.job_fields));
            }

            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('GET request error:', error);
            });
    }

    const addClick = () => {

        const customFieldsCount = customFields.length;

        setCustomFields(prevCustomFields => [
            { ["label"]: "", type: "textbox", options: [], ["key"]: "", ["alternate"]: "", sorting_order: customFieldsCount + 1 },
            ...prevCustomFields
        ]);
    };

    const addOptionClick = (i) => {
        const updatedCustomFields = [...customFields];
        updatedCustomFields[i].options = [...updatedCustomFields[i].options, { ["option"]: "" }];
        setCustomFields(updatedCustomFields);
    };

    const createUIReorder = () => {
        return customFields.map((el, i) => (
            <div className="border py-3 px-2" key={i}>
                {/* ... rest of the JSX */}
            </div>
        ));
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index);
        setDraggedIndex(index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        const draggedOverIndex = index;
        if (draggedIndex !== draggedOverIndex) {
            const newCustomFields = [...customFields];
            const draggedRow = newCustomFields[draggedIndex];
            newCustomFields[draggedIndex] = newCustomFields[draggedOverIndex];
            newCustomFields[draggedOverIndex] = draggedRow;
            setCustomFields(newCustomFields);
            setDraggedIndex(draggedOverIndex); // Update draggedIndex for the next iteration
        }
    };

    const handleDrop = (e, index) => {
        customFields[index].sorting_order = index + 1;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    const createUI = () => {
        return customFields.sort((field) => field.sorting_order).map((el, i) => (
            <>
                <div className={`row ${el.options.length > 0 ? '' : 'mb-2'}`}
                    key={i}
                    draggable
                    onDragStart={(e) => handleDragStart(e, i)}
                    onDragOver={(e) => handleDragOver(e, i)}
                    onDrop={(e) => handleDrop(e, i)}
                    onDragEnd={handleDragEnd}
                    style={{ cursor: 'grabbing' }}
                >
                    <Col md="3">
                        <Form.Group className="form-group ">
                            <div className="form-control-wrap">
                                <Form.Control size="sm" placeholder="Enter label" name="key" required="" id="label" type="text"
                                    className="form-control" value={`${el[`key`]}` || ''} readOnly onChange={(e) => handleChange(i, e)}
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md="3">
                        <Form.Group className="form-group ">
                            <div className="form-control-wrap">
                                <Form.Control size="sm" placeholder="Enter label" name="label" required="required" id="label" type="text"
                                    className="form-control" value={`${el[`label`]}` || ''} onChange={(e) => handleChange(i, e)}
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md="3">
                        <Form.Group className="form-group ">
                            <div className="form-control-wrap">
                                <Form.Control size="sm" placeholder="Enter Alternate value" name="alternate" required="" type="text"
                                    className="form-control" value={`${el[`alternate`]}` || ''} onChange={(e) => handleChange(i, e)}
                                />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md="3">
                        <InputGroup>
                            <Form.Select type="select"
                                value={el.type || 'textbox'}
                                name={`type`}
                                onChange={(e) => handleChange(i, e)}
                                size="sm"
                            >
                                <option value="textbox">Text Box</option>
                                <option value="textarea">Text Area</option>
                                <option value="nametextbox">Name</option>
                                <option value="phonetextbox">Phone</option>
                                <option value="mobiletextbox">Mobile</option>
                                <option value="emailtextbox">Email</option>
                                <option value="date">Date</option>
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="select">Select</option>
                                <option value="linkfield">Link</option>
                            </Form.Select>
                            <InputGroup.Text className="p-0">
                                <Button className="btn-soft" variant="danger" size="sm" onClick={(e) => removeClick(i, e)}>
                                    <Icon name={'trash'} ></Icon>
                                </Button>
                            </InputGroup.Text>
                        </InputGroup>

                    </Col>


                </div>
                <div className={`${el.options.length > 0 ? 'border py-2 px-2 mb-2 mt-1' : ''}`} >
                    <div className="row">
                        {
                            el.options.map((a, k) => (
                                <div className="col-md-4 mb-3" key={k}>

                                    <InputGroup>
                                        <Form.Control size="sm" placeholder="Enter label" name={`option`} required="required" type="text"
                                            className="form-control "
                                            value={`${a[`option`]}` || ''}
                                            onChange={(e) => handleOptionChange(i, k, e)}
                                        />
                                        <InputGroup.Text className="p-0">
                                            <Button className="btn-soft" variant="danger" size="sm" onClick={(e) => removeOptionClick(i, k, e)}>
                                                <Icon name={'trash'}></Icon>
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </div>

                            ))
                        }
                        {
                            el.options.length > 0 && (
                                <div className="col-md-12 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-info btn-sm width-350"
                                        onClick={(e) => addOptionClick(i, e)}>
                                        Add Options
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        ));
    };

    const removeOptionClick = (i, k) => {
        const updatedCustomFields = [...customFields];
        updatedCustomFields[i].options.splice(k, 1);
        setCustomFields(updatedCustomFields);
    };

    const handleOptionChange = (i, k, e) => {
        const { name, value } = e.target;
        const updatedCustomFields = [...customFields];
        updatedCustomFields[i].options[k] = { ...updatedCustomFields[i].options[k], [name]: value };
        setCustomFields(updatedCustomFields);
    };

    const handleChange = (i, e) => {
        const { name, value } = e.target;

        let updatedFields = [...customFields];
        if (name.includes("label")) {
            updatedFields[i] = {
                ...updatedFields[i],
                [name]: value,
                ['key']: value.replace(/[^A-Z0-9]+/ig, "_").toLowerCase(),
            };
        } else {
            updatedFields[i] = {
                ...updatedFields[i],
                [name]: value,
            };
        }

        const displayOption = ['radio', 'checkbox', 'select'];
        if (name === 'type' && displayOption.includes(value)) {
            updatedFields[i].options = [...updatedFields[i].options, { option: '' }];
        } else {
            if (!displayOption.includes(updatedFields[i].type)) {
                updatedFields[i].options = [];
            }
        }
        setCustomFields(updatedFields);
    };

    const removeClick = (i) => {
        const updatedCustomFields = [...customFields];
        updatedCustomFields.splice(i, 1);
        setCustomFields(updatedCustomFields);
    };

    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const submitData = new FormData();
        submitData.append('job_fields', JSON.stringify(customFields));
        sendRequest(`crm/custom/fields/job/update/${data.public_id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire({
                        title: response.message,
                        icon: 'success',
                    });
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Custom Fields" content="container-fluid">
            <div className="main-custom-field">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Custom Fields</Block.Title>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/jobs/lists">Jobs</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Custom Fields</li>
                                </ol>
                            </nav>
                        </Block.HeadContent>
                        <Block.HeadContent>

                            <ul className="d-flex">
                                <JobsNav from={3} />
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>

                                            <ul className="d-flex gap g-3 flex-wrap mb-3 justify-content-end">
                                                <li><Button size="sm" onClick={() => addClick()} type="button"> Add Field</Button></li>
                                                <li><Button size="sm" type="submit"> Save</Button></li>
                                            </ul>

                                            <div className="row">
                                                <div className="col-md-3 mb-2 CustonField">Custom Token Index</div>
                                                <div className="col-md-3 mb-2 CustonField">Custom Token Values</div>
                                                <div className="col-md-3 mb-2 CustonField">Alternate Token Values</div>
                                                <div className="col-md-3 mb-2 CustonField">Type</div>
                                                <div className="col-lg-2"></div>
                                            </div>
                                            <div className="height-350">
                                                {createUI()}
                                            </div>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default CustomFields;