import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select, Tinymce } from '../../../components';
import { sendRequest } from "../../../utilities/api";

function EditScriptTemplate(props) {
    const { id } = useParams();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRecordById();
    }, []);



    const fetchRecordById = () => {
        sendRequest(`templates/script/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`templates/script/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate(`/template-manage/script/lists`);
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Script Template" content="container-fluid">
            <div className="script-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Script</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to={`/template-manage/script/lists`}>Script</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading && (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        )
                    }


                    <Card className="card-gutter-md">
                        <Card.Body>
                            <div className="bio-block">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Title</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control defaultValue={data.title} type="text" name="title" placeholder="Title" required />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col md="12">
                                            <Form.Group className="form-group">
                                                <Tinymce leadToken={true} height={700} textareaName={'body'} toolbar={true} initialValue={data.body} />
                                            </Form.Group>
                                        </Col>


                                        <Col className="col-12">
                                            <Button className="btn-sm" type="submit">Update</Button>
                                        </Col>


                                    </Row>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>


                </Block >
            </div>
        </Layout >
    )
}

export default EditScriptTemplate;