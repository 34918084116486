import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner, Tab, Nav, OverlayTrigger, Tooltip, Overlay, Popover, Modal } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { sendRequest } from "../../utilities/api";
import { TopNav } from "./TopNav";
import Basic from "./Basic";
import Inbound from "./Inbound";
import IvrUpdate from "./IvrUpdate";
import Outbound from "./Outbound";
import Automation from "./Automation";
import { Icon, Select, Tinymce } from "../../components";
import { truncateString } from "../../utilities";

function Flow(props) {
    const { id, section, group_id, automation_id } = useParams();
    const [meta, setMeta] = useState({ coachPartners: [] });
    const [record, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showP, setShowP] = useState({});
    const [target, setTarget] = useState(null);
    const [formFieldData, setFormFieldData] = useState({});

    const [childid, setChildid] = useState(0);
    const [nextId, setNextId] = useState(0);
    const [nodeId, setNodeId] = useState(0);
    const [isOk, setIsOk] = useState('no');
    const [g_cond, setGCond] = useState('');
    const [menu, setMenu] = useState("sms");


    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            fetchRecordById();
        };
        fetchData();
    }, []);



    const handleClick = (event, key) => {
        setShowP({ ...showP, [key]: true });
        // setShowP(false);
        // setShowP(true);
        setTarget(event.target);
    };


    const fetchMeta = async () => {
        const formData = new FormData();
        formData.append('campaign_id', id);
        formData.append('section', section);
        formData.append('group_id', group_id);
        if (automation_id) {
            formData.append('automation_id', automation_id);
        }

        await sendRequest(`campaign/${id}/automation/meta/fetch`, 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = (loader = true) => {
        if (loader) {
            setLoading(false);
        }
        const formData = new FormData();
        formData.append('campaign_id', id);
        formData.append('section', section);
        formData.append('group_id', group_id);
        if (automation_id) {
            formData.append('automation_id', automation_id);
        }
        sendRequest(`campaign/${id}/automation/series`, 'POST', formData).then(response => {
            if (response.status === true) {
                setData(response.data);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const deleteNode = ($nodeId, $childid, $previous_id, $type) => {
        console.log($nodeId, $childid, $previous_id, $type);
        const formData = new FormData();
        formData.append('nodeId', $nodeId);
        formData.append('childId', $childid);
        formData.append('previousId', $previous_id);
        formData.append('type', $type);
        formData.append('master_type', record.section);

        if (automation_id) {
            formData.append('automation_id', automation_id);
        }

        sendRequest(`campaign/${id}/automation/series/remove`, 'POST', formData).then(response => {
            if (response.status === true) {
                fetchRecordById();
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });

    }

    const addNote = async (event) => {
        // setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        let nodeId = 0;
        for (let [key, value] of formData.entries()) {
            if (key == 'nodeId') {
                nodeId = value;
            }
            submitData.append(key, value);
        }

        sendRequest(`campaign/${id}/automation/series/notes/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    document.getElementsByClassName('text-note-' + nodeId)[0].value = '';
                    fetchRecordById(false);
                }
                // setLoading(false);

            })
            .catch(error => {
                // setLoading(false);
                console.error('POST request error:', error);
            });
    }

    const deleteNote = ($nodeId, $noteIndex) => {
        // setLoading(true);
        const submitData = new FormData();
        submitData.append('nodeId', $nodeId);
        submitData.append('index', $noteIndex);

        sendRequest(`campaign/${id}/automation/series/note/remove`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchRecordById(false);
                }
                // setLoading(false);

            })
            .catch(error => {
                // setLoading(false);
                console.error('POST request error:', error);
            });
    }

    const renderHtml = (data) => {
        if (data.records.length > 0) {

            let obj = Object.values(data.bTree);
            // console.log(obj);
            return obj.map(($treeArray, $parentNode) => {
                //let idx = $treeArray.indexOf(); 
                //console.log($treeArray, 'is here');
                let $nodeId = $treeArray['0'];
                let $childid = $treeArray['1'];
                //let $parentNode = $treeArray['2'];
                //console.log($parentNode, 'parentNode');
                let $nxtId = 0;
                if ($childid != 0) {
                    $nxtId = $nodeId;
                }

                if ($parentNode == 0) {
                    return (
                        <li className="plus_outer startNow" key={$parentNode}>
                            <ul>
                                <li>
                                    {addChildHtml($nodeId, $childid)}
                                </li>

                            </ul>
                        </li>
                    )
                }
            });
        } else {
            return <li className="plus_outer startNow">
                <button className="plus" onClick={(e) => chooseAction(0, 0, e)}>
                    <Icon name={'plus'} />
                </button>
            </li>;
        }
    }

    const addChildHtml = ($nodeId, $childid) => {


        let $dataArray = record.dataArray;

        let $childData = $dataArray[$nodeId];
        let $series = JSON.parse($childData.seriesData);
        let $returnHtml = '<p>A</p>';

        if ($childData.length == 0) {
            return $returnHtml;
        }

        $series.p_series_id = $dataArray[$nodeId].id;
        $series.html = $dataArray[$nodeId].html;


        let $childNotes = $childData.notes != '' ? JSON.parse($childData.notes) : 0;



        return (
            <>
                <button
                    className="delete"
                    onClick={() => deleteNode($nodeId, $childid, $childData.previous_id, $childData.type)}
                    title="Delete"
                >
                    <Icon name={'cross'} />
                </button>

                <div style={{ position: 'relative' }}>
                    <div className='button-notes-view'>
                        <button variant='none' className='notes-list' onClick={(e) => handleClick(e, `key_${$nodeId}`)}>
                            <Icon name={'file'} />
                        </button>

                        <Overlay
                            show={showP[`key_${$nodeId}`]}
                            target={target}
                            placement="right"
                            rootClose={true}
                            onHide={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })}
                        >
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Notes </Popover.Header>
                                <Popover.Body>
                                    <div className="add_note_drapdown_body">
                                        <div className={`col-md-12 show-notes nkt-${$nodeId}`}>
                                            {
                                                $childNotes.length > 0 && (
                                                    $childNotes.map(($note, i) => {
                                                        return <div className={`notes notee-${i}`}>
                                                            <div class="d-flex justify-content-between smaller">
                                                                <span>{$note}</span>
                                                                <div class="change down" onClick={() => deleteNote($nodeId, i)}>
                                                                    <Icon name={'trash'} size={'sm'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                )
                                            }
                                        </div>
                                        <form className="email-form" onSubmit={addNote}>
                                            <input type="hidden" name="nodeId" value={$nodeId} />
                                            <textarea name="note" className={`form-control text-note-${$nodeId}`} placeholder="Type note here ..."></textarea>
                                            <div className="add_note_drapdown_button mt-2">
                                                <button type="button" onClick={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })} className="btn btn-info mr-5">Cancel</button>
                                                <button type="submit" className="btn btn-primary">Add</button>
                                            </div>
                                        </form>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
                {
                    $childData.type == 'sms' ?
                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'sms', 'sms')}>
                            <OverlayTrigger
                                // placement="right"
                                //   delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        FollowUpId # {$series.p_series_id}
                                    </Tooltip>
                                }
                            >
                                <div className="sb_button_inner  sb_editable"
                                    childid={`${$nodeId}`}>
                                    <i className="fa fa-mobile" aria-hidden="true"></i>  SMS
                                </div>
                            </OverlayTrigger>
                        </div>
                        :
                        $childData.type == 'voicemail' ?
                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'voicemail', 'voicemail')}>
                                <OverlayTrigger
                                    // placement="right"
                                    //  delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            FollowUpId # {$series.p_series_id}
                                        </Tooltip>
                                    }
                                >

                                    <div className="sb_button_inner sb_editable"
                                        childid={`${$nodeId}`}>
                                        <i className="fa fa-phone" aria-hidden="true"></i> RVM
                                    </div>
                                </OverlayTrigger>
                            </div>
                            : $childData.type == 'webhook' ?
                                <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'webhook', 'webhook')}>
                                    <OverlayTrigger
                                        // placement="right"
                                        //   delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                FollowUpId # {$series.p_series_id}
                                            </Tooltip>
                                        }
                                    >

                                        <div className="sb_button_inner sb_editable"
                                            childid={`${$nodeId}`}>
                                            <i className="fa fa-link" aria-hidden="true"></i> webhook ( {truncateString($series.webhook_url, 30)} )
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                :
                                $childData.type == 'email' ?
                                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'email', 'email')} >
                                        <OverlayTrigger
                                            // placement="right"
                                            //    delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    FollowUpId # {$series.p_series_id}
                                                </Tooltip>
                                            }
                                        >

                                            <div className="sb_button_inner sb_editable"
                                                childid={`${$nodeId}`}>
                                                <i className="fa fa-envelope" aria-hidden="true"></i> Email
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    :
                                    $childData.type == 'notify' ?
                                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'notify', 'notify')}>
                                            <OverlayTrigger
                                                // placement="right"
                                                //     delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        FollowUpId # {$series.p_series_id}
                                                    </Tooltip>
                                                }
                                            >

                                                <div className="sb_button_inner sb_editable"
                                                    childid={`${$nodeId}`}>
                                                    <i className="fa fa-envelope" aria-hidden="true"></i> Notify ({$series.notify})
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        : $childData.type == 'start' ?
                                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'start', 'start')}>
                                                <OverlayTrigger
                                                    // placement="right"
                                                    //       delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            FollowUpId # {$series.p_series_id}
                                                        </Tooltip>
                                                    }
                                                >

                                                    <div className="sb_button_inner sb_editable"
                                                        childid={`${$nodeId}`}>
                                                        <i className="fas fa-sync" aria-hidden="true"></i> Start Automation ( {truncateString($series.title, 30)} )
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            : $childData.type == 'end' ?
                                                // onClick={() => dynamicSetting(true, $series, 'end', 'end')}
                                                <div className="sb_button" >
                                                    <OverlayTrigger
                                                        // placement="right"
                                                        //         delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                            <Tooltip id="button-tooltip">
                                                                FollowUpId # {$series.p_series_id}
                                                            </Tooltip>
                                                        }
                                                    >

                                                        <div className="sb_button_inner sb_editable"
                                                            childid={`${$nodeId}`}>
                                                            <i className="fas fa-sync" aria-hidden="true"></i> End Automation
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                                :
                                                $childData.type == 'no_response' ?
                                                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'no_response', 'no_response')} >
                                                        <OverlayTrigger
                                                            // placement="right"
                                                            // delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id="button-tooltip">
                                                                    FollowUpId # {$series.p_series_id}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="sb_button_inner btn-primary sb_editable"
                                                                childid={`${$nodeId}`}>
                                                                <i className="fa fa-ban" aria-hidden="true"></i>  No Response Action
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    : $childData.type == 'condition' ?
                                                        <></>
                                                        // <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                                                        //     <div className="sb_button_inner sb_editable"
                                                        //         childid={`${$nodeId}`}>
                                                        //         <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                                                        //     </div>
                                                        // </div>

                                                        : "h"

                }



                {/* {
                    $childData.type == 'condition' ? (
                        conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                    ) : conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                } */}
                {conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)}

            </>
        );



    }

    function notIn($type) {
        let $actionArr = ['end', 'goto', 'start'];
        if ($actionArr.includes($type) === false) {
            return true;
        } else {
            return false;
        }
    }

    const conditionHtml = ($childData, $dataArray, $nodeId, $childid, $childNotes, $series) => {
        if ($childData.type == 'condition') {
            let $subChild = $dataArray[$childid] ? $dataArray[$childid] : {};
            let $preChild = [];
            if ($childData.pre_next_id != 0 && $childData.pre_next_id != $childid) {
                $preChild = $dataArray[$childData.pre_next_id];
            }

            let $trueChildId = 0;
            let $falseChildId = 0;

            if ($subChild.condition_status == 'true' && $subChild.series_id == $childid) {
                $trueChildId = $childid;
            }
            if ($subChild.condition_status == 'true' && $subChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childid) {
                $trueChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childid) {
                $falseChildId = $childid;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childid) {
                $falseChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }

            return (
                <>
                    <div className="sb_button conditional" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                        <div className="sb_button_inner sb_editable"
                            childid={`${$nodeId}`}>
                            <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.condition_title})
                        </div>
                    </div>

                    <ul className='tree-condition'>
                        <li>
                            <ul className='tree-condition-ul'>
                                <li className='tree-condition-li-yes'>
                                    <ul>
                                        <li className="plus_outer yes-outer">
                                            <a className="sb_button_inner condition_yes " childid="end">Yes</a>
                                            <br />
                                            <button className="plus" onClick={(e) => chooseAction($trueChildId, $nodeId, e, 'yes')}>
                                                <Icon name={'plus'} />
                                            </button>
                                            {/* $returnHtml .= $this->getChild($nodeId, $trueChildId); */}
                                            {getChild($nodeId, $trueChildId)}
                                        </li>
                                    </ul>
                                </li>
                                <li className='tree-condition-li-no'>
                                    <ul>
                                        <li className="plus_outer">
                                            <a className="sb_button_inner condition_no" id={`yes_${$nodeId}`} childid="end">No</a>
                                            <br />
                                            <button className="plus" onClick={(e) => chooseAction($falseChildId, $nodeId, e, 'no')}>
                                                <Icon name={'plus'} />
                                            </button>
                                            {getChild($nodeId, $falseChildId)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )


        }

        if (notIn($childData.type)) {
            return <ul>
                <li className="plus_outer">
                    <button className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                        <Icon name={'plus'} />
                    </button>
                    {getChild($nodeId, $childid)}
                </li>
            </ul>
        }
    }

    const getChild = ($parentNode, $nodeId) => {
        let $dataArray = record.dataArray;

        if ($nodeId != 0) {
            let $childData = $dataArray[$nodeId];
            let $childid = $childData.next_id;
            return (
                <ul>
                    <li>
                        {addChildHtml($nodeId, $childid)}
                    </li>
                </ul>
            )
        }
    }

    const dynamicSetting = (modal, data, type, contetType) => {

        console.log(data, 'sdassss')
        // if (data.html) {
        //     setHtml(data.html);
        // } else {
        //     setHtml('');
        // }

        data.body = data.html;
        if (data.condition) {
            data.condition_id = data.condition;
        }

        setShow(modal);
        setFormFieldData(data);
        setMenu(type);
        //setTypeContent(contetType);
        //setEditModal(true);



    }

    const chooseAction = (nId, cId, obj, cond = '') => {
        // $('.btn-group-vertical .btn.green').removeClass('active');
        // $('.action-description').html('<h4 style="text-align:center;">Select Action From Menu</h4>');
        // // getTypeContent('type');
        // $('#action-detail').removeClass('col-md-12');
        // $('#action-detail').addClass('col-md-9');
        // $('#action-nav').show();
        // // $('.modal-title').html('Add New Action');
        setShow(true);
        setChildid(0);
        setNextId(nId);
        setNodeId(cId);
        setIsOk('no');
        setGCond(cond);

    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('campaignId', id);
        submitData.append('childId', childid);
        submitData.append('nextId', nextId);
        submitData.append('nodeId', nodeId);
        submitData.append('group_id', group_id);
        submitData.append('automationId', automation_id);
        submitData.append('cond', g_cond);
        submitData.append('isOk', isOk);
        submitData.append('type', menu);
        submitData.append('master_type', record.section);


        sendRequest(`campaign/${id}/automation/series/create`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    fetchRecordById();
                    setFormFieldData({});
                    setShow(false);
                }
                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };


    const handleChange = (e, type = '') => {
        const { name, value } = e.target;

        setFormFieldData({ ...formFieldData, [name]: value });

        if (name == 'template_id' || name == 'automation_id') {
            let templates = [];
            if (type == 'sms') {
                templates = meta?.smsTemplates;

            } else if (type == 'email') {
                templates = meta?.emailTemplates;

            } else if (type == 'voicemail') {
                templates = meta?.voiceTemplates;
            } else if (type == 'automation') {
                templates = meta?.getAutomations;
            }

            const desiredObject = templates.find(item => item.id == e.target.value);;
            const body = desiredObject?.body || "";
            const subject = desiredObject?.title || "";
            console.log(body, subject, desiredObject)
            setFormFieldData((prevState) => ({
                ...prevState,
                body: body,
                subject: subject
            }));
        }

    }

    const menuLeft = {
        "Trigger": ["sms", "email", "voicemail", "webhook", "notify", "start", "end"],
        "Drip": ["sms", "email", "voicemail", "no_response"],
        "EventReminder": ["sms", "email", "voicemail"],
    };


    const getNavItemLabel = (eventKey) => {
        switch (eventKey) {
            case 'sms':
                return 'Send SMS';
            case 'email':
                return 'Send Email';
            case 'voicemail':
                return 'Voicemail';
            case 'webhook':
                return 'Webhook';
            case 'notify':
                return 'Notify Someone';
            case 'start':
                return 'Start Automation';
            case 'end':
                return 'End Automation';
            case 'no_response':
                return 'No Response Action';
            default:
                return '';
        }
    };

    const remindOn = [
        { label: "Same Day", value: "0", isDisabled: false },
        { label: "Before 1 Day", value: "-1" },
        { label: "Before 2 Days", value: "-2" },
        { label: "Before 3 Days", value: "-3" },
        { label: "Before 4 Days", value: "-4" },
        { label: "Before 5 Days", value: "-5" },
        { label: "Before 14 Weeks", value: "-14" },
        { label: "Before 30 Month", value: "-30" },
        { label: "Before 45 Days", value: "-45" },
        { label: "Before 60 Days", value: "-60" },
        { label: "Before 90 Days", value: "-90" },
        { label: "Before 180 Days", value: "-180" },
        { label: "Before 365 Days", value: "-365" },
        { label: "After", value: "-0", isDisabled: true },
        { label: "After 1 Day", value: "1" },
        { label: "After 2 Days", value: "2" },
        { label: "After 3 Days", value: "3" },
        { label: "After 4 Days", value: "4" },
        { label: "After 5 Days", value: "5" },
        { label: "After 14 Weeks", value: "14" },
        { label: "After 30 Month", value: "30" },
        { label: "After 45 Days", value: "45" },
        { label: "After 60 Days", value: "60" },
        { label: "After 90 Days", value: "90" },
        { label: "After 180 Days", value: "180" },
        { label: "After 365 Days", value: "365" },
    ];

    return (
        <Layout title="Flow" content="container">
            <div className="text-end mb-1">
                <Link to={`/campaign-manage/details/${id}/automations`} className="btn btn-primary">
                    Back
                </Link>
            </div>
            <div className="flow-create-edit">
                <Block>
                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className='card card-body house-tree custom-scroll text-center' >


                                        <div className='event-tree text-center'>
                                            <h5>{meta?.currentAutomations?.event_title ?? section}</h5>


                                        </div>
                                        <ul className="tree-point pb-4">
                                            {renderHtml(record)}
                                        </ul>


                                    </div>

                                </Card.Body>
                            </Card>
                        )
                    }

                    <Modal show={show} size="xl">
                        <Modal.Header closeButton onHide={() => { setFormFieldData({}); setShow(false) }}>
                            <Modal.Title>Add User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="automation-action">
                            <Tab.Container className="disabled" disabled onSelect={(e) => { setFormFieldData({}); setMenu(e); }} id="left-tabs-example" defaultActiveKey={menu}>
                                <Row>
                                    <Col md={`${formFieldData.p_series_id ? 0 : 4}`} className={`${formFieldData.p_series_id ? 'd-none' : ''}`} >
                                        <Nav variant="pills" className="flex-column">
                                            {
                                                menuLeft[section].map((item, subIndex) => (
                                                    <Nav.Item key={`${subIndex}`}>
                                                        <Nav.Link eventKey={item}>{getNavItemLabel(item)}</Nav.Link>
                                                    </Nav.Item>
                                                ))
                                            }
                                        </Nav>
                                    </Col>
                                    <Col md={`${formFieldData.p_series_id ? 12 : 8}`}>
                                        <Tab.Content>
                                            <form className="email-form" onSubmit={handleSubmit}>

                                                {
                                                    record.section == 'EventReminder' && (
                                                        <div>
                                                            <div className="form-group mb-3">
                                                                <label>Tags (Optional)</label>
                                                                <Select multiple defaultValue={formFieldData.copy_groups_id} name={"tags_id[]"}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.tags.map((row, i) => {
                                                                            return <option key={i} value={row.id}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label>Event Field(Type) </label>
                                                                <Select defaultValue={formFieldData.event_field} name={"event_field"}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        meta.customDateFields.map((row, i) => {
                                                                            return <option key={i} value={row.key}>{row.label}</option>
                                                                        })
                                                                    }
                                                                </Select>


                                                            </div>
                                                            <div className="form-group mb-3" style={{ display: "none" }}>
                                                                <label>Trigger Interval:</label>
                                                                <div className="input-group">
                                                                    <select defaultValue={formFieldData.trigger_interval} onChange={(e) => handleChange(e)} name="trigger_interval" className='form-control' >
                                                                        {/* <option value={''}>Select</option> */}
                                                                        <option value="Yearly">Yearly</option>
                                                                        {/* <option value="Biyearly">Biyearly</option>
                                                                            <option value="Monthly">Monthly</option> */}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className='col-md-6'>
                                                                    <div className="form-group mb-3">
                                                                        <label>Remind On:</label>
                                                                        <Select defaultValue={formFieldData.remind_on} name={"remind_on"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                remindOn.map((row, i) => {
                                                                                    return <option key={i} value={row.value}>{row.label}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <div className="form-group mb-3">
                                                                        <label>Trigger on(Time):</label>
                                                                        <Select defaultValue={formFieldData.execution_time} name={"execution_time"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.time_duration.map((row, i) => {
                                                                                    return <option key={i} value={row}>{row}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    record.section == 'Autoresponder' && (
                                                        <div>
                                                            <div className='row'>

                                                                <div className='col-md-6'>

                                                                    <div className="form-group mb-3">
                                                                        <label className='text-capitalize'>{menu} Interval:</label>
                                                                        <div className="input-group">
                                                                            <input required defaultValue={formFieldData.interval_of_day} className="form-control"
                                                                                onChange={(e) => handleChange(e)}
                                                                                readOnly={formFieldData.p_series_id ? true : false}
                                                                                name="interval_of_day"
                                                                                placeholder="Enter number of day(s)(e.g. 0 or 1 etc )" maxLength={'3'} type="text" />
                                                                        </div>
                                                                        <span className="text text-muted smaller">In days after which {menu} will trigger at, whenever a new contact added</span>
                                                                    </div>
                                                                </div>


                                                                {
                                                                    formFieldData.interval_of_day >= 1 ? (
                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mb-3">
                                                                                <label>Trigger on(Time):</label>
                                                                                <Select defaultValue={formFieldData.execution_time} name={"execution_time"}>
                                                                                    <option value="">Select</option>
                                                                                    {
                                                                                        meta.time_duration.map((row, i) => {
                                                                                            return <option key={i} value={row}>{row}</option>
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                    ) : formFieldData.interval_of_day == 0 ? (
                                                                        <>
                                                                            <div className='col-md-6'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-2'>
                                                                                        {formFieldData.interval_of_day == 0 && (
                                                                                            <div className='toogle-button'>
                                                                                                <label style={{ width: '100%' }}>&nbsp;</label>
                                                                                                <button type='button' className='btn btn-success btn-sm' onClick={() => setFormFieldData((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    ext_time: formFieldData.ext_time ? !formFieldData.ext_time : true,
                                                                                                }))}><Icon name={'plus'} /></button>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    {
                                                                                        formFieldData.ext_time && (
                                                                                            <>
                                                                                                <div className="col-md-4">
                                                                                                    <label>Wait For: </label>
                                                                                                    <input required maxLength={'3'} defaultValue={formFieldData.wait_for_zero_interval}
                                                                                                        onChange={(e) => handleChange(e)}
                                                                                                        type="text" name="wait_for_zero_interval" className="form-control" />
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <label className='visibilty-hidden'>Wait For: </label>
                                                                                                    <select required defaultValue={formFieldData.zero_interval_wait_type} onChange={(e) => handleChange(e)} className="form-control" name="zero_interval_wait_type">
                                                                                                        <option value="">Select Type</option>
                                                                                                        <option value="minutes">Minute(s)</option>
                                                                                                        <option value="hours">Hour(s)</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>


                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {menu == "email" && (
                                                    <Tab.Pane eventKey="email">
                                                        <div className="email-content">

                                                            <div className="form-group mb-3">
                                                                <label>Email Template</label>
                                                                <div className="input-group">
                                                                    <select defaultValue={formFieldData.template_id} onChange={(e) => handleChange(e, 'email')} name="template_id" className='form-control' >
                                                                        <option value={''}>Select</option>
                                                                        {
                                                                            meta.emailTemplates && meta.emailTemplates.map((email, i) => {
                                                                                return <option key={i} value={email.id}>{email.title}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label> Email Token</label>
                                                                <div className="input-group">
                                                                    <div className="input-group">
                                                                        <select name="token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                            <option value={''}>Select Token</option>
                                                                            <optgroup label="Lead Default Field Token" >
                                                                                {
                                                                                    meta?.tokens?.default_fields.map((df, d) => {
                                                                                        return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                            <optgroup label="Lead Custom Field Token" >
                                                                                {
                                                                                    meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                        return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                            <optgroup label="User Tokens" >
                                                                                {
                                                                                    meta?.tokens?.users_token.map((cf, c) => {
                                                                                        return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        </select>

                                                                        <button
                                                                            disabled={!formFieldData.token}
                                                                            type='button'
                                                                            onClick={() => setFormFieldData({
                                                                                ...formFieldData,
                                                                                subject: formFieldData.subject ? `${formFieldData.subject}  ${formFieldData.token}` : formFieldData.token
                                                                            })}
                                                                            className='btn btn-primary btn-md'
                                                                        >
                                                                            Insert Into Subject
                                                                        </button>
                                                                    </div>

                                                                    {/* {html == "" && (
                                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['email_body']: formFieldData.email_body + '  ' + formFieldData.email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                                )} */}
                                                                </div>
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label>Email Subject</label>
                                                                <div className="input-group">
                                                                    <input value={formFieldData.subject} onChange={(e) => handleChange(e)} className='form-control' name="subject" />
                                                                </div>
                                                            </div>

                                                            {!formFieldData.template_id ? (
                                                                <div className="form-group mb-3">
                                                                    <label>Email Body</label>
                                                                    <div className="input-group">
                                                                        <Tinymce
                                                                            leadToken={true} userToken={true} height={300} initialValue={formFieldData.body} textareaName={'body'} toolbar={true}
                                                                        />
                                                                        {/* <Froala btnId={'add-drip-email'} content={formFieldData.body} handleSubmit={handleEmailBody} use_token={'users'} /> */}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        id="custom-template"
                                                                        className="height-500 custom-scroll" style={{ Width: "100%" }}
                                                                        dangerouslySetInnerHTML={{ __html: formFieldData.body }}
                                                                    />
                                                                </>
                                                            )}


                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "sms" && (
                                                    <Tab.Pane eventKey="sms">
                                                        <div className="sms-content">
                                                            <div className="form-group mb-3">
                                                                <label>SMS Template</label>
                                                                <div className="input-group">
                                                                    <select defaultValue={formFieldData.template_id} onChange={(e) => handleChange(e, 'sms')} name="template_id" className='form-control' >
                                                                        <option value={''}>Select</option>
                                                                        {
                                                                            meta.smsTemplates && meta.smsTemplates.map((sms, s) => {
                                                                                return <option data-id={sms.id} key={s} value={sms.id}>{sms.title}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <input type={'hidden'} name="title" value={formFieldData.subject} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label> SMS Token</label>
                                                                <div className="input-group">
                                                                    <select name="token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                        <option value={''}>Select Token</option>
                                                                        <optgroup label="Lead Default Field Token" >
                                                                            {
                                                                                meta?.tokens?.default_fields.map((df, d) => {
                                                                                    return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                })
                                                                            }
                                                                        </optgroup>
                                                                        <optgroup label="Lead Custom Field Token" >
                                                                            {
                                                                                meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                    return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                })
                                                                            }
                                                                        </optgroup>
                                                                        <optgroup label="User Tokens" >
                                                                            {
                                                                                meta?.tokens?.users_token.map((cf, c) => {
                                                                                    return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                })
                                                                            }
                                                                        </optgroup>
                                                                    </select>

                                                                    <button
                                                                        disabled={!formFieldData.token}
                                                                        type='button'
                                                                        onClick={() => setFormFieldData({
                                                                            ...formFieldData,
                                                                            body: formFieldData.body ? `${formFieldData.body}  ${formFieldData.token}` : formFieldData.token
                                                                        })}
                                                                        className='btn btn-primary btn-md'
                                                                    >
                                                                        Insert Into Body
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label>SMS Body</label>
                                                                <div className="input-group">
                                                                    <textarea onChange={(e) => handleChange(e)}
                                                                        value={formFieldData.body ?? ''} placeholder='Add message body' rows="4" cols="50" name="body" className='form-control' />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "voicemail" && (
                                                    <Tab.Pane eventKey="voicemail">
                                                        <div className="voicemail-content">
                                                            <div className="form-group mb-3">
                                                                <label>RVM Template</label>
                                                                <div className="input-group">
                                                                    <select required defaultValue={formFieldData.template_id} name="template_id" className='form-control' onChange={(e) => handleChange(e, 'voicemail')} >
                                                                        <option value={''}>Select</option>
                                                                        {
                                                                            meta.voiceTemplates && meta.voiceTemplates.map((rvm, r) => {
                                                                                return <option data-id={rvm.id} key={r} value={rvm.id}>{rvm.title}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <input type={'hidden'} name="title" value={formFieldData.subject} />
                                                                </div>
                                                            </div>
                                                            <input type="hidden" onChange={(e) => handleChange(e)}
                                                                value={formFieldData.body ?? ''} placeholder='Add message body' rows="4" cols="50" name="body" className='form-control' />


                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "webhook" && (
                                                    <Tab.Pane eventKey="webhook">
                                                        <div className="webhook-content">
                                                            <input required type="url" onChange={(e) => handleChange(e)}
                                                                value={formFieldData.webhook_url ?? ''} placeholder='Enter url' name="webhook_url" className='form-control' />
                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "notify" && (
                                                    <Tab.Pane eventKey="notify">
                                                        <div className="notify-content">
                                                            <div className="form-group">
                                                                <label className="control-label"> Notifications: </label>
                                                                <div className="input-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="checkbox" defaultChecked={formFieldData.notify_email} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_email']: e.target.checked })} name="notify_email" />
                                                                        <label className="form-check-label"> Email (Team)</label>
                                                                    </div>
                                                                    <div className="cform-check form-check-inline">
                                                                        <input className="form-check-input" type="checkbox" defaultChecked={formFieldData.notify_sms} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_sms']: e.target.checked })} name="notify_sms" />
                                                                        <label className="form-check-label"> SMS (Team)</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                formFieldData.notify_email && (
                                                                    <div className="notify-section p-3 bg-light bg-gradient mb-1">
                                                                        <div className="form-group mb-3">
                                                                            <label>Notification Email</label>
                                                                            <input className="form-control" value={formFieldData.notification_email} onChange={(e) => handleChange(e)} name="notification_email" placeholder="Add one/more notification email(s) separated by comma" type="text" />
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label> Email Token</label>
                                                                            <div className="input-group">
                                                                                <select name="notify_email_token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                                    <optgroup label="Lead Default Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.default_fields.map((df, d) => {
                                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="Lead Custom Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="User Tokens" >
                                                                                        {
                                                                                            meta?.tokens?.users_token.map((cf, c) => {
                                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                </select>
                                                                                <button
                                                                                    disabled={!formFieldData.notify_email_token}

                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_email_subject: formFieldData.notify_email_subject ? `${formFieldData.notify_email_subject}  ${formFieldData.notify_email_token}` : formFieldData.notify_email_token
                                                                                    })}

                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                >
                                                                                    Insert Subject
                                                                                </button>

                                                                                <button
                                                                                    disabled={!formFieldData.notify_email_token}
                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_email_body: formFieldData.notify_email_body ? `${formFieldData.notify_email_body}  ${formFieldData.notify_email_token}` : formFieldData.notify_email_token
                                                                                    })}
                                                                                    type='button' className='btn btn-primary btn-md'>
                                                                                    Insert Into Body
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group mb-3">
                                                                            <label>Email Subject</label>
                                                                            <div className="input-group">
                                                                                <input value={formFieldData.notify_email_subject ?? ""} onChange={(e) => handleChange(e)} className='form-control' name="notify_email_subject" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label>Email Body</label>
                                                                            <div className="input-group">
                                                                                <textarea value={formFieldData.notify_email_body ?? ""} onChange={(e) => handleChange(e)} name="notify_email_body" className='form-control' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                formFieldData.notify_sms && (
                                                                    <div className="notify-section p-3 bg-light bg-gradient">
                                                                        <div className="form-group mb-3">
                                                                            <label>Notification Number</label>
                                                                            <input className="form-control" value={formFieldData.notification_number} onChange={(e) => handleChange(e)} name="notification_number" placeholder="Add one/more notification phone(s) with country code separated by comma" type="text" />
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label> Sms Token</label>
                                                                            <div className="input-group">
                                                                                <select name="notify_sms_token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                                    <optgroup label="Lead Default Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.default_fields.map((df, d) => {
                                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="Lead Custom Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="User Tokens" >
                                                                                        {
                                                                                            meta?.tokens?.users_token.map((cf, c) => {
                                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                </select>


                                                                                <button
                                                                                    disabled={!formFieldData.notify_sms_token}
                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_sms_body: formFieldData.notify_sms_body ? `${formFieldData.notify_sms_body}  ${formFieldData.notify_sms_token}` : formFieldData.notify_sms_token
                                                                                    })}
                                                                                    type='button' className='btn btn-primary btn-md'>
                                                                                    Insert Into Body
                                                                                </button>

                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group mb-3">
                                                                            <label>SMS Text</label>
                                                                            <div className="input-group">
                                                                                <textarea value={formFieldData.notify_sms_body ?? ""} onChange={(e) => handleChange(e)} name="notify_sms_body" className='form-control' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "start" && (
                                                    <Tab.Pane eventKey="start">
                                                        <div className="start-content">
                                                            <label> Select Automation</label>
                                                            <div className="input-group">
                                                                <select defaultValue={formFieldData.automation_id} onChange={(e) => handleChange(e, 'automation')} name="automation_id" className='form-control' >
                                                                    <option value={''}>Select Automation</option>
                                                                    {
                                                                        meta.getAutomations && meta.getAutomations.map((row, a) => {
                                                                            return <option value={row.id} key={a}>{row.title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                <input type={'hidden'} defaultValue={formFieldData.subject} name="title" />


                                                            </div>

                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "end" && (
                                                    <Tab.Pane eventKey="end">
                                                        <div className="end-content">
                                                            <p className="text-danger">This will stop the the automation</p>
                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                {menu == "no_response" && (
                                                    <Tab.Pane eventKey="no_response">
                                                        <div className="no-response-content">
                                                            <div className="form-group">
                                                                <label className="control-label"> Notifications: </label>
                                                                <div className="input-group">
                                                                    <div className="form-check form-check-inline">
                                                                        <input className="form-check-input" type="checkbox" defaultChecked={formFieldData.notify_email} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_email']: e.target.checked })} name="notify_email" />
                                                                        <label className="form-check-label"> Email (Team)</label>
                                                                    </div>
                                                                    <div className="cform-check form-check-inline">
                                                                        <input className="form-check-input" type="checkbox" defaultChecked={formFieldData.notify_sms} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_sms']: e.target.checked })} name="notify_sms" />
                                                                        <label className="form-check-label"> SMS (Team)</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                formFieldData.notify_email && (
                                                                    <div className="notify-section p-3 bg-light bg-gradient mb-1">
                                                                        <div className="form-group mb-3">
                                                                            <label>Notification Email</label>
                                                                            <input className="form-control" value={formFieldData.notification_email} onChange={(e) => handleChange(e)} name="notification_email" placeholder="Add one/more notification email(s) separated by comma" type="text" />
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label> Email Token</label>
                                                                            <div className="input-group">
                                                                                <select name="notify_email_token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                                    <optgroup label="Lead Default Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.default_fields.map((df, d) => {
                                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="Lead Custom Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="User Tokens" >
                                                                                        {
                                                                                            meta?.tokens?.users_token.map((cf, c) => {
                                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                </select>
                                                                                <button
                                                                                    disabled={!formFieldData.notify_email_token}

                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_email_subject: formFieldData.notify_email_subject ? `${formFieldData.notify_email_subject}  ${formFieldData.notify_email_token}` : formFieldData.notify_email_token
                                                                                    })}

                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                >
                                                                                    Insert Subject
                                                                                </button>

                                                                                <button
                                                                                    disabled={!formFieldData.notify_email_token}
                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_email_body: formFieldData.notify_email_body ? `${formFieldData.notify_email_body}  ${formFieldData.notify_email_token}` : formFieldData.notify_email_token
                                                                                    })}
                                                                                    type='button' className='btn btn-primary btn-md'>
                                                                                    Insert Into Body
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group mb-3">
                                                                            <label>Email Subject</label>
                                                                            <div className="input-group">
                                                                                <input value={formFieldData.notify_email_subject ?? ""} onChange={(e) => handleChange(e)} className='form-control' name="notify_email_subject" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label>Email Body</label>
                                                                            <div className="input-group">
                                                                                <textarea value={formFieldData.notify_email_body ?? ""} onChange={(e) => handleChange(e)} name="notify_email_body" className='form-control' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                formFieldData.notify_sms && (
                                                                    <div className="notify-section p-3 bg-light bg-gradient">
                                                                        <div className="form-group mb-3">
                                                                            <label>Notification Number</label>
                                                                            <input className="form-control" value={formFieldData.notification_number} onChange={(e) => handleChange(e)} name="notification_number" placeholder="Add one/more notification phone(s) with country code separated by comma" type="text" />
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <label> Sms Token</label>
                                                                            <div className="input-group">
                                                                                <select name="notify_sms_token" className='form-control' onChange={(e) => handleChange(e)} >
                                                                                    <optgroup label="Lead Default Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.default_fields.map((df, d) => {
                                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="Lead Custom Field Token" >
                                                                                        {
                                                                                            meta?.tokens?.custom_fields.map((cf, c) => {
                                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                    <optgroup label="User Tokens" >
                                                                                        {
                                                                                            meta?.tokens?.users_token.map((cf, c) => {
                                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </optgroup>
                                                                                </select>


                                                                                <button
                                                                                    disabled={!formFieldData.notify_sms_token}
                                                                                    onClick={() => setFormFieldData({
                                                                                        ...formFieldData,
                                                                                        notify_sms_body: formFieldData.notify_sms_body ? `${formFieldData.notify_sms_body}  ${formFieldData.notify_sms_token}` : formFieldData.notify_sms_token
                                                                                    })}
                                                                                    type='button' className='btn btn-primary btn-md'>
                                                                                    Insert Into Body
                                                                                </button>

                                                                            </div>
                                                                        </div>


                                                                        <div className="form-group mb-3">
                                                                            <label>SMS Text</label>
                                                                            <div className="input-group">
                                                                                <textarea value={formFieldData.notify_sms_body ?? ""} onChange={(e) => handleChange(e)} name="notify_sms_body" className='form-control' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            <div className="contact_action-content">
                                                                <div className="form-group">
                                                                    <label className="control-label"> Available Actions: </label>
                                                                    <div className="action-controls">
                                                                        <div className='row'>
                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline mr-10">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.Copy} onChange={(e) => setFormFieldData({ ...formFieldData, ['Copy']: e.target.checked })} name="Copy" />
                                                                                    <span> Copy</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline mr-10">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.Move} onChange={(e) => setFormFieldData({ ...formFieldData, ['Move']: e.target.checked })} name="Move" />
                                                                                    <span> Move</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.Remove} onChange={(e) => setFormFieldData({ ...formFieldData, ['Remove']: e.target.checked })} name="Remove" />
                                                                                    <span> Remove</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.AddTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddTags']: e.target.checked })} name="AddTags" />
                                                                                    <span> Add Tags</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.RemoveTags} onChange={(e) => setFormFieldData({ ...formFieldData, ['RemoveTags']: e.target.checked })} name="RemoveTags" />
                                                                                    <span> Remove Tags</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.AddNotes} onChange={(e) => setFormFieldData({ ...formFieldData, ['AddNotes']: e.target.checked })} name="AddNotes" />
                                                                                    <span>Add Notes</span>
                                                                                </label>
                                                                            </div>


                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.UnsubscribePhone} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribePhone']: e.target.checked })} name="UnsubscribePhone" />
                                                                                    <span>Unsubscribe Phone</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <label className="checkbox-inline">
                                                                                    <input type="checkbox" defaultChecked={formFieldData.UnsubscribeEmail} onChange={(e) => setFormFieldData({ ...formFieldData, ['UnsubscribeEmail']: e.target.checked })} name="UnsubscribeEmail" />
                                                                                    <span>Unsubscribe Email</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {formFieldData.Copy && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Copy Contact To:</label>
                                                                        <Select multiple defaultValue={formFieldData.copy_groups_id} name={"copy_groups_id[]"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.groups.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>


                                                                    </div>
                                                                )}

                                                                {formFieldData.Move && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Move Contact To:</label>
                                                                        <Select multiple defaultValue={formFieldData.move_groups_id} name={"move_groups_id[]"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.groups.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                )}

                                                                {formFieldData.Remove && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Remove Contact From Group:</label>
                                                                        <Select multiple defaultValue={formFieldData.remove_groups_id} name={"remove_groups_id[]"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.groups.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                )}

                                                                {formFieldData.AddTags && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Add Tag:</label>
                                                                        <Select multiple defaultValue={formFieldData.add_tags_id} name={"add_tags_id[]"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.tags.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                )}

                                                                {formFieldData.RemoveTags && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Remove Tag:</label>
                                                                        <Select multiple defaultValue={formFieldData.remove_tags_id} name={"remove_tags_id[]"}>
                                                                            <option value="">Select</option>
                                                                            {
                                                                                meta.tags.map((row, i) => {
                                                                                    return <option key={i} value={row.id}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                )}

                                                                {formFieldData.AddNotes && (
                                                                    <div className="form-group mb-3">
                                                                        <label>Add Notes:</label>
                                                                        <textarea value={formFieldData.notes} onChange={(e) => handleChange(e)} name="notes" className='form-control' />

                                                                    </div>
                                                                )}
                                                            </div>


                                                        </div>
                                                    </Tab.Pane>
                                                )}
                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary pull-right save-btn mt-2" >Save</button>
                                                </div>
                                            </form>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Modal.Body>
                    </Modal>

                </Block >
            </div>
        </Layout >
    )
}

export default Flow;