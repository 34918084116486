const HostName = (window.location.host == '192.168.5.88:5001' || window.location.host == '192.168.5.88:3001' || window.location.host == 'localhost:5001') ?
    'http://192.168.5.55/10xcrm/api/' :
    "https://ws.10xcrm.com/api/";

export const ApiUrl = HostName;


const HostWebUrl = (window.location.host == '192.168.5.88:5001' || window.location.host == '192.168.5.88:3001' || window.location.host == 'localhost:5001') ?
    'http://' + window.location.host :
    "https://newapp.10xcrm.com";
export const HostUrl = HostWebUrl;