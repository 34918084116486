import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';

import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media, Select } from '../../../components';
import { toInitials } from "../../../utilities";
import { sendRequest } from "../../../utilities/api";
import { countries } from "../../../store/customData";

function SubManagerEdit(props) {
    const { id } = useParams();
    const [meta, setMeta] = useState([]);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMeta();
            fetchRecordById();
        };
        fetchData();
    }, []);

    const fetchMeta = async () => {
        const formData = new FormData();
        formData.set('type', 'default');
        formData.set('user_id', id);
        await sendRequest(`users/SubManager/meta/fetch`, 'POST', formData).then(response => {
            if (response.status === true) {
                setMeta(response.data);
            }
        })
            .catch(error => {
                console.error('POST request error:', error);
            });
    }

    const fetchRecordById = async () => {
        await sendRequest(`users/SubManager/${id}`, 'POST').then(response => {
            if (response.status === true) {
                setData(response.data.list);
            }
            setLoading(true);
        })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    }


    const handleSubmit = async (event) => {
        setLoading(false);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        sendRequest(`users/SubManager/update/${id}`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    props.navigate('/user-manage/submanager/lists');
                }
                setLoading(true);
            })
            .catch(error => {
                setLoading(true);
                console.error('POST request error:', error);
            });
    };
    return (
        <Layout title="Sub Manager" content="container-fluid">
            <div className="SubManager-edit">
                <Block.Head>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Sub-Manager</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/user-manage/submanager/lists">Sub-Manager Manage</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit</li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                </Block.Head>
                <Block>

                    {
                        !loading ? (
                            <Spinner animation="grow" variant="primary"></Spinner>
                        ) : (
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <div className="bio-block">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="g-3">
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="first_name">First Name</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.first_name} type="text" name="first_name" placeholder="First Name" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.last_name} type="text" name="last_name" placeholder="Last Name" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.email} type="email" name="email" placeholder="Email" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Password</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control type="password" name="password" placeholder="Password" />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="country_code">Country Code</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Select defaultValue={data.country_code} name="country_code">
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countries.map((row, i) => {
                                                                        return <option key={i} value={row.code}>{row.label}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Phone</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control defaultValue={data.phone} type="number" name="phone" placeholder="Phone" required />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="location_id">Default Location</Form.Label>
                                                        {meta.locations && meta.locations.length > 0 ? (
                                                            <Select defaultValue={data.location_id} name="location_id">
                                                                {/* <option value="">Select Location</option> */}
                                                                {
                                                                    meta.locations.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        ) : (
                                                            <Select name="location_id">
                                                                <option value="">Select Location</option>
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="assign_location">Assign Location</Form.Label>
                                                        {meta.locations && meta.locations.length > 0 ? (
                                                            <Select defaultValue={data.locationIds} removeItemButton key={4} name="assign_location[]" multiple>
                                                                {/* <option value="">Select Location</option> */}
                                                                {
                                                                    meta.locations.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        ) : (
                                                            <Select key={5} name="assign_location">
                                                                <option value="">Select Location</option>
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="assign_calanders">Assign Calanders</Form.Label>
                                                        {meta.calanders && meta.calanders.length > 0 ? (
                                                            <Select defaultValue={data.calandersIds} removeItemButton key={8} name="assign_calanders[]" multiple>
                                                                {/* <option value="">Select Group</option> */}
                                                                {
                                                                    meta.calanders.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.title}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        ) : (
                                                            <Select key={9} name="assign_calanders">
                                                                <option value="">Select Calanders</option>
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="assign_groups">Assign Groups</Form.Label>
                                                        {meta.groups && meta.groups.length > 0 ? (
                                                            <Select defaultValue={data.groupsIds} removeItemButton key={10} name="assign_groups[]" multiple>
                                                                {/* <option value="">Select Group</option> */}
                                                                {
                                                                    meta.groups.map((row, i) => {
                                                                        return <option key={i} value={row.id}>{row.title}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        ) : (
                                                            <Select key={11} name="assign_groups">
                                                                <option value="">Select Groups</option>
                                                            </Select>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="phone">Status </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Select defaultValue={data.status} name="status">
                                                                <option value="">Select Status</option>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>

                                                            </Form.Select>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col className="col-12">
                                                    <Button type="submit" className="btn-sm">Update</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    }

                </Block >
            </div>
        </Layout >
    )
}

export default SubManagerEdit;