
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import {  Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { sendRequest } from "../../utilities/api";
import Swal from "sweetalert2";

function Outbound(props) {

    const [id] = useState(props.id);
    const [meta] = useState(props.meta);
    const [data, setData] = useState(props.data);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        sendRequest(`campaign/${id}/update`, 'POST', submitData)
            .then(response => {
                if (response.status === true) {
                    Swal.fire('Success', response.message, 'success')
                }
                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.error('POST request error:', error);
            });
    };

    return (
        <div>
            <div className="bio-block">
                <Form onSubmit={handleSubmit}>
                    <Row className="g-3">
                        <Col md="12">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="title">Maximum Dial Attempts</Form.Label>
                                <div className="form-control-wrap">
                                    <Form.Control min={0} defaultValue={data.dial_attempts} type="number" name="dial_attempts" placeholder="Dial Attempts" required />
                                </div>
                            </Form.Group>
                        </Col>
                        <Form.Control defaultValue={5} type="hidden" name="step" required />

                        <Col className="col-12">
                            <Button className="btn-sm" type="submit" variant="primary" disabled={loading}>
                                {
                                    loading && (
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    )
                                }
                                <span className="ms-1">Update</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};


export default Outbound;